import { useQuery } from 'react-query';
import { QueryKeys } from '../../config/QueryKeys';

import { requestDataGlossaryTerms } from '../../services/api/terms';
import { normalizeRemoveAccents } from '../../utils/normalizeRemoveAccents';

export interface GlossaryData {
  id: string;
  tags: string;
  title: string;
  description: string;
  source_url: string;
}

const sortByTitle = (a: GlossaryData, b: GlossaryData) => {
  if (a.title.toLowerCase() < b.title.toLowerCase()) {
    return -1;
  }
  return 1;
};

export const useGetDataGlossaryTerms = (searchText: string) => {
  const searchTextParsed = normalizeRemoveAccents(searchText.trim().toLowerCase());
  return useQuery<GlossaryData[]>(QueryKeys.getTerms, () => requestDataGlossaryTerms(), {
    refetchOnWindowFocus: false,
    select: (glossary) => {
      if (!searchTextParsed) {
        return glossary.sort(sortByTitle);
      }

      return glossary
        .filter((term) => {
          const text = normalizeRemoveAccents(JSON.stringify(term).toLowerCase());
          return text.includes(searchTextParsed);
        })
        .sort(sortByTitle);
    },
  });
};
