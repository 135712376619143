import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import QueEstaoComecando from './images/que-estao-comecando.svg';

export const WomenWhoAreStarting = () => {
  const womenTextItems = [
    {
      title: 'Aproveite cada fase do seu crescimento.',
      description:
        'Existem muitas fases até chegar onde você quer. A fase em que você está bem no comecinho e tudo é novidade, a fase em que você já começa a ganhar a maturidade e precisa aperfeiçoar aquilo que aprendeu, e por aí em diante. A verdade é que cada fase tem seu lado bom e aproveitar cada uma delas é ótimo pra um crescimento saudável.',
    },
    {
      title: 'Não existe sênior de 1 mês.',
      description:
        'Todo mundo começa do início e é normal querer crescer na carreira, mas um cargo é muito mais que um nome, é sobre o nível de preparo tanto técnico quanto comportamental e isso não se conquista da noite pro dia. Então, tenha paciência e enquanto aproveita cada fase, continue estudando e se esforçando pra chegar onde você quer.',
    },
    {
      title: 'Evite se comparar com outras mulheres.',
      description:
        'Cada um tem sua história e sua realidade. Algumas pessoas têm mais tempo livre, outras mudaram de carreira mais fácil por estarem em uma forte. Sabe aquele projeto que você fez há meses atrás? Que tal olhar pra ele e comparar com o que você fez hoje? Essa sim é uma comparação saudável.',
    },
    {
      title: 'Converse com mulheres que te inspiram.',
      description:
        'Tem mulheres incríveis por aí que já chegaram onde você quer e estão abertas pra conversar e ajudar. Que tal aproveitar isso pra aprender com a trajetória delas e usar esses aprendizados na sua carreira? Aprender a ouvir é importante.',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Para mulheres que estão começando: 🛣️
        </Typography>
        <Typography variant="body2">Estratégias pra você crescer de forma saudável.</Typography>
      </Box>
      <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <img
          src={QueEstaoComecando}
          aria-hidden="true"
          alt="Imagem ilustrativa de uma mulher sentada segurando uma seta."
          style={{ maxWidth: '100%', width: '430px', height: '300px' }}
        />
      </Stack>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={womenTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/mulheres/para-quem-lidera" />
        </Stack>
      </Box>
    </Stack>
  );
};
