import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import AnaGouvea from './images/AnaGouvea.png';
import AnaPaulaPadrao from './images/AnaPaulaPadrao.png';
import CamilaFarani from './images/CamilaFarani.png';
import DaniJunco from './images/DaniJunco.png';
import GabiOliveira from './images/GabiOliveira.png';
import JanainaMoreira from './images/JanainaMoreira.png';
import MaluDini from './images/MaluDini.png';
import NathFinancas from './images/NathFinancas.png';
import { ListOfPeople } from '../ListOfPeople';

export const WomenInspirations = () => {
  const ListOfInspirationalPeople = [
    {
      info: {
        name: 'Nath Finanças',
        description:
          'É orientadora financeira e Administradora, tem a missão de ensinar educação financeira de uma maneira fácil e prática pra quem nunca estudou ou não entende do assunto. Tem foco no público de baixa renda, sejam estudantes, estagiários, desempregados ou trabalhadores. O objetivo dela é que as pessoas saiam das dívidas e passem a usar o dinheiro da melhor forma, mantendo a saúde mental e financeira.',
      },
      image: {
        personImportName: NathFinancas,
        altText: 'Foto da Nath Finanças com a boca aberta e a mão com os dedos abertos frente a ela.',
      },
      buttons: [
        {
          label: 'Seguir no Linkedin',
          url: 'https://www.linkedin.com/in/nathfinancas/',
        },
        {
          label: 'Seguir no Instagram ',
          url: 'https://www.instagram.com/nathfinancas/?igshid=YmMyMTA2M2Y%3D',
        },
      ],
    },
    {
      info: {
        name: 'Camila Farani',
        description:
          'Investidora que acredita que o empreendedorismo muda a realidade das pessoas. Investidora na versão brasileira do maior reality de empreendedorismo do mundo, o Shark Tank do Canal Sony, e presidente da G2 Capital, uma boutique de investimento em startups e TOP VOICE no LinkedIn. Única mulher bicampeã como “Melhor Investidor Anjo” do Brasil, além de ser por 2 anos consecutivos nomeada pela LAVCA como TOP WOMEN INVESTING IN LATIN AMERICAN TECH.',
      },
      image: {
        personImportName: CamilaFarani,
        altText: 'Foto da Camila Farani com as mãos posicionadas no topo da orelha segurando o cabelo.',
      },
      buttons: [
        {
          label: 'Seguir no Linkedin',
          url: 'https://www.linkedin.com/in/camilafarani/',
        },
      ],
    },
    {
      info: {
        name: 'Ana Paula Padrão',
        description:
          'Tem 35 anos de carreira na TV, sendo 28 no jornalismo. Foi repórter, correspondente internacional, âncora de telejornal, além de editora-chefe. Passou pela Manchete, Globo, SBT, Record e Band. Além dos projetos na TV, fundou a Touareg Conteúdo (agência especializada em desenvolver conteúdo em vídeo) e a Escola de Você: ferramenta de desenvolvimento da inteligência feminina, criada para incentivar o empreendedorismo na vida e nos negócios.',
      },
      image: {
        personImportName: AnaPaulaPadrao,
        altText: 'Foto da Ana Paula Padrão com o olhar fixo para a frente.',
      },
      buttons: [
        {
          label: 'Seguir no Instagram',
          url: 'https://www.instagram.com/anapaulapadraooficial/?igshid=YmMyMTA2M2Y%3D',
        },
      ],
    },
    {
      info: {
        name: 'Gabi Oliveira',
        description:
          'Tem um canal no Youtube para conversar sobre estética negra e relações étnico-raciais com empatia e bom-humor. Em 2016, o seu canal DePretas foi vencedor do Youtube NextUp. A premiação foi entregue aos 16 canais mais promissores do ano, segundo análise feita pela equipe do Youtube Brasil.',
      },
      image: {
        personImportName: GabiOliveira,
        altText: 'Foto da Gabi Oliveira com a mão apoiada na nuca.',
      },
      buttons: [
        {
          label: 'Seguir no Instagram',
          url: 'https://www.instagram.com/gabidepretas/?igshid=YmMyMTA2M2Y%3D',
        },
      ],
    },
    {
      info: {
        name: 'Ana Gouvêa',
        description:
          'É mulher cega, trabalha com estratégia de negócio e impacto social. Atualmente, trabalha em prol da acessibilidade no PicPay. Entende que é um prazer desenvolver esse trabalho, mas também um dever enquanto pessoa cega. O objetivo é falar sobre acessibilidade para educar amigos e colegas para as boas práticas de inclusão. Tem formação em UX Writing, e  contribui com as análises de conteúdo como um todo, trazendo à tona as possíveis dificuldades de cognição por parte da população neuro divergente, com baixo letramento ou, por exemplo, com transtorno do espectro autista.',
      },
      image: {
        personImportName: AnaGouvea,
        altText: 'Foto da Ana Gouvêa sorrindo.',
      },
      buttons: [
        {
          label: 'Seguir no Linkedin',
          url: 'https://www.linkedin.com/in/aanagouvea/',
        },
      ],
    },
    {
      info: {
        name: 'Janaína Moreira',
        description:
          'Se diz apaixonada por Design de Acessibilidade e DE&I. É especialista em projetar e desenvolver soluções voltadas à construção de uma realidade mais inclusiva, diversa e acessível. Trabalha para encontrar áreas de melhoria para o negócio, conduzindo a conversa sobre acessibilidade e D&I em linguagem simples. Gerencia todos os níveis de desenvolvimento de produtos para identificar e remover barreiras ao acesso.',
      },
      image: {
        personImportName: JanainaMoreira,
        altText: 'Foto da Janaína Moreira usando óculos com o  olhar para frente.',
      },
      buttons: [
        {
          label: 'Seguir no Linkedin',
          url: 'https://www.linkedin.com/in/janainalmoreira/',
        },
      ],
    },
    {
      info: {
        name: 'Dani Junco',
        description: (
          <p>
            Conecta e capacita mães e mulheres ao ecossistema de inovação e tecnologia. CEO do B2Mamy <a href="www.b2mamy.com.br">(www.b2mamy.com.br)</a>. Atua como mentora, e se
            diz apaixonada por startups.
          </p>
        ),
      },
      image: {
        personImportName: DaniJunco,
        altText: 'Foto da Dani Junco sorrindo olhando de lado.',
      },
      buttons: [
        {
          label: 'Seguir no Linkedin',
          url: 'https://www.linkedin.com/in/danijunco/',
        },
      ],
    },
    {
      info: {
        name: 'Malu Dini',
        description:
          'Designer e pedagoga, especialista em acessibilidade pra produtos digitais. Tem experiência em processos de design que vão desde a fase de discovery/delivery, princípios de acessibilidade e design inclusivo.',
      },
      image: {
        personImportName: MaluDini,
        altText: 'Foto da Malu Dini sorrindo olhando para frente. Ela usa óculos.',
      },
      buttons: [
        {
          label: 'Seguir no Instagram',
          url: 'https://www.instagram.com/maludini/',
        },
      ],
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Inspirações de mulheres. ✨
        </Typography>
        <Typography variant="body2">Redes sociais de mulheres incríveis pra você se inspirar.</Typography>
      </Box>
      <ListOfPeople people={ListOfInspirationalPeople} />
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/mulheres/indicacao" />
        </Stack>
      </Box>
    </Stack>
  );
};
