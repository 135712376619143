import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import Capacitism from './images/illustrations/capacitism.svg';

export const BlindPeopleCapacitism = () => {
  const blindPeopleTextItems = [
    {
      title: 'O que é capacitismo?',
      description:
        'O capacitismo é uma forma de preconceito que discrimina e exclui pessoas com deficiência do mercado de trabalho e da sociedade. Ele pode acontecer de várias formas: falta de confiança, sentimento de pena, julgar incapaz, etc.',
    },
    {
      title: 'Evite revisar o trabalho da pessoa sem necessidade.',
      description:
        'Fazer isso desrespeita a capacidade profissional da pessoa e a coloca numa posição de inferior apenas por conta da deficiência dela. Revise o trabalho apenas quando necessário, caso contrário, respeite o espaço e aprenda a confiar na pessoa.',
    },
    {
      title: 'Evite desconfiar da pessoa cega. ',
      description:
        'É comum que pessoas cegas tenham que se provar capazes o tempo inteiro. Isso acontece por vários motivos, mas o principal é por diminuírem as pessoas apenas por serem cegas.',
    },
    {
      title: 'Pergunte se a pessoa precisa de ajuda antes de ajudar',
      description:
        'Antes de ajudar uma pessoa pergunte se ela quer ajuda. Caso a resposta seja negativa, aceite, respeite e não insista, caso ela aceite a sua ajuda, pergunte qual é a melhor forma de fazer isso.',
    },
    {
      title: 'Como ajudar uma pessoa cega a se deslocar. ',
      description:
        'Quando a pessoa precisar ser acompanhada em algum lugar, ajude, mas evite segurar o braço dela, ao invés disso pergunte se prefere encostar no seu ombro ou pegar no seu braço. ',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          O que é capacitismo e como evitar. 🤝
        </Typography>
        <Typography variant="body2">O time e a liderança na criação de uma rotina com respeito.</Typography>
      </Box>
      <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <img src={Capacitism} alt="Imagem ilustrativa de uma mulher mexendo em um aparelho de tecnologia." style={{ maxWidth: '430px', width: '100%' }} aria-hidden="true" />
      </Stack>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={blindPeopleTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-com-deficiencia-visual/dia-a-dia" />
        </Stack>
      </Box>
    </Stack>
  );
};
