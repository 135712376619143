import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import Autopromocao from './images/autopromocao.svg';

export const WomenSelfPromotion = () => {
  const womenTextItems = [
    {
      title: 'Valorize sua carreira. ',
      description:
        'Existem mulheres com uma carreira incrível, mas que têm medo ou vergonha  de contar sua própria história nas redes sociais ou até no trabalho. Falar sobre quem você lutou pra se tornar não é arrogância, é um direito e uma estratégia que te gera mais valor.',
    },
    {
      title: ' Compartilhe suas conquistas com o time.',
      description:
        'Se recebeu um prêmio, bateu uma meta, ou ganhou uma promoção, tudo isso merece ser comunicado. É isso que vai te posicionando no mercado e na mente das pessoas como a profissional incrível que você é, o que nos leva ao próximo tópico.',
    },
    {
      title: 'Você é ótima e as pessoas precisam saber disso.',
      description:
        'Pode parecer óbvio, mas as pessoas só saberão quem você é se você comunica isso, caso contrário suas horas de estudos e trabalho só vão ser vistas por você mesma. O LinkedIn é uma ótima forma de começar a compartilhar.',
    },
    {
      title: ' Mantenha seu portfólio atualizado!',
      description:
        'Evite esperar sair de um trabalho pra só então compartilhar suas conquistas, valorizar suas habilidades e enriquecer seu networking. Faça isso enquanto está no trabalho, assim você se mantém ativa no mercado sempre.',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Autopromoção no trabalho. 📣
        </Typography>
        <Typography variant="body2">Saber que você é boa é tão importante quanto comunicar isso.</Typography>
      </Box>
      <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <img src={Autopromocao} aria-hidden="true" alt="Ilustração de uma mulher realizando uma apresentação" style={{ maxWidth: '100%', width: '430px', height: '300px' }} />
      </Stack>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={womenTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/mulheres/nao-sao-rivais" />
        </Stack>
      </Box>
    </Stack>
  );
};
