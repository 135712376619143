export enum QueryKeys {
  postTokenByEmail = 'request-token-email',
  getValidateToken = 'validate-token',
  getProfile = 'data-profile',
  getQuestions = 'data-faq',
  getTerms = 'data-glossary',
  getFormsPreview = 'data-form-preview',
  getFormsAnswers = 'data-form-answer',
  getSurveyPreview = 'data-survey-preview',
  getSurveyAnswers = 'data-survey-answer',
  getSequenceData = 'data-sequence',
  getJourney = 'data-journey',
}
