import React from 'react';

import { AxiosError } from 'axios';
import { Typography } from '@mui/material';

export const SequenceLoadError = ({ error }: { error: unknown }) => {
  console.error('Error loading sequence. Error: ', JSON.stringify(error));
  if (error instanceof Error) {
    const axiosError = error as AxiosError;
    if (axiosError.response?.status === 401) {
      return (
        <Typography variant="body1" component="p">
          Seu link de acesso expirou. Acesso o <a href="/">portal</a> com seu email para receber um novo acesso.
        </Typography>
      );
    } else {
      return (
        <Typography variant="body1" component="p">
          Ocorreu um probleminha. Aqui alguns detalhes: {error.message}
        </Typography>
      );
    }
  }
  return (
    <Typography variant="body1" component="p">
      Ocorreu um probleminha ao carregar a trilha.
    </Typography>
  );
};
