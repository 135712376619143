import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

export const AutisticPeopleWorkEnvinroment = () => {
  const autisticPeopleTextItems = [
    {
      title: 'Fale da forma mais específica possível por textos e áudios.',
      description: (
        <>
          Sua comunicação precisa ser detalhada. Tente falar o que você realmente espera e se possível em texto ou áudio. Uma comunicação mais ampla pode ser um desafio pra pessoas
          autistas.
          <p>
            <strong>Evite dizer:</strong> vamos ter uma reunião daqui a pouco.
          </p>
          <p>Pois assim a pessoa autista pode parar o que está fazendo e esperar na frente do computador, pois ela entende que a reunião vai acontecer a qualquer momento.</p>
          <p>
            <strong>Fale assim:</strong> teremos uma reunião daqui a 15 minutos.
          </p>
        </>
      ),
    },
    {
      title: 'Saber o que é autismo é mais que conhecer pessoas.',
      description:
        'Conhecer alguém autista não significa saber tudo sobre autismo e sobre a pessoa que você convive. O autismo em adultos ainda é pouco discutido e é importante lembrar que cada pessoa autista é única. Converse com a pessoa e aprenda sobre como ela prefere que a comunicação e adaptações do dia a dia aconteçam. ',
    },
    {
      title: 'Respeite a forma como autistas trabalham.',
      description:
        'O jeito de trabalhar da pessoa autista pode ser diferente. Isso acontece por conta da forma de se organizar, produzir e comunicar. É importante que você aprenda a respeitar isso. Converse com a pessoa, pergunte se ela precisa de alguma adaptação e entrem em acordo juntos.',
    },
    {
      title: 'Inclua autistas no dia a dia.',
      description:
        'Escrever e-mails, conduzir reuniões e apresentações da forma acessível são algumas das ações que tornam a rotina mais inclusiva. Você pode ter uma comunicação simples, específica e clara sobre o que a pessoa precisa fazer.',
    },
    {
      title: 'Evite interromper uma pessoa autista.',
      description:
        'Interromper o pensamento é ruim para qualquer pessoa, mas pode ser um desafio ainda maior para uma pessoa autista. Evite interromper uma fala ou um momento de concentração. O ideal é perguntar como ela prefere que isso aconteça.',
    },
    {
      title: 'Use negritos em partes importantes do texto. ',
      description: (
        <>
          Textos de e-mail, apresentações e documentos podem ser simplificados no dia a dia. Que tal negritar palavras-chave para melhorar a leitura? Assim você evita que
          informações importantes deixem de ser lidas.
          <p>
            <strong>Ex.:</strong> você precisa terminar o <strong>treinamento</strong> de segurança <strong>até amanhã</strong> (terça-feira) às 10h.
          </p>
          <p>Perceba que eu negritei palavras específicas, isso é importante porque caso você use muito o negrito o efeito é contrário.</p>
        </>
      ),
    },
    {
      title: 'Escreva de forma simples.',
      description: (
        <>
          Isso é muito importante pro entendimento e inclusão. Pra te ajudar com isso criamos uma ferramenta exclusiva que avalia o nível de facilidade do seu texto e te retorna
          com o que pode ser ajustado pra tornar o conteúdo mais acessível.{' '}
          <a href="https://blog.inboarding.co/editor-comunicacao-inclusiva-e-acessivel/">Pra acessar a ferramenta clique aqui.</a>
        </>
      ),
    },
    {
      title: 'Empatia em momentos difíceis.',
      description:
        'Cuidado com piadas e comentários ofensivos no dia a dia e em momentos difíceis como crises. Evite questionar ou superestimar a capacidade das pessoas autistas. Tenha empatia e seja gentil, isso é importante pra que a pessoa se sinta acolhida.',
    },
    {
      title: 'Rede de apoio é importante',
      description:
        'Nem toda pessoa autista tem crises (momentos de alta sensibilidade aos estímulos visuais ou sonoros), mas se tiver é importante que ela tenha alguém de suporte pra esses momentos. É importante que a pessoa autista possa escolher quem vai apoiá-la em momentos de crise. Pergunte se ela tem interesse nesse acompanhamento.',
    },
    {
      title: 'Cordão de deficiência invisível existe',
      description:
        'Pessoas autistas têm uma deficiência invisível o que faz com que, muitas vezes, elas não sejam reconhecidas na sociedade, o que é ruim até mesmo para os direitos  Pra mudar isso, existem formas de de identificação que podem ser um cordão com símbolo de girassóis, infinito, quebra-cabeça e mais. O ideal é que você pergunte como ela prefere ser identificada.',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Ambiente de trabalho mais saudável pra pessoas autistas.
        </Typography>
        <Typography variant="body2">Boas práticas para a rotina de trabalho.</Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={autisticPeopleTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-autistas/autoconfianca" />
        </Stack>
      </Box>
    </Stack>
  );
};
