import React, { ReactNode } from 'react';

import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';

import ella from '../../assets/ella.svg';
import { AppUserContext } from '../../config/AppUserContext';

interface IEllaMessageProps {
  children: ReactNode;
}

export const EllaMessage = ({ children }: IEllaMessageProps) => {
  const userProfileData = React.useContext(AppUserContext);
  const avatarImage = userProfileData?.company?.avatar_url;
  const avatarName = userProfileData?.company?.avatar_name;

  return (
    <Paper elevation={1} sx={{ backgroundColor: '#E8F1F8', borderRadius: 4 }}>
      <div style={{ display: 'flex', alignItems: 'flex-start', margin: '10px', padding: '15px 5px' }}>
        <Avatar alt={!avatarName ? 'Ella' : avatarName} src={!avatarImage ? ella : avatarImage} sx={{ width: '3.5em', height: '3.5em' }} style={{ marginRight: '5px' }} />
        <div style={{ marginLeft: '0.5rem' }}>{children}</div>
      </div>
    </Paper>
  );
};
