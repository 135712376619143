import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';

import BeMyEyes from './images/contents/BeMyEyes1.jpg';
import EditoraRealize1 from './images/contents/EditoraRealize1.jpg';
import EditoraRealize2 from './images/contents/EditoraRealize2.jpg';
import Scielo1 from './images/contents/Scielo1.jpg';
import Scielo2 from './images/contents/Scielo2.jpg';
import TEDTalks from './images/contents/TEDTalks.jpg';
import WebParaTodos from './images/contents/WebParaTodos.jpg';
import { ListOfContents } from '../ListOfContents';

export const BlindPeopleContents = () => {
  const listOfContents = [
    {
      contentType: 'article',
      title: 'Projeto Be My Eyes: Levando a visão para pessoas cegas.',
      description: 'Texto no site: BeMyEyes',
      imageImportName: BeMyEyes,
      altText: 'Imagem da tela inicial do artigo no site BeMyEyes.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://www.bemyeyes.com/language/portuguese-brazil',
    },
    {
      contentType: 'article',
      title: 'TED: Caroline Casey: Olhando para lá dos limites',
      description: 'Texto no site: TEDTalks',
      imageImportName: TEDTalks,
      altText: 'Imagem da tela inicial do artigo no site TEDTalks.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://www.ted.com/talks/caroline_casey_looking_past_limits?language=pt',
    },
    {
      contentType: 'article',
      title: 'A pessoa cega e a inclusão: um olhar a partir da psicologia histórico-cultural',
      description: 'Texto no site: Scielo',
      imageImportName: Scielo1,
      altText: 'Imagem da tela inicial do artigo no site Scielo.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://www.scielo.br/j/ccedes/a/7YjyWBtNkLdHRRHjvfSCvpC/?lang=pt',
    },
    {
      contentType: 'video',
      title: 'Como uma pessoa cega faz uma compra online com o leitor de tela.',
      channel: 'Web Para Todos',
      videoUrl: 'https://www.youtube.com/embed/Uvd7Wbq14is',
    },
    {
      contentType: 'article',
      title: 'Barreiras digitais enfrentadas por uma pessoa com baixa visão no ambiente de trabalho',
      description: 'Texto no site: Web Para Todos',
      imageImportName: WebParaTodos,
      altText: 'Imagem da tela inicial do artigo no site Web Para Todos.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://mwpt.com.br/barreiras-digitais-enfrentadas-por-uma-pessoa-com-baixa-visao-no-ambiente-de-trabalho/',
    },
    {
      contentType: 'article',
      title: 'Gênero, raça e a inclusão de pessoas com deficiência visual',
      description: 'Texto no site: Editora Realize',
      imageImportName: EditoraRealize1,
      altText: 'Imagem da tela inicial do artigo no site Editora Realize.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://www.editorarealize.com.br/editora/anais/enlacando/2017/TRABALHO_EV072_MD1_SA23_ID1414_12082017140511.pdf',
    },
    {
      contentType: 'article',
      title: 'Gênero, raça e a inclusão de pessoas com deficiências visuais em escolas de Rio Branco/Acre',
      description: 'Texto no site: Editora Realize',
      imageImportName: EditoraRealize2,
      altText: 'Imagem da tela inicial do artigo no site Editora Realize.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://www.editorarealize.com.br/artigo/visualizar/30593',
    },
    {
      contentType: 'article',
      title: 'Nem toda pessoa cega lê em Braille nem toda pessoa surda se comunica em língua de sinais',
      description: 'Texto no site: Scielo',
      imageImportName: Scielo2,
      altText: 'Imagem da tela inicial do artigo no site Scielo.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://www.scielo.br/j/ep/a/DmVQcky9hfRjBHzdYcjmLJw/?lang=pt#:~:text=Esse%20conjunto%20de%20textos%20legais,se%20ler%20textos%20em%20Braille',
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Conteúdos que a Inboarding indica. ✨
        </Typography>
        <Typography variant="body2">Pra você ir além desse manual.</Typography>
      </Box>
      <ListOfContents contents={listOfContents} />
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
        </Stack>
      </Box>
    </Stack>
  );
};
