import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import AmbienteDeTrabalho from './images/illustrations/ambiente-de-trabalho.svg';

export const BlackPeopleWorkEnvironment = () => {
  const blackPeopleTextItems = [
    {
      title: 'Lute contra o racismo.',
      description:
        'Falar palavras racistas, calar outras pessoas negras, diminuir os esforços delas e não abrir espaço para que cresçam na empresa, são formas de tornar o ambiente nada saudável. Evitar essas ações ajuda a tornar a empresa mais justa e acolhedora pra pessoas negras.',
    },
    {
      title: 'Atitudes pra ajudar na equidade racial.',
      description:
        'Em uma sociedade racista, não adianta não ser racista, é importante que a liderança se posicione de maneira antirracista. Como você age quando vê alguma ação racista? Qual é o seu posicionamento quando alguém faz “piadas” racistas? Se pessoas brancas ainda ocupam a maior parte dos cargos de liderança, o que você planeja pra mudar isso? Estar ao lado da luta antirracista é entender que isso é algo estrutural e que as mudanças acontecem a longo prazo, mas que começam com ações no dia a dia.',
    },
    {
      title: 'Pare de falar expressões racistas.',
      description: (
        <>
          <p>
            Falar palavras racistas é algo que faz parte do dia a dia da maioria das pessoas. Pra te ajudar a melhorar isso, selecionamos algumas expressões racistas que você pode
            evitar a partir de agora:
          </p>{' '}
          <ul>
            <li>
              <strong>Denegrir:</strong> sinônimo de difamar e caluniar. Significa “tornar negro” e é usada associando a ideia de que “enegrecer” alguém ou alguma coisa é ruim.
            </li>
            <li>
              <strong>A coisa tá preta:</strong> o termo diz que a palavra “preto” significa algo desconfortável, ruim, difícil, ou perigoso. Que tal usar “a coisa tá difícil”?
            </li>
            <li>
              <strong>Nhaca:</strong> é usado como sinônimo de mal cheiro, mas “Inhaca” é o nome de uma Ilha de Maputo que fica na região sul da África. A expressão é racista por
              associar pessoas negras a um forte odor.
            </li>
            <li>
              <strong>Doméstica:</strong> surgiu na época da escravidão com a ideia de que mulheres escravizadas que serviam dentro da casa eram “domesticadas” para o serviço.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: 'O racismo reverso não existe.',
      description:
        'Algumas pessoas acreditam que "racismo reverso" existe com o argumento de que pessoas negras falam ofensas racistas contra pessoas brancas, mas isso não é racismo. Racismo vai além de ofensas verbais, ele exclui, violenta e oprime pessoas negras há séculos. A nossa história mostra o quanto pessoas negras foram excluídas da sociedade.',
    },
    {
      title: 'Acolha mães negras no trabalho.',
      description:
        'A maternidade entre mulheres negras precisa ser pauta antirracista. Dados do IBGE de 2020 apontam que o Brasil tem mais de 11,4 milhões de famílias formadas por mães solteiras, e a grande maioria delas é negra (7,4 milhões). Falar sobre maternidade negra é falar na necessidade de superarmos a pobreza, solidão, abandono, racismo, desemprego e a falta de humanização dessas mulheres.',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Criando um ambiente de trabalho mais saudável pra pessoas negras. 👋
        </Typography>
        <Typography variant="body2">Uma conversa sobre racismo, oportunidades e crescimento.</Typography>
      </Box>
      <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <img
          src={AmbienteDeTrabalho}
          aria-hidden="true"
          alt="Ilustração com três colegas de trabalho discutindo ideias"
          style={{ maxWidth: '100%', width: '430px', height: '300px' }}
        />
      </Stack>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={blackPeopleTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-negras/autoestima" />
        </Stack>
      </Box>
    </Stack>
  );
};
