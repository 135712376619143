import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import { ListOfPeople } from '../ListOfPeople';

export const PwPDIncredibleAchievements = () => {
  const ListOfPeopleAndAchievements = [
    {
      info: {
        name: 'Frida Kahlo',
        description: (
          <>
            Magdalena Carmen Frida Kahlo y Calderón nasceu em Coyoacán, no México, em 6 de julho de 1907. Frida sofreu de poliomielite aos seis anos e, com isso, teve sequelas em
            uma de suas pernas.
            <p>
              Foi uma pintora que retratava aspectos íntimos e femininos, como abortos e feminicídios, algo considerado um tabu na época. Aos 18 anos, sofreu um acidente onde foi
              atingida por uma barra de ferro, ocorrendo uma tríplice fratura na região pélvica e atingindo sua coluna vertebral. Após a cirurgia, ficou 7 meses com o corpo
              engessado e, assim, apenas com os pés e as mãos livres, nasceu a pintora.
            </p>
          </>
        ),
      },
      buttons: [],
    },
    {
      info: {
        name: 'Maria da Penha',
        description: (
          <>
            Maria da Penha Maia Fernandes nasceu em Fortaleza-CE, no dia 1º de fevereiro de 1945. É farmacêutica bioquímica e se formou na Faculdade de Farmácia e Bioquímica da
            Universidade Federal do Ceará em 1966, tendo concluído o seu mestrado em Parasitologia em Análises Clínicas na Faculdade de Ciências Farmacêuticas da Universidade de
            São Paulo em 1977.
            <p>
              Vítima da violência doméstica, Maria da Penha lutou para que seu agressor fosse condenado. Autora do livro “Sobrevivi... posso contar” (1994) e fundadora do Instituto
              Maria da Penha (2009), ela fala sobre a sua experiência, dá palestras e luta contra a impunidade dessa violência que afeta milhares de mulheres, adolescentes e
              meninas em todo o mundo.
            </p>
          </>
        ),
      },
      buttons: [],
    },
    {
      info: {
        name: 'Fernando Fernandes',
        description: (
          <>
            É um apresentador e atleta paralímpico brasileiro e desbravador dos esportes adaptados. Atleta desde a infância, foi jogador de futebol profissional, boxeador amador,
            modelo internacional estrelando campanhas como Dolce & Gabbana por Mario Testino e Abercrombie & Fitch por Bruce Weber e participou do reality show Big Brother Brasil 2
            (2002) da Rede Globo.
            <p>
              No mundo Paralímpico se consagrou como tetracampeão Mundial (2009, 2010, 2011 e 2012), Tricampeão Panamericano, Tetracampeão Sul-americano e Tetracampeão Brasileiro
              de Paracanoagem.
            </p>
          </>
        ),
      },
      buttons: [],
    },
    {
      info: {
        name: 'Flávia Cintra',
        description: (
          <>
            Flávia sofreu um acidente de carro aos 18 anos que a deixou tetraplégica. É jornalista e palestrante, é ativista pelos direitos da PcD. Trabalha na TV Globo desde 2010
            como repórter.
            <p>
              Escreveu o livro Maria de Rodas para compartilhar suas experiências com a maternidade. Foi vice-presidente do Instituto Paradigma e membro do comitê Ad Hoc, criado
              pela ONU para elaboração do conteúdo da Convenção sobre os Direitos da Pessoa com Deficiência, aprovada em 2007.
            </p>
          </>
        ),
      },
      buttons: [],
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Conquistas incríveis. 🏆
        </Typography>
        <Typography variant="body2">Uma lista de pessoas com deficiencia física que fizeram história.</Typography>
      </Box>
      <ListOfPeople people={ListOfPeopleAndAchievements} />
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-com-deficiencia-fisica/datas-importantes" />
        </Stack>
      </Box>
    </Stack>
  );
};
