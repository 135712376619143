/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { List, ListItemButton, ListItemText } from '@mui/material';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { useNavigate } from 'react-router-dom';
import { ModalTextSecondaryButton } from '../ModalTextSecondaryButton';

export const DiversityAutisticPeople = () => {
  const modalText = (
    <>
      <p style={{ textAlign: 'justify' }}>
        O motivo de aparentar haver menos autistas negros do que brancos, é o racismo. Pessoas negras normalmente são erroneamente diagnosticadas com qualquer tipo de transtorno
        mental ou doença, menos autismo.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Um aspecto que deve ser ressaltado é que pessoas negras são diagnosticadas mais tarde e com menos frequência. De acordo com o Center for Disease Control (Centro de Controle
        de Doenças - órgão dos EUA responsável por centralizar pesquisas na área de saúde), crianças brancas possuem cerca de 30% mais chances de serem diagnosticadas com
        Transtorno do Espectro do Autismo do que crianças negras.
      </p>
      <p style={{ textAlign: 'justify' }}>
        Após diversos estudos e pesquisas, foi possível identificar que as pesquisas sobre autismo geralmente se concentram nas necessidades de pessoas brancas. Isso gera inúmeros
        impactos, inclusive a escassez de diagnósticos dentro deste grupo.
      </p>
    </>
  );

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Pessoas autistas.
        </Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1, mt: 2, textAlign: 'justify' }}>
        <Typography variant="body1" component="p" sx={{ mb: 1 }}>
          O Transtorno do Espectro Autista (TEA) é considerado uma deficiência e se ampara na Lei N° 12.764/2012. O autismo é um espectro, mas não significa que seja linear como
          uma régua onde pessoas são “mais” ou “menos” autistas. O autismo também se caracteriza por comportamentos diversos na comunicação e na interação social.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mb: 1 }}>
          No Brasil, há cerca de <strong>2 milhões de autistas</strong>, segundo dados do Instituto Brasileiro de Geografia e Estatística (IBGE). Para comprovar esse número, o
          IBGE, colocou pela primeira vez o autismo no radar das estatísticas, com o objetivo de mapear quantas pessoas vivem com o espectro e quantas podem ter, mas ainda não
          receberam o diagnóstico.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mb: 1 }}>
          Sobre o mercado de trabalho, de acordo com o levantamento de dados realizado pelo IBGE, 85% das pessoas autistas estão desempregadas. Quando falamos de ambiente de
          trabalho e pessoas autistas, é importante pensarmos o quão importante é realizar ações individualizadas, voltadas para as características de cada pessoa para que ela
          possa se desenvolver totalmente, de acordo com o possível.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mb: 1 }}>
          Pessoas autistas não são iguais, elas podem ter demandas diferentes em diversos contextos.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mb: 2 }}>
          Você já deve ter ouvido a frase: &quot;Eu conheço uma pessoa autista de grau leve.&quot; Porém, não existe autismo de grau leve, moderado ou severo. Essa definição é
          errada, apesar de ser usada por muita gente.
        </Typography>
        <Typography variant="body1" component="p">
          <strong>É mais correto pensar em definições como:</strong>
          <ul>
            <li>pouca necessidade de suporte;</li>
            <li>necessidade moderada de suporte;</li>
            <li>necessidade substancial de suporte.</li>
          </ul>
        </Typography>
        <Typography variant="body1" component="p" sx={{ mb: 1 }}>
          Essa necessidade de ajuda varia de acordo com as características e níveis do autismo.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mb: 1 }}>
          Você já parou pra pensar por que parece que o autismo é um transtorno mais que acontece mais em pessoas brancas do que em <strong>pessoas negras?</strong>
          <ModalTextSecondaryButton title="Existem razões pra isso, conheça aqui." description={modalText} />
        </Typography>
        <Typography variant="body1" component="p" sx={{ mb: 1 }}>
          Pensando nisso, a equipe da Inboarding criou esse manual pra te ajudar a tornar o ambiente de trabalho da sua empresa mais saudável e favorável ao crescimento de pessoas
          autistas.{' '}
        </Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1, mt: 2 }}>
        <Typography variant="h6" component="h3">
          Vamos conversar sobre
        </Typography>
        <AutisticPersonTopics />
      </Box>
    </Stack>
  );
};

export const TopiceMenuListItem = ({ title, text, handerClick }: { title: string; text: string; handerClick: () => void }) => {
  return (
    <ListItemButton disableGutters={true} onClick={handerClick} sx={{ p: 1 }}>
      <ListItemText primary={title} secondary={text} />
      <ArrowForwardIosIcon sx={{ fontSize: 15 }} />
    </ListItemButton>
  );
};

const AutisticPersonTopics = () => {
  const navigate = useNavigate();
  const handlerRoutePage = (path: string) => navigate(path);

  return (
    <>
      <List component="nav">
        <TopiceMenuListItem
          title="Aprenda a ser acessível antes e depois do sim."
          text="Entrevistas e onboarding para trabalho remoto ou presencial."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-autistas/onboarding-acessivel');
          }}
        />
        <TopiceMenuListItem
          title="Ambiente de trabalho mais saudável pra pessoas com autismo."
          text="Boas práticas para a rotina de trabalho."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-autistas/ambiente-de-trabalho');
          }}
        />
        <TopiceMenuListItem
          title="Aprendendo a ter mais autoconfiança"
          text="Como as pessoas autistas podem ser mais confiantes."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-autistas/autoconfianca');
          }}
        />
        <TopiceMenuListItem
          title="Uma conversa com quem lidera pessoas com autismo."
          text="Como criar oportunidades reais e justas."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-autistas/para-lideres');
          }}
        />
        <TopiceMenuListItem
          title="Inspirações de pessoas com autismo."
          text="Redes sociais de pessoas autistas pra você se inspirar todos os dias."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-autistas/inspiracoes');
          }}
        />
        <TopiceMenuListItem
          title="Datas importantes."
          text="Datas que valorizam e marcam a história de pessoas autistas."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-autistas/datas-importantes');
          }}
        />
        <TopiceMenuListItem
          title="Conteúdos que a Inboarding indica"
          text="Pra você ir além desse manual."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-autistas/conteudos');
          }}
        />
      </List>
    </>
  );
};
