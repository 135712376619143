import React from 'react';

import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop, convertToPixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import { Avatar, Button } from '@mui/material';

import { UserProfileData } from '../../hooks/queries';
import { LoadingButton } from '@mui/lab';
import { FileUpload } from '@mui/icons-material';
import { uploadPhotoProfile } from '../../services/api';
import { useDebounceEffect } from '../../utils/useDebounceEffect';

const TO_RADIANS = Math.PI / 180;

async function canvasPreview(image: HTMLImageElement, canvas: HTMLCanvasElement, crop: PixelCrop, scale = 1, rotate = 0): Promise<void> {
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, image.naturalWidth, image.naturalHeight);

  ctx.restore();
}

// This is to demonstate how to make and center a % aspect crop. which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number): Crop {
  return centerCrop(makeAspectCrop({ unit: '%', width: 100 }, aspect, mediaWidth, mediaHeight), mediaWidth, mediaHeight);
}

interface IProfileAvatarImageProps {
  profile: UserProfileData;
  setMessageAlert: (message: string) => void;
  setMessageSuccess: (message: string) => void;
}

export const ProfileAvatarImage = ({ profile, setMessageAlert, setMessageSuccess }: IProfileAvatarImageProps): JSX.Element => {
  const [isSavingImg, setIsSavingImg] = React.useState<boolean>(false);
  const [savedPhotoImgSrc, setSavedPhotoImgSrc] = React.useState<string>(profile.photo_url || '');
  const [photoImgSrc, setPhotoImgSrc] = React.useState<string>('');
  const [photoCrop, setPhotoCrop] = React.useState<Crop>();
  const [completedCrop, setCompletedCrop] = React.useState<PixelCrop>();
  const photoPreviewCanvasRef = React.useRef<HTMLCanvasElement>(null);
  const photoImgRef = React.useRef<HTMLImageElement>(null);
  const aspect = 1; // square
  const scale = 1; // full size
  const rotate = 0;

  const isEditingPhoto = Boolean(photoImgSrc);

  function onCancelPhotoUpdate(): void {
    setPhotoImgSrc('');
    setCompletedCrop(undefined);
    setPhotoCrop(undefined);
    setIsSavingImg(false);
  }

  function onSelectPhotoFile(e: React.ChangeEvent<HTMLInputElement>): void {
    if (e.target.files && e.target.files.length > 0) {
      setPhotoCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener('load', () => setPhotoImgSrc(reader?.result ? reader.result.toString() || '' : ''));
      reader.readAsDataURL(e.target.files[0]);
    }
    e.target.value = ''; // Clear to fix erro when select the same file the second time
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>): void {
    const { width, height } = e.currentTarget;
    const initialCrop = centerAspectCrop(width, height, aspect);
    setPhotoCrop(initialCrop);
    setCompletedCrop(convertToPixelCrop(initialCrop, width, height));
  }

  async function photoDataURItoBlob(dataURI: string): Promise<Blob> {
    const blob = await (await fetch(dataURI)).blob();
    return blob;
  }

  async function onSavePhoto(): Promise<void> {
    setIsSavingImg(true);
    if (!photoPreviewCanvasRef?.current) {
      setMessageAlert('Imagem inválida para ser enviada.');
      return;
    }
    const photoSrcCanvas = photoPreviewCanvasRef.current.toDataURL();
    const photoBlob = await photoDataURItoBlob(photoSrcCanvas);
    const result = await uploadPhotoProfile(profile, photoBlob);
    if (!result) {
      console.error('Error on sending profile picture.');
      setMessageAlert('Ocorreu um probleminha ao enviar a sua foto. Por favor tente novamente.');
      onCancelPhotoUpdate();
      return;
    }

    setTimeout((): void => {
      const photoSrc = photoPreviewCanvasRef?.current?.toDataURL('image/jpeg', 0.8);
      if (photoSrc) {
        setSavedPhotoImgSrc(photoSrc);
      }
      onCancelPhotoUpdate();
      setMessageSuccess('Foto do perfil atualizada com sucesso.');
    }, 1000);
  }

  useDebounceEffect(
    async () => {
      if (completedCrop?.width && completedCrop?.height && photoImgRef.current && photoPreviewCanvasRef.current) {
        canvasPreview(photoImgRef.current, photoPreviewCanvasRef.current, completedCrop, scale, rotate);
      }
    },
    100,
    [completedCrop, scale, rotate],
  );

  return (
    <>
      <label htmlFor="photo_url_file" style={{ cursor: 'pointer' }}>
        <Avatar
          alt={profile.first_name || ''}
          src={savedPhotoImgSrc || ''}
          sx={{ width: isEditingPhoto ? 90 : 150, height: isEditingPhoto ? 90 : 150, mt: 3, mb: 1 }}
          color="primary"
          title="Clique aqui para enviar uma nova foto."
        />
        <Button
          disabled={isEditingPhoto}
          component="a"
          size="small"
          variant="outlined"
          startIcon={<FileUpload fontSize="inherit" />}
          aria-label="Clique aqui para enviar um nova foto"
          sx={{ ml: 2, mb: 2 }}
          style={isEditingPhoto ? { display: 'none' } : {}}
        >
          Editar Foto
        </Button>
        <input type="file" id="photo_url_file" style={{ display: 'none' }} accept="image/*" onChange={onSelectPhotoFile} />
      </label>
      {isEditingPhoto && (
        <div style={{ padding: '1rem' }}>
          <div style={{ maxWidth: '200px' }}>
            <ReactCrop
              crop={photoCrop}
              onChange={(_, percentCrop): void => setPhotoCrop(percentCrop)}
              onComplete={(c): void => setCompletedCrop(c)}
              aspect={aspect}
              circularCrop={true}
            >
              <img ref={photoImgRef} alt="Edite e recorte a sua foto." src={photoImgSrc} style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }} onLoad={onImageLoad} />
            </ReactCrop>
          </div>
          <LoadingButton variant="contained" onClick={onSavePhoto} loading={!!isSavingImg}>
            Confirmar Foto
          </LoadingButton>
          <Button onClick={onCancelPhotoUpdate} disabled={!!isSavingImg} sx={{ ml: 2 }}>
            Cancelar
          </Button>
        </div>
      )}
      <div>
        {Boolean(completedCrop) && (
          <canvas
            ref={photoPreviewCanvasRef}
            style={{
              display: 'none',
              objectFit: 'contain',
              width: completedCrop?.width,
              height: completedCrop?.height,
            }}
            aria-hidden={true}
          />
        )}
      </div>
    </>
  );
};
