import { useMutation } from 'react-query';

import { updateDataDraftForm, updateDataForm } from '../../services/api/form-data';

export interface FormAnswerData {
  id: string;
  label: string;
  description: string;
  value: string;
  blockFieldType: string;
}

export type FormAnswersData = FormAnswerData[];

export const useFormAnswerUpdate = () => {
  return useMutation(updateDataForm, {});
};

export const useFormDraftUpdate = () => {
  return useMutation(updateDataDraftForm, {});
};
