import { FormAnswersData } from '../../hooks/queries/useFormAnswerUpdate';
import { API } from './fetcher';

export const getFormDataPreview = async (journeyId: string, stepId: string) => {
  const { data, status } = await API.get(`/forms/preview/${journeyId}/${stepId}`);
  return { data, status };
};

export const getFormDataQuestions = async (shortId: string) => {
  const { data, status } = await API.get(`/forms/${shortId}`);
  return { data, status };
};

export const updateDataForm = async ({ shortId, answers }: { shortId: string; answers: FormAnswersData }) => {
  try {
    const payload = {
      data: answers,
    };
    const { data, status } = await API.post(`/forms/${shortId}`, payload);
    return { data, status };
  } catch (error) {
    return { error };
  }
};

export const updateDataDraftForm = async ({ shortId, answers }: { shortId: string; answers: FormAnswersData }) => {
  try {
    const payload = {
      data: answers,
    };
    const { data, status } = await API.put(`/forms/draft/${shortId}`, payload);
    return { data, status };
  } catch (error) {
    return { error };
  }
};
