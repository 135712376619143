import React, { useState } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// import HelpIcon from '@mui/icons-material/Help';
import PersonIcon from '@mui/icons-material/Person';
import ExploreIcon from '@mui/icons-material/Explore';
import ArticleIcon from '@mui/icons-material/Article';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import SummarizeIcon from '@mui/icons-material/Summarize';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { AppDialog } from '../../components';
import { CardProfile } from './CardProfile';

import { removeStorangeToken } from '../../utils';
import { AppUserContext } from '../../config/AppUserContext';
import { FooterInboarding } from '../../components/FooterInboarding';

export const ProfileMenuListItem = ({ icon, text, handerClick }: { icon: JSX.Element; text: string; handerClick: () => void }) => {
  return (
    <ListItemButton disableGutters={true} onClick={handerClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
      <ArrowForwardIosIcon sx={{ fontSize: 15 }} />
    </ListItemButton>
  );
};

export const ProfileMenu = () => {
  const navigate = useNavigate();
  const [isAboutAppOpen, setIsAboutAppOpen] = useState(false);
  const userProfileData = React.useContext(AppUserContext);

  const logoutApp = () => {
    navigate('/login');
    removeStorangeToken();
  };

  const openProfileEdit = () => {
    navigate('/perfil/editar');
  };

  const openGlossaryTerms = () => {
    navigate('/termos');
  };

  const openQuestionsFaq = () => {
    navigate('/faq');
  };

  const openJourney = () => {
    navigate('/jornada');
  };

  const handleOpenAboutApp = () => {
    setIsAboutAppOpen(true);
  };

  const handleCloseAboutApp = () => {
    setIsAboutAppOpen(false);
  };

  // if (isError) {
  //   return <ErrorMessage error={error} />;
  // }

  return (
    <Stack sx={{ pt: 2, pb: 1 }}>
      <Box sx={{ pl: 1 }}>
        <Typography variant="h5" color="primary">
          Meu perfil
        </Typography>
      </Box>
      <Box>
        <CardProfile data={userProfileData} openProfileEdit={openProfileEdit} />
      </Box>
      <Box sx={{ py: 4 }}>
        {/* <CardItem title={'Meus dados'} bgColor={'#fff'} icon={<PersonIcon />} /> */}
        <List component="nav" sx={{ pt: 2 }}>
          <ProfileMenuListItem icon={<PersonIcon />} text="Meus dados" handerClick={openProfileEdit} />
          {/* <ProfileMenuListItem
            icon={<HelpIcon />}
            text="Preciso de ajuda"
            handerClick={(): void => {
              ('');
            }}
          /> */}
          <ProfileMenuListItem icon={<ExploreIcon />} text="Minha jornada" handerClick={openJourney} />
          <ProfileMenuListItem icon={<ArticleIcon />} text="Glossário" handerClick={openGlossaryTerms} />
          <ProfileMenuListItem icon={<LiveHelpIcon />} text="Perguntas frequentes" handerClick={openQuestionsFaq} />
          <ProfileMenuListItem icon={<SummarizeIcon />} text="Sobre" handerClick={handleOpenAboutApp} />
          <ProfileMenuListItem icon={<CancelIcon />} text="Sair do aplicativo" handerClick={logoutApp} />
        </List>
      </Box>
      <AppDialog title="Sobre o Aplicativo" open={isAboutAppOpen} onClose={handleCloseAboutApp}>
        <Typography gutterBottom>Versão do applicativo: {BUILD_PACKAGE_VERSION}</Typography>
        <Typography gutterBottom>Versão de build: {new Date(BUILD_VERSION_DATETIME).toLocaleString()}</Typography>
        <Typography gutterBottom>Ambiente: API {BUILD_APP_ENV}</Typography>
        <Typography gutterBottom>
          Termo e politíca de uso:{' '}
          <a href="https://www.inboarding.co/termos/" target="_blank" rel="noreferrer">
            acessar aqui
          </a>
        </Typography>
        <Typography gutterBottom>© Inboarding Tecnologia LTDA</Typography>
      </AppDialog>
      <Box style={{ width: '100%' }}>
        <FooterInboarding divider={false} />
      </Box>
    </Stack>
  );
};
