import React, { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Container, Box, Typography } from '@mui/material';

import undrawConfirmed from '../../assets/undrawConfirmed.svg';

import { Loading, TopToolbar } from '../../components';

import { useGetValidateTokenQuery } from '../../hooks/queries';
import { setStorangeToken } from '../../utils';
import { getStorangeAuthUser } from '../../utils/authUserStorange';
import { FooterInboarding } from '../../components/FooterInboarding';

export const WelcomePortal = () => {
  const navigate = useLocation();
  const [searchParams] = useSearchParams();
  const userProfileData = getStorangeAuthUser();
  const { mutateAsync: validateToken } = useGetValidateTokenQuery();

  const extractToken = () => {
    const token: string | null = searchParams.get('token');
    return setStorangeToken(token || null);
  };

  useEffect(() => {
    extractToken();
    validateToken();
  }, [navigate]);

  return (
    <>
      <Container
        disableGutters
        maxWidth="sm"
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <TopToolbar logoUrl={userProfileData?.company?.logo_url} logoName={userProfileData?.company?.name} />
        <Box
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifySelf: 'center',
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <img style={{ maxHeight: '14em' }} src={undrawConfirmed} alt="mail-sent" />
          <Typography variant="h6">Eba, você está indo pro Portal do Colaborador!</Typography>
          <Box>
            <Loading title="Só 1 minuto, seu código está sendo validado." />
          </Box>
        </Box>
      </Container>
      <FooterInboarding />
    </>
  );
};
