import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';

import Capacitismo from './images/contents/Capacitismo.jpg';
import CorposDeficientes from './images/contents/CorposDeficientes.jpg';
import Movimento from './images/contents/Movimento.jpg';

import { ListOfContents } from '../ListOfContents';

export const PwPDContents = () => {
  const listOfContents = [
    {
      contentType: 'video',
      title: 'A diferença não é um defeito | Lorrane Silva | TEDx',
      channel: 'TEDx Talks',
      videoUrl: 'https://www.youtube.com/embed/JkrO5iYbN5Y',
    },
    {
      contentType: 'video',
      title: 'A eficiência na deficiência: tipos de movimento | Nathalia Blagevitch | TEDx',
      channel: 'TEDx Talks',
      videoUrl: 'https://www.youtube.com/embed/k8_vgZvF8LA',
    },
    {
      contentType: 'video',
      title: 'A Inclusão do Deficiente no Mercado de Trabalho | Ana Silvia Marcatto Begalli | TEDxUnicampLive',
      channel: 'TEDx Talks',
      videoUrl: 'https://www.youtube.com/embed/zNLg26tL-xE',
    },
    {
      contentType: 'video',
      title: 'Inclusão e acessibilidade: Flávio Arruda at TEDxFortaleza',
      channel: 'TEDx Talks',
      videoUrl: 'https://www.youtube.com/embed/-bgA4r-Qnbg',
    },
    {
      contentType: 'video',
      title: 'O Filho do Vizinho - Curta-Metragem',
      channel: 'Alex Vidigal',
      videoUrl: 'https://www.youtube.com/embed/I-UMVQ9uSIc',
    },
    {
      contentType: 'article',
      title: 'Corpos deficientes, eficientes e diferentes: uma visão a partir da educação física',
      description: 'Autores: Andréa Rechineli, Eline Tereza Rozante Porto, Wagner Wey Moreira',
      imageImportName: CorposDeficientes,
      altText: 'Imagem da tela inicial do artigo no site Scielo.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://www.scielo.br/j/rbee/a/Kd3GKHzFrsTNBrrFGwbXSGx/?lang=pt',
    },
    {
      contentType: 'article',
      title: 'Capacitismo e os desafios das pessoas com deficiência',
      description: 'Texto no site: Politize',
      imageImportName: Capacitismo,
      altText: 'Imagem da tela inicial do artigo no site Politize.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://www.politize.com.br/equidade/blogpost/capacitismo-e-os-desafios-das-pessoas-com-deficiencia/',
    },
    {
      contentType: 'article',
      title: 'MOVIMENTO POR VIDAS NEGRAS ANTIRRACISTA e ANTICAPACITISTA',
      description: 'Texto no site: Vidas Negras com Deficiência',
      imageImportName: Movimento,
      altText: 'Imagem da tela inicial do artigo no site Vidas Negras com Deficiência.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://vidasnegrascomdeficiencia.org/quem-somos',
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Conteúdos que a Inboarding indica. ✨
        </Typography>
        <Typography variant="body2">Pra você ir além desse manual.</Typography>
      </Box>
      <ListOfContents contents={listOfContents} />
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
        </Stack>
      </Box>
    </Stack>
  );
};
