import React from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { DateFormatted, EllaMessage } from '../../components';

import { DashboardActions } from './DashboardActions';
import { AppUserContext } from '../../config/AppUserContext';
import { UserProfileData } from '../../hooks/queries/useProfileQuery';
import { LastJourneyMessage } from './LastJourneyMessage';

function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export const Dashboard = () => {
  const userProfileData = React.useContext(AppUserContext);
  const firstDay = userProfileData?.start_date;
  const firstDayPlusSevenDays = addDays(new Date(firstDay as string), 7);
  const todayDate = new Date();

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1 }}>
        <DashboardWelcomeMessage userProfileData={userProfileData} />
      </Box>
      {firstDay && todayDate <= firstDayPlusSevenDays && (
        <Box sx={{ pl: 1, mt: 3 }}>
          <Typography variant="subtitle2">
            <strong>Seu primeiro dia</strong>
          </Typography>
          <Typography variant="body2">
            <DateFormatted dateISO={firstDay} />
          </Typography>
        </Box>
      )}
      <Box sx={{ pl: 1, mt: 3 }}>
        <Typography variant="subtitle2">
          <strong>Ações</strong>
        </Typography>
        <Box sx={{ pt: 2 }}>
          <DashboardActions />
        </Box>
      </Box>
    </Stack>
  );
};

function DashboardWelcomeMessage({ userProfileData }: { userProfileData: UserProfileData }): JSX.Element {
  if (userProfileData && userProfileData.last_journey_step_sent?.step_name) {
    return <LastJourneyMessage userProfileData={userProfileData}></LastJourneyMessage>;
  }

  return (
    <EllaMessage>
      <>
        <Typography variant="h6" color="primary">
          Olá, {userProfileData?.short_name || userProfileData?.first_name}! 👋
        </Typography>
        <Typography variant="body2">Aqui você encontra materiais de apoio, acompanha suas tarefas e toda a sua jornada na empresa. Vamos começar?</Typography>
      </>
    </EllaMessage>
  );
}
