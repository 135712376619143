import type { FormBlocks } from '@quillforms/types';

export const sampleQuillFormBlocks = [
  {
    name: 'welcome-screen',
    id: 'jg1401r',
    attributes: {
      label: 'Boas vindas ao formulário de exemplo',
      description: 'Aqui é apenas uma descrição do formulário exemplo para você visualizar como é exibido para os usuário.',
      buttonText: 'Vamos começar!',
      attachment: {
        type: 'image',
        url: 'https://quillforms.com/wp-content/uploads/2022/01/4207-ai-1.jpeg',
      },
    },
  },
  {
    name: 'inboarding-survey-options',
    id: 'survey1234',
    attributes: {
      required: true,
      label: 'Como foi sua experiência com os treinamentos?',
      defaultValue: '4 - Bom 😃',
      choices: [
        {
          label: 'Muito bom 😍',
          value: '5 - Muito bom 😍',
        },
        {
          label: 'Bom 😃',
          value: '4 - Bom 😃',
        },
        {
          label: 'Regular 😐',
          value: '3 - Regular 😐',
        },
        {
          label: 'Ruim 😞',
          value: '2 - Ruim 😞',
        },
        {
          label: 'Muito Ruim 😖',
          value: '1 - Muito Ruim 😖',
        },
      ],
    },
  },
  {
    name: 'long-text',
    id: 'kd12edg',
    attributes: {
      required: true,
      label: 'Vamos começar com o seu nome',
      description: 'Descrição do campo aqui, exibido abaixo do campo',
    },
  },
  {
    name: 'statement',
    id: 'fd12e12s',
    attributes: {
      buttonText: 'Continuar',
      label: 'Aqui um título de uma sessão',
      description: 'Aqui é apenas uma descrição da sessão',
      quotationMarks: false,
    },
  },
  {
    name: 'statement',
    id: 'fd12e13s',
    attributes: {
      buttonText: 'Continuar',
      label: 'Aqui um título de uma sessão com imagem',
      description: 'Aqui é apenas uma descrição da sessão com imagem',
      quotationMarks: false,
      attachment: {
        type: 'image',
        url: 'https://quillforms.com/wp-content/uploads/2022/01/4207-ai-1.jpeg',
      },
    },
  },
  {
    name: 'inboarding-profile-picture',
    id: 'td14e15s',
    attributes: {
      buttonText: 'Continuar',
      label: 'Aqui um campo para fazer upload tha imagem do perfil',
      description: 'Aqui é apenas uma descrição com instruções.',
      peopleId: '1234',
      required: true,
    },
  },
  {
    name: 'multiple-choice',
    id: 'gqr1294c',
    attributes: {
      required: true,
      multiple: false,
      verticalAlign: false,
      label: 'Qual opção você tem mais interesse?',
      description: 'Aqui uma descrição e permite adicionar uma imagem também',
      // attachment: {
      //   type: 'image',
      //   url: 'https://quillforms.com/wp-content/uploads/2022/01/4207-ai-1.jpeg',
      // },
      choices: [
        {
          label: 'Physics',
          value: 'physics',
        },
        {
          label: 'Math',
          value: 'math',
        },
        {
          label: 'English',
          value: 'english',
        },
        {
          label: 'Biology',
          value: 'biology',
        },
      ],
    },
  },
  {
    name: 'multiple-choice',
    id: 'hqr1292c',
    attributes: {
      required: true,
      multiple: false,
      verticalAlign: true,
      label: 'Você comemora o seu aniversário?',
      description: 'Aqui uma descrição ',
      choices: [
        {
          label: 'Sim',
          value: 'Sim',
        },
        {
          label: 'Não',
          value: 'Não',
        },
      ],
    },
  },
] as FormBlocks;
