import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import { ListOfPeople } from '../ListOfPeople';

export const PwPDInspirations = () => {
  const ListOfInspirationalPeople = [
    {
      info: {
        name: 'Marsha Elle',
        description:
          'Nasceu com uma deficiência no fêmoro que a levou a amputar a perna direita ainda na infância. Ela tem 25 anos e faz sucesso no Instagram com suas frases motivacionais e fotos inspiradoras. Atualmente é cantora, modelo, influencer e palestrante sobre: inclusão, diversidade e autoestima de pessoas com deficiência.',
      },
      buttons: [
        {
          label: 'Siga Marsha no Instagram',
          url: ' https://www.instagram.com/marshaellemusic/',
        },
      ],
    },
    {
      info: {
        name: 'Ana Clara Moniz',
        description: (
          <>
            Aborda em suas redes sociais assuntos relacionados à atrofia muscular espinhal (AME), como autoestima, capacitismo, acessibilidade e representatividade.
            <p>
              A doença que ela convive, desde um ano de idade, é genética, degenerativa e afeta todos os músculos do corpo. Não existe cura para a AME, mas existem tratamentos que
              podem ajudar no seu controle, dependendo do tipo e da gravidade dos sintomas, e incluem cuidados respiratórios, fisioterapia e controle nutricional.
            </p>
          </>
        ),
      },
      buttons: [
        {
          label: 'Siga Ana no YouTube',
          url: 'https://www.youtube.com/@AnaClaraMoniz',
        },
      ],
    },
    {
      info: {
        name: 'Paola Antonini',
        description: (
          <>
            Paola perdeu a perna esquerda em um acidente de carro com 20 anos. A partir daí a modelo e apresentadora decidiu compartilhar com outras pessoas o seu dia a dia com a
            prótese.
            <p>
              Escreveu o livro “Perdi uma parte de mim e renasci”, onde conta sua história de vida e aprendizados que obteve com a sua nova realidade. Em dezembro de 2020, ela
              fundou o Instituto Paola Antonini, que reabilita pessoas com deficiência física, por meio da doação de próteses, órteses, cadeiras de rodas e acessórios, e também
              oferece assistência psicológica e fisioterapia aos pacientes.
            </p>
          </>
        ),
      },
      buttons: [
        {
          label: 'Siga Paola no Instagram',
          url: 'https://www.instagram.com/paola_antonini/?utm_source=ig_embed&ig_rid=17dcfb81-6834-4c9d-879b-2a373aea5add',
        },
      ],
    },
    {
      info: {
        name: 'Pequena Lo',
        description: (
          <>
            Lorrane Silva, de 25 anos, mais conhecida como Pequena Lô é mineira de Araxá, Minas Gerais e cria conteúdo para internet desde 2014. Os conteúdos dela passam por
            entretenimento com cenas do cotidiano e temas como capacitismo e direito das pessoas com deficiência.
            <p>
              Reconhecida pela Forbes como uma das personalidades mais influentes abaixo dos 30 anos, Pequena Lo é uma influenciadora que você precisa ter na sua rede social, tanto
              para garantir boas risadas ao longo do dia quanto para aprender sobre os desafios que ela enfrenta.
            </p>
          </>
        ),
      },
      buttons: [
        {
          label: 'Siga Pequena Lo no Instagram',
          url: 'https://www.instagram.com/_pequenalo/',
        },
      ],
    },
    {
      info: {
        name: 'Andrea Schwarz',
        description: (
          <>
            Andrea é empreendedora, influencer, palestrante, Linkedin Top Voice, autora dos livros “Cotas – como vencer os desafios da contratação de pessoas com deficiência”,
            “Guia Brasil para todos” e “Guia São Paulo adaptada 2001”, ativista dos direitos de pessoas com deficiência e umas das 500 pessoas mais influentes da América Latina, na
            lista da Bloomberg Línea.´
            <p>
              Em seus empreendimentos, Andrea também busca sempre fazer a diferença na vida das pessoas. Por meio da empresa que criou “ igual”, ela conseguiu alocar mais de 20 mil
              pessoas com deficiência no mercado de trabalho.
            </p>
          </>
        ),
      },
      buttons: [
        {
          label: 'Siga Andrea no Instagram',
          url: 'https://www.instagram.com/dea_schwarz/',
        },
      ],
    },
    {
      info: {
        name: 'Mara Gabrilli',
        description: (
          <>
            É publicitária, psicóloga e senadora pelo PSD/SP. Foi secretária da Pessoa com Deficiência da Prefeitura da capital paulista, vereadora na Câmara Municipal de SP e
            Deputada Federal por dois mandatos consecutivos.
            <p>
              Em junho de 2018, Mara foi eleita para um mandato de quatro anos em um comitê da ONU do qual nunca houve um representante brasileiro. O Comitê sobre os Direitos das
              Pessoas com Deficiência reúne peritos de diferentes países e monitora a implementação, pelos Estados Partes, da Convenção da ONU sobre os Direitos das Pessoas com
              Deficiência.
            </p>
            <p>
              Empreendedora social, fundou em 1997 o Instituto Mara Gabrilli, ONG que apoia pesquisas científicas para cura de paralisias, atletas de alto rendimento do
              paradesporto e no desenvolvimento social de pessoas com deficiência em situação de vulnerabilidade.
            </p>
          </>
        ),
      },
      buttons: [
        {
          label: 'Siga Mara no Instagram',
          url: 'https://www.instagram.com/maragabrilli/',
        },
      ],
    },
    {
      info: {
        name: 'Ivan Baron',
        description: (
          <>
            É um influenciador e ativista anticapacitista potiguar, formado em pedagogia, Atua nas mídias sociais defendendo e divulgando políticas de inclusão para pessoas com
            deficiência. Baron possui paralisia cerebral decorrente de uma meningite viral que contraiu aos 3 anos de idade, em razão de uma intoxicação alimentar.
            <p>
              Participou da entrega da faixa presidencial durante a posse de Luiz Inácio Lula da Silva em 2023. Ivan declarou que sua participação partiu de um convite feito pela
              primeira-dama Rosângela Lula da Silva. Além disso, foi consultor de acessibilidade do Festival do Futuro na comemoração da posse.
            </p>
          </>
        ),
      },
      buttons: [
        {
          label: 'Siga Ivan no Instagram',
          url: 'https://www.instagram.com/ivanbaron/?next=%2Falifenino%2F&hl=en',
        },
      ],
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Inspirações de pessoas com deficiência física. ✨
        </Typography>
        <Typography variant="body1" sx={{ pt: 2, pb: 3 }}>
          Redes sociais de pessoas com deficiência pra você se inspirar todos os dias.
        </Typography>
      </Box>
      <ListOfPeople people={ListOfInspirationalPeople} />
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-com-deficiencia-fisica/conquistas-incriveis" />
        </Stack>
      </Box>
    </Stack>
  );
};
