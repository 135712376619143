import React from 'react';
import { Snackbar, Alert, AlertColor } from '@mui/material';

interface SnackbarMessageProps {
  message: string;
  onClose: () => void;
  severity?: AlertColor;
  open?: boolean;
}

export const SnackbarMessage = ({ message, onClose, severity = 'success', open = true }: SnackbarMessageProps) => {
  if (!message) {
    return <></>;
  }
  return (
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} onClose={() => onClose()}>
      <Alert variant="filled" severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};
