import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

export const PwPDHowToReceive = () => {
  const PwPDTextItems = [
    {
      title: 'Eduque a equipe',
      description:
        'Isso é importante para que a pessoa com deficiência física seja bem recebida e comportamentos capacitistas sejam evitados. Muitas vezes as pessoas oferecem ajuda de forma invasiva ou diminuem profissionais por serem pessoas com deficiência física. A equipe precisa saber qual é o papel dela pra agir com empatia e respeito.',
    },
    {
      title: 'Acessibilidade no espaço físico',
      description:
        'Você pode oferecer sinalização tátil, sonora e visual além de banheiros adequados e rotas acessíveis. Um ambiente adaptado vai dar liberdade e autonomia pra pessoas com deficiência. Converse com a pessoa pra saber quais adaptações de ergonomia ela precisa como altura da mesa, cadeiras e mouse.',
    },
    {
      title: 'Aprenda a dividir tarefas',
      description:
        'Isso é importante pra que a pessoa saiba qual é o papel dela na equipe. Você pode dividir essas tarefas antes ou no primeiro dia de trabalho. O importante aqui é que isso seja feito junto com a pessoa. Lembrando que pessoas com deficiência são profissionais com qualificação e merecem ter funções definidas como todo mundo.',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Como receber uma pessoa com deficiência física no time. 👋
        </Typography>
        <Typography variant="body2">O que fazer antes de receber a pessoa na empresa (remoto e presencial)</Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={PwPDTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-com-deficiencia-fisica/ambiente-de-trabalho" />
        </Stack>
      </Box>
    </Stack>
  );
};
