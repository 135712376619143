import React from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';

interface ILoading {
  title?: string | null;
  size?: number | null;
}

export const Loading = ({ title, size }: ILoading) => {
  return (
    <Box sx={{ height: '100%', textAlign: 'center', py: 2, my: 3 }}>
      <CircularProgress color="primary" size={size || 40} />
      <Typography color="primary" sx={{ mt: 2 }}>
        {title || 'Carregando...'}
      </Typography>
    </Box>
  );
};
