import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

export const PwPDImportantDates = () => {
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Datas importantes. 🤝
        </Typography>
        <Typography variant="body2">Datas que valorizam e marcam a história de pessoas com deficiência física.</Typography>
      </Box>
      <Stack sx={{ pl: 1, pb: 1, pt: 1, justifyContent: 'left', alignItems: 'left' }}>
        <Typography variant="body1" sx={{ pt: 1, pl: 1 }} paragraph={true}>
          <p>
            <strong>12 de Maio</strong>
          </p>
          <p>Dia Nacional de Conscientização e Enfrentamento da Fibromialgia</p>
        </Typography>
        <Typography variant="body1" sx={{ pl: 1 }} paragraph={true}>
          <p>
            <strong>Terceira Quinta-Feira de Maio</strong>
          </p>
          <p>Dia Mundial de Conscientização sobre a Acessibilidade (GAAD)</p>
        </Typography>
        <Typography variant="body1" sx={{ pl: 1 }} paragraph={true}>
          <p>
            <strong>21 de Junho</strong>
          </p>
          <p>Dia Nacional de Luta Contra a Esclerose Lateral Amiotrófica (ELA)</p>
        </Typography>
        <Typography variant="body1" sx={{ pl: 1 }} paragraph={true}>
          <p>
            <strong>06 de Julho</strong>
          </p>
          <p>Lei Brasileira de Inclusão da Pessoa com Deficiência</p>
        </Typography>
        <Typography variant="body1" sx={{ pl: 1 }} paragraph={true}>
          <p>
            <strong>08 de Agosto</strong>
          </p>
          <p>Dia Nacional da Pessoa com Atrofia Muscular Espinhal (AME)</p>
        </Typography>
        <Typography variant="body1" sx={{ pl: 1 }} paragraph={true}>
          <p>
            <strong>30 de Agosto</strong>
          </p>
          <p>Dia Nacional de Conscientização sobre a Esclerose Múltipla</p>
        </Typography>
        <Typography variant="body1" sx={{ pl: 1 }} paragraph={true}>
          <p>
            <strong>22 de Setembro</strong>
          </p>
          <p>Dia Nacional do Atleta Paralímpico</p>
        </Typography>
        <Typography variant="body1" sx={{ pl: 1 }} paragraph={true}>
          <p>
            <strong>25 de Outubro</strong>
          </p>
          <p>Dia Nacional de Combate ao Preconceito contra as Pessoas com Nanismo</p>
        </Typography>
      </Stack>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-com-deficiencia-fisica/conteudos" />
        </Stack>
      </Box>
    </Stack>
  );
};
