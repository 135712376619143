import React from 'react';
import { Alert, AlertColor, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { removeStorangeToken } from '../../utils';

interface IErrorMessageProps {
  error: unknown;
}

const notAuthorizedHttpCodes = [401, 403];

const MessageBox = ({ message, severity }: { message: string; severity: AlertColor }) => {
  return (
    <Box sx={{ height: '100%', textAlign: 'center', py: 2, mt: 2 }}>
      <Alert severity={severity}>{message}</Alert>
    </Box>
  );
};

export const ErrorMessage = ({ error }: IErrorMessageProps) => {
  console.error('Error message: ', JSON.stringify(error));

  const navigate = useNavigate();

  const errorParsed = error as { message?: string; stack?: string; response?: { status?: number } };

  if (errorParsed?.response?.status && notAuthorizedHttpCodes.includes(errorParsed.response.status)) {
    setTimeout(() => {
      removeStorangeToken();
      return navigate('/login');
    }, 5000);

    return <MessageBox severity="warning" message="Autenticação expirou. Você será redirecionado para o login em 3 segundos."></MessageBox>;
  }

  return <MessageBox severity="error" message={errorParsed?.message || 'Ocorreu um erro.'}></MessageBox>;
};
