import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

export const PeopleWithTDAHAccessibleOnboarding = () => {
  const peopleWithTDAHTextItems = [
    {
      title: 'Evite fazer entrevistas longas e com muitas pessoas',
      description:
        'Preferir entrevistas mais objetivas com etapas curtas pode ajudar a pessoa a se cansar menos, direcionar melhor a atenção e chegar até o final com mais engajamento. Lembre-se que atividades repetitivas e longas podem não ser acessíveis pra pessoas com TDAH.',
    },
    {
      title: 'Explique como o processo seletivo vai acontecer',
      description:
        'Isso pode diminuir a ansiedade de todo mundo, mas especialmente de pessoas com TDAH. Porque sabendo exatamente o que elas vão fazer se torna mais fácil direcionar a atenção pras atividades, evitando o sentimento de ansiedade pelo inesperado.',
    },
    {
      title: 'Divida as informações em diversas sessões curtas.',
      description: (
        <>
          Um dia inteiro de onboarding (presencial ou remoto) pode gerar grande sobrecarga e não ser bem aproveitado por uma pessoa com TDAH. O ideal é que as pessoas façam
          atividades diferentes de forma alternada:
          <ul>
            <li>Ler um documento;</li>
            <li>Ter uma conversa de 30 minutos;</li>
            <li>Assistir um vídeo curto.</li>
          </ul>
          Assim você consegue mais engajamento de pessoas com TDAH.
        </>
      ),
    },
    {
      title: 'Eduque a equipe',
      description:
        'O time precisa saber o que é TDAH e como podem receber e lidar com essa pessoa no dia a dia. E-mails, materiais, comunicações e reuniões são algumas das pautas importantes que a equipe precisa conhecer sobre as principais boas práticas. Envie lembretes sobre reuniões e prazos quando puder e seja paciente.',
    },
    {
      title: 'Crie apresentações acessíveis',
      description:
        'Em casos de muitas informações, divida o parágrafo em poucas linhas e prefira organizar o texto em listas, ordem numérica ou tópicos. Cuidado com informações extras sem necessidade e foque naquilo que é realmente útil pra que a pessoa faça a tarefa. Você também pode usar imagens pra reforçar o tom do conteúdo.',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Aprenda a ser acessível antes e depois do sim.
        </Typography>
        <Typography variant="body2">Entrevistas e onboarding para remoto ou presencial.</Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={peopleWithTDAHTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-com-tdah/ambiente-de-trabalho" />
        </Stack>
      </Box>
    </Stack>
  );
};
