import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import DayByDay from './images/illustrations/dayByDay.svg';

export const BlindPeopleDayByDay = () => {
  const blindPeopleTextItems = [
    {
      title: 'Crie textos alternativos para posts.',
      description: (
        <>
          <p>
            Você pode descrever em poucas linhas quais são as pessoas ou objetos presentes na imagem, o cenário principal e o tom da imagem, se as pessoas estão sorrindo, se estão
            sérias, ou chorando. Só cuidado pra não influenciar o texto com sua percepção sobre a emoção;{' '}
          </p>
          <p>
            <strong>Escreva assim:</strong> uma mulher com sorriso aberto no rosto.
          </p>
          <p>
            <strong>Evite escrever assim:</strong> uma mulher muito feliz.
          </p>
        </>
      ),
    },
    {
      title: 'Use emojis no lugar de emoticons.',
      description: (
        <>
          <p>
            Emoticons como ‘:)’ não são lidos pelo leitor de tela de forma entendível. Para expressar sentimentos de um jeito acessível o melhor a ser feito é usar o emoji. Nesse
            caso, você pode usar o ‘🙂’. Só tenha cuidado com excesso e emojis desconhecidos, opte sempre pelos mais usados e posicione eles sempre no final da frase. Use tom de
            peles diferentes apenas quando necessário porque emojis com variação de tom de pele geram texto alternativos mais longos pro leitor de tela.
          </p>
          <p>
            <strong>Escreva assim:</strong> “Parabéns, escolhemos você! 🙂”
          </p>
          <p>
            <strong>Evite escrever assim:</strong> “Parabéns, escolhemos você! :)”
          </p>
        </>
      ),
    },
    {
      title: 'Evite usar GIFs em e-mails.',
      description:
        'GIF não tem texto alternativo, o que faz com que o leitor de tela não consiga ler o conteúdo. Opte sempre por e-mails em texto e se for usar GIF tenha em mente que ele não pode ser o foco da mensagem, mas sim apenas algo acessório. Escreva o e-mail em texto e use emojis quando quiser.',
    },
    {
      title: 'Tenha atenção com links.',
      description: (
        <>
          <p>
            Ao enviar links em e-mails, evite escrever textos genéricos. É importante sempre dar contexto pra que a pessoa cega saiba exatamente o que será feito através do clique
            no link.{' '}
          </p>
          <p>
            <strong>Escreva assim:</strong> “Ler manual sobre segurança”
          </p>
          <p>
            <strong>Evite escrever assim:</strong> “saiba mais”
          </p>
        </>
      ),
    },
    {
      title: 'Materiais de apresentação.',
      description:
        'Quando usar imagens nos seus materiais, adicione um texto de apoio com a descrição do conteúdo. Por ex.: “gráfico demonstrando o crescimento de 20% da empresa de janeiro até março de 2023.” Percebe como o mais importante é dar foco para a mensagem principal?',
    },
    {
      title: 'Documentos com textos ampliados.',
      description:
        'Algumas pessoas com baixa visão ampliam textos para conseguir enxergar e ler. Para facilitar, em caso de documentos impressos você já pode entregar o material com o texto ampliado para a pessoa, basta perguntar como ela prefere.',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Dicas práticas pra você incluir pessoas com deficiência visual na rotina. 📧
        </Typography>
        <Typography variant="body2">Reuniões, e-mails e dia a dia.</Typography>
      </Box>
      <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <img src={DayByDay} alt="Ilustração de duas pessoas em telas de computador." style={{ maxWidth: '430px', width: '100%' }} aria-hidden="true" />
      </Stack>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={blindPeopleTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-com-deficiencia-visual/inspiracoes" />
        </Stack>
      </Box>
    </Stack>
  );
};
