import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { List, ListItemButton, ListItemText } from '@mui/material';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';

import blackPeopleGraphic from './images/blackPeopleGraphic.jpeg';

export const DiversityBlackPeople = () => {
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Mercado de trabalho pra pessoas negras. 🏭
        </Typography>
      </Box>
      <Box sx={{ pl: 1 }}>
        <Typography variant="body1" component="p">
          Para a construção de uma identidade negra positiva no mercado de trabalho, é importante criar um ambiente que não reproduza racismo. Além disso, que encontre formas de
          posicionar pessoas negras em papéis de protagonismo.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          Que tal aprender quais caminhos seguir para construir um ambiente de trabalho que pessoas negras sejam reconhecidas e valorizadas? Um ambiente que crie e promova
          possibilidades.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          Menos de 30% dos cargos de liderança são ocupados por pessoas negras. Esse número é ainda mais baixo quando falamos de mulheres negras.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          Segundo pesquisa do Instituto Ethos:
        </Typography>
        <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <img
            src={blackPeopleGraphic}
            alt="Imagem de um gráfico que informa os seguintes dados: pessoas negras representam 55,9% da população brasileira, mas ocupam apenas 4,7% dos cargos de liderança nas 500 maiores empresas do país."
            style={{ maxWidth: '430px', width: '100%', maxHeight: '450px' }}
          />
        </Stack>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          Pessoas negras continuam fazendo parte de uma minoria em cargos mais altos, se restringindo a cargos mais baixos na empresa e sem oportunidades de crescimento.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          O crescimento de pessoas negras, quando acontece, leva mais tempo do que o de pessoas brancas para o mesmo cargo. E como podemos ajudar a resolver esse problema? Como
          abrir portas para pessoas negras nas empresas e criar um ambiente favorável ao crescimento delas?
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          Mulheres negras escreveram esse manual junto a outras pessoas diversas da nossa equipe. Os desafios e soluções compartilhadas aqui surgiram a partir de vivências reais de
          pessoas negras no mercado de trabalho.
        </Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1, mt: 2 }}>
        <Typography variant="h6" component="h3">
          Vamos conversar sobre
        </Typography>
        <BlackPersonTopics />
      </Box>
    </Stack>
  );
};

export const TopiceMenuListItem = ({ title, text, handerClick }: { title: string; text: string; handerClick: () => void }) => {
  return (
    <ListItemButton disableGutters={true} onClick={handerClick} sx={{ p: 1 }}>
      <ListItemText primary={title} secondary={text} />
      <ArrowForwardIosIcon sx={{ fontSize: 15 }} />
    </ListItemButton>
  );
};

const BlackPersonTopics = () => {
  const navigate = useNavigate();
  const handlerRoutePage = (path: string) => navigate(path);

  return (
    <>
      <List component="nav">
        <TopiceMenuListItem
          title="Criando um ambiente de trabalho mais saudável pra pessoas negras."
          text="Uma conversa sobre racismo, oportunidades e crescimento."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-negras/ambiente-de-trabalho');
          }}
        />
        <TopiceMenuListItem
          title="Aprendendo a ter mais autoestima."
          text="Como as pessoas negras podem ser mais confiantes no trabalho e na vida."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-negras/autoestima');
          }}
        />
        <TopiceMenuListItem
          title="Uma conversa com quem lidera pessoas negras."
          text="O papel da liderança na criação de oportunidades. "
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-negras/para-quem-lidera');
          }}
        />
        <TopiceMenuListItem
          title="Conselhos para pessoas negras que estão começando.
          "
          text="Como ajudar profissionais iniciantes a se sentirem em segurança."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-negras/que-estao-comecando');
          }}
        />
        <TopiceMenuListItem
          title="Criando uma rede de apoio para pessoas negras."
          text="A importância dessa rede para pessoas negras crescerem."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-negras/rede-de-apoio');
          }}
        />
        <TopiceMenuListItem
          title="Bem-estar e crescimento para mães negras."
          text="Uma conversa sobre autoestima, segurança e carreira."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-negras/maes');
          }}
        />
        <TopiceMenuListItem
          title="Lideranças negras que fazem a diferença."
          text="Lideranças negras que fazem a diferença."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-negras/lideranca');
          }}
        />
        <TopiceMenuListItem
          title="Inpirações de pessoas negras."
          text="Para você seguir e acompanhar nas redes sociais."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-negras/inspiracoes');
          }}
        />
        <TopiceMenuListItem
          title="Conquistas incríveis"
          text="Uma lista de pessoas negras  que fizeram história."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-negras/conquistas-incriveis');
          }}
        />
        <TopiceMenuListItem
          title="Datas importantes."
          text="Datas que valorizam e marcam a história de pessoas negras. "
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-negras/datas-importantes');
          }}
        />
        <TopiceMenuListItem
          title="Conteúdos que a Inboarding indica."
          text="Pra você ir além desse manual."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-negras/indicacao');
          }}
        />
      </List>
    </>
  );
};
