/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import type { SubmissionData, SubmissionDispatchers } from '../FormQuestions/quillFormTypes';
import type { FormBlock } from '@quillforms/types';

import { useSelect } from '@wordpress/data';
import { AsyncQuillForm } from '../FormQuestions/AsyncQuillForm';
import { setStorangeToken } from '../../utils';

import { SurveyAnswerData, useSurveyAnswerUpdate, useSurveyDraftUpdate } from '../../hooks/queries/useSurveyAnswerUpdate';
import { useGetDataSurveyQuestions } from '../../hooks/queries/useSurveyGetQuery';

import { FormQuestionLoadError } from '../FormQuestions/FormQuestionLoadError';
import { FormSurveyLoading } from './FormSurveyLoading';
import { FormSurveyInvalidNotFound } from './FormSurveyInvalidNotFound';
import { useDebounceEffect } from '../../utils/useDebounceEffect';
import { PeopleJourneyStepsStatus } from '../../hooks/queries/useJourneyStepsQuery';
import { FormCompleted } from '../FormQuestions/FormCompleted';

interface StoreAnswersValues {
  [key: string]: string;
}

export const FormSurveyAnswers = () => {
  const [formFieldsAnswered, setFormFieldsAnswered] = React.useState<StoreAnswersValues>();
  const { shortId } = useParams();
  const [searchParams] = useSearchParams();
  const answerDefaultFirstValue = searchParams.get('answerValue');
  const token = searchParams.get('token');
  if (token) {
    setStorangeToken(token);
  }

  if (!shortId) {
    return <FormSurveyInvalidNotFound />;
  }

  const { data, isLoading, isError, error } = useGetDataSurveyQuestions(shortId);
  const surveyData = data?.data?.data;

  const parsedBlocks = surveyData && surveyData.step_status ? surveyData.survey_questions : surveyData;

  const { mutateAsync: updateDraftAnswers } = useSurveyDraftUpdate();
  const { mutateAsync: updateAnswers, isLoading: isLoadingUpdateAnswers } = useSurveyAnswerUpdate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectSurveyFieldsAnswered = useSelect((select: (name: string) => any) => {
    const storeAnswers = select('quillForms/renderer-core');
    return storeAnswers?.getAnswersValues();
  }) as StoreAnswersValues;

  // Not block on typing fields values
  useDebounceEffect(
    () => {
      // Before it was saying every second. Doing comparation to check if values have really changed
      if (JSON.stringify(selectSurveyFieldsAnswered) !== JSON.stringify(formFieldsAnswered)) {
        setFormFieldsAnswered(selectSurveyFieldsAnswered);
      }
    },
    1000,
    [selectSurveyFieldsAnswered],
  );

  // Save draft only after 5 seconds... avoid saving on typing
  useDebounceEffect(
    async () => {
      if (!parsedBlocks || !formFieldsAnswered || isLoadingUpdateAnswers) {
        return;
      }
      const formFieldsAnsweredParsed = formFieldsAnswered as { [key: string]: string };
      const values = parsedBlocks
        .filter((block: FormBlock) => block.name !== 'statement')
        .map((block: FormBlock) => {
          let answeredValue = formFieldsAnsweredParsed[block.id];
          if (block.name == 'inboarding-profile-picture') {
            answeredValue = answeredValue ? 'uploaded' : '';
          } else if (block.name == 'multiple-choice') {
            if (answeredValue?.length === 1) {
              answeredValue = answeredValue[0];
            }
          }
          return {
            id: block.id,
            label: block.attributes?.label || '',
            description: block.attributes?.description || '',
            value: answeredValue,
            blockPollType: block.attributes?.blockPollType || '',
          } as SurveyAnswerData;
        });
      const { data, status } = await updateDraftAnswers({
        shortId: shortId,
        answers: values,
      });
      if (status !== 200) {
        console.error('Error on draft survey form answers update. Status: ', status, ' data: ', data);
      }
    },
    5000,
    [formFieldsAnswered],
  );

  if (isLoading) {
    return <FormSurveyLoading />;
  }

  if (isError) {
    return <FormQuestionLoadError error={error} />;
  }

  if (!data || !parsedBlocks || !parsedBlocks.length) {
    return <FormSurveyInvalidNotFound />;
  }

  if (answerDefaultFirstValue) {
    const firstBlockQuestion = parsedBlocks[0];
    if (firstBlockQuestion && firstBlockQuestion.attributes) {
      firstBlockQuestion.attributes.defaultValue = answerDefaultFirstValue;
    }
  }

  const onFormSubmit = async (data: SubmissionData, { completeForm, setIsSubmitting, setSubmissionErr }: SubmissionDispatchers) => {
    const answers = data.answers;
    const answersValues = parsedBlocks
      .filter((block: FormBlock) => block.name !== 'statement')
      .map((block: FormBlock) => {
        const answeredValue = answers[block.id].value;
        return {
          id: block.id,
          label: block.attributes?.label || '',
          description: block.attributes?.description || '',
          value: answeredValue,
          blockPollType: block.attributes?.blockPollType || '',
        } as SurveyAnswerData;
      });

    console.log('Submmited data = ', answersValues);

    const { data: updateData, status } = await updateAnswers({
      shortId: shortId,
      answers: answersValues,
    });

    if (status === 200) {
      setIsSubmitting(false);
      completeForm();
    } else {
      console.error('Error on submmit survey form answers update. Status: ', status, ' data: ', updateData);
      setSubmissionErr('Ocorreu um probleminha ao salvar suas respostas. Por favor tente novamente.');
    }
  };

  const surveyCompleted = surveyData && surveyData.step_status && surveyData.step_status === PeopleJourneyStepsStatus.Confirmed;

  return surveyCompleted ? (
    <FormCompleted />
  ) : (
    <div style={{ width: '100%', height: '90vh' }}>
      <AsyncQuillForm formId={1} blocks={parsedBlocks} onSubmit={onFormSubmit} />
    </div>
  );
};
