import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Routing } from './routes';
import { ThemeProvider, Typography } from '@mui/material';
import { Toaster } from 'react-hot-toast';
import { createAppThemeFromUser } from './utils/createAppThemeFromUser';
import { getStorangeAuthUser } from './utils/authUserStorange';
import { FeatureFlagsApp } from './config/FeatureFlagsApp';
import UpdateNewVersionDialog from './components/UpdateNewVersionDialog';

const queryClient = new QueryClient();

interface AppProps {
  appUpdatePending: boolean;
  updateAction: () => void;
}

function App(props: AppProps) {
  const { appUpdatePending, updateAction } = props;
  const [showUpdateDialog, setShowUpdateDialog] = React.useState(appUpdatePending);

  const userProfileData = getStorangeAuthUser();
  const theme = createAppThemeFromUser(userProfileData);

  React.useEffect(() => {
    setShowUpdateDialog(appUpdatePending);
  }, [appUpdatePending]);

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <FeatureFlagsApp>
          <React.Fragment>
            <UpdateNewVersionDialog showUpdateDialog={showUpdateDialog} updateAction={updateAction} />
            <Routing />
          </React.Fragment>
        </FeatureFlagsApp>
        <Typography variant="body1" component="div">
          <Toaster position="top-center" toastOptions={{ duration: 6000 }} />
        </Typography>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
