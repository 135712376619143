import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function UpdateNewVersionDialog({ showUpdateDialog, updateAction }: { showUpdateDialog: boolean; updateAction: () => void }) {
  const handleClickUpdate = () => {
    updateAction();
  };

  return (
    <Dialog open={showUpdateDialog} aria-labelledby="update-dialog-title" aria-describedby="update-dialog-description">
      <DialogTitle id="update-dialog-title">{'Nova versão do Portal disponível!'}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="update-dialog-description">
          <p>Clique em atualizar para instalar a última versão.</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClickUpdate}>Atualizar versão</Button>
      </DialogActions>
    </Dialog>
  );
}
