import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import { TextListAccordion } from '../TextListAccordion';

export const PeopleWithTDAHSelfConfidence = () => {
  const PeopleWithTDAHTextItems = [
    {
      title: 'Conheça suas forças.',
      description:
        'Lembre-se que você tem muitas habilidades importantes. Entenda quais são seus pontos fortes e explore-os. Isso pode fazer toda a diferença na sua vida profissional e também na vida pessoal.',
    },
    {
      title: 'Ferramentas podem te ajudar na gestão do tempo.',
      description:
        'Use agendas, assistentes virtuais, alarmes e o que preferir pra te ajudar a lembrar de tarefas, de horários e reuniões. Essas ferramentas são grandes aliadas pra organização do seu dia, o Trello e o Google Agenda, por exemplo, são gratuitas e simples de serem usadas. Outra coisa que pode ser feita é adicionar na rotina o hábito de revisar a agenda e prioridades no fim do dia e no início da manhã pra se preparar para o próximo dia.',
    },
    {
      title: 'Descubra como você prefere se comunicar.',
      description:
        'Você prefere que te enviem informações por áudio ou por texto? Essas são adaptações simples que o time pode fazer pra te ajudar no dia a dia. Você pode preferir acelerar ou ouvir um áudio várias vezes. Também pode ser que textos sejam mais fáceis pra você saber o que precisa fazer. Quem sabe, você até prefira reuniões com a equipe pra tirar dúvidas. O importante é se auto avaliar e descobrir.',
    },
    {
      title: 'Seja você e converse com sua liderança.',
      description:
        'Se conheça e acolha suas características. Se conhecer é importante pra reconhecer suas habilidades e desafios. Saber conversar sobre esses desafios com sua liderança pode ser bom para o seu desenvolvimento na empresa. Assim a liderança pode explorar suas habilidades e encontrar formas de lidar com os desafios junto com você.  Tá tudo bem ter sua forma única de lidar com a rotina.',
    },
    {
      title: 'Aprenda a se respeitar.',
      description:
        'Entenda que você não é igual a todo mundo, logo ter formas diferentes de lidar com horários e rotina não te torna menos profissional. Aprender a respeitar isso vai te tornar mais autoconfiante. ',
    },
    {
      title: 'Evite se comparar com outras pessoas.',
      description:
        'A única coisa que você tem em comum com outras pessoas com TDAH é o fato de vocês terem TDAH. Evite comparar suas habilidades com a de outras pessoas, cada um tem uma história e um processo diferente. Se você não se sente confortável fazendo certa tarefa que outra pessoa faz com tranquilidade, isso não é um problema.',
    },
    {
      title: 'Aproveite cada fase do seu crescimento.',
      description:
        'Existem muitas fases até chegar onde você quer. A que você está bem no comecinho e tudo é novidade, a que você já começa a ganhar a maturidade e precisa aperfeiçoar aquilo que aprendeu, e por aí em diante. A verdade é que cada fase tem seu lado bom e aproveitar cada uma delas é ótimo pra um crescimento saudável.',
    },
    {
      title: 'Lembre-se de fazer pausas',
      description:
        'Às vezes você pode precisar fazer algumas pausas, respeite isso, faça as pausas necessárias e volte a trabalhar depois. Não precisa se forçar a continuar, as pausas podem te ajudar a aliviar o estresse e aumentar sua produtividade. Quando voltar da pausa, você vai estar com mais disposição para fazer outras atividades com mais qualidade.',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 3 }}>
        <Typography variant="h5" color="primary" component="h2">
          Aprendendo a ter mais autoconfiança. 💪
        </Typography>
        <Typography variant="body2">Como as pessoas com TDAH podem ser mais confiantes.</Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={PeopleWithTDAHTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-com-tdah/para-lideres" />
        </Stack>
      </Box>
    </Stack>
  );
};
