import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import AdaLovelace from './images/AdaLovelace.png';
import GraceHopper from './images/GraceHopper.png';
import GwynneShotwell from './images/GwynneShotwell.png';
import SaraWahedi from './images/SaraWahedi.png';
import RajSeshadri from './images/RajSeshadri.png';
import AnaFontes from './images/AnaFontes.png';
import RoniceMullerDeQuadros from './images/RoniceMullerDeQuadros.png';
import LuizaTrajano from './images/LuizaTrajano.png';
import DuiliaDeMello from './images/DuiliaDeMello.png';
import SuzanaHerculanoHouzel from './images/SuzanaHerculanoHouzel.png';
import { ListOfPeople } from '../ListOfPeople';

export const WomenIncredibleAchievements = () => {
  const ListOfPeopleAndAchievements = [
    {
      info: {
        name: 'Ada Lovelace',
        description:
          'A Condessa de Lovelace é a primeira programadora da história. No século XIX, Ada foi responsável por classificar o algoritmo contido no primeiro modelo de computador do mundo.',
      },
      image: {
        personImportName: AdaLovelace,
        altText: 'Ilustração da Ada Lovelace com o cabelo preso usando acessórios na cor amarela.',
      },
      buttons: [
        {
          label: 'Leia mais sobre Ada',
          url: 'https://www.umov.me/mulheres-na-tecnologia/',
        },
      ],
    },
    {
      info: {
        name: 'Grace Hopper',
        description:
          'Foi a primeira mulher a se formar na Universidade de Yale com um PhD em matemática, a primeira almirante da marinha dos EUA e uma das criadoras do COBOL, uma linguagem de programação para bancos de dados comerciais. Criou linguagens de programação para o UNIVAC, um dos primeiros computadores comerciais fabricados nos Estados Unidos.',
      },
      image: {
        personImportName: GraceHopper,
        altText: 'Foto de Grace Hopper ao lado do computador UNIVAC I, o primeiro computador da história a ser vendido em larga escala no mercado. A foto está em tons de cinza.',
      },
      buttons: [
        {
          label: 'Leia mais sobre Grace',
          url: 'https://www.umov.me/mulheres-na-tecnologia/',
        },
      ],
    },
    {
      info: {
        name: 'Gwynne Shotwell, presidente e COO da SpaceX.',
        description:
          'Recebeu um Prêmio Mundial em Tecnologia na categoria “Individual Achievement in Space” e seu nome ficou gravado no hall internacional da fama das mulheres na tecnologia. Através da sua liderança, tanto corporativa como em programas externos, Gwynne ajudou a arrecadar mais de US$ 1.4 milhões para programas de educação em STEM.',
      },
      image: {
        personImportName: GwynneShotwell,
        altText: 'Foto da Gwynne Shotwell sorrindo de braços cruzados.',
      },
      buttons: [
        {
          label: 'Leia mais sobre Gwynne',
          url: 'https://www.umov.me/mulheres-na-tecnologia/',
        },
      ],
    },
    {
      info: {
        name: 'Sara Wahedi, CEO e fundadora da startup Ehtesab.',
        description:
          'Criou uma startup que teve como primeiro produto um aplicativo que envia alertas de segurança, energia e trânsito em tempo real aos moradores de Cabul. A empresária de tecnologia integra a lista de “Líderes da próxima geração”, da revista Time Magazine de 2021 e a BBC 100 Women, lista de mulheres inspiradoras e influentes de 2021.',
      },
      image: {
        personImportName: SaraWahedi,
        altText: 'Foto da Sara Wahedi utilizando um Hijab com parte do cabelo a mostra.',
      },
      buttons: [
        {
          label: 'Siga no Instagram',
          url: 'https://www.instagram.com/wahedigram/?igshid=YmMyMTA2M2Y%3D',
        },
      ],
    },
    {
      info: {
        name: 'Raj Seshadri, presidente de dados e serviços na Mastercard.',
        description:
          'É responsável pela entrega global de diversas capacitações na Mastercard, incluindo serviços de analytics, consultas, engajamento e inovação. É membro do conselho de diretores da Raymond James Financial Inc. e do conselho administrativo da escola de pós-graduação em Negócios de Stanford. Também integra a mesa conselheira para o South Asian Youth Action e o conselho global da American India Foundation.',
      },
      image: {
        personImportName: RajSeshadri,
        altText: 'Foto da Raj Seshadri de braços cruzados e sorrindo.',
      },
      buttons: [
        {
          label: 'Leia mais sobre Raj Seshadri',
          url: 'https://www.umov.me/mulheres-na-tecnologia/',
        },
      ],
    },
    {
      info: {
        name: 'Ana Fontes',
        description:
          'Empreendedora social, professora, delegada líder do Brasil no W20, palestrante, fundadora da RME - Rede Mulher Empreendedora e do Instituto RME. Eleita uma das 20 mulheres mais poderosas do Brasil pela revista Forbes em 2019. Linkedin Top Voices 2020.',
      },
      image: {
        personImportName: AnaFontes,
        altText: 'Foto da Ana Fontes com braços ao lado do corpo com olhar fixo para frente.',
      },
      buttons: [
        {
          label: 'Leia mais sobre Ana',
          url: 'https://forbes.com.br/forbes-mulher/2020/11/ana-fontes-da-rede-mulher-empreendedora-o-brasil-precisa-de-politicas-de-inovacao-que-incluam-mulheres/',
        },
      ],
    },
    {
      info: {
        name: 'Luiza Trajano, Fundadora da Magazine Luiza.',
        description:
          'Presidente do Conselho de Administração do Magazine Luiza. Foi responsável pelo salto de inovação e crescimento que colocou o Magazine Luiza entre as maiores varejistas do Brasil. Também atua como Conselheira em 12 diferentes entidades. Foi a primeira colocada como Top Influencer do LinkedIn brasileiro em 2019 e eleita Personalidade do Ano de 2020 pela Câmara do Comércio Brasil-EUA.',
      },
      image: {
        personImportName: LuizaTrajano,
        altText: 'Foto da Luiza Trajano posando com a mão apoiando o queixo.',
      },
      buttons: [
        {
          label: 'Leia mais sobre Luiza',
          url: 'https://aventurasnahistoria.uol.com.br/noticias/historia-hoje/luiza-trajano-e-uma-das-8-brasileiras-bilionarias-na-lista-da-forbes.phtml',
        },
      ],
    },
    {
      info: {
        name: 'Duília de Mello, Astrônoma',
        description:
          'Astrônoma, escritora, professora, doutora e vice-reitora de Estratégias Globais. Ingressou no corpo docente da CUA e em 2016 foi a primeira mulher a ser promovida a Professora Catedrática do Departamento de Física. Autora e coautora de mais de 100 artigos científicos.',
      },
      image: {
        personImportName: DuiliaDeMello,
        altText: 'Foto da Duília de sorrindo com o dedo indicador encostado na bochecha.',
      },
      buttons: [
        {
          label: 'Leia mais sobre Duília',
          url: 'https://gaiaciencia.com.br/duilia-de-mello-quem-e-a-mulher-das-estrelas-biografias',
        },
      ],
    },
    {
      info: {
        name: 'Suzana Herculano-Houzel',
        description:
          'É uma neurocientista brasileira conhecida por seu trabalho de divulgação científica. Graduada em biologia, tem mestrado, doutorado e pós-doutorado em neurociência. É professora universitária, escreveu diversos artigos, livros e participou de programas de TV. Seu principal campo de pesquisa é em neuroanatomia comparada.',
      },
      image: {
        personImportName: SuzanaHerculanoHouzel,
        altText: 'Foto da Suzana Herculano segurando uma maquete em forma de cérebro.',
      },
      buttons: [
        {
          label: 'Leia mais sobre Suzana',
          url: 'https://www.suzanaherculanohouzel.com/',
        },
      ],
    },
    {
      info: {
        name: 'Ronice Müller de Quadros',
        description:
          'É CODA (pessoa ouvinte que tem os pais Surdos), linguista e pedagoga brasileira conhecida por seus trabalhos sobre a língua brasileira de sinais. Fez parte da criação do primeiro curso de Letras Libras do Brasil. É mestre e doutora pela Pontifícia Universidade Católica do Rio Grande do Sul e pós-doutora pela Universidade de Harvard.',
      },
      image: {
        personImportName: RoniceMullerDeQuadros,
        altText: 'Foto da Ronice Muller de Quadros sorrindo com o dedo indicador encostado na bochecha. Ela usa um óculos.',
      },
      buttons: [
        {
          label: 'Leia mais sobre Ronice',
          url: 'https://ppglin.posgrad.ufsc.br/ronice-muller-de-quadros/',
        },
        {
          label: 'Siga no Instagram',
          url: 'https://www.instagram.com/ronicequadros/',
        },
      ],
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Conquistas incríveis. 🏆
        </Typography>
        <Typography variant="body2">Uma lista de indicações de mulheres que fizeram história.</Typography>
        <Typography variant="body1" sx={{ pt: 2 }}>
          Quando tornamos os espaços mais inclusivos, as Mulheres podem expressar todo seu talento. Pra você conhecer um pouco de tudo que essas pessoas têm feito, separamos alguns
          nomes ganhadores de prêmios, concursos e mais. Confira:
        </Typography>
      </Box>
      <ListOfPeople people={ListOfPeopleAndAchievements} />
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/mulheres/inspiracoes" />
        </Stack>
      </Box>
    </Stack>
  );
};
