import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Stack, Box, Typography, Paper, Button } from '@mui/material';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import HearingDisabledIcon from '@mui/icons-material/HearingDisabled';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import GroupsIcon from '@mui/icons-material/Groups';
import FemaleIcon from '@mui/icons-material/Female';
import AccessibleIcon from '@mui/icons-material/Accessible';
import ExtensionIcon from '@mui/icons-material/Extension';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';

import { EllaMessage } from '../../components';

export const Diversity = () => {
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h1">
          Grupos de diversidade
        </Typography>
      </Box>
      <Box sx={{ pl: 1 }}>
        <EllaMessage>
          <>
            <Typography variant="body2" component="p">
              Aqui você aprende a ser uma pessoa mais inclusiva com seus colegas de time de um jeito simples e com dicas práticas.
            </Typography>
            <Typography variant="body2" component="p" sx={{ mt: 1 }}>
              Assim, você vai conseguir melhorar o dia a dia de todo mundo com ajustes pequenos, mas que fazem toda a diferença. Vamos começar?
            </Typography>
          </>
        </EllaMessage>
      </Box>
      <Box sx={{ pl: 1, mt: 2 }}>
        <DiversityGroups />
      </Box>
    </Stack>
  );
};

interface IActionsButtonProps {
  label: string | JSX.Element;
  icon: React.ReactNode;
  locked?: boolean;
  onClick: () => void;
}

const ActionsButton = ({ label, icon, locked, onClick }: IActionsButtonProps) => {
  function handleOnClick() {
    if (locked) {
      alert('Opção bloqueada. Ainda não disponível para a sua empresa.');
      return;
    }
    return onClick();
  }
  return (
    <Button sx={{ padding: '0', ml: '10px', mb: '10px' }} onClick={handleOnClick} style={locked ? { filter: 'grayscale(100%) opacity(0.7)' } : {}}>
      <Paper elevation={3} sx={{ minWidth: '145px', py: '20px', borderRadius: 4 }}>
        {locked ? <LockOutlinedIcon fontSize="small" color="primary" style={{ position: 'absolute', top: '10px', right: '10px' }} /> : null}
        <Box>{icon}</Box>
        <Box sx={{ alignContent: 'center', minHeight: '48px', display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ textTransform: 'none', width: '100%' }}>
            {label}
          </Typography>
        </Box>
      </Paper>
    </Button>
  );
};

const DiversityGroups = () => {
  const navigate = useNavigate();
  const handlerRoutePage = (path: string) => navigate(path);

  return (
    <Box sx={{ display: 'flex', alignItems: 'stretch', flexWrap: 'wrap', minWidth: 300, width: '100%' }}>
      <ActionsButton label="Mulheres" icon={<FemaleIcon fontSize="large" color="primary" />} onClick={() => handlerRoutePage('/diversidade/mulheres')} />
      <ActionsButton label="Pessoas Negras" icon={<GroupsIcon fontSize="large" color="primary" />} onClick={() => handlerRoutePage('/diversidade/pessoas-negras')} />
      <ActionsButton label="Pessoas Surdas" icon={<HearingDisabledIcon fontSize="large" color="primary" />} onClick={() => handlerRoutePage('/diversidade/pessoas-surdas')} />
      <ActionsButton
        label={
          <>
            Pessoas com <br />
            deficiência visual
          </>
        }
        icon={<VisibilityOffIcon fontSize="large" color="primary" />}
        onClick={() => handlerRoutePage('/diversidade/pessoas-com-deficiencia-visual')}
      />
      <ActionsButton
        label={
          <>
            Pessoas com <br /> deficiência física
          </>
        }
        icon={<AccessibleIcon fontSize="large" color="primary" />}
        onClick={() => handlerRoutePage('/diversidade/pessoas-com-deficiencia-fisica')}
      />
      <ActionsButton label="Pessoas Autistas" icon={<ExtensionIcon fontSize="large" color="primary" />} onClick={() => handlerRoutePage('/diversidade/pessoas-autistas')} />
      <ActionsButton
        label={
          <>
            Pessoas <br /> com TDAH
          </>
        }
        icon={<AllInclusiveIcon fontSize="large" color="primary" />}
        onClick={() => handlerRoutePage('/diversidade/pessoas-com-tdah')}
      />
    </Box>
  );
};
