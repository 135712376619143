import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';

import RelacoesRaciaisDiscussao from './images/recommendations/RelacoesRaciaisDiscussao.jpg';
import RelacoesRaciaisBrasil from './images/recommendations/RelacoesRaciaisBrasil.jpg';
import NocoesDeRaca from './images/recommendations/NocoesDeRaca.jpg';
import LetramentoRacial from './images/recommendations/LetramentoRacial.jpg';
import Genocidio from './images/recommendations/Genocidio.jpg';
import Africa from './images/recommendations/Africa.jpg';
import PequenoManual from './images/recommendations/PequenoManual.jpg';
import CotasRaciais from './images/recommendations/CotasRaciais.jpg';
import Empoderamento from './images/recommendations/Empoderamento.jpg';
import PactoDaBranquitude from './images/recommendations/PactoDaBranquitude.jpg';
import GenocidioDoNegro from './images/recommendations/GenocidioDoNegro.jpg';

import { ListOfContents } from '../ListOfContents';

export const BlackPeopleRecommendations = () => {
  const listOfContents = [
    {
      contentType: 'video',
      title: 'A luta contra o racismo deve estar em todos os lugares | Maurício Pestana | TEDxBeloHorizonte',
      channel: 'TEDx Talks.',
      videoUrl: 'https://www.youtube.com/embed/Kzk0u4pcJnE',
    },
    {
      contentType: 'video',
      title: 'O que é racismo estrutural? | Silvio Almeida',
      channel: 'TV Boitempo.',
      videoUrl: 'https://www.youtube.com/embed/PD4Ew5DIGrU',
    },
    {
      contentType: 'video',
      title: '(RE)ancestralizar as vozes através das filosofias africanas | Katiúscia Ribeiro | TEDxUnisinos',
      channel: 'TEDx Talks.',
      videoUrl: 'https://www.youtube.com/embed/7rsIUDAMJl4',
    },
    {
      contentType: 'article',
      title: 'Podcast - “Por Dentro da África.”',
      description: 'Spotify: Por Dentro da África.',
      imageImportName: Africa,
      altText: 'Imagem do site Spotify',
      buttonLabel: 'Escutar Podcast no site',
      buttonUrl: 'https://open.spotify.com/show/6BDLLpMyJImE4ejUMPSiCK',
    },
    {
      contentType: 'video',
      title: 'Letramento Racial Crítico no contexto da Lei 10.639/03',
      channel: 'Canal do Youtube: PROAES UFRRJ.',
      videoUrl: 'https://www.youtube.com/embed/8P2JAh-Wes',
    },
    {
      contentType: 'video',
      title: 'ENTENDA o MITO da DEMOCRACIA RACIAL',
      channel: 'Canal Preto.',
      videoUrl: 'https://www.youtube.com/embed/d775DrTsgqM',
    },
    {
      contentType: 'article',
      title: 'Educação e letramento racial',
      description: 'Texto em site: UFMG.',
      imageImportName: LetramentoRacial,
      altText: 'Imagem do site da UFMG.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://ufmg.br/comunicacao/publicacoes/boletim/edicao/2081/educacao-e-letramento-racial',
    },
    {
      contentType: 'article',
      title: 'UMA ABORDAGEM CONCEITUAL DAS NOÇÕES DE RACA, RACISMO, IDENTIDADE E ETNIA.',
      description: 'Texto no site: Geledes.',
      imageImportName: NocoesDeRaca,
      altText: 'Imagem do artigo de título: UMA ABORDAGEM CONCEITUAL DAS NOÇÕES DE RACA, RACISMO, IDENTIDADE E ETNIA.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://www.geledes.org.br/wp-content/uploads/2014/04/Uma-abordagem-conceitual-das-nocoes-de-raca-racismo-dentidade-e-etnia.pdf',
    },
    {
      contentType: 'article',
      title: 'TEORIA SOCIAL E RELAÇÕES RACIAIS NO BRASIL CONTEMPORÂNEO.',
      description: 'Texto no site: MPRJ.',
      imageImportName: RelacoesRaciaisBrasil,
      altText: 'Imagem do artigo de título: TEORIA SOCIAL E RELAÇÕES RACIAIS NO BRASIL CONTEMPORÂNEO.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://www.mprj.mp.br/documents/20184/172682/teoria_social_relacoes_sociais_brasil_contemporaneo.pdf',
    },
    {
      contentType: 'article',
      title: 'ALGUNS TERMOS E CONCEITOS PRESENTES NO DEBATE SOBRE RELAÇÕES RACIAIS NO BRASIL: UMA BREVE DISCUSSÃO.',
      description: 'Texto no site: Geledes',
      imageImportName: RelacoesRaciaisDiscussao,
      altText: 'Imagem do artigo de título: ALGUNS TERMOS E CONCEITOS PRESENTES NO DEBATE SOBRE RELAÇÕES RACIAIS NO BRASIL: UMA BREVE DISCUSSÃO.',
      buttonLabel: 'Ler texto no site',
      buttonUrl:
        'https://www.geledes.org.br/wp-content/uploads/2017/03/Alguns-termos-e-conceitos-presentes-no-debate-sobre-Rela%C3%A7%C3%B5es-Raciais-no-Brasil-uma-breve-discuss%C3%A3o.pdf',
    },
    {
      contentType: 'article',
      title: 'O Genocídio do Negro Brasileiro: processo de um racismo mascarado',
      description: 'Texto no site: USP.',
      imageImportName: Genocidio,
      altText: 'Imagem da capa do livro de título: O Genocídio do Negro Brasileiro: processo de um racismo mascarado.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://edisciplinas.usp.br/pluginfile.php/4288330/mod_resource/content/1/O%20Genoc%C3%ADdio%20do%20Negro%20Brasileiro.pdf',
    },
    {
      contentType: 'book',
      title: 'Pequeno manual antirracista - Djamila Ribeiro',
      imageImportName: PequenoManual,
      altText: 'Capa do Livro Pequeno Manual Antirracista. A capa é da cor amarela, e contém o título do livro escrito com letras grandes no centro na cor preta.',
    },
    {
      contentType: 'book',
      title: 'Cotas raciais - Lívia Sant’Anna Vaz.',
      imageImportName: CotasRaciais,
      altText: 'Capa do livro Cotas Raciais, que apresenta o título do livro e uma foto da autora, nas cores preto e branco.',
    },
    {
      contentType: 'book',
      title: 'Empoderamento - Joice Berth',
      imageImportName: Empoderamento,
      altText: 'Capa do livro Empoderamento, que apresenta o título do livro e uma foto da autora, nas cores preto e branco.',
    },
    {
      contentType: 'book',
      title: 'O pacto da branquitude - Cida Bento',
      imageImportName: PactoDaBranquitude,
      altText: 'Capa do Livro O Pacto da Branquitude, que apresenta o título do livro no centro e algumas formas aleatórias em volta, nas cores rosa, preto e laranja.',
    },
    {
      contentType: 'book',
      title: 'O Genocídio do negro brasileiro: Processo de um racismo mascarado - Abdias do Nascimento',
      imageImportName: GenocidioDoNegro,
      altText: 'Capa do livro com o título O Genocídio do negro brasileiro, e ao fundo a foto de uma mulher negra.',
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Conteúdos que a Inboarding indica. ✨
        </Typography>
        <Typography variant="body2">Pra você ir além desse manual.</Typography>
      </Box>
      <ListOfContents contents={listOfContents} />
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
        </Stack>
      </Box>
    </Stack>
  );
};
