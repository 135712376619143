import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';
import { ListOfPeople } from '../ListOfPeople';

import Akin from './images/inspirations/Akin.jpg';
import LucianaViegas from './images/inspirations/LucianaViegas.jpg';
import SabrinaNascimento from './images/inspirations/SabrinaNascimento.jpg';
import DanielaSales from './images/inspirations/DanielaSales.jpg';
import TioFaso from './images/inspirations/TioFaso.jpg';
import JuneGomes from './images/inspirations/JuneGomes.png';

export const AutisticPeopleInspirations = () => {
  const ListOfInspirationalPeople = [
    {
      info: {
        name: 'Akin Sá',
        description:
          'É estudante de Farmácia na Universidade Federal do Paraná, Transmaculino não-binário, autista e ativista na luta pela diversidade no Brasil. Akin é criador de conteúdos sobre o autismo no instagram através da página “Hey Autista”, quer saber mais?',
      },
      image: {
        personImportName: Akin,
        altText: 'Foto de Akin em pé, vestindo uma blusa amarela, de chapéu e óculos.',
      },
      buttons: [
        {
          label: 'Siga Akin no Instagram',
          url: 'https://instagram.com/heyautista?igshid=YmMyMTA2M2Y',
        },
      ],
    },
    {
      info: {
        name: 'Luciana Viegas',
        description:
          'É autista, mulher preta e mãe de um menino autista não oralizado. Formada em pedagogia, atua como educadora popular e inclusiva desde 2014. Em 2020, decidiu criar o instagram @umamaepretaeautistafalando para falar sobre seu diagnóstico de autismo tardio, a vida de uma mãe preta neurodivergente. É ativista da luta anticapacitista e antirracista. É Idealizadora do Movimento Vidas Negras Com Deficiência Importam no BRASIL. Foi eleita D-30 Desability impact List pela Diversability em 2022.',
      },
      image: {
        personImportName: LucianaViegas,
        altText: 'Foto da Luciana sorrindo. Ela usa uma camiseta preta e uma blusa florida.',
      },
      buttons: [
        {
          label: 'Siga Luci no Instagram',
          url: 'https://instagram.com/umamaepretaautistafalando?igshid=YmMyMTA2M2Y',
        },
        {
          label: 'Siga Luci no Twitter',
          url: 'https://twitter.com/luu_viegas',
        },
      ],
    },
    {
      info: {
        name: 'Sabrina Nascimento',
        description:
          'É autista, mãe atípica, funcionária pública e Mestranda em Educação. Idealizadora da página “Uma autista diferentona”, Sabrina compartilha conteúdos de extrema relevância sobre autismo em pessoas adultas e negras e como isso impacta a sua vida.',
      },
      image: {
        personImportName: SabrinaNascimento,
        altText: 'Foto da Sabrina sorrindo. Ela está maquiada e usando brincos nas orelhas.',
      },
      buttons: [
        {
          label: 'Siga Sabrina no Instagram',
          url: 'https://instagram.com/uma.autista.diferentona?igshid=YmMyMTA2M2Y',
        },
        {
          label: 'Siga Sabrina no Twitter',
          url: 'https://twitter.com/sabrinaautie',
        },
      ],
    },
    {
      info: {
        name: 'Daniela Sales ou apenas "Dani"',
        description: (
          <>
            <p>
              Autista (TEA) diagnosticada somente na fase adulta, aos 42 anos. O grau TEA era leve ou grau 1 até novembro de 2021 e após novos testes e avaliação psiquiátrica para
              laudo médico o grau mudou para moderado ou grau 2.
            </p>
            <p>
              Já teve e ainda tem outros transtornos: Transtorno Obsessivo Compulsivo (TOC), traços do Transtorno de Personalidade Histriônica, Ansiedade, Depressão, Síndrome do
              Pânico e Transtorno Alimentar (Obesidade). Ela fala sobre os transtornos associados ao autismo e minhas experiências no dia a dia: na vida pessoal e profissional.
            </p>
          </>
        ),
      },
      image: {
        personImportName: DanielaSales,
        altText: 'Foto da Dani sorrindo.',
      },
      buttons: [
        {
          label: 'Siga Dani no Instagram',
          url: 'https://instagram.com/vidadeautista?igshid=YmMyMTA2M2Y',
        },
      ],
    },
    {
      info: {
        name: 'Tio Faso',
        description:
          'Tio Faso tem 39 anos, é autista diagnosticado de forma tardia, pai de uma criança autista de quase 4 anos, e criador da página “Se eu falar não sai direito”. Ele tem trabalhado ativamente para conscientizar as pessoas sobre autismo e como incluir autistas na sociedade.',
      },
      image: {
        personImportName: TioFaso,
        altText: 'Foto de Tio Faso sorrindo.',
      },
      buttons: [
        {
          label: 'Siga Tio Faso no Instagram',
          url: 'https://instagram.com/seeufalarnaosaidireito?igshid=YmMyMTA2M2Y',
        },
        {
          label: 'Siga Tio Faso no Twitter',
          url: 'https://twitter.com/tiofaso',
        },
      ],
    },
    {
      info: {
        name: 'June Gomes',
        description: (
          <>
            <p>
              June Gomes tem 22 anos, é Transgênero Não binário, Pessoa Negra, Autista e Alto habilidosa/Superdotada, Mãe da Esther de 7 anos, que também é autista. Tia da Angela
              Beatriz, Neta da dona Nazaré e Do “Seu” Nem. Tutora de 4 cães e 14 aves de diferentes espécies, escritora, artesã, educadora parental, vestibulanda, divulgadora
              científica e uma mente criativa.
            </p>
            <p>
              June tem hiperfoco em animais (mais especificamente animais exóticos), em neurociência (desenvolvimento infantil/humano), Baco exu do blues (todas suas músicas e
              obras), trabalhos manuais, organização (arrumar seus livros, armários, roupas e qualquer coisa que esteja fora do local. Tem seletividade alimentar desde a infância,
              e já teve um padrão bem restrito a ponto de precisar suplementar todos os anos.
            </p>
          </>
        ),
      },
      image: {
        personImportName: JuneGomes,
        altText: 'Selfie de June Gomes. Ela está vestindo uma blusa vermelha com bolinhas brancas.',
      },
      buttons: [
        {
          label: 'Siga June no Instagram',
          url: 'https://instagram.com/gomes_june?igshid=YmMyMTA2M2Y',
        },
      ],
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Inspirações de pessoas com autismo. ✨
        </Typography>
        <Typography variant="body2">Redes sociais de pessoas com autismo pra você se inspirar todos os dias.</Typography>
      </Box>
      <ListOfPeople people={ListOfInspirationalPeople} />
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-autistas/datas-importantes" />
        </Stack>
      </Box>
    </Stack>
  );
};
