import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useFeatureFlags } from '../../config/FeatureFlagsApp';

import { Box, Typography, Paper, Button } from '@mui/material';

import PersonIcon from '@mui/icons-material/Person';
import ExploreIcon from '@mui/icons-material/Explore';
import ArticleIcon from '@mui/icons-material/Article';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import Diversity2Icon from '@mui/icons-material/Diversity2';

interface IActionsButtonProps {
  label: string;
  icon: React.ReactNode;
  onClick: () => void;
}

const ActionsButton = ({ label, icon, onClick }: IActionsButtonProps) => {
  return (
    <Button sx={{ padding: '0', ml: '10px', mb: '10px' }} onClick={onClick}>
      <Paper elevation={3} sx={{ minWidth: '145px', py: '20px', borderRadius: 4 }}>
        <Box>{icon}</Box>
        <Box sx={{ alignContent: 'center', minHeight: '48px', display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" sx={{ textTransform: 'none', width: '95%' }}>
            {label}
          </Typography>
        </Box>
      </Paper>
    </Button>
  );
};

export const DashboardActions = () => {
  const navigate = useNavigate();
  const handlerRoutePage = (path: string) => navigate(path);
  const featureFlags = useFeatureFlags();

  return (
    <Box sx={{ display: 'flex', alignItems: 'stretch', flexWrap: 'wrap', minWidth: 300, width: '100%' }}>
      <ActionsButton label="Atualizar perfil" icon={<PersonIcon fontSize="large" color="primary" />} onClick={() => handlerRoutePage('/perfil/editar')} />
      <ActionsButton label="Minha Jornada" icon={<ExploreIcon fontSize="large" color="primary" />} onClick={() => handlerRoutePage('/jornada')} />
      <ActionsButton label="Glossário" icon={<ArticleIcon fontSize="large" color="primary" />} onClick={() => handlerRoutePage('/termos')} />
      <ActionsButton label="Perguntas" icon={<LiveHelpIcon fontSize="large" color="primary" />} onClick={() => handlerRoutePage('/faq')} />
      {featureFlags.showDiversityGroups ? (
        <ActionsButton label="Grupos de diversidade" icon={<Diversity2Icon fontSize="large" color="primary" />} onClick={() => handlerRoutePage('/diversidade')} />
      ) : null}
    </Box>
  );
};
