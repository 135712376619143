import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import WorkEnvironment from './images/illustrations/workEnvironment.svg';

export const BlindPeopleWorkEnvironment = () => {
  const blindPeopleTextItems = [
    {
      title: 'Avise a pessoa antes de sair de um ambiente.',
      description:
        'Quando estiver conversando com uma pessoa com deficiência visual e precisar sair avise. Caso você saia, a pessoa com deficiência visual não vai perceber e vai continuar conversando quando na verdade você saiu. Isso deixa a pessoa com deficiência visual muito desconfortável.',
    },
    {
      title: 'Diga seu nome quando você falar.',
      description:
        'Em reuniões, seja online ou presencial, é importante dizer qual é o seu nome antes de começar a falar. Com o tempo a pessoa com deficiência visual vai começar a reconhecer sua voz e isso vai deixar de ser necessário, mas não custa ter esse cuidado para facilitar a comunicação.',
    },
    {
      title: 'Faça uma autodescrição de você.',
      description: (
        <>
          Você pode se apresentar focando na sua aparência física como a cor dos olhos, cabelo e cor da pele. Além de vestimentas como acessórios na cabeça, rosto ou corpo. Diga
          também o cenário em que você está, isso é importante para chamadas de vídeo, por exemplo.
          <ul>
            <li>
              <strong>Em casos de chamada de vídeo:</strong>
            </li>
            Ex.: Olá, eu sou fulana. Sou preta, tenho olhos castanhos escuros, cabelos curtos e pretos. Estou usando uma tiara vermelha na cabeça e brincos de argola grandes. Estou
            vestindo uma camisa branca sem estampa e atrás de mim tem uma parede rosa com alguns quadros dos meus super-heróis favoritos.
            <li>
              <strong>Em casos de contato presencial:</strong>
            </li>
            Ex.: Olá, eu sou fulana. Sou branca, tenho olhos verdes escuros, cabelos médios e loiros. Uso óculos e uma tiara no cabelo. Sou alta e estou usando um vestido preto sem
            estampa.
          </ul>
        </>
      ),
    },
    {
      title: 'Evite mudar os móveis de lugar.',
      description:
        'Se uma pessoa com deficiência visual se acostumou com uma posição dos móveis é importante manter o ambiente assim. Uma lixeira que é mudada de posição pode fazer com que a pessoa cega esbarre. Não é proibido mudar a organização dos móveis no espaço, mas é recomendado fazer isso com uma certa distância de tempo e avaliar a real necessidade dessas mudanças. Aproveite para não deixar as portas meio abertas, o ideal é que elas fiquem completamente abertas ou fechadas.',
    },
    {
      title: 'Descreva figurinhas quando usar.',
      description:
        'Figurinhas não têm texto alternativo. Se uma pessoa com deficiência visual está no grupo de comunicação da empresa (WhatsApp, Slack, MS Teams, etc), tenha o cuidado de descrever a figurinha para que ela consiga entender o conteúdo. Ex.: “figurinha de uma mulher branca dançando de um jeito engraçado”. Você também pode usar emojis no lugar de figurinhas, pois eles têm texto alternativo, só cuidado com excessos pra não prejudicar o entendimento.',
    },
    {
      title: 'Evite comparar pessoas com deficiência visual entre elas.',
      description:
        'Assim como pessoas sem deficiência não são iguais, pessoas com deficiência visual também não são. A única coisa em comum entre pessoas com deficiência visual é a cegueira que inclusive pode variar - como vimos no início deste manual. É importante respeitar essas diferenças, ou seja, se você trabalhou com uma pessoa cega ou com baixa visão que conseguia fazer uma certa tarefa, respeite caso a pessoa que você trabalha hoje não se sinta à vontade em fazer essa mesma tarefa.',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Criando um ambiente de trabalho mais saudável pra pessoas com deficiência visual. 💪
        </Typography>
        <Typography variant="body2">Boas práticas para a rotina de trabalho.</Typography>
      </Box>
      <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <img src={WorkEnvironment} alt="Imagem ilustrativa de dois homens conversando, um deles é cego." style={{ maxWidth: '430px', width: '100%' }} aria-hidden="true" />
      </Stack>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={blindPeopleTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-com-deficiencia-visual/autoconfianca" />
        </Stack>
      </Box>
    </Stack>
  );
};
