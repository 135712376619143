import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

export const AutisticPeopleAccessibleOnboarding = () => {
  const autisticPeopleTextItems = [
    {
      title: 'Saiba como perguntar na entrevista',
      description: (
        <>
          Aprenda a perguntar de forma acessível e respeitosa. Você pode tentar algumas formas de perguntar se a pessoa tem alguma deficiência:
          <ul>
            <li>“Você tem alguma questão de saúde que pode piorar?”</li>
            <li>“Se isso acontecer enquanto você está aqui na empresa, o que podemos fazer?”</li>
          </ul>
        </>
      ),
    },
    {
      title: 'Evite onboardings cansativos',
      description:
        'O onboarding, normalmente, tem muitas informações. É comum que sejam dias cheios, com informações importantes. Então, é importante dividir as informações e apresentá-las de forma visual. Quando possível, enviá-las de forma escrita para que as pessoas possam revisar e acessar quando quiserem.',
    },
    {
      title: 'Deixe claro quais são as atividades',
      description: (
        <>
          O onboarding precisa trazer informações básicas sobre o trabalho que a pessoa vai desenvolver na empresa. Também é importante informar quais as palavras que a empresa
          mais usa no dia a dia pra evitar que a pessoa se sinta perdida. Muitas informações sem necessidade podem atrapalhar a pessoa na integração. Então, busque apresentar as
          informações no momento em que elas são realmente importantes.
          <p>
            <strong>Ex.:</strong> Talvez não faça sentido explicar como é o recesso de final de ano se o onboarding está sendo realizado em abril.
          </p>
        </>
      ),
    },
    {
      title: 'Acessibilidade não termina no onboarding',
      description:
        'Existem várias práticas diárias que podem tornar a empresa mais acessível. Reuniões, e-mails, interações, planejamento e organização diária, tudo isso tem relação com acessibilidade.',
    },
    {
      title: 'Cuide de você',
      description: (
        <>
          Existem comentários ofensivos e que algumas pessoas usam sem perceber. Listamos alguns deles:
          <ul>
            <li>
              <strong>“Você é tão inteligente, não pode ser autista”</strong>
            </li>
            Esse comentário parte da ideia de que ser autista é algo ruim.
            <li>
              <strong>“Você conseguiu concluir a faculdade então seu autismo deve ser leve”</strong>
            </li>
            Pessoas autistas têm níveis de suporte diferentes e todas elas são tão capazes como qualquer outra pessoa.
            <li>
              <strong>“Tão bonita, nem parece autista”</strong>
            </li>
            Presumir que uma pessoa autista não pode ser bonita, bem sucedida, ter um relacionamento e ser feliz é capacitista e ofensivo.
          </ul>
        </>
      ),
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Aprenda a ser acessível antes e depois do sim.
        </Typography>
        <Typography variant="body2">Entrevistas e onboarding para remoto ou presencial.</Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={autisticPeopleTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-autistas/ambiente-de-trabalho" />
        </Stack>
      </Box>
    </Stack>
  );
};
