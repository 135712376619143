import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import DatasImportantes from './images/illustrations/datas-importantes.svg';

export const BlackPeopleImportantDates = () => {
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Datas importantes. 🤝
        </Typography>
        <Typography variant="body2">Datas que valorizam e marcam a história de pessoas negras.</Typography>
      </Box>
      <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <img src={DatasImportantes} alt="Imagem ilustrativa de uma mulher adicionando pontos em um calendário." style={{ maxWidth: '430px', width: '100%' }} aria-hidden="true" />
      </Stack>
      <Stack sx={{ pl: 1, pb: 1, pt: 1, justifyContent: 'left', alignItems: 'left' }}>
        <Typography variant="body1" sx={{ pt: 1, pl: 1 }} paragraph={true}>
          <p>
            <strong>09 de Janeiro de 2003 </strong>
          </p>
          <p>Promulgada a Lei Federal Nº 10.639, que rege a obrigatoriedade do ensino da história afro-brasileira na rede oficial de ensino.</p>
        </Typography>
        <Typography variant="body1" sx={{ pl: 1 }} paragraph={true}>
          <p>
            <strong>25 de Janeiro de 1835 na cidade de Salvador/BA </strong>
          </p>
          <p>Aconteceu a Revolta dos Malês, rebelião contra o escravismo e a imposição da religião católica.</p>
        </Typography>
        <Typography variant="body1" sx={{ pl: 1 }} paragraph={true}>
          <p>
            <strong>31 de Janeiro de 1986, União dos Palmares/AL </strong>
          </p>
          <p>Tombamento da Serra da Barriga, berço da resistência negra, onde nasceu o Quilombo dos Palmares e viveu seu maior líder, Zumbi dos Palmares.</p>
        </Typography>
        <Typography variant="body1" sx={{ pl: 1 }} paragraph={true}>
          <p>
            <strong>07 de Julho de 1978 </strong>
          </p>
          <p>Fundado o Movimento Negro Unificado Contra a Discriminação Racial.</p>
        </Typography>
        <Typography variant="body1" sx={{ pl: 1 }} paragraph={true}>
          <p>
            <strong>25 de Julho </strong>
          </p>
          <p>Dia Internacional da Mulher Negra.</p>
        </Typography>
        <Typography variant="body1" sx={{ pl: 1 }} paragraph={true}>
          <p>
            <strong>20 de novembro</strong>
          </p>
          <p>Dia Nacional da Consciência Negra.</p>
        </Typography>
        <Typography variant="body1" sx={{ pl: 1 }} paragraph={true}>
          <p>
            <strong>20 de dezembro </strong>
          </p>
          <p>Lei nº 7437/85 Estabelece como contravenção penal o tratamento discriminatório no mercado de trabalho, por motivo de raça/cor.</p>
        </Typography>
      </Stack>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-negras/indicacao" />
        </Stack>
      </Box>
    </Stack>
  );
};
