import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

export const PeopleWithTDAHImportantDates = () => {
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Datas importantes. 🤝
        </Typography>
        <Typography variant="body2">Datas que valorizam e marcam a história de pessoas com TDAH.</Typography>
      </Box>
      <Stack sx={{ pl: 1, pb: 1, pt: 1, justifyContent: 'left', alignItems: 'left' }}>
        <Typography variant="body1" sx={{ pt: 1, pl: 1 }} paragraph={true}>
          <p>
            <strong>21 de Março</strong>
          </p>
          <p>Semana de Celebração da Neurodiversidade. Existe desde 2018 para celebrar as diferenças neurológicas da população, sobretudo as com deficiências.</p>
        </Typography>
        <Typography variant="body1" sx={{ pl: 1 }} paragraph={true}>
          <p>
            <strong>13 de Julho</strong>
          </p>
          <p>
            Dia internacional de sensibilização ao TDAH. Data para lembrarmos de todas as crianças e adultos no mundo que apresentam o quadro de TDAH – Transtorno do Déficit de
            Atenção e Hiperatividade.
          </p>
        </Typography>
      </Stack>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-com-tdah/conteudos" />
        </Stack>
      </Box>
    </Stack>
  );
};
