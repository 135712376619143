import { useMutation, useQueryClient } from 'react-query';

import { QueryKeys } from '../../config/QueryKeys';
import { updateSequenceCompleted } from '../../services/api/sequence';

export const useSequenceUpdateComplete = () => {
  const queryClient = useQueryClient();
  return useMutation(updateSequenceCompleted, {
    onMutate: () => {
      queryClient.cancelQueries(QueryKeys.getSequenceData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.getSequenceData);
    },
  });
};
