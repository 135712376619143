import React from 'react';

import { Stack, Box, Typography, Grid } from '@mui/material';
import { List, ListItemButton, ListItemText } from '@mui/material';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { ModalTextButton } from '../ModalTextButton';
import { useNavigate } from 'react-router-dom';

import blindPeopleGraphic from './images/blindPeopleGraphic.jpeg';

export const DiversityBlindPeople = () => {
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Pessoas com deficiência visual. 👨‍🦯👩‍🦯
        </Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1, mt: 2 }}>
        <Typography variant="body1" component="p">
          De acordo com um levantamento de dados realizado pelo IBGE em 2010, no Brasil existem mais de 6,5 milhões de pessoas com alguma deficiência visual que se distribuem da
          seguinte forma:
        </Typography>
        <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <img
            src={blindPeopleGraphic}
            alt="Imagem de um óculos, cada lente do óculos traz um dado importante. Na lente da esquerda, está escrito que mais de 500 mil pessoas não enxergam (têm deficiência visual). Na lente da direita, a informação diz que 6 milhões de pessoas tem baixa visão (grande dificuldade de enxergar)."
            style={{ maxWidth: '750px', width: '100%', maxHeight: '430px' }}
          />
        </Stack>
        <Typography variant="body1" component="p" sx={{ pb: 1 }}>
          Outros 29 milhões de pessoas declararam possuir alguma dificuldade permanente de enxergar, ainda que usando óculos ou lentes.
        </Typography>
        <Typography variant="body1" component="p">
          Além disso, as regiões Nordeste e Sudeste possuem o maior número de pessoas com deficiência visual no país.
        </Typography>
        <Typography variant="body1" component="p">
          De acordo com informações do MTE - Ministério do Trabalho e Emprego, pessoas com baixa visão ou cegueira representam apenas 16,68% das pessoas com deficiência que possuem
          trabalho formal. A maior parte delas (44,46%) possuem deficiência física e a segunda maior parte possuem deficiência auditiva (17,89%).
        </Typography>
        <Typography variant="h6" component="h3" sx={{ pt: 1, pb: 1 }}>
          Pessoas com deficiência visual são diversas:
        </Typography>
        <Typography variant="body1" component="p" sx={{ pb: 2 }}>
          Pessoas com deficiência visual têm histórias diferentes e isso faz com prefiram tecnologias e adaptações diferentes. Muito além da deficiência, é importante entender que
          pessoas com deficiência visual são profissionais. Ou seja, há uma diversidade de trabalhos que podem ser feitos por uma pessoa com deficiência visual. Ter esse grupo de
          pessoas na sua empresa pode contribuir para a produtividade e inovação.
        </Typography>
        <Box sx={{ pl: 1, mt: 2 }}>
          <BlindPersonGroups />
        </Box>
        <Typography variant="body1" component="p" sx={{ pt: 3 }}>
          Pensando nisso, a equipe da Inboarding criou esse manual pra te ajudar a tornar o ambiente de trabalho da sua empresa mais saudável, e favorável ao crescimento de pessoas
          com deficiência visual.
        </Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1, mt: 2 }}>
        <Typography variant="h6" component="h3">
          Vamos conversar sobre
        </Typography>
        <BlindPersonTopics />
      </Box>
    </Stack>
  );
};

const BlindPersonGroups = () => {
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} md={4}>
        <ModalTextButton
          title="Nasceram videntes"
          description={
            <>
              <p>
                Uma pessoa pode ficar cega ou ter com deficiência visual por vários motivos: acidentes, doenças, entre outros. É importante entender que a experiência de quem nasce
                com com deficiência visual e de quem passa a conviver com uma deficiência visual são diferentes.
              </p>
              <p>É importante entender que nem todo mundo teve contato com certas coisas, saber disso é necessário para personalizar a experiência da pessoa.</p>
            </>
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <ModalTextButton
          title="Nasceram cegas"
          description={
            <>
              <p>A cegueira pode acontecer desde o nascimento, nesse caso é definida de cegueira congênita.</p>
              <p>Quando uma criança nasce com complicações no campo visual que são detectadas na gestação ou através do teste do olhinho.</p>
            </>
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <ModalTextButton
          title="Tem visão monocular ou baixa visão"
          description={
            <>
              <p>
                Com visão igual ou inferior a 20%, as pessoas monoculares têm dificuldades com noções de distância, profundidade e espaço. Isso tudo prejudica a coordenação motora
                e o equilíbrio. A deficiência pode acontecer por algum tipo de acidente ou por doenças.
              </p>
              <p>
                Pessoas com baixa visão tem 30% ou menos de visão no melhor olho, o que faz com que elas tenham dificuldades de ver detalhes no dia a dia. Por exemplo: veem as
                pessoas, mas não reconhecem o rosto. Enxergam o texto, porém não identificam as palavras.
              </p>
            </>
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <ModalTextButton
          title="Leem em braile"
          description={
            <>
              <p>
                O Braile é uma forma de escrita normalmente impressa em relevo que faz com que pessoas cegas ou com baixa visão possam ler sem auxílio de outras tecnologias. Para
                ler em braile a pessoa com deficiência visual precisa ser alfabetizada no código. Importante lembrar que nem todas as pessoas com deficiência visual usam braile por
                vários motivos, seja por impossibilidade ou por preferir outras tecnologias.
              </p>
            </>
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <ModalTextButton
          title="Contam com cão guia "
          description={
            <>
              <p>
                Os cães-guia são animais que passam por treinamento pra se tornarem acompanhantes de pessoas cegas ou com baixa visão. O objetivo é dar mais segurança e agilidade
                para essas pessoas.
              </p>
              <p>
                Os cães conduzem a pessoa por muitas horas do dia, inclusive parando em meios-fios antes de atravessarem ruas e desviando-as de obstáculos. Eles são treinados para
                evitar distrações, como cheiros, pessoas, sons e outros animais.
              </p>
              <p>
                É importante lembrar que quando a pessoa com deficiência visual estiver acompanhada por um cão-guia, ele está a trabalho, por isso, evite fazer carinho ou desviar o
                foco do animal.
              </p>
            </>
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <ModalTextButton
          title="Usam bengala"
          description={
            <>
              <p>Você sabia que existem três tipos de bengala usadas por pessoas com deficiência visual?</p>
              <p>
                <strong>Diferença das cores das bengalas:</strong>
              </p>
              <ul>
                <li>
                  <strong>Branca:</strong> usada por pessoas que são cegas, ou seja, que possuem ausência total da visão.
                </li>
                <li>
                  <strong>Verde:</strong> usada por pessoas que possuem baixa visão, ou seja, que têm visão parcial e conseguem identificar a luz. Enxergam vultos e em alguns casos
                  podem identificar pessoas e objetos.
                </li>
                <li>
                  <strong>Vermelha:</strong> usada por pessoas que são surdas e cegas, ou seja a pessoa que não enxerga e não escuta. Para a sua comunicação é necessário diferentes
                  formas como:
                  <p>- Tadoma que é uma Língua de sinais em que a pessoa surdocega posiciona o polegar na boca e no queixo da pessoa falante para se comunicar.</p>
                  <p>- Língua de sinais tátil que é adaptada ao tato pra que a pessoa surdocega converse com as mãos.</p>
                </li>
              </ul>
            </>
          }
        />
      </Grid>
    </Grid>
  );
};

export const TopiceMenuListItem = ({ title, text, handerClick }: { title: string; text: string; handerClick: () => void }) => {
  return (
    <ListItemButton disableGutters={true} onClick={handerClick} sx={{ p: 1 }}>
      <ListItemText primary={title} secondary={text} />
      <ArrowForwardIosIcon sx={{ fontSize: 15 }} />
    </ListItemButton>
  );
};

const BlindPersonTopics = () => {
  const navigate = useNavigate();
  const handlerRoutePage = (path: string) => navigate(path);

  return (
    <>
      <List component="nav">
        <TopiceMenuListItem
          title="Como receber uma pessoa com deficiência visual no time"
          text="Ferramentas, divisão do trabalho e empatia."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-deficiencia-visual/como-receber');
          }}
        />
        <TopiceMenuListItem
          title="Criando um ambiente de trabalho mais saudável pra pessoas com deficiência visual"
          text="Boas práticas para a rotina de trabalho."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-deficiencia-visual/ambiente-de-trabalho');
          }}
        />
        <TopiceMenuListItem
          title="Aprendendo a ter mais autoconfiança"
          text="Para pessoas com deficiência visual que querem ter mais confiança no trabalho e na vida"
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-deficiencia-visual/autoconfianca');
          }}
        />
        <TopiceMenuListItem
          title="Uma conversa com quem lidera pessoas com deficiência visual"
          text="O papel da liderança para criar oportunidades."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-deficiencia-visual/para-quem-lidera');
          }}
        />
        <TopiceMenuListItem
          title="O que é capacitismo e como evitar"
          text="O time e a liderança na criação de uma rotina com respeito."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-deficiencia-visual/capacitismo');
          }}
        />
        <TopiceMenuListItem
          title="Reuniões, e-mails e dia a dia"
          text="Dicas práticas pra você incluir pessoas com deficiência visual na rotina."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-deficiencia-visual/dia-a-dia');
          }}
        />
        <TopiceMenuListItem
          title="Inspirações de pessoas cegas"
          text="Redes sociais de pessoas com deficiência visual pra você se inspirar todos os dias. "
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-deficiencia-visual/inspiracoes');
          }}
        />
        <TopiceMenuListItem
          title="Conquistas incríveis"
          text="Uma lista de pessoas com deficiência visual que fizeram história."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-deficiencia-visual/conquistas-incriveis');
          }}
        />
        <TopiceMenuListItem
          title="Datas importantes"
          text="Datas que valorizam e marcam a história de pessoas com deficiência visual."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-deficiencia-visual/datas-importantes');
          }}
        />
        <TopiceMenuListItem
          title="Conteúdos que a Inboarding indica"
          text="Pra você ir além desse manual."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-deficiencia-visual/indicacao');
          }}
        />
      </List>
    </>
  );
};
