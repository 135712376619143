import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import NaoSaoRivais from './images/nao-sao-rivais.svg';

export const WomenAreNotOpponents = () => {
  const womenTextItems = [
    {
      title: 'Acolha mulheres perto de você.',
      description:
        'Sabe aquela colega de trabalho que acabou de chegar? Preste apoio a ela, compartilhe o que você sabe e dê espaço pra que ela cresça junto com você. Isso ajuda a tornar o ambiente de trabalho melhor e mais favorável pra mulheres.',
    },
    {
      title: 'Ajude a combater interrupções.',
      description:
        'Quando perceber que uma mulher está sendo interrompida sugira para todas as pessoas que não ocorram mais interrupções. Uma sugestão de fala é: “o que acham de deixarmos ela terminar a fala sem mais interrupções?”',
    },
    {
      title: 'Experimente se unir ao invés de competir.',
      description:
        'Tem outra profissional tão incrível ou até mais habilidosa que você no trabalho? Maravilha, busque aprender com ela, ninguém sabe de tudo e ter pessoas competentes perto de você é uma ótima oportunidade de crescer mais rápido. Juntas chegamos mais longe do que sozinhas.',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Mulheres não são rivais. 😉
        </Typography>
        <Typography variant="body2">Como mulheres podem se unir no trabalho e na vida.</Typography>
      </Box>
      <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <img src={NaoSaoRivais} aria-hidden="true" alt="Ilustração de duas mulheres compartilhando projetos" style={{ maxWidth: '100%', width: '430px', height: '300px' }} />
      </Stack>
      <Box sx={{ pl: 1 }}>
        <Typography variant="body1" component="p">
          <p>Práticas de como mulheres podem se unir no trabalho e na vida.</p>
          <p>
            Aprendemos a competir com outras mulheres desde crianças. Essa é uma forma de nos dividir e diminuir nossas forças, mas podemos mudar essa situação sendo empáticas com
            outras mulheres (sororidade), nos unindo e não sendo rivais.
          </p>
        </Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={womenTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/mulheres/que-estao-comecando" />
        </Stack>
      </Box>
    </Stack>
  );
};
