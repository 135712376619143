import toast, { ToastOptions } from 'react-hot-toast';

export class MessageToaster {
  static loading(message: string, options?: ToastOptions): string {
    return toast.loading(message, options);
  }

  static success(message: string, options?: ToastOptions): string {
    return toast.success(message, options);
  }

  static error(message: string, options?: ToastOptions): string {
    return toast.error(message, options);
  }

  static dismiss(messageId: string): void {
    toast.dismiss(messageId);
  }

  static remove(messageId: string): void {
    toast.remove(messageId);
  }

  static custom(customMessage: JSX.Element, opts?: ToastOptions): void {
    toast(customMessage, opts);
  }
}
