import axios from 'axios';
import { API } from './fetcher';
import { UserProfileData } from '../../hooks/queries';

interface EmployeePhotoMetadata {
  contentType: string;
  title: string;
  description: string;
  url?: string;
}

const photoProfileCreateLink = async (profileId: string, metadata: EmployeePhotoMetadata) => {
  try {
    const payload = {
      data: metadata,
    };
    const { data, status } = await API.post(`/profile/photo/${profileId}`, payload);
    return { data, status };
  } catch (error) {
    return { error };
  }
};

export const uploadPhotoProfile = async (profileData: UserProfileData, photoFile: Blob): Promise<boolean> => {
  const metadata: EmployeePhotoMetadata = {
    title: profileData.first_name || '',
    description: `Employee picture from person id ${profileData.id}`,
    contentType: photoFile.type,
  };

  const { data } = await photoProfileCreateLink(profileData.id || '', metadata);
  const s3UploadUrl = data?.data?.url;
  if (!s3UploadUrl) {
    return false;
  }

  await uploadFileToS3(s3UploadUrl, photoFile, metadata);
  return true;
};

const uploadFileToS3 = async (s3PutObjectUrl: string, photoFile: Blob, metadata: EmployeePhotoMetadata) => {
  return axios.put(s3PutObjectUrl, photoFile, {
    headers: {
      'Content-Type': metadata.contentType,
    },
  });
};
