import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

export const PeopleWithTDAHWorkEnvinroment = () => {
  const PeopleWithTDAHTextItems = [
    {
      title: 'Respeite a forma de trabalho',
      description:
        'Pessoas com TDAH podem ter uma forma diferente para fazer algumas tarefas.  É possível que precisem organizar o tempo de outros jeitos através de intervalos, por exemplo. Podem ter dificuldade com cobrança por horários e normalmente preferem fazer entregas por produtividade.',
    },
    {
      title: 'Pergunte como elas preferem receber as tarefas',
      description:
        'Pessoas com TDAH podem ter preferências diferentes. Algumas podem preferir saber o que precisa ser feito através de áudio, outras por texto e algumas através de reuniões. Pergunte como elas preferem e, sempre que possível, respeite isso. Ainda que haja uma preferência por alguma forma de comunicação, a pessoa com TDAH pode se adaptar à forma como a empresa costuma se comunicar. Mas, sempre que possível, busque entender como melhorar a comunicação com as pessoas com quem você trabalha.',
    },
    {
      title: 'Cuidado com reuniões longas',
      description:
        'Reuniões com mais de 1h podem ser ruins pra que as pessoas se mantenham engajadas e possam entender o que precisa ser feito. Prefira sempre reuniões mais objetivas e focadas. Pra ajudar nisso, prepare uma pauta do que vai ser conversado e compartilhe isso com os participantes antes da reunião. Caso a reunião seja longa, ao final reforce o objetivo do encontro e o que a equipe precisa fazer.',
    },
    {
      title: 'Se prepare pras reuniões',
      description:
        'Existem formas de tornar uma reunião mais produtiva e simples pra pessoas com TDAH. Uma dessas formas é incentivar que  cada pessoa do time escreva antes da reunião sobre o que vai apresentar. Além disso, você pode divulgar essas pautas do time antes da reunião acontecer, mas cuidado com longos e-mails.',
    },
    {
      title: 'Deixe que elas acessem um ambiente mais tranquilo',
      description:
        'Se possível, é importante que a pessoa com TDAH acesse ambientes de trabalho individuais, com fluxo reduzido de pessoas e poucos estímulos visuais e sonoros. Isso é essencial pra que as pessoas com TDAH do time possam trabalhar de forma mais tranquila.',
    },
    {
      title: 'Prefira comunicações em texto ou áudio',
      description:
        'Dessa forma elas conseguem ler ou reproduzir quantas vezes quiserem, o que é melhor pro entendimento. Ao invés de fazer reuniões pra toda e qualquer pauta, prefira se comunicar por texto ou áudio. Você também pode - caso queira fazer a reunião - enviar uma ata ao fim dela, dessa forma a pessoa pode revisar o que foi conversado.',
    },
    {
      title: 'Deixe que as pessoas perguntem quantas vezes quiserem',
      description:
        'Pessoas com TDAH podem se sentir envergonhadas por perguntar várias vezes. Pra evitar isso, explique que elas podem perguntar e tirar dúvidas sempre que quiserem. Mostre que a empresa é um espaço aberto e que perguntar faz parte disso.',
    },
    {
      title: 'Organize tarefas e prazos de forma visual',
      description:
        'Algumas pessoas com TDAH preferem quando as demandas são apresentadas de forma organizada e visual. Você pode fazer isso no miro, jira, trello ou canva que são ferramentas online que dividem as informações por quadros, onde é possível identificar prioridades e prazos.',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 3 }}>
        <Typography variant="h5" color="primary" component="h2">
          Ambiente de trabalho mais saudável pra pessoas com TDAH. 👍
        </Typography>
        <Typography variant="body2">Boas práticas para a rotina de trabalho.</Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={PeopleWithTDAHTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-com-tdah/autoconfianca" />
        </Stack>
      </Box>
    </Stack>
  );
};
