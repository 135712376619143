import { SurveyAnswersData } from '../../hooks/queries/useSurveyAnswerUpdate';
import { API } from './fetcher';

export const getSurveyDataPreview = async (journeyId: string, stepId: string) => {
  const { data, status } = await API.get(`/surveys/preview/${journeyId}/${stepId}`);
  return { data, status };
};

export const getSurveyDataQuestions = async (shortId: string) => {
  const { data, status } = await API.get(`/surveys/${shortId}`);
  return { data, status };
};

export const updateSurveyAsnwersData = async ({ shortId, answers }: { shortId: string; answers: SurveyAnswersData }) => {
  try {
    const payload = {
      data: answers,
    };
    const { data, status } = await API.post(`/surveys/${shortId}`, payload);
    return { data, status };
  } catch (error) {
    return { error };
  }
};

export const updateSurveyAsnwersDraftData = async ({ shortId, answers }: { shortId: string; answers: SurveyAnswersData }) => {
  try {
    const payload = {
      data: answers,
    };
    const { data, status } = await API.put(`/surveys/draft/${shortId}`, payload);
    return { data, status };
  } catch (error) {
    return { error };
  }
};
