import React from 'react';
import { Box, Typography } from '@mui/material';

import { AppDialog } from '../../components';

interface Props {
  openDialog: boolean;
  onClose?: () => void;
}

export const HelpLogin = ({ openDialog, onClose }: Props) => {
  return (
    <AppDialog title="Ajuda com o acesso ao portal" open={openDialog} onClose={onClose}>
      <Box mb={3}>
        <Typography variant="h6" gutterBottom>
          Qual e-mail eu preciso utilizar para acessar o portal?
        </Typography>
        <Typography>
          Você pode utilizar seu e-mail pessoal antes do seu primeiro dia de trabalho, o mesmo que você utilizou no processo seletivo, ou o seu e-mail de trabalho no primeiro dia
          de trabalho e depois.
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="h6" gutterBottom>
          Posso acessar o portal do colaborador antes do primeiro dia de trabalho?
        </Typography>
        <Typography>Sim, você pode acessar sim. Utilize o seu e-mail pessoal, o mesmo que você utilizou no processo seletivo.</Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="h6" gutterBottom>
          O que é esse aplicativo de portal do colaborador?
        </Typography>
        <Typography>
          Este aplicativo do portal do colaborador é para ajudar você antes do seu primeiro dia de trabalho e durante sua jornada na empresa. É um aplicativo disponibilizado em
          parceria com a sua empresa e a Inboarding, responsáveis pelo aplicativo.
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography variant="h6" gutterBottom>
          Qual é a versão deste aplicativo?
        </Typography>
        <Typography>
          Você está acessado o aplicativo do portal do colaborador na versão {BUILD_PACKAGE_VERSION}, com build de {new Date(BUILD_VERSION_DATETIME).toLocaleString()} no ambiente
          de API {BUILD_APP_ENV}.
        </Typography>
      </Box>
    </AppDialog>
  );
};
