export const setStorangeToken = (token: string | null) => {
  return token && localStorage.setItem('@inboarding_token', token);
};

export const getStorangeToken = () => {
  return localStorage.getItem('@inboarding_token');
};

export const removeStorangeToken = () => {
  return localStorage.removeItem('@inboarding_token');
};
