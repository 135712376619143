import { useQuery } from 'react-query';
import { QueryKeys } from '../../config/QueryKeys';
import { requestEmployeeJourneySteps } from '../../services/api/journey';

export enum PeopleJourneyStepsStatus {
  Removed = 'REMOVED', // Manually marked as remove when start journey
  Scheduled = 'SCHEDULED',
  Sending = 'SENDING',
  Sent = 'SENT',
  Delivered = 'DELIVERED',
  Opened = 'OPENED',
  Clicked = 'CLICKED',
  Rejected = 'REJECTED', // Eg: inbox full or out of space
  Bounced = 'BOUNCED', // invalid email or blocked by the server
  Error = 'ERROR', // general error
  ManualRead = 'MANUAL_READ', // Manually confirmed as Read in the portal
  Confirmed = 'CONFIRMED', // Task completed (submmited form, survey, sequence)
  Disabled = 'DISABLED', // Disabled when person is disabled or email bounced
}

export enum MessageBlocksTypes {
  CallToAction = 'CALL-TO-ACTION',
  Poll = 'POLL',
  Task = 'TASK',
  Form = 'FORM',
  Sequence = 'SEQUENCE',
  PortalLink = 'PORTAL-LINK',
}

export enum PeopleJourneyStepsChannel {
  Email = 'EMAIL',
  Slack = 'SLACK',
  Whatsapp = 'WHATSAPP',
}

export interface WhatsAppSendMessage {
  message?: {
    body?: string;
    template?: string;
    image?: string;
    interactive?: string;
    location?: string;
    contacts?: string;
  };
  type?: unknown;
  to?: {
    phoneNumber: string;
  };
}

export interface StepEmailMessage {
  from?: string;
  to?: string[];
  cc?: string[];
  replyTo?: string[];
  subject?: string;
  html?: string;
  text?: string;
}

export interface StepSlackMessage {
  text?: string;
  blocks?: unknown[];
  username?: string;
  thread_ts?: string;
}

export interface StepMessageHtml {
  text: string;
  actions: string;
}

export interface PeopleJourneyStepsData {
  id: string;
  id_short: string;
  step_name: string;
  step_channel: PeopleJourneyStepsChannel;
  schedule_date: string;
  status: PeopleJourneyStepsStatus;
  message_sent: StepEmailMessage | StepSlackMessage | WhatsAppSendMessage;
  sent_date: string;
  createdAt: string;
  message_html?: StepMessageHtml | undefined;
  message_block_type?: MessageBlocksTypes | undefined;
}

export const useGetJourneySteps = () => {
  return useQuery(QueryKeys.getJourney, async () => requestEmployeeJourneySteps(), { refetchOnWindowFocus: false });
};
