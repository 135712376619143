const APP_ENVIRONMENT = process.env.REACT_APP_ENV;

export abstract class Environment {
  static isProduction(): boolean {
    return APP_ENVIRONMENT === 'prod';
  }
  static isStaging(): boolean {
    return APP_ENVIRONMENT === 'dev';
  }
  static isTest(): boolean {
    return APP_ENVIRONMENT === 'test';
  }
  static isLocal(): boolean {
    return APP_ENVIRONMENT === 'local';
  }
}
