import { useMutation, useQueryClient } from 'react-query';

import { QueryKeys } from '../../config/QueryKeys';
import { requestTokenByEmail, validateToken } from '../../services/api';

export const usePostTokenByEmailMutation = (_requestTokenByEmail = requestTokenByEmail) => {
  const queryClient = useQueryClient();
  return useMutation(_requestTokenByEmail, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.postTokenByEmail);
    },
  });
};

export const useGetValidateTokenQuery = (_validateToken = validateToken) => {
  return useMutation(() => _validateToken());
};
