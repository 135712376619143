import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';

import VencendoTDAH from './images/contents/VencendoTDAH.jpg';
import TrabalharComTDAH from './images/contents/TrabalharComTDAH.jpg';
import JobSearch from './images/contents/JobSearch.jpg';
import ADHDAtWork from './images/contents/ADHDAtWork.jpg';
import ADHDAtWork2 from './images/contents/ADHDAtWork2.jpg';
import Impactos from './images/contents/Impactos.jpg';
import AttentionDeficit from './images/contents/AttentionDeficit.jpg';

import { ListOfContents } from '../ListOfContents';

export const PeopleWthTDAHContents = () => {
  const listOfContents = [
    {
      contentType: 'video',
      title: 'TDAH - Uma pessoa que faz muita coisa ao mesmo tempo | Haroldo Guimarães | TEDxFortaleza',
      channel: 'TEDx Talks',
      videoUrl: 'https://www.youtube.com/embed/ekyIkGxoDt4',
    },
    {
      contentType: 'video',
      title: 'Mitos e Verdades sobre o TDAH | Luis Rohde | TEDxUFRGS',
      channel: 'TEDx Talks',
      videoUrl: 'https://www.youtube.com/embed/6Xzha28mfV0',
    },
    {
      contentType: 'video',
      title: 'Autismo e TDAH - aprendizagem, carreira e desafios - como usar a neurodiversidade a seu favor',
      channel: 'Lygia Pereira - Espectro Feminino',
      videoUrl: 'https://www.youtube.com/embed/zWlzkbGSJW8',
    },
    {
      contentType: 'book',
      title: 'Vencendo o transtorno de déficit de atenção/hiperatividade adulto',
      imageImportName: VencendoTDAH,
      altText:
        'Capa do livro Vencendo TDAH Adulto. A capa contpem o título, um fundo na cor amarela e a imagem de uma seta em volta de um globo cheio de labirintos, como se indicasse o caminho certo.',
    },
    {
      contentType: 'article',
      title: 'Como trabalhar com TDAH?',
      description: 'Texto no site: LinkedIn',
      imageImportName: TrabalharComTDAH,
      altText: 'Imagem da tela inicial do artigo no site LinkedIn.',
      buttonLabel: 'Ler artigo no site',
      buttonUrl: 'https://www.linkedin.com/pulse/como-trabalhar-em-com-tdah-jorge-silva/',
    },
    {
      contentType: 'article',
      title: 'The Job Search with ADHD: How to Communicate Your Strengths',
      description: 'Texto no site: Enna',
      imageImportName: JobSearch,
      altText: 'Imagem da tela inicial do artigo no site JobSearch.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://enna.org/the-job-search-with-adhd-how-to-communicate-your-strengths/',
    },
    {
      contentType: 'article',
      title: 'I Am an Employer - orientações para pessoas empregadoras sobre como lidar com pessoas com TDAH no ambiente de trabalho',
      description: 'Texto no site: ADHD At Work',
      imageImportName: ADHDAtWork,
      altText: 'Imagem da tela inicial do artigo no site ADHD At Work.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://adhdatwork.add.org/i-am-an-employer/',
    },
    {
      contentType: 'article',
      title: 'Providing Accommodations for ADHD at Work',
      description: 'Texto no site: ADHD At Work',
      imageImportName: ADHDAtWork2,
      altText: 'Imagem da tela inicial do artigo no site ADHD At Work.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://adhdatwork.add.org/accommodations-for-adhd/',
    },

    {
      contentType: 'article',
      title: 'Gênero, raça e a inclusão de pessoas com deficiência visual',
      description: 'Texto no site: Editora Realize',
      imageImportName: TrabalharComTDAH,
      altText: 'Imagem da tela inicial do artigo no site Editora Realize.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://www.editorarealize.com.br/editora/anais/enlacando/2017/TRABALHO_EV072_MD1_SA23_ID1414_12082017140511.pdf',
    },
    {
      contentType: 'article',
      title: 'Gênero, raça e a inclusão de pessoas com deficiências visuais em escolas de Rio Branco/Acre',
      description: 'Texto no site: Editora Realize',
      imageImportName: JobSearch,
      altText: 'Imagem da tela inicial do artigo no site Editora Realize.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://www.editorarealize.com.br/artigo/visualizar/30593',
    },
    {
      contentType: 'article',
      title: 'Os impactos gerados pelo Transtorno do Déficit de Atenção com Hiperatividade no processo de aprendizagem de adultos no Ensino Superior.',
      description: 'Autores: Carla Gabriela Nobre da Silveira e mais.',
      imageImportName: Impactos,
      altText: 'Imagem do site que leva até o artigo.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://periodicos.ufrn.br/casoseconsultoria/article/view/30415/16451',
    },
    {
      contentType: 'article',
      title: 'Attention-deficit hyperactivity disorder stigma: The silent barrier to care.',
      description: 'Autores: Renata Schoeman e Tawni Voges',
      imageImportName: AttentionDeficit,
      altText: 'Imagem do site que leva até o artigo.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://pubmed.ncbi.nlm.nih.gov/36569805/',
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Conteúdos que a Inboarding indica. ✨
        </Typography>
        <Typography variant="body2">Pra você ir além desse manual.</Typography>
      </Box>
      <ListOfContents contents={listOfContents} />
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
        </Stack>
      </Box>
    </Stack>
  );
};
