import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import AccessibleContent from './images/illustrations/accessible-content.jpg';

export const DeafPeopleCreateAccessibleContent = () => {
  const deafTextItems = [
    {
      title: 'Nada de textos longos.',
      description: 'Evite textos longos nas apresentações e prefira tópicos em forma de lista que tornem o conteúdo mais simples.',
    },
    {
      title: 'Confira se os vídeos tem legenda.',
      description: 'Sempre que for apresentar vídeos pra equipe, confira se o material está legendado em português.',
    },
    {
      title: 'Evite expressões idiomáticas.',
      description:
        'Evite expressões idiomáticas e linguísticas como ‘pé direito’, por exemplo. Pessoas surdas podem entender literalmente a palavra e não o sentido figurado, pois o português é a segunda língua delas.',
    },
    {
      title: 'Evite palavras formais.',
      description: 'Use palavras simples. Evite formalismos, termos corporativos e palavras muito longas.',
    },
    {
      title: 'Explique termos e siglas.',
      description: 'Explique sempre os termos técnicos e siglas. Isso é muito útil quando sua fala está sendo interpretada para Libras e também pra compreensão do texto.',
    },
    {
      title: 'Converse com a intérprete de Libras.',
      description:
        'Combine o tempo de antecedência que você deve seguir para enviar a apresentação pra quem vai interpretar sua fala pra Libras. Se tiver, também compartilhe materiais com significado de termos técnicos da empresa.',
    },
    {
      title: 'Cuidado com o contraste de cores.',
      description: 'Garanta que a apresentação seja legível e evite usar cores que não garantam contraste de cor, assim você melhora a leitura.',
    },
    {
      title: 'Conte com dispositivos de transcrição automática.',
      description:
        'Se a pessoa surda concordar, opte por um dispositivo que faça transcrição automática de fala e confirme se a sua velocidade de fala está adequada pra ele. O meet é uma das plataformas que contam com esse recurso.',
    },
    {
      title: 'Evite usar termos estrangeiros.',
      description: 'Mas se usar, coloque a tradução ao lado entre parênteses. Isso vai ajudar a pessoa surda a entender o conteúdo.',
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          <strong>Como criar conteúdos acessíveis pra apresentações e eventos: 📊</strong>
        </Typography>
        <Typography variant="body2">Dicas pra você criar apresentações acessíveis no trabalho.</Typography>
      </Box>
      <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <img src={AccessibleContent} alt="Imagem ilustrativa de um homem desenhando em um quadro." style={{ maxWidth: '430px', width: '100%' }} aria-hidden="true" />
      </Stack>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={deafTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-surdas/trabalho-remoto" />
        </Stack>
      </Box>
    </Stack>
  );
};
