import axios from 'axios';
import { getStorangeToken } from '../../utils';

export const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

// eslint-disable-next-line
API.interceptors.request.use(async (config: any) => {
  const TOKEN: string | null = getStorangeToken();

  config.headers['Authorization'] = TOKEN ? `Bearer ${TOKEN}` : '';

  return config;
});
