import React from 'react';
import { Button } from '@mui/material';

import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export const NavigationButtonNext = ({ label, route }: { label: string; route: string }) => {
  return (
    <Button component={Link} to={route} size="small" variant="contained" endIcon={<ArrowForwardIosIcon />}>
      {label}
    </Button>
  );
};
