import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import Troy from './images/achievements/Troy.jpg';
import Gladis from './images/achievements/Gladis.jpg';
import Guilherme from './images/achievements/Guilherme.jpg';
import Makkari from './images/achievements/Makkari.jpg';
import Maria from './images/achievements/Maria.jpg';
import Marlee from './images/achievements/Marlee.jpg';
import Millie from './images/achievements/Millie.jpg';
import Natalia from './images/achievements/Natalia.jpg';
import Rose from './images/achievements/Rose.jpg';
import Sueli from './images/achievements/Sueli.jpg';
import Thaisy from './images/achievements/Thaisy.jpg';
import Thomas from './images/achievements/Thomas.jpg';
import { ListOfPeople } from '../ListOfPeople';

export const DeafPeopleIncredibleAchievements = () => {
  const ListOfPeopleAndAchievements = [
    {
      info: {
        name: 'Troy Kotsur',
        description: 'Em 2022 Troy Kotsur levou a estatueta de Melhor Ator Coadjuvante pela sua interpretação no filme “No Ritmo do Coração”.',
      },
      image: {
        personImportName: Troy,
        altText: 'Foto de Troy segurando o troféu do Oscar e fazendo o sinal de "eu te amo" com a outra mão. Está vestindo uma roupa preta com uma boina preta.',
      },
      buttons: [],
    },
    {
      info: {
        name: 'Marlee Matlin',
        description:
          'Atuou em No Ritmo do Coração, vencedor do último Oscar de Melhor Filme, é a mais nova integrante do conselho de diretores da Academia de Artes e Ciências Cinematográficas. Marlee foi a primeira atriz surda a ganhar um Oscar, por seu trabalho em Filhos do Silêncio em 1986.',
      },
      image: {
        personImportName: Marlee,
        altText: 'Foto de Marlee segurando o troféu do Oscar e fazendo o sinal de "eu te amo" com a outra mão. Está vestindo uma roupa lilás e sorrindo.',
      },
      buttons: [
        {
          label: 'Ler mais sobre Marlee',
          url: 'https://ensino.digital/blog/conheca-a-atriz-surda-que-ganhou-o-oscar-de-melhor-atriz',
        },
      ],
    },
    {
      info: {
        name: 'Lauren Ridloff',
        description:
          'Atriz, interpretou a heroína surda do filme ‘Eternos’ da Marvel. Desde o início da divulgação do filme da Marvel ‘Eternos’, que tem uma heroína surda, o interesse em aprender língua de sinais aumentou 250%, nos Estados Unidos, segundo pesquisa da Preply.',
      },
      image: {
        personImportName: Makkari,
        altText: 'Foto da heroína Makkari da série Marvel Eternos.',
      },
      buttons: [
        {
          label: 'Ler mais sobre Makkari',
          url: 'https://www.librasol.com.br/eternos-aumentou-interesse-do-publico-por-lingua-de-sinais-diz-estudo/',
        },
      ],
    },
    {
      info: {
        name: 'Thaisy Payo',
        description:
          'Você sabia que existe um concurso de Miss Surda? Em 2013 uma participante brasileira, Thaisy Payo, ganhou o concurso na República Tcheca. Thaisy venceu  o concurso mundial “Miss surda 2013”.',
      },
      image: {
        personImportName: Thaisy,
        altText: 'Foto de Thaisy sorrindo, usando um vestido dourado, uma faixa escrito "Miss Deaf World 2013" e uma coroa.',
      },
      buttons: [
        {
          label: 'Ler mais sobre Thaisy',
          url: 'https://www.gazetadopovo.com.br/vida-e-cidadania/paranaense-vence-o-concurso-miss-surda-2013-na-republica-tcheca-cdnxuo5dx6fxbkr8ygeqmangu/',
        },
      ],
    },
    {
      info: {
        name: 'Maria Otávia Cardazzo',
        description: 'É atriz, já trabalhou em novelas da Rede Globo, e tem uma perda severa de audição. Faz leitura labial para atuar e depende de aparelhos auditivos pra ouvir.',
      },
      image: {
        personImportName: Maria,
        altText: 'Foto de Maria sorrindo. Ela está usando um vestido preto.',
      },
      buttons: [],
    },
    {
      info: {
        name: 'Millie Bobby Brown',
        description: 'Millie Bobby Brown, estrela da série Stranger Things da Netflix, é totalmente surda de um dos ouvidos.',
      },
      image: {
        personImportName: Millie,
        altText: 'Foto de Millie acenando para as câmeras. Ela está usando uma roupa branca e dourada e óculos.',
      },
      buttons: [],
    },
    {
      info: {
        name: 'Gladis Perlin',
        description: 'A primeira surda a obter título de doutora no Brasil. e é uma grande referência no campo dos Estudos Surdos.',
      },
      image: {
        personImportName: Gladis,
        altText: 'Foto de Gladis fazendo sinais com as mãos.',
      },
      buttons: [
        {
          label: 'Ler mais sobre Gladis',
          url: 'https://www.furg.br/noticias/noticias-arquivo/furg-18536',
        },
      ],
    },
    {
      info: {
        name: 'Guilherme Maia',
        description:
          'Ganhou medalha inédita de ouro na prova de 200 metros livres na Surdolimpíada de Verão na Turquia. A competição, que é organizada pelo Comitê Internacional de Esportes para Surdos, acontece a cada quatro anos.',
      },
      image: {
        personImportName: Guilherme,
        altText: 'Foto de Guilherme dentro da piscina olímpica, com os braços abertos.',
      },
      buttons: [
        {
          label: 'Ler mais sobre Guilherme',
          url: 'https://agenciabrasil.ebc.com.br/geral/noticia/2017-07/atletas-brasileiros-superam-desempenho-em-surdolimpiada-na-turquia',
        },
      ],
    },
    {
      info: {
        name: 'Natália Martins',
        description: 'Primeira jogadora de voleibol surda a atuar profissionalmente no Brasil.',
      },
      image: {
        personImportName: Natalia,
        altText: 'Foto de Nathália vestindo uma roupa verde e amarela. Ela está de pé na areia e com uma bola de vôlei embaixo do braço.',
      },
      buttons: [
        {
          label: 'Ler mais sobre Natália',
          url: 'https://www.olimpiadatododia.com.br/volei/239807-nati-martins-vira-embaixadora-de-empresa-de-aparelhos-para-surdos/',
        },
      ],
    },
    {
      info: {
        name: 'Rose Ayling-Ellis',
        description: 'Atriz britânica surda, é estrela da primeira campanha da Barbie com aparelhos auditivos.',
      },
      image: {
        personImportName: Rose,
        altText: 'Foto de Rose sorrindo e segurando uma boneca Barbie na mão esquerda. Ela está usando uma roupa rosa.',
      },
      buttons: [
        {
          label: 'Ler mais sobre Rose',
          url: 'https://www.librasol.com.br/atriz-britanica-surda-e-estrela-da-primeira-campanha-da-barbie-com-aparelhos-auditivos/',
        },
      ],
    },
    {
      info: {
        name: 'Sueli',
        description: 'Primeira personagem surda da turma da Mônica. É uma garota de 9 anos que ama esportes e se comunica pela Língua Brasileira de Sinais (Libras).',
      },
      image: {
        personImportName: Sueli,
        altText: 'Imagem de Sueli fazendo um sinal de Libras com as mãos. Ela usa uma camiseta rosa e uma calça jeans, com tênis amarelos.',
      },
      buttons: [
        {
          label: 'Ler mais sobre Sueli',
          url: 'https://independente.com.br/sueli-e-a-primeira-personagem-surda-da-turma-da-monica/',
        },
      ],
    },
    {
      info: {
        name: 'Thomas Edison',
        description: 'O inventor da lâmpada, se tornou surdo durante a adolescência.',
      },
      image: {
        personImportName: Thomas,
        altText: 'Foto de Thomas Edison segurando uma lâmpada na mão direita. Ele está vestindo terno.',
      },
      buttons: [],
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          <strong>Conquistas incríveis. 🏆</strong>
        </Typography>
        <Typography variant="body1" sx={{ pt: 2 }}>
          Uma lista de pessoas surdas que fizeram história.
        </Typography>
      </Box>
      <ListOfPeople people={ListOfPeopleAndAchievements} />
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-surdas/inspiracoes" />
        </Stack>
      </Box>
    </Stack>
  );
};
