import React from 'react';

import { Typography } from '@mui/material';

export const FormQuestionLoading = () => {
  return (
    <Typography variant="body1" component="p">
      Carregando dados do formulário...
    </Typography>
  );
};
