/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import type { SubmissionData, SubmissionDispatchers } from '../FormQuestions/quillFormTypes';

import { AsyncQuillForm } from '../FormQuestions/AsyncQuillForm';
import { setStorangeToken } from '../../utils';

import { useGetDataSurveyPreview } from '../../hooks/queries/useSurveyPreviewQuery';

import { FormQuestionLoadError } from '../FormQuestions/FormQuestionLoadError';
import { FormSurveyLoading } from './FormSurveyLoading';
import { FormSurveyInvalidNotFound } from './FormSurveyInvalidNotFound';

export const FormSurveyPreview = () => {
  const { journeyId, stepId } = useParams();
  const [searchParams] = useSearchParams();
  const answerDefaultFirstValue = searchParams.get('answerValue');
  const token = searchParams.get('token');
  if (token) {
    setStorangeToken(token);
  }

  if (!journeyId || !stepId) {
    return <FormSurveyInvalidNotFound />;
  }

  const { data, isLoading, isError, error } = useGetDataSurveyPreview(journeyId, stepId);

  if (isLoading) {
    return <FormSurveyLoading />;
  }

  if (isError) {
    return <FormQuestionLoadError error={error} />;
  }

  if (!data) {
    return <FormSurveyInvalidNotFound />;
  }

  const parsedBlocks = data.data.data;

  if (!parsedBlocks || !parsedBlocks.length) {
    return <FormSurveyInvalidNotFound />;
  }

  if (answerDefaultFirstValue) {
    const firstBlockQuestion = parsedBlocks[0];
    if (firstBlockQuestion && firstBlockQuestion.attributes) {
      firstBlockQuestion.attributes.defaultValue = answerDefaultFirstValue;
    }
  }

  const previewBlocks = [
    {
      name: 'welcome-screen',
      id: 'preview-welcome-screen',
      attributes: {
        label: 'Você está abrindo a pesquisa em modo visualização e os dados não serão salvos.',
        description: 'O usuário ao acessar o link oficial não vai ver essa etapa.',
        buttonText: 'Visualizar a pesquisa!',
      },
    },
    ...parsedBlocks,
  ];
  const onFormSubmit = (data: SubmissionData, { completeForm, setIsSubmitting }: SubmissionDispatchers) => {
    setTimeout(() => {
      setIsSubmitting(false);
      completeForm();
    }, 3000);
  };

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <AsyncQuillForm formId={1} blocks={previewBlocks} onSubmit={onFormSubmit} />
    </div>
  );
};
