import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import { TextListAccordion } from '../TextListAccordion';
import Autoconfianca from './images/autoconfianca.svg';

export const WomenSelfConfidence = () => {
  const womenTextItems = [
    {
      title: 'Nada nunca será perfeito e isso é ótimo.',
      description:
        'A mulher perfeita não existe e por mais óbvio que isso pareça, muitas mulheres ainda se cobram por resultados sempre perfeitos no trabalho e na vida, mas nem sempre isso será possível. Afinal, o que é ser suficiente? Falhar faz parte, o importante é o que você faz a partir disso. ',
    },
    {
      title: 'Você não é impostora, abrace as oportunidades.',
      description:
        'Síndrome do impostor pode acontecer com todo mundo, mas com as mulheres isso é ainda mais delicado, pois duvidam da sua capacidade o tempo todo. O primeiro passo pra combater isso é entender que você estudou, trabalhou duro, conquistou o lugar que está hoje e não tem nada de errado com isso.',
    },
    {
      title: 'Valorize sua trajetória.',
      description:
        'Duvidar das suas capacidades, se colocar num lugar de alguém que não sabe, falar de si própria com desprezo, todas essas atitudes são injustas com você mesma. Valorize quem você lutou pra ser, e isso começa da forma como você fala de si mesma. ',
    },
    {
      title: ' Acolha e comemore os bons feedbacks. ',
      description:
        'Você merece comemorar os bons feedbacks e não apenas prestar atenção aos negativos. Você se esforçou e ser recompensada por isso faz parte. Uma vida de muita autocobrança pode te adoecer, seja mais gentil consigo mesma.',
    },
    {
      title: 'Dividir tarefas é essencial pra sua saúde mental.',
      description:
        'Estamos acostumadas a nos sobrecarregar com várias tarefas sozinhas. “Eu dou conta” ou “Deixa que eu faço” são expressões que falamos o tempo todo, mas isso só nos prejudica porque é impossível dar conta de tudo sozinha e viver uma vida plena e saudável. Aprenda a confiar no outro e responsabilizar outras pessoas por tarefas que elas podem fazer também.',
    },
    {
      title: 'Não espere saber tudo pra se candidatar a uma vaga.',
      description:
        'Muitas mulheres deixam de se candidatar a uma ótima vaga porque não têm uma ou algumas das competências. Ter autoconfiança é entender também que nunca saberemos de tudo, o que não podemos é perder uma oportunidade por conta disso. Mulheres tendem a se cobrar mais que homens e com isso se invalidam muito mais.',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Autoconfiança no trabalho. 💪
        </Typography>
        <Typography variant="body2">O primeiro passo pra se valorizar é confiar em você mesma.</Typography>
      </Box>
      <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <img src={Autoconfianca} aria-hidden="true" alt="Ilustração de uma mulher segurando cartazes" style={{ maxWidth: '100%', width: '430px', height: '300px' }} />
      </Stack>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={womenTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/mulheres/autopromocao" />
        </Stack>
      </Box>
    </Stack>
  );
};
