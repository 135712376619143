import { useQuery } from 'react-query';

import { QueryKeys } from '../../config/QueryKeys';
import { getSurveyDataPreview } from '../../services/api/survey-data';

export const useGetDataSurveyPreview = (journeyId: string, stepId: string) => {
  return useQuery(QueryKeys.getSurveyPreview, () => getSurveyDataPreview(journeyId, stepId), {
    refetchOnWindowFocus: false,
    retry: false,
  });
};
