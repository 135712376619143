import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import QueEstaoComecando from './images/illustrations/que-estao-comecando.svg';

export const BlackPeopleWhoAreStarting = () => {
  const blackPeopleTextItems = [
    {
      title: ' Acredite em você, mas respeite seu processo.',
      description:
        'As pessoas têm histórias e realidades diferentes. Isso não significa que você não pode sonhar, mas que não precisa se cobrar tanto para alcançar o que uma pessoa com muitos mais recursos que você alcançou. Faça o que você pode fazer com os recursos que tem hoje, lembrando que disciplina não é sinônimo de falta de qualidade de vida e tempo livre.',
    },
    {
      title: 'Fale sobre suas conquistas e aprendizados.',
      description:
        'Certificados de cursos e eventos podem ser compartilhados nas redes sociais. Além disso, você pode aproveitar o espaço pra compartilhar aprendizados sobre a sua área, conhecer e se inspirar em outras trajetórias.',
    },
    {
      title: ' Evite se comparar com outras pessoas.',
      description:
        'A comparação pode levar ao estresse, além de gerar ansiedade e até aumentar as chances de causar depressão. Respeite o seu processo e esforços, tenha suas próprias metas reais e pensadas pra sua realidade, afinal sua história é única.',
    },
    {
      title: ' Pense em você.',
      description:
        'Ter dedicação no trabalho é bom, mas não quando isso prejudica sua vida pessoal ao ponto de te distanciar de pessoas queridas e de você mesmo. Se cobrar pra se mostrar como alguém competente é algo que acontece com mais intensidade entre pessoas negras, justamente por conta da falta de oportunidades, mas é importante lembrar que encontrar o equilíbrio é a forma mais saudável de crescer.',
    },
    {
      title: 'Você não é uma pessoa impostora.',
      description:
        'Acreditar em você é sobre valorizar e reconhecer suas entregas, tendo certeza que seus esforços estão dando resultados. Então, se imponha, pois isso não fará de você uma pessoa arrogante. Aprenda também a ter referências saudáveis que inspirem e ajudem você a ter mais confiança.',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Conselhos para pessoas negras que estão começando. 😉
        </Typography>
        <Typography variant="body2">Como ajudar profissionais iniciantes a se sentirem em segurança.</Typography>
      </Box>
      <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <img
          src={QueEstaoComecando}
          aria-hidden="true"
          alt="Ilustração de um homem negro e uma mulher negra segurando juntos um cartaz que apresenta um gráfico de metas. A mulher está de costas para a tela e o homem está de frente."
          style={{ maxWidth: '100%', width: '430px', height: '300px' }}
        />
      </Stack>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={blackPeopleTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-negras/rede-de-apoio" />
        </Stack>
      </Box>
    </Stack>
  );
};
