import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

export const PwPDLeaders = () => {
  const PwPDTextItems = [
    {
      title: 'Inclusão é mais que contratar',
      description:
        'Mais que vagas afirmativas, é preciso criar um plano de desenvolvimento para as pessoas com deficiência física. Isso é importante para que elas se sintam parte da empresa.',
    },
    {
      title: 'Importância do trabalho remoto',
      description:
        'A necessidade de se locomover pode ser um desafio pra pessoa com deficiência física. Caso a empresa possa oferecer a modalidade remota ou híbrida, a pessoa pode se beneficiar, já que assim é possível trabalhar sem precisar enfrentar desafios como transporte público. Conversar com a pessoa sobre isso também pode ser bom tanto pra empresa quanto pra ela.',
    },
    {
      title: 'Crie um plano de desenvolvimento',
      description:
        'O número de pessoas com deficiência física em cargos de liderança ainda é muito pequeno, para mudar esse cenário você pode criar planos de desenvolvimento que contribuam para o crescimento dessas pessoas na empresa. Nesse plano você pode abordar habilidades técnicas e comportamentais.',
    },
    {
      title: 'Crie oportunidades em diferentes setores',
      description:
        'Não recrute pessoas com deficiência física apenas para ocupar os cargos relacionados a Diversidade & Inclusão ou para preencher as vagas afirmativas. Crie oportunidades para que elas ocupem diferentes cargos em vários setores. Isso é importante para tornar a empresa diversa.',
    },
    {
      title: 'Promova a equidade salarial',
      description: (
        <>
          Quem tem deficiência ganha menos do que outras pessoas,{' '}
          <a href="https://www.revistas.usp.br/wp/noticias/pessoas-com-deficiencia-a-inclusao-e-as-questoes-de-salario-e-emprego-no-mercado-de-trabalho/">
            de acordo com pesquisa da (OMS) de 2013
          </a>
          . Uma das razões para que isso aconteça é a ideia capacitista de que esses profissionais vão ser menos produtivos para a empresa. Segundo{' '}
          <a href="https://www.ihu.unisinos.br/622216-efetiva-inclusao-de-deficientes-fisicos-no-mundo-do-trabalho-ainda-esta-diante-de-abismo-a-ser-transposto-entrevista-especial-com-marina-sampaio">
            Marina Sampaio, auditora-fiscal do Trabalho
          </a>
          , pessoas sem deficiência com ensino superior completo recebiam em média R$ 3.175,68, enquanto os com deficiência ganhavam R$ 1.392,00. É importante eliminar essas
          barreiras.
        </>
      ),
    },
    {
      title: 'Dê feedback sobre o trabalho delas',
      description:
        'Pessoas com deficiência têm o direito de saber sobre o trabalho delas. Isso é importante para que elas se sintam acolhidas e tenham espaço para que se desenvolvam profissionalmente.',
    },
    {
      title: 'Escolha alguém pra apoiar novos talentos',
      description:
        'Também é possível abrir a opção da pessoa se voluntariar, ao invés de você escolher. Essas pessoas podem ser chamadas de padrinhos, madrinhas, parças ou o que você quiser. São pessoas que recebem e acompanham talentos desde o primeiro dia até o fim do primeiro trimestre na empresa. Essa recepção acontece para qualquer pessoa, mas pode ser ainda mais impactante para pessoas com  deficiência que em alguns casos precisam de apoio para fazer tarefas como ir ao banheiro ou se alimentar.',
    },
    {
      title: 'Abra oportunidades pra pessoas que estão começando',
      description:
        'Muito além de ter pessoas com deficiência na empresa, é importante dar oportunidade também pra quem está começando. Você pode fazer isso abrindo vagas para profissionais juniores, também é possível criar bootcamps ou desafios, onde a premiação é ter uma oportunidade no seu time, dessa forma você mede a capacidade técnica da pessoa. É importante que esses desafios não sejam muito longos. Só crie os desafios e o bootcamp caso você tenha real interesse em fazer contratações.',
    },
    {
      title: 'Acompanhe a inclusão',
      description:
        'Foque no  desenvolvimento, formação e bem-estar pra ajudar na adaptação da pessoa com deficiência. Tenha atenção às barreiras que podem surgir e no relacionamento da pessoa com o time, principalmente sobre falas e comportamentos capacitistas. Além disso, dê oportunidade para que a pessoa com deficiência dê feedback sobre as ações de acessibilidade da empresa. Você pode fazer isso através de pesquisas anônimas.',
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Uma conversa com quem lidera pessoas com deficiência física. 🤝
        </Typography>
        <Typography variant="body2">Como criar oportunidades reais e justas.</Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={PwPDTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-com-deficiencia-fisica/inspiracoes" />
        </Stack>
      </Box>
    </Stack>
  );
};
