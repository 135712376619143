import React from 'react';

// eslint-disable-next-line @typescript-eslint/ban-types
export function useDebounceEffect(fn: Function, waitTime: number, deps: unknown[]): void {
  React.useEffect(() => {
    const time = setTimeout(() => {
      fn(...deps);
    }, waitTime);
    return (): void => {
      clearTimeout(time);
    };
  }, deps);
}
