import React from 'react';

interface IDateFormattedProps {
  dateISO: string;
}

export const DateFormatted = ({ dateISO }: IDateFormattedProps) => {
  const date = new Date(dateISO);

  const day = date.getUTCDate();
  const month = ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'][date.getUTCMonth()];
  const year = date.getUTCFullYear();

  return <>{`${day} de ${month} de ${year}`}</>;
};
