import React, { useState } from 'react';
import { Container, Box, Button } from '@mui/material';

import { FormLogin } from './FormLogin';
import { Confirmation } from './Confirmation';
import { HelpLogin } from './HelpLogin';
import { TopToolbar } from '../../components';
import { FooterInboarding } from '../../components/FooterInboarding';

import { getStorangeAuthUser } from '../../utils/authUserStorange';

export const Login = () => {
  const [email, setEmail] = useState('');
  const [toggleLogin, setToggleLogin] = useState(true);
  const [openHelp, setOpenHelp] = useState(false);
  const userProfileData = getStorangeAuthUser();

  const handlerToggle = () => setToggleLogin(!toggleLogin);
  const handlerOpenHelp = () => setOpenHelp(true);
  const handlerCloseHelp = () => setOpenHelp(false);
  const handlerSetEmail = (email: string) => setEmail(email);

  return (
    <>
      <Container disableGutters maxWidth="sm">
        <TopToolbar logoUrl={userProfileData?.company?.logo_url} logoName={userProfileData?.company?.name} />
        {toggleLogin ? <FormLogin handler={handlerToggle} handlerSetEmail={handlerSetEmail} /> : <Confirmation handler={handlerToggle} email={email} />}
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Button
            variant="text"
            size="medium"
            sx={{
              color: '#808B96',
            }}
            onClick={handlerOpenHelp}
          >
            Preciso de Ajuda?
          </Button>
          <HelpLogin openDialog={openHelp} onClose={handlerCloseHelp} />
        </Box>
      </Container>
      <FooterInboarding />
    </>
  );
};
