import React from 'react';
import { Environment } from './Environment';
import { FeatureFlagsContext, FeatureFlags } from './FeatureFlagsContext';

interface FeatureFlagsAppProps {
  children: JSX.Element;
}

export function useFeatureFlags(): FeatureFlags {
  return React.useContext(FeatureFlagsContext);
}

export const FeatureFlagsApp = ({ children }: FeatureFlagsAppProps) => {
  const featureFlags = {
    showDiversityGroups: Environment.isProduction() ? false : true,
  } as FeatureFlags;

  return <FeatureFlagsContext.Provider value={featureFlags}>{children}</FeatureFlagsContext.Provider>;
};
