import { SequenceData } from '../../hooks/queries/useSequenceGetQuery';
import { API } from './fetcher';

export const getSequenceDataOfficial = async (shortId: string) => {
  const { data } = await API.get(`/sequence/${shortId}`);
  return data.data as SequenceData;
};

export const getSequenceDataPreview = async (journeyId: string, stepId: string) => {
  const { data } = await API.get(`/sequence/preview/${journeyId}/${stepId}`);
  return data.data as SequenceData;
};

export const updateSequenceCompleted = async (shortId: string) => {
  try {
    const payload = { data: { shortId } };
    const { data, status } = await API.put(`/sequence/${shortId}/complete`, payload);
    return { data, status };
  } catch (error) {
    return { error };
  }
};

export const updateSequenceLastIndex = async ({ shortId, stepIndex }: { shortId: string; stepIndex: number }) => {
  try {
    const payload = {
      data: { step: '' + stepIndex },
    };
    const { data, status } = await API.put(`/sequence/${shortId}/step`, payload);
    return { data, status };
  } catch (error) {
    return { error };
  }
};
