import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { LayoutComponent } from '../components';
import { LayoutBlocksHeaderComponent } from '../components/LayoutBlocksHeader';
import { getStorangeToken, removeStorangeToken } from '../utils';
import { useGetValidateTokenQuery } from '../hooks/queries';

import { Dashboard, Journey, ProfileMenu, ProfileEdit, Login, WelcomePortal, Glossary, Faq } from '../pages';
import { MessageToaster } from '../utils/messageToaster';
import { FormQuestions } from '../pages/FormQuestions';
import { FormQuestionsPreview } from '../pages/FormQuestions/FormQuestionsPreview';
import { FormQuestionsAnswers } from '../pages/FormQuestions/FormQuestionsAnswers';
import { FormSurveyPreview } from '../pages/FormSurvey/FormSurveyPreview';
import { FormSurveyAnswers } from '../pages/FormSurvey/FormSurveyAnswers';
import { Diversity } from '../pages/Diversity';
import { DiversityDeafPeople } from '../pages/Diversity/Deaf/DeafPeople';
import { DeafPeopleWorkEnvironment } from '../pages/Diversity/Deaf/DeafPeopleWorkEnvironment';
import { DeafPeopleCreateAccessibleContent } from '../pages/Diversity/Deaf/DeafPeopleCreateAccessibleContent';
import { DeafPeopleRemoteWork } from '../pages/Diversity/Deaf/DeafPeopleRemoteWork';
import { DeafPeopleLeaders } from '../pages/Diversity/Deaf/DeafPeopleLeaders';
import { DeafPeopleCuriosities } from '../pages/Diversity/Deaf/DeafPeopleCuriosities';
import { DeafPeopleIncredibleAchievements } from '../pages/Diversity/Deaf/DeafPeopleIncredibleAchievements';
import { DeafPeopleInspirations } from '../pages/Diversity/Deaf/DeafPeopleInspirations';
import { DeafPeopleContents } from '../pages/Diversity/Deaf/DeafPeopleContents';
import { DeafPeopleSelfConfidence } from '../pages/Diversity/Deaf/DeafPeoplSelfConfidence';
import { ForWomenLeaders } from '../pages/Diversity/Women/ForWomenLeaders';
import { WomenAreNotOpponents } from '../pages/Diversity/Women/WomenAreNotOpponents';
import { WomenIncredibleAchievements } from '../pages/Diversity/Women/WomenIncredibleAchievements';
import { WomenInspirations } from '../pages/Diversity/Women/WomenInspirations';
import { WomenSelfConfidence } from '../pages/Diversity/Women/WomenSelfConfidence';
import { WomenSelfPromotion } from '../pages/Diversity/Women/WomenSelfPromotion';
import { WomenWhoAreStarting } from '../pages/Diversity/Women/WomenWhoAreStarting';
import { WomenWorkEnvironment } from '../pages/Diversity/Women/WomenWorkEnvironment';
import { WomenContents } from '../pages/Diversity/Women/WomenContents';
import { DiversityWomen } from '../pages/Diversity/Women/Women';
import { DiversityBlindPeople } from '../pages/Diversity/BlindPeople/BlindPeople';
import { BlindPeopleCapacitism } from '../pages/Diversity/BlindPeople/BlindPeopleCapacitism';
import { BlindPeopleContents } from '../pages/Diversity/BlindPeople/BlindPeopleContents';
import { BlindPeopleDayByDay } from '../pages/Diversity/BlindPeople/BlindPeopleDayByDay';
import { BlindPeopleHowToReceive } from '../pages/Diversity/BlindPeople/BlindPeopleHowToReceive';
import { BlindPeopleImportantDates } from '../pages/Diversity/BlindPeople/BlindPeopleImportantDates';
import { BlindPeopleIncredibleAchievements } from '../pages/Diversity/BlindPeople/BlindPeopleIncredibleAchievements';
import { BlindPeopleInspirations } from '../pages/Diversity/BlindPeople/BlindPeopleInspirations';
import { BlindPeopleSelfConfidence } from '../pages/Diversity/BlindPeople/BlindPeopleSelfConfidence';
import { BlindPeopleWorkEnvironment } from '../pages/Diversity/BlindPeople/BlindPeopleWorkEnvironment';
import { ForBlindPeopleLeaders } from '../pages/Diversity/BlindPeople/ForBlindPeopleLeaders';
import { DiversityBlackPeople } from '../pages/Diversity/BlackPeople/BlackPeople';
import { BlackPeopleImportantDates } from '../pages/Diversity/BlackPeople/BlackPeopleImportantDates';
import { BlackPeopleIncredibleAchievements } from '../pages/Diversity/BlackPeople/BlackPeopleIncredibleAchievements';
import { BlackPeopleInspirations } from '../pages/Diversity/BlackPeople/BlackPeopleInspirations';
import { BlackPeopleMothers } from '../pages/Diversity/BlackPeople/BlackPeopleMothers';
import { BlackPeopleLeadership } from '../pages/Diversity/BlackPeople/BlackPeopleLeadership';
import { BlackPeopleRecommendations } from '../pages/Diversity/BlackPeople/BlackPeopleRecommendations';
import { BlackPeopleSelfEsteem } from '../pages/Diversity/BlackPeople/BlackPeopleSelfEsteem';
import { BlackPeopleSupportNetwork } from '../pages/Diversity/BlackPeople/BlackPeopleSupportNetwork';
import { BlackPeopleWhoAreStarting } from '../pages/Diversity/BlackPeople/BlackPeopleWhoAreStarting';
import { BlackPeopleWorkEnvironment } from '../pages/Diversity/BlackPeople/BlackPeopleWorkEnvironment';
import { ForBlackPeopleLeaders } from '../pages/Diversity/BlackPeople/ForBlackPeopleLeaders';
import { DeafPeopleImportantDates } from '../pages/Diversity/Deaf/DeafPeopleImportantDates';
import { DeafPeopleAccessibleHiring } from '../pages/Diversity/Deaf/DeafPeopleAccessibleHiring';
import { DeafPeopleCapacitism } from '../pages/Diversity/Deaf/DeafPeopleCapacitism';
import { DeafPeopleHowToReceive } from '../pages/Diversity/Deaf/DeafPeopleHowToReceive';
import { DiversityPeopleWithPhisycalDisability } from '../pages/Diversity/PhysicalDisability/PeopleWithPhysicalDisability';
import { PwPDCapacitism } from '../pages/Diversity/PhysicalDisability/PwPDCapacitism';
import { PwPDHowToReceive } from '../pages/Diversity/PhysicalDisability/PwPDHowToReceive';
import { PwPDInspirations } from '../pages/Diversity/PhysicalDisability/PwPDInspirations';
import { PwPDLeaders } from '../pages/Diversity/PhysicalDisability/PwPDLeaders';
import { PwPDSelfConfidence } from '../pages/Diversity/PhysicalDisability/PwPDSelfConfidence';
import { PwPDWorkEnvironment } from '../pages/Diversity/PhysicalDisability/PwPDWorkEnvironment';
import { PwPDAccessibleHiring } from '../pages/Diversity/PhysicalDisability/PwPDAccessibleHiring';
import { PwPDImportantDates } from '../pages/Diversity/PhysicalDisability/PwPDImportantDates';
import { PwPDIncredibleAchievements } from '../pages/Diversity/PhysicalDisability/PwPDIncredibleAchievements';
import { PwPDContents } from '../pages/Diversity/PhysicalDisability/PwPDContents';
import { DiversityAutisticPeople } from '../pages/Diversity/Autism/AutisticPeople';
import { AutisticPeopleAccessibleOnboarding } from '../pages/Diversity/Autism/AutisticPeopleAccessibleOnboarding';
import { AutisticPeopleWorkEnvinroment } from '../pages/Diversity/Autism/AutisticPeopleWorkEnvinroment';
import { AutisticPeopleSelfConfidence } from '../pages/Diversity/Autism/AutisticPeopleSelfConfidence';
import { AutisticPeopleLeaders } from '../pages/Diversity/Autism/AutisticPeopleLeaders';
import { AutisticPeopleContents } from '../pages/Diversity/Autism/AutisticPeopleContents';
import { AutisticPeopleImportantDates } from '../pages/Diversity/Autism/AutisticPeopleImportantDates';
import { AutisticPeopleInspirations } from '../pages/Diversity/Autism/AutisticPeopleInspirations';
import { DiversityPeopleWithTDAH } from '../pages/Diversity/TDAH/PeopleWithTDAH';
import { PeopleWithTDAHAccessibleOnboarding } from '../pages/Diversity/TDAH/TDAHAccessibleOnboarding';
import { PeopleWithTDAHWorkEnvinroment } from '../pages/Diversity/TDAH/TDAHWorkEnvironment';
import { PeopleWithTDAHSelfConfidence } from '../pages/Diversity/TDAH/TDAHSelfConfidence';
import { PeopleWithTDAHLeaders } from '../pages/Diversity/TDAH/TDAHLeaders';
import { PeopleWithTDAHInspirations } from '../pages/Diversity/TDAH/TDAHInspirations';
import { PeopleWithTDAHImportantDates } from '../pages/Diversity/TDAH/TDAHImportantDates';
import { PeopleWthTDAHContents } from '../pages/Diversity/TDAH/TDAHContents';
import { Sequence } from '../pages/Sequence/Sequence';
import { SequencePreview } from '../pages/Sequence/SequencePreview';

export const Routing = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { mutateAsync: validateToken } = useGetValidateTokenQuery();

  const authNotRequired = () => {
    const authNotRequiredPathForm = '/form/';
    const authNotRequiredPathSurvey = '/survey/';
    const authNotRequiredPathLearn = '/learn/';
    return (
      location.pathname.indexOf(authNotRequiredPathForm) > -1 ||
      location.pathname.indexOf(authNotRequiredPathSurvey) > -1 ||
      location.pathname.indexOf(authNotRequiredPathLearn) > -1
    );
  };

  const alreadyInLoginPage = () => {
    const authLoginPath = '/login';
    return location.pathname.indexOf(authLoginPath) > -1;
  };

  const checkToken = async () => {
    if (authNotRequired()) {
      return;
    }
    const token: string | null = getStorangeToken();
    if (token) {
      const { data } = await validateToken();
      if (!data) {
        if (alreadyInLoginPage()) {
          removeStorangeToken();
        } else {
          MessageToaster.error('Autenticação expirou. \nVocê será redirecionado para a tela de login em 3 segundos...');
          return setTimeout(() => {
            logout();
          }, 4000);
        }
      }

      return navigate('/dashboard');
    }
    return navigate('/login');
  };

  const logout = () => {
    removeStorangeToken();
    return navigate('/login');
  };

  useEffect(() => {
    checkToken();
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/comecar" element={<WelcomePortal />} />
      <Route path="/form/:id" element={<FormQuestions />} />
      <Route path="/" element={<LayoutBlocksHeaderComponent />}>
        <Route path="/learn/p/:journeyId/:stepId" element={<SequencePreview />} />
        <Route path="/learn/a/:shortId" element={<Sequence />} />
        <Route path="/survey/a/:shortId" element={<FormSurveyAnswers />} />
        <Route path="/survey/p/:journeyId/:stepId" element={<FormSurveyPreview />} />
        <Route path="/form/p/:journeyId/:stepId" element={<FormQuestionsPreview />} />
        <Route path="/form/a/:shortId" element={<FormQuestionsAnswers />} />
      </Route>
      <Route path="/" element={<LayoutComponent />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/jornada" element={<Journey />} />
        <Route path="/perfil/editar" element={<ProfileEdit />} />
        <Route path="/perfil" element={<ProfileMenu />} />
        <Route path="/termos" element={<Glossary />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/diversidade/pessoas-surdas/o-que-fazer" element={<DeafPeopleWorkEnvironment />} />
        <Route path="/diversidade/pessoas-surdas/criar-conteudos-acessiveis" element={<DeafPeopleCreateAccessibleContent />} />
        <Route path="/diversidade/pessoas-surdas/trabalho-remoto" element={<DeafPeopleRemoteWork />} />
        <Route path="/diversidade/pessoas-surdas/lider-pessoas-surdas" element={<DeafPeopleLeaders />} />
        <Route path="/diversidade/pessoas-surdas/curiosidades" element={<DeafPeopleCuriosities />} />
        <Route path="/diversidade/pessoas-surdas/conquistas-incriveis" element={<DeafPeopleIncredibleAchievements />} />
        <Route path="/diversidade/pessoas-surdas/inspiracoes" element={<DeafPeopleInspirations />} />
        <Route path="/diversidade/pessoas-surdas/conteudos-indicados" element={<DeafPeopleContents />} />
        <Route path="/diversidade/pessoas-surdas/autoconfianca-e-autopromocao-no-trabalho" element={<DeafPeopleSelfConfidence />} />
        <Route path="/diversidade/pessoas-surdas/datas-importantes" element={<DeafPeopleImportantDates />} />
        <Route path="/diversidade/pessoas-surdas/contratacao-acessivel" element={<DeafPeopleAccessibleHiring />} />
        <Route path="/diversidade/pessoas-surdas/capacitismo" element={<DeafPeopleCapacitism />} />
        <Route path="/diversidade/pessoas-surdas/como-receber" element={<DeafPeopleHowToReceive />} />
        <Route path="/diversidade/pessoas-surdas" element={<DiversityDeafPeople />} />
        <Route path="/diversidade/mulheres" element={<DiversityWomen />} />
        <Route path="/diversidade/mulheres/ambiente-de-trabalho" element={<WomenWorkEnvironment />} />
        <Route path="/diversidade/mulheres/autoconfianca" element={<WomenSelfConfidence />} />
        <Route path="/diversidade/mulheres/autopromocao" element={<WomenSelfPromotion />} />
        <Route path="/diversidade/mulheres/nao-sao-rivais" element={<WomenAreNotOpponents />} />
        <Route path="/diversidade/mulheres/que-estao-comecando" element={<WomenWhoAreStarting />} />
        <Route path="/diversidade/mulheres/para-quem-lidera" element={<ForWomenLeaders />} />
        <Route path="/diversidade/mulheres/conquistas-incriveis" element={<WomenIncredibleAchievements />} />
        <Route path="/diversidade/mulheres/inspiracoes" element={<WomenInspirations />} />
        <Route path="/diversidade/mulheres/indicacao" element={<WomenContents />} />
        <Route path="/diversidade/pessoas-com-deficiencia-visual" element={<DiversityBlindPeople />} />
        <Route path="/diversidade/pessoas-com-deficiencia-visual/como-receber" element={<BlindPeopleHowToReceive />} />
        <Route path="/diversidade/pessoas-com-deficiencia-visual/ambiente-de-trabalho" element={<BlindPeopleWorkEnvironment />} />
        <Route path="/diversidade/pessoas-com-deficiencia-visual/autoconfianca" element={<BlindPeopleSelfConfidence />} />
        <Route path="/diversidade/pessoas-com-deficiencia-visual/para-quem-lidera" element={<ForBlindPeopleLeaders />} />
        <Route path="/diversidade/pessoas-com-deficiencia-visual/capacitismo" element={<BlindPeopleCapacitism />} />
        <Route path="/diversidade/pessoas-com-deficiencia-visual/dia-a-dia" element={<BlindPeopleDayByDay />} />
        <Route path="/diversidade/pessoas-com-deficiencia-visual/conquistas-incriveis" element={<BlindPeopleIncredibleAchievements />} />
        <Route path="/diversidade/pessoas-com-deficiencia-visual/datas-importantes" element={<BlindPeopleImportantDates />} />
        <Route path="/diversidade/pessoas-com-deficiencia-visual/inspiracoes" element={<BlindPeopleInspirations />} />
        <Route path="/diversidade/pessoas-com-deficiencia-visual/indicacao" element={<BlindPeopleContents />} />
        <Route path="/diversidade/pessoas-negras" element={<DiversityBlackPeople />} />
        <Route path="/diversidade/pessoas-negras/datas-importantes" element={<BlackPeopleImportantDates />} />
        <Route path="/diversidade/pessoas-negras/ambiente-de-trabalho" element={<BlackPeopleWorkEnvironment />} />
        <Route path="/diversidade/pessoas-negras/autoestima" element={<BlackPeopleSelfEsteem />} />
        <Route path="/diversidade/pessoas-negras/para-quem-lidera" element={<ForBlackPeopleLeaders />} />
        <Route path="/diversidade/pessoas-negras/maes" element={<BlackPeopleMothers />} />
        <Route path="/diversidade/pessoas-negras/lideranca" element={<BlackPeopleLeadership />} />
        <Route path="/diversidade/pessoas-negras/indicacao" element={<BlackPeopleRecommendations />} />
        <Route path="/diversidade/pessoas-negras/conquistas-incriveis" element={<BlackPeopleIncredibleAchievements />} />
        <Route path="/diversidade/pessoas-negras/rede-de-apoio" element={<BlackPeopleSupportNetwork />} />
        <Route path="/diversidade/pessoas-negras/inspiracoes" element={<BlackPeopleInspirations />} />
        <Route path="/diversidade/pessoas-negras/que-estao-comecando" element={<BlackPeopleWhoAreStarting />} />
        <Route path="/diversidade/pessoas-com-deficiencia-fisica" element={<DiversityPeopleWithPhisycalDisability />} />
        <Route path="/diversidade/pessoas-com-deficiencia-fisica/capacitismo" element={<PwPDCapacitism />} />
        <Route path="/diversidade/pessoas-com-deficiencia-fisica/contratacao-acessivel" element={<PwPDAccessibleHiring />} />
        <Route path="/diversidade/pessoas-com-deficiencia-fisica/como-receber" element={<PwPDHowToReceive />} />
        <Route path="/diversidade/pessoas-com-deficiencia-fisica/ambiente-de-trabalho" element={<PwPDWorkEnvironment />} />
        <Route path="/diversidade/pessoas-com-deficiencia-fisica/autoconfianca" element={<PwPDSelfConfidence />} />
        <Route path="/diversidade/pessoas-com-deficiencia-fisica/para-lideres" element={<PwPDLeaders />} />
        <Route path="/diversidade/pessoas-com-deficiencia-fisica/inspiracoes" element={<PwPDInspirations />} />
        <Route path="/diversidade/pessoas-com-deficiencia-fisica/conquistas-incriveis" element={<PwPDIncredibleAchievements />} />
        <Route path="/diversidade/pessoas-com-deficiencia-fisica/datas-importantes" element={<PwPDImportantDates />} />
        <Route path="/diversidade/pessoas-com-deficiencia-fisica/conteudos" element={<PwPDContents />} />
        <Route path="/diversidade/pessoas-autistas" element={<DiversityAutisticPeople />} />
        <Route path="/diversidade/pessoas-autistas/onboarding-acessivel" element={<AutisticPeopleAccessibleOnboarding />} />
        <Route path="/diversidade/pessoas-autistas/ambiente-de-trabalho" element={<AutisticPeopleWorkEnvinroment />} />
        <Route path="/diversidade/pessoas-autistas/autoconfianca" element={<AutisticPeopleSelfConfidence />} />
        <Route path="/diversidade/pessoas-autistas/para-lideres" element={<AutisticPeopleLeaders />} />
        <Route path="/diversidade/pessoas-autistas/inspiracoes" element={<AutisticPeopleInspirations />} />
        <Route path="/diversidade/pessoas-autistas/datas-importantes" element={<AutisticPeopleImportantDates />} />
        <Route path="/diversidade/pessoas-autistas/conteudos" element={<AutisticPeopleContents />} />
        <Route path="/diversidade/pessoas-com-tdah" element={<DiversityPeopleWithTDAH />} />
        <Route path="/diversidade/pessoas-com-tdah/onboarding-acessivel" element={<PeopleWithTDAHAccessibleOnboarding />} />
        <Route path="/diversidade/pessoas-com-tdah/ambiente-de-trabalho" element={<PeopleWithTDAHWorkEnvinroment />} />
        <Route path="/diversidade/pessoas-com-tdah/autoconfianca" element={<PeopleWithTDAHSelfConfidence />} />
        <Route path="/diversidade/pessoas-com-tdah/para-lideres" element={<PeopleWithTDAHLeaders />} />
        <Route path="/diversidade/pessoas-com-tdah/inspiracoes" element={<PeopleWithTDAHInspirations />} />
        <Route path="/diversidade/pessoas-com-tdah/datas-importantes" element={<PeopleWithTDAHImportantDates />} />
        <Route path="/diversidade/pessoas-com-tdah/conteudos" element={<PeopleWthTDAHContents />} />
        <Route path="/diversidade" element={<Diversity />} />
      </Route>
    </Routes>
  );
};
