import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

export const PwPDWorkEnvironment = () => {
  const PwPDTextItems = [
    {
      title: 'Ter empatia é diferente de pena',
      description:
        'Oferecer ajuda e se mostrar disponível pra ajudar faz parte da rotina de receber qualquer pessoa na empresa. É comum que existam dúvidas, mas normalmente pessoas com deficiência se sentem desconfortáveis em perguntar ou pedir qualquer tipo de ajuda, por isso é importante que a equipe se mostre disponível desde o primeiro dia.',
    },
    {
      title: 'Aprenda a confiar',
      description:
        'Muitas pessoas com deficiência têm o trabalho revisado sem necessidade. Isso acontece porque muita gente ainda acredita que pessoas com deficiência não são profissionais competentes o suficiente. Revise o trabalho delas apenas quando for necessário e se essa for a sua função. Aproveite para fortalecer a sua confiança com quem você trabalha.',
    },
    {
      title: 'Pessoas com deficiência não são iguais',
      description:
        'Saber disso é importante pra evitar comparações, respeitar diferenças, limites e capacidades. Cada pessoa é única e isso faz com que tenham preferências diferentes entre elas. Se seu colega PcD pediu por uma adaptação específica ou se tem alguma limitação, não o compare com outras pessoas, acolha e aprenda com a história e perfil dessa pessoa.',
    },
    {
      title: 'Crie um mapa de acessibilidade',
      description: (
        <>
          <p>
            Você pode identificar as barreiras que dificultam o acesso da pessoa com deficiência pra ajudar na locomoção. Desenhe, de forma acessível,{' '}
            <a href="https://www.vivadecora.com.br/pro/planta-baixa/">uma planta baixa</a> do escritório e dos espaços comuns identificando onde tem portas estreitas para passar
            uma cadeira de rodas, banheiro acessível, etc.
          </p>{' '}
          <p>
            Você pode disponibilizar o mapa no portal da empresa. Conversar com uma pessoa da área de segurança, da CIPA ou da brigada de incêndio do trabalho também pode ajudar.
            Se a empresa já tiver um mapa, adapte para que se torne acessível. Lembrando que é muito útil para todo o time.
          </p>
        </>
      ),
    },
    {
      title: 'Faça parte da rede de apoio',
      description:
        'Essa rede pode ser composta por familiares, amigos, colegas de trabalho, terapeutas ou outros profissionais da saúde. O mais importante é que a liderança queira fazer parte dessa rede pra que a pessoa com deficiência se sinta mais confiante e acolhida. É positivo também que a liderança converse com a pessoa pra que ela saiba que esse espaço existe e que ela não está só. ',
    },
    {
      title: 'Aprenda a escrever links',
      description: (
        <>
          Quando escrever e-mails e for usar links ou botões, opte por usar um espaço grande de clique.
          <ul>
            <li>
              Como fazer: <a href="/#">acessar manual de treinamento</a>
            </li>
            <li>
              Evit fazer: <a href="/#">acessar</a> manual de treinamento
            </li>
          </ul>
        </>
      ),
    },
    {
      title: 'Grupos de afinidade',
      description:
        'São grupos com pessoas que têm interesses em comum que podem ser aproveitados para discussões e ações de crescimento na empresa. Os grupos ajudam pessoas com deficiência física a se sentirem mais engajadas, seguras e motivadas. Além disso, fazem com que elas se sintam parte da empresa, o que ajuda a crescer e enfrentar desafios em conjunto.',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Ambiente de trabalho mais saudável para pessoas com deficiência física. 👍
        </Typography>
        <Typography variant="body2">Boas práticas para a rotina de trabalho.</Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={PwPDTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-com-deficiencia-fisica/autoconfianca" />
        </Stack>
      </Box>
    </Stack>
  );
};
