import React from 'react';

import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

export const TagsChips = ({ tags }: { tags: string }) => {
  if (!tags || !tags.trim()) {
    return <></>;
  }

  const parsedTags = tags
    .split(',')
    .map((tag) => tag.trim())
    .filter((tag) => !!tag);

  return (
    <Stack direction="row" spacing={1}>
      {parsedTags.map((tag: string, index: number) => (
        <Chip key={index} label={tag} />
      ))}
    </Stack>
  );
};
