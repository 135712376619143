import React from 'react';

import { Box, Typography, Stack } from '@mui/material';

import { Loading } from '../../components';
import { useGetDataProfile, UserProfileData } from '../../hooks/queries';
import { FooterInboarding } from '../../components/FooterInboarding';
import { FormProfile } from './FormProfile';
import { LastJourneyMessage } from '../Dashboard/LastJourneyMessage';

// import dateFnsParse from 'date-fns/parse';

export const ProfileEdit = () => {
  const { data: profile, isLoading } = useGetDataProfile();
  const userProfileData = profile?.data?.data as UserProfileData;

  if (isLoading) {
    return <Loading />;
  }

  // if (userProfileData.birth_date && !userProfileData.birth_date.includes('000Z')) {
  //   console.log(' START data.birth_date = ', userProfileData.birth_date);
  //   const date = dateFnsParse(userProfileData.birth_date, 'yyyy-MM-dd', new Date());
  //   console.log(' AFTER parsed date = ', date);
  //   userProfileData.birth_date = date.toISOString();
  //   console.log(' AFTER data.birth_date = ', userProfileData.birth_date);
  // }

  return (
    <Stack sx={{ pt: 2, pb: 1 }}>
      <Box sx={{ pl: 1 }}>
        <Typography variant="h5" color="primary">
          Editar perfil
        </Typography>
      </Box>
      <Box>
        <FormProfile data={userProfileData} />
      </Box>
      {userProfileData?.last_journey_step_sent?.step_name ? (
        <Box sx={{ pt: 2, px: 2 }}>
          <LastJourneyMessage userProfileData={userProfileData}></LastJourneyMessage>
        </Box>
      ) : (
        <></>
      )}
      <Box style={{ width: '100%' }}>
        <FooterInboarding divider={false} />
      </Box>
    </Stack>
  );
};
