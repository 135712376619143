import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { FormContainer, SelectElement, TextFieldElement } from 'react-hook-form-mui';
// import { DatePickerElement } from 'react-hook-form-mui';

import { Button, Grid, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import ptBrLocale from 'date-fns/locale/pt-BR';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { ProfileAvatarImage } from './FormProfilePicture';
import { UserProfileData } from '../../hooks/queries';
import { useProfileUpdate } from '../../hooks/queries/useProfileUpdate';
import { SnackbarMessage } from '../../components/SnackbarMessage';

const PopoverTshirtImage = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'tshirt-image-popover' : undefined;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <div style={{ marginTop: '25px' }}>
      <Button aria-describedby={id} onClick={handleClick}>
        Ver tamanhos
      </Button>
      <Modal open={open} onClose={handleClose} aria-label="Image mostrando o modelo da camisa e a tabela de medidas.">
        <Box sx={style} onClick={handleClose}>
          <img
            src="https://www.inboarding.co/assets/brand/asaas/asaas-tamanho-camisas-2.jpg"
            alt="Imagem mostrando o modelo da camisa e a tabela de medidas."
            style={{ width: '100%' }}
          />
        </Box>
      </Modal>
    </div>
  );
};

const AsaasFormProfileCustomFields = ({ data }: IFormProps) => {
  const [editPreferences, setEditPreferences] = React.useState(false);

  if (!data || !data.custom_fields_values) {
    return <></>;
  }

  // Save for local testing
  // data.address = {
  //   address: 'Rua das laranjeiras do sul',
  //   number: '100',
  //   complement: 'Apt Xpt A 123',
  //   zip_code: '89210-022',
  //   city: 'Joinville',
  //   state: 'SC',
  //   district: 'Bom Retiro',
  // };

  const showAddress = data.address && data.address.city;
  const addressValue = showAddress
    ? `${data.address.address}, ${data.address.number}, ${data.address.complement} - ${data.address.zip_code}, ${data.address.city}, ${data.address.state}`
    : '';

  return (
    <>
      <Grid item xs={12}>
        <Button
          variant="outlined"
          fullWidth
          size="large"
          onClick={() => setEditPreferences(!editPreferences)}
          startIcon={editPreferences ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          sx={{ my: 2 }}
        >
          Editar minhas preferências
        </Button>
      </Grid>
      <Grid container item xs={12} spacing={1} sx={{ display: editPreferences ? 'inherit' : 'none', visibility: editPreferences ? 'visible' : 'hidden' }}>
        <Grid item xs={12}>
          <Typography variant="body2">Essas respostas são muito importantes para cuidarmos melhor de você e da sua experiência aqui.</Typography>
        </Grid>
        <Grid item md={4} xs={12}>
          <SelectElement
            label="Você possui filhos?"
            name="custom_fields_values.children"
            options={[
              {
                id: 'yes',
                label: 'Sim',
              },
              {
                id: 'no',
                label: 'Não',
              },
            ]}
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextFieldElement name="custom_fields_values.children_names" label="Qual o nome dos seus filhos?" variant="outlined" margin="normal" fullWidth />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextFieldElement name="custom_fields_values.children_births_year" label="Qual o(s) ano(s) de nascimento?" variant="outlined" margin="normal" fullWidth />
        </Grid>
        <Grid item md={6} xs={12}>
          <SelectElement
            label="Você possui alguma restrição alimentar?"
            name="custom_fields_values.food_restriction"
            options={[
              {
                id: 'lactose',
                label: 'Intolerante ou Alérgico à Lactose',
              },
              {
                id: 'celiaco',
                label: 'Celíaco',
              },
              {
                id: 'diabetico',
                label: 'Diabético',
              },
              {
                id: 'vegano',
                label: 'Vegano',
              },
              {
                id: 'vegetariano',
                label: 'Vegetariano',
              },
              {
                id: 'frutos do mar',
                label: 'Alérgico à frutos do mar',
              },
              {
                id: 'outros',
                label: 'Outros',
              },
              {
                id: 'no',
                label: 'Não possuo restrições.',
              },
            ]}
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextFieldElement
            name="custom_fields_values.food_restriction_detail"
            label="Informe mais detalhes da sua restrição alimentar."
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <SelectElement
            label="Você gosta de beber no seu tempo livre?"
            helperText="Pode responder sinceramente, é só pra te conhecer melhor."
            name="custom_fields_values.drink_alcohol"
            options={[
              {
                id: '',
                label: '-',
              },
              {
                id: 'yes',
                label: 'Sim, socialmente!',
              },
              {
                id: 'no',
                label: 'Não gosto de bebida alcoólica.',
              },
            ]}
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextFieldElement name="custom_fields_values.drink_alcohol_detail" label="Se sim, qual é a sua bebida preferida?" variant="outlined" margin="normal" fullWidth />
        </Grid>
        <Grid item xs={10}>
          <SelectElement
            label="Qual o modelo e tamanho da sua camiseta?"
            helperText="Por favor, escolha se prefere modelo babylook ou tradicional. 😉"
            name="custom_fields_values.tshirt"
            options={[
              {
                id: '',
                label: '-',
              },
              {
                id: 'Tradicional - PP',
                label: 'Tradicional - PP',
              },
              {
                id: 'Tradicional - P',
                label: 'Tradicional - P',
              },
              {
                id: 'Tradicional - M',
                label: 'Tradicional - M',
              },
              {
                id: 'Tradicional - G',
                label: 'Tradicional - G',
              },
              {
                id: 'Tradicional - GG',
                label: 'Tradicional - GG',
              },
              {
                id: 'Tradicional - XG',
                label: 'Tradicional - XG',
              },
              {
                id: 'Tradicional - ESP',
                label: 'Tradicional - ESP',
              },
              {
                id: 'Baby Look - PP',
                label: 'Baby Look - PP',
              },
              {
                id: 'Baby Look - P',
                label: 'Baby Look - P',
              },
              {
                id: 'Baby Look - M',
                label: 'Baby Look - M',
              },
              {
                id: 'Baby Look - G',
                label: 'Baby Look - G',
              },
              {
                id: 'Baby Look - GG',
                label: 'Baby Look - GG',
              },
              {
                id: 'Baby Look - XG',
                label: 'Baby Look - XG',
              },
            ]}
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <PopoverTshirtImage />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextFieldElement name="custom_fields_values.shoes" label="Que número você calça para sapatos fechados?" variant="outlined" margin="normal" fullWidth />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextFieldElement name="custom_fields_values.shoes_sandals" label="E para sapatos abertos, como chinelos e sandálias?" variant="outlined" margin="normal" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <SelectElement
            label="Você é um pessoa com deficiência?"
            helperText="Usamos a sua resposta para oferecer um ambiente de trabalho mais inclusivo."
            name="pwd_disability"
            options={[
              {
                id: 'Auditiva',
                label: 'Auditiva',
              },
              {
                id: 'Física',
                label: 'Física',
              },
              {
                id: 'Intelectual',
                label: 'Intelectual',
              },
              {
                id: 'Psicossocial',
                label: 'Psicossocial',
              },
              {
                id: 'Visual',
                label: 'Visual',
              },
              {
                id: 'Outro',
                label: 'Outro',
              },
              {
                id: 'no',
                label: 'Não possuo deficiência',
              },
              {
                id: 'Prefiro não responder',
                label: 'Prefiro não responder',
              },
            ]}
            margin="normal"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldElement
            multiline={true}
            name="inclusive_notes"
            label="Como podemos tornar a sua experiência e o ambiente mais inclusivo para você?"
            variant="outlined"
            margin="normal"
            fullWidth
          />
        </Grid>
        {showAddress ? (
          <Grid item xs={12} data-private>
            <TextField
              disabled={true}
              value={addressValue}
              label="Seu endereço cadastrado que vamos usar para entregas."
              helperText="Se precisar alterar, utilize o sistema de folha de pagamento."
              variant="outlined"
              margin="normal"
              fullWidth
            />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

interface IFormProps {
  data: UserProfileData;
}

export const FormProfile = ({ data }: IFormProps) => {
  const navigate = useNavigate();
  const [messageSuccess, setMessageSuccess] = React.useState('');
  const [messageAlert, setMessageAlert] = React.useState('');
  const { mutateAsync: updateProfile, isLoading } = useProfileUpdate();

  // Issue: https://github.com/mui/mui-x/issues/4848#issuecomment-1123744174
  // React.useEffect(() => {
  //   if (data.birth_date) {
  //     console.log(' START data.birth_date = ', data.birth_date);
  //     const d = new Date(data.birth_date);
  //     // d.setUTCHours(0, 0, 0, 0);
  //     data.birth_date = d.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
  //     console.log(' AFTER data.birth_date = ', data.birth_date);
  //   }
  // }, [data.birth_date]);

  const formContext = useForm<UserProfileData>({
    defaultValues: data,
  });

  let birthDateValue = '-';
  if (data.birth_date) {
    const d = new Date(data.birth_date);
    birthDateValue = d.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
  }

  const handleCancelGoBackPage = () => {
    navigate(-1);
  };

  const handleSubmit = async (formData: UserProfileData) => {
    // console.log('formData ', formData);
    const { data, status } = await updateProfile(formData);
    if (status === 200) {
      setMessageSuccess('Perfil atualizado com sucesso!');
    } else {
      console.error('Error on profile update. Status: ', status, ' data: ', data);
      setMessageAlert('Ocorreu um probleminha ao salvar os dados! Confira seus dados e tente novamente.');
    }
  };

  function dateTimeFormat(date: string | undefined | null): string {
    if (!date) {
      return '';
    }
    const parsedDate = new Date(date);
    return `${parsedDate.toLocaleDateString('pt-BR')} ${parsedDate.toLocaleTimeString('pt-BR')}`;
  }

  return (
    <>
      {!!messageAlert && <SnackbarMessage message={messageAlert} severity="warning" onClose={() => setMessageAlert('')} />}
      {!!messageSuccess && <SnackbarMessage message={messageSuccess} severity="success" onClose={() => setMessageSuccess('')} />}

      <ProfileAvatarImage profile={data} setMessageAlert={setMessageAlert} setMessageSuccess={setMessageSuccess} />

      <FormContainer onSuccess={handleSubmit} formContext={formContext}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextFieldElement disabled name="first_name" label="Nome" required variant="outlined" margin="normal" sx={{ mr: 1 }} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextFieldElement disabled name="last_name" label="Sobrenome" required variant="outlined" margin="normal" fullWidth />
          </Grid>
          <Grid item md={7} xs={12}>
            <TextFieldElement
              name="short_name"
              label="Nome curto ou apelido"
              helperText="Esse nome será utilizado para chamar você no dia-a-dia."
              variant="outlined"
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item md={5} xs={12}>
            <SelectElement
              label="Pronomes de gênero"
              name="gender_pronouns"
              helperText="Como você gostaria que os outros se refiram a você."
              options={[
                {
                  id: '',
                  label: '-',
                },
                {
                  id: 'Ela/Dela',
                  label: 'Ela/Dela',
                },
                {
                  id: 'Ele/Dele',
                  label: 'Ele/Dele',
                },
                {
                  id: 'Elu/Delu',
                  label: 'Elu/Delu',
                },
              ]}
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item md={4} xs={12} data-private>
            <TextField disabled={true} value={birthDateValue} label="Sua data de aniversário." variant="outlined" margin="normal" fullWidth />
            {/* <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBrLocale}>
              <DatePickerElement
                name="birth_date"
                label="Data de nascimento"
                helperText="Qual é a sua data de aniversário?"
                inputProps={{ style: { marginTop: '16px', width: '100%' } }}
                disableFuture={true}
                parseDate={(value: Date) => {
                  value.setUTCHours(0, 0, 0, 0);
                  const dateIso = value.toISOString();
                  console.log('*** change date value = ', dateIso);
                  return dateIso;
                }}
              />
            </LocalizationProvider> */}
          </Grid>
          <Grid item md={8} xs={12}>
            <SelectElement
              label="Você comemora o seu aniversário?"
              name="birth_date_celebrate"
              helperText="Algumas pessoas não comemoram o aniversário."
              options={[
                {
                  id: 'yes',
                  label: 'Sim, comemoro',
                },
                {
                  id: 'no',
                  label: 'Não, não comemoro',
                },
              ]}
              margin="normal"
              fullWidth
            />
          </Grid>
          <AsaasFormProfileCustomFields data={data} />
          <Grid item xs={12}>
            <Typography variant="caption">Última atualização em: {dateTimeFormat(data.updatedAt)}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={2} direction="row" sx={{ mt: 3 }}>
              <LoadingButton loading={isLoading} type="submit" color="primary" variant="contained" size="large">
                Salvar
              </LoadingButton>
              <Button onClick={handleCancelGoBackPage}>Cancelar</Button>
            </Stack>
          </Grid>
        </Grid>
      </FormContainer>
    </>
  );
};
