import * as React from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

export interface TextItem {
  title: string;
  description: string | JSX.Element;
}

export interface ITextListAccordionProps {
  items: TextItem[];
}

export const TextListAccordion = ({ items }: ITextListAccordionProps): JSX.Element => {
  return (
    <>
      {items.map((textItem: TextItem, index: number) => (
        <TextItem index={index} item={textItem} key={textItem.title} />
      ))}
    </>
  );
};

const TextItem = ({ index, item }: { index: number; item: TextItem }): JSX.Element => {
  const [expanded, setExpanded] = React.useState<boolean>(true);

  return (
    <Accordion key={item.title} expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          {index + 1} - {item.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>{item.description}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};
