import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

export const DeafPeopleAccessibleHiring = () => {
  const deafPeopleTextItems = [
    {
      title: 'Cuidado com perguntas capacitistas na entrevista',
      description: (
        <>
          Se concentre em perguntar sobre o que de fato impacta no campo profissional. Perguntas pessoais em relação a vida amorosa e comentários que coloquem a pessoa como coitada
          não são necessários.
          <p>
            <strong>Perguntas capacitistas pra você evitar:</strong>
          </p>
          <ul>
            <li>
              <strong>Você nasceu assim, foi acidente ou foi doença?</strong>
            </li>
            <p>Você realmente precisa dessa informação ou é só curiosidade? Respeite o espaço da pessoa entrevistada.</p>
            <li>
              <strong>Que legal, alguém casou com você?</strong>
            </li>
            <p>Se relacionar com uma pessoa com deficiência não é caridade. Essas pessoas podem ter uma vida comum como todo mundo.</p>
            <li>
              <strong>Você já pensou em se aposentar por invalidez?</strong>
            </li>
            <p>Pessoas com deficiência podem trabalhar, ter sonhos e crescer na carreira como todo mundo.</p>
          </ul>
        </>
      ),
    },
    {
      title: 'Escolha clínicas do trabalho acessíveis',
      description:
        'Quando for escolher uma clínica, procure saber sobre se os profissionais da clínica são sensíveis e dispostos a receber um surdo oralizado e se oferece intérprete de Libras se for a necessidade da pessoa surda que está sendo contratada. Além disso, você pode optar por clínicas remotas. Aproveite para fazer uma pesquisa anônima de satisfação sobre a clínica do trabalho, pois dessa forma você avalia se o atendimento médico está sendo acolhedor ou capacitista.',
    },
    {
      title: 'Cuidado com a quantidade de vagas afirmativas',
      description: (
        <>
          Normalmente, vagas afirmativas são ofertadas apenas para cumprir a lei, mesmo que a diversidade seja muito importante para os resultados da empresa e{' '}
          <a href="https://brasil.un.org/pt-br/sdgs">um dos objetivos sustentáveis da ONU</a>. Além disso, a quantidade de pessoas com deficiência no mundo é muito maior do que a
          quantidade de vagas afirmativas. Pra combater essa desigualdade você pode abrir vagas para que todo mundo possa se candidatar.
        </>
      ),
    },
    {
      title: 'Pergunte quais adaptações a pessoa precisa',
      description:
        'Você pode fazer essa pergunta depois do ‘sim’. Dessa forma você já pode preparar o local pra receber o profissional. A verdade é que você só vai saber sobre o que ela precisa perguntando.',
    },
    {
      title: 'Oferte vagas que tenham a ver com o perfil profissional',
      description:
        'Pessoas surdas recebem muitas ofertas que não fazem sentido para a carreira e perfil delas. Isso normalmente acontece porque recrutadores se atentam apenas para o fato do profissional ter deficiência. Oferecer uma vaga de Assistente Administrativo para uma pessoa que trabalha com tecnologia é só um exemplo de oferta que não combina com o perfil profissional.',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Aprenda a ser acessível com pessoas surdas na contratação. 💼
        </Typography>
        <Typography variant="body2">Clínica do trabalho, vagas afirmativas e o que perguntar na entrevista.</Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={deafPeopleTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-surdas/como-receber" />
        </Stack>
      </Box>
    </Stack>
  );
};
