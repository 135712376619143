import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { List, ListItemButton, ListItemText } from '@mui/material';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { useNavigate } from 'react-router-dom';
import { ModalTextSecondaryButton } from '../ModalTextSecondaryButton';

export const DiversityPeopleWithPhisycalDisability = () => {
  const modalText = (
    <>
      <strong>Lista com algumas das diversidades que podem ser encontradas nas pessoas com deficiência física</strong>
      <p>
        <ul>
          <li>
            <strong>Paraplegia:</strong> perda total das funções motoras dos membros inferiores;
          </li>
          <li>
            <strong>Paraparesia:</strong> perda parcial das funções motoras dos membros inferiores;
          </li>
          <li>
            <strong>Monoplegia:</strong> perda total das funções motoras de um só membro (inferior ou superior);
          </li>
          <li>
            <strong>Monoparesia:</strong> perda parcial das funções motoras de um só membro (inferior ou superior);
          </li>
          <li>
            <strong>Tetraplegia:</strong> perda total das funções motoras dos membros inferiores e superiores;
          </li>
          <li>
            <strong>Tetraparesia:</strong> perda parcial das funções motoras dos membros inferiores e superiores;
          </li>
          <li>
            <strong>Triplegia:</strong> perda total das funções motoras em três membros;
          </li>
          <li>
            <strong>Triparesia:</strong> perda parcial das funções motoras em três membros;
          </li>
          <li>
            <strong>Hemiplegia:</strong> perda total das funções motoras de um hemisfério do corpo (direito ou esquerdo);
          </li>
          <li>
            <strong>Hemiparesia:</strong> perda parcial das funções motoras de um hemisfério do corpo (direito ou esquerdo);
          </li>
          <li>
            <strong>Amputação:</strong> perda total ou parcial de um determinado membro ou segmento de membro;
          </li>
          <li>
            <strong>Ausência de membro:</strong> ausência total ou parcial, desde o nascimento, de um determinado membro ou segmento de membro;
          </li>
          <li>
            <strong>Paralisia cerebral:</strong> lesão de uma ou mais áreas do sistema nervoso central, tendo como conseqüência alterações psicomotoras, podendo ou não causar
            deficiência intelectual;
          </li>
          <li>
            <strong>Nanismo:</strong> deficiência acentuada no crescimento. É importante ter em mente que o conceito de deficiência inclui a incapacidade relativa, parcial ou
            total, para o desempenho da atividade dentro do padrão considerado normal para o ser humano. Esclarecemos que a pessoa com deficiência pode desenvolver atividades
            laborais desde que tenha condições e apoios adequados às suas características.
          </li>
        </ul>
      </p>
    </>
  );

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Pessoas com deficiência física
        </Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1, mt: 2, textAlign: 'justify' }}>
        <Typography variant="body1" component="p">
          Segundo dados do último censo do IBGE, no Brasil a maioria das pessoas com deficiência física são limitadas a funções operacionais no trabalho e raramente ocupam cargos
          de liderança.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          Mais de 30% delas vivem em situação de miséria, com menos de um salário mínimo por mês. O que reforça a desigualdade social e a importância de um investimento de longo
          prazo para este grupo.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          Segundo a Biblioteca Virtual de Saúde, existem mais de 13 milhões de pessoas com deficiências físicas no Brasil (mais de 6% da população). Há uma enorme diversidade entre
          as pessoas com deficiência física, até mesmo algumas invisíveis.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          <ul>
            <li>
              <strong>Monoplegia:</strong> perda total das funções motoras de um só membro (inferior ou superior);
            </li>
            <li>
              <strong>Tetraplegia:</strong> perda total das funções motoras dos membros inferiores e superiores;
            </li>
            <li>
              <strong>Hemiplegia:</strong> perda total das funções motoras de um hemisfério do corpo (direito ou esquerdo);
            </li>
            <li>
              <strong>Paraplegia:</strong> perda total das funções motoras dos membros inferiores;
            </li>
            <li>
              <strong>Nanismo:</strong> deficiência acentuada no crescimento.
            </li>
            <li>
              <strong>Ostomia:</strong> intervenção cirúrgica que cria um ostoma (abertura) na parede abdominal para adaptação de bolsa de fezes e/ou urina.
            </li>
          </ul>
        </Typography>
        <ModalTextSecondaryButton title="Confira outros tipos de deficiência física" description={modalText} />
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          Pensando nisso, a equipe da Inboarding criou esse manual pra te ajudar a tornar o ambiente de trabalho da sua empresa mais saudável e favorável ao crescimento de pessoas
          com deficiência física.
        </Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1, mt: 2 }}>
        <Typography variant="h6" component="h3">
          Vamos conversar sobre
        </Typography>
        <PersonWithPhysicalDisabilityTopics />
      </Box>
    </Stack>
  );
};

export const TopiceMenuListItem = ({ title, text, handerClick }: { title: string; text: string; handerClick: () => void }) => {
  return (
    <ListItemButton disableGutters={true} onClick={handerClick} sx={{ p: 1 }}>
      <ListItemText primary={title} secondary={text} />
      <ArrowForwardIosIcon sx={{ fontSize: 15 }} />
    </ListItemButton>
  );
};

const PersonWithPhysicalDisabilityTopics = () => {
  const navigate = useNavigate();
  const handlerRoutePage = (path: string) => navigate(path);

  return (
    <>
      <List component="nav">
        <TopiceMenuListItem
          title="O que é capacitismo e como evitar."
          text="O time e a liderança na criação de uma rotina com respeito.."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-deficiencia-fisica/capacitismo');
          }}
        />
        <TopiceMenuListItem
          title="Aprenda a ser acessível na contratação."
          text="Clínica do trabalho, vagas afirmativas e o que perguntar na entrevista."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-deficiencia-fisica/contratacao-acessivel');
          }}
        />
        <TopiceMenuListItem
          title="Como receber uma pessoa com deficiência física no time."
          text="O que fazer antes de receber a pessoa na empresa (remoto e presencial)"
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-deficiencia-fisica/como-receber');
          }}
        />
        <TopiceMenuListItem
          title="Ambiente de trabalho mais saudável pra pessoas com deficiência física."
          text="Boas práticas para a rotina de trabalho."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-deficiencia-fisica/ambiente-de-trabalho');
          }}
        />
        <TopiceMenuListItem
          title="Aprendendo a ter mais autoconfiança"
          text="Como as pessoas com deficiência física podem ser mais confiantes."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-deficiencia-fisica/autoconfianca');
          }}
        />
        <TopiceMenuListItem
          title="Uma conversa com quem lidera pessoas com deficiência física."
          text="Como criar oportunidades reais e justas."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-deficiencia-fisica/para-lideres');
          }}
        />
        <TopiceMenuListItem
          title="Inspirações de pessoas com deficiência física."
          text="Redes sociais de pessoas com deficiência física pra você se inspirar todos os dias."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-deficiencia-fisica/inspiracoes');
          }}
        />
        <TopiceMenuListItem
          title="Conquistas incríveis"
          text="Uma lista de pessoas com deficiência que fizeram história."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-deficiencia-fisica/conquistas-incriveis');
          }}
        />
        <TopiceMenuListItem
          title="Datas importantes."
          text="Datas que valorizam e marcam a história de pessoas com TDAH."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-deficiencia-fisica/datas-importantes');
          }}
        />
        <TopiceMenuListItem
          title="Conteúdos que a Inboarding indica"
          text="Pra você ir além desse manual."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-deficiencia-fisica/conteudos');
          }}
        />
      </List>
    </>
  );
};
