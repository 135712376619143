import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import { setStorangeAuthUser } from './authUserStorange';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setSessionUserData = (userProfileData: any | undefined) => {
  try {
    if (userProfileData) {
      // Storage Auth User to load custom logo and colors on other pages, e.g. expired token
      setStorangeAuthUser(userProfileData);
      // Sentry error monitoring
      try {
        Sentry.setUser({ id: userProfileData.id, name: userProfileData.first_name, email: userProfileData.email });
      } catch (error) {
        console.log('[Warning] not sending session monitoring data to Sentry.');
      }

      // LogRocket error sessions monitoring
      try {
        LogRocket.identify(userProfileData.email || userProfileData.id, {
          name: userProfileData.first_name,
          email: userProfileData.email,
          mobile: userProfileData.mobile_number,
          inboarding_id: userProfileData.id,
        });
      } catch (error) {
        console.log('[Warning] not sending session monitoring data to LogRocket.');
      }
    }
  } catch (error) {
    console.log('[Warning] profile not loded, not sending session monitoring', error);
  }
};
