import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { setStorangeToken } from '../../utils';
import { SequenceInvalidNotFound } from './SequenceInvalidNotFound';
import { useGetSequencePreview } from '../../hooks/queries/useSequenceGetQuery';
import { SequenceLoading } from './SequenceLoading';
import { SequenceLoadError } from './SequenceError';
import { SequenceLearn } from './SequenceLearn';

export const SequencePreview = () => {
  const { journeyId, stepId } = useParams();
  if (!journeyId || !stepId) {
    return <SequenceInvalidNotFound />;
  }

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  if (token) {
    setStorangeToken(token);
  }

  const { data, isLoading, isError, error } = useGetSequencePreview(journeyId || '', stepId || '');

  if (isLoading) {
    return <SequenceLoading />;
  }

  if (isError) {
    return <SequenceLoadError error={error} />;
  }

  if (!data?.sequence_blocks?.length) {
    return <SequenceInvalidNotFound />;
  }

  return <SequenceLearn sequenceLearnBlocks={data.sequence_blocks} currentStep={0} />;
};
