import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import HowToReceive from './images/illustrations/howToReceive.svg';

export const BlindPeopleHowToReceive = () => {
  const blindPeopleTextItems = [
    {
      title: 'Evite comprar ferramentas sem consultar a pessoa com deficiência visual.',
      description:
        'Nem sempre a pessoa com deficiência visual precisa de uma ferramenta cara e complicada, mas você só vai saber disso perguntando. Você pode perguntar antes mesmo dela entrar na empresa, assim que for aprovada. Uma pergunta como: “quais adaptações você precisa no seu computador?” já pode melhorar a experiência da pessoa na empresa.',
    },
    {
      title: 'Pergunte qual leitor de tela ela usa no computador.',
      description:
        'Normalmente pessoas cegas usam tecnologias comuns com o auxílio de leitores de tela. No caso de celulares, o leitor de tela  já vem disponível na própria configuração, bastando ativar. Já em computadores é necessário instalar o leitor. O mais comum é o NVDA, mas pergunte antes para entregar o computador com a ferramenta necessária para a pessoa.',
    },
    {
      title: 'Converse sobre as funções da pessoa antes do primeiro dia.',
      description:
        'Algumas pessoas com deficiência visual chegam na empresa e se sentem perdidas sobre o que fazer. Muitas vezes isso acontece porque a liderança não pensou sobre as funções que essa pessoa vai ter. Líder, apresente as funções e pergunte o que é possível e o que não é para ela. Lembrando que pessoas com deficiência visual são profissionais competentes  para a vaga. Apenas adaptações são necessárias para que possam fazer as funções muito bem.',
    },
    {
      title: 'Facilite o envio de documentos.',
      description:
        'Separar documentos em papel é um grande desafio para pessoas com deficiência visual, mesmo que algumas contem com o apoio de familiares ou amigos. Além disso, nem sempre os softwares de seleção ou de envio de documentos são acessíveis, por isso é importante perguntar como a pessoa prefere enviar os documentos. O Google Drive, por exemplo, é uma ferramenta acessível e que pode ajudar no anexo de documento, mas pergunte antes para entender o que a pessoa prefere.',
    },
    {
      title: 'Empatia é diferente de pena.',
      description:
        'Todo mundo, independente de ter deficiência ou não, precisa ser tratado com empatia. É importante entender que se mostrar disponível a ajudar não é o mesmo que diminuir as capacidades de pessoas com deficiência visual. A equipe precisa ser educada sobre como ser acolhedora com a pessoa com deficiência visual.',
    },
    {
      title: 'Conversar pode melhorar tudo.',
      description:
        'A melhor forma de receber uma pessoa com deficiência visual no ambiente de trabalho é tendo uma primeira conversa aberta e acolhedora depois da aprovação no processo seletivo. Faça isso para entender as adaptações que ela precisa, as ferramentas que preferem e as atividades que pode fazer.',
    },
    {
      title: 'A deficiência visual não precisa ser um tabu.',
      description:
        'As adaptações que podem ser necessárias para a inclusão de pessoas com deficiência visual podem ser simples e de fácil acesso. Isso acontece porque existem várias tecnologias - algumas até gratuitas - que ajudam a incluir essas pessoas no dia a dia sem muitas dificuldades. Muitas vezes você pode achar que pessoas cegas são difíceis ou não conseguem fazer certas atividades, quando na verdade podem se adaptar mais fácil do que você imagina. Com as adaptações necessárias, elas podem se especializar e se tornar profissionais ótimos.',
    },
    {
      title: 'O trabalho presencial precisa de adaptações.',
      description:
        'Antes de receber a pessoa no local, ajuste o espaço da empresa pra que seja mais acessível. Isso pode ser feito através de placas em braile para identificar banheiros e salas, e indicação sonora para o elevador. Os elevadores podem ter indicações em braile em todos os andares e no painel interno, também podem comunicar o andar que pararam com som. Os sites e softwares usados pela empresa podem ser acessíveis para os leitores de tela.',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Como receber uma pessoa com deficiência visual no time. 👋
        </Typography>
        <Typography variant="body2">Ferramentas, divisão do trabalho e empatia.</Typography>
      </Box>
      <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <img src={HowToReceive} alt="Imagem ilustrativa de uma mulher cega e de um homem ao seu lado." style={{ maxWidth: '430px', width: '100%' }} aria-hidden="true" />
      </Stack>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={blindPeopleTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-com-deficiencia-visual/ambiente-de-trabalho" />
        </Stack>
      </Box>
    </Stack>
  );
};
