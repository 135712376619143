import { useMutation } from 'react-query';

import { updateSurveyAsnwersData, updateSurveyAsnwersDraftData } from '../../services/api/survey-data';

export interface SurveyAnswerData {
  id: string;
  label: string;
  description: string;
  value: string;
  blockPollType: string;
}

export type SurveyAnswersData = SurveyAnswerData[];

export const useSurveyAnswerUpdate = () => {
  return useMutation(updateSurveyAsnwersData, {});
};

export const useSurveyDraftUpdate = () => {
  return useMutation(updateSurveyAsnwersDraftData, {});
};
