import { useQuery } from 'react-query';

import { QueryKeys } from '../../config/QueryKeys';
import { requestDataProfile } from '../../services/api';

export interface UserProfileCustomValues {
  [key: string]: string;
}

interface ProfileLastJourneyStep {
  journey_id?: string;
  journey_name?: string;
  step_id?: string;
  step_name?: string;
  step_subject?: string;
  status?: string;
  channel?: string;
}

export interface UserProfileData {
  id?: string | null;
  email?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  short_name?: string | null;
  gender_pronouns?: string | null;
  birth_date?: string | null;
  birth_date_celebrate?: string | null;
  pwd_disability?: string | null;
  inclusive_notes?: string | null;
  personal_email?: string | null;
  work_email?: string | null;
  mobile_number?: string | null;
  start_date?: string | null;
  job_title?: string | null;
  department?: string | null;
  cost_center?: string | null;
  photo_url?: string | null;
  instagram_handle?: string | null;
  linkedin_url?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  company?: {
    name?: string | null;
    logo_url?: string | null;
    logo_small_url?: string | null;
    avatar_name?: string | null;
    avatar_url?: string | null;
    color_primary?: string | null;
    color_secondary?: string | null;
  };
  address: {
    zip_code: string;
    address: string;
    number: string;
    complement?: string;
    state: string;
    city: string;
    district?: string;
  };
  custom_fields_values?: UserProfileCustomValues;
  last_journey_step_sent?: ProfileLastJourneyStep;
}

export const useGetDataProfile = () => {
  return useQuery(QueryKeys.getProfile, () => requestDataProfile(), { refetchOnWindowFocus: false });
};
