import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import MarcusLima from './images/inspirations/MarcusLima.jpg';
import NicolasDeAssis from './images/inspirations/NicolasDeAssis.jpg';
import NathaliaSantos from './images/inspirations/NathaliaSantos.jpg';
import IsaMeirelles from './images/inspirations/IsaMeirelles.jpg';
import HabenGirma from './images/inspirations/HabenGirma.jpg';
import CarolineCasey from './images/inspirations/CarolineCasey.jpg';
import { ListOfPeople } from '../ListOfPeople';

export const BlindPeopleInspirations = () => {
  const ListOfInspirationalPeople = [
    {
      info: {
        name: 'Marcus Lima',
        description:
          'Ele é uma pessoa cega que compartilha assuntos sobre deficiência visual. Ele escreveu uma coletânea sobre sua própria vida, a obra “Histórias de Cego” e, a partir de então, começou a compartilhar também conteúdo com leveza e bom humor pelas redes sociais.',
      },
      image: {
        personImportName: MarcusLima,
        altText: 'Foto de Marcus. Ele veste uma camiseta listrada e está segurando um microfone na mão esquerda.',
      },
      buttons: [
        {
          label: 'Siga o Marcus',
          url: 'https://www.instagram.com/historiasdecego/?hl=pt-br',
        },
      ],
    },
    {
      info: {
        name: 'Nicolas de Assis',
        description:
          'Em 2022, o Nícolas foi diagnosticado com a Neuropatia Óptica de Leber, uma doença genética que causa problemas visuais por conta da atrofia do nervo óptico. Assim, Nícolas passou a pesquisar muito sobre a sua doença e buscou se adaptar para voltar às atividades cotidianas. O Nícolas é super bem humorado e carismático, ele tem 26 anos e é de Belo Horizonte - MG.',
      },
      image: {
        personImportName: NicolasDeAssis,
        altText: 'Foto de Nicolas. Ele veste uma camiseta listrada e está sorrindo.',
      },
      buttons: [
        {
          label: 'Siga o Nicolas',
          url: 'https://www.instagram.com/nick_assis/?igshid=MWI4MTIyMDE%3D',
        },
      ],
    },
    {
      info: {
        name: 'Nathalia Santos',
        description: (
          <>
            <p>
              Nathalia Santos é carioca de 25 anos. Com toda sua jovialidade, inteligência, bom-humor, conhecimento, vivências e reflexões como mulher negra, a jornalista foi
              considerada a primeira apresentadora cega do Brasil, através do noticiário &quot;Atualiza!&quot; Youtuber e dona do canal &quot;Como assim, cega?&quot;, a jornalista
              Nathalia Santos produz conteúdos diversos sobre as próprias vivências enquanto pessoa com deficiência visual.
            </p>
            <p>
              Em um vídeo específico, a influenciadora explicou com detalhes por que o uso da letra &quot;x&quot; (xis) ou do sinal de &quot;@&quot; (arroba) atrapalham a
              acessibilidade para quem depende de leitores digitais que traduzem para áudio o que está escrito.
            </p>
          </>
        ),
      },
      image: {
        personImportName: NathaliaSantos,
        altText: 'Foto de Nathalia. Ele está sorrindo e vesindo uma blusa branca estampada com imagens coloridas.',
      },
      buttons: [
        {
          label: 'Conheça seu canal no Youtube',
          url: 'https://www.youtube.com/@ComoAssimCega',
        },
      ],
    },
    {
      info: {
        name: 'Isa Meirelles',
        description: (
          <>
            <p>Isa é uma provocadora de novas narrativas sobre Diversidade & Inclusão pelo olhar do Design Inclusivo e Criadora de novas linguagens na moda, beleza e nas artes.</p>
            <p>
              Palestrante em Comunicação e Design Inclusivo para empresas e digital influencer em Moda e Design Inclusivo. Tem experiência 360º na área de Comunicação e Branding,
              com foco em Diversidade e Design Inclusivo, Novos Canais Digitais e Construção de Influência.
            </p>
          </>
        ),
      },
      image: {
        personImportName: IsaMeirelles,
        altText: 'Foto de Isa. Ele veste uma blusa branca e seu olho esquerdo está com uma maquiagem colorida.',
      },
      buttons: [
        {
          label: 'Siga a Isa',
          url: 'https://www.instagram.com/isa.meirelles/',
        },
      ],
    },
    {
      info: {
        name: 'Haben Girma',
        description: (
          <>
            <p>
              Haben Girma é uma defensora americana dos direitos das pessoas com deficiência e a primeira graduada surdocega da Harvard Law School. Haben Girma é uma advogada de
              direitos humanos que promove a justiça para pessoas com deficiência.
            </p>
            <p>
              O presidente Obama a nomeou Campeã da Mudança na Casa Branca. Ela recebeu o Helen Keller Achievement Award, um lugar na lista Forbes 30 Under 30 e o TIME100 Talks. O
              presidente Bill Clinton, o primeiro-ministro Justin Trudeau e a chanceler Angela Merkel homenagearam Haben. O New York Times, a Oprah Magazine e o TODAY Show
              apresentaram seu livro de memórias, Haben: The Deafblind Woman Who Conquered Harvard Law.
            </p>
          </>
        ),
      },
      image: {
        personImportName: HabenGirma,
        altText: 'Foto do rosto de Haben de perfil.',
      },
      buttons: [
        {
          label: 'Siga a Haben',
          url: 'https://www.instagram.com/habengirma/',
        },
      ],
    },
    {
      info: {
        name: 'Caroline Casey',
        description: (
          <>
            <p>
              Caroline é uma ativista irlandesa e consultora de gestão. Ela é uma pessoa cega devido ao albinismo ocular. Em 2000, aos 28 anos, ela deixou seu emprego na Accenture
              para lançar a Fundação Aisling com o objetivo de melhorar o tratamento da deficiência. Ela também é a empresária e ativista por trás do The Valuable 500, o maior
              coletivo de CEOs do mundo e movimento empresarial para a inclusão de pessoas com deficiência.
            </p>
            <p>
              Casey lançou o movimento na Cúpula de Davos do Fórum Econômico Mundial em 2019 e, desde então, assinou 500 organizações multinacionais com uma receita combinada de
              mais de US$ 8 trilhões, empregando 20 milhões de pessoas em todo o mundo para transformar radicalmente o sistema de negócios. A associação inclui 36 das empresas FTSE
              100, 46 da Fortune 500 e 28 do Nikkei.
            </p>
            <p>
              Recentemente nomeada presidente da IAPB, Caroline também participa de vários conselhos de diversidade e inclusão, incluindo L&lsquo;Oréal, Sanofi e Sky, e é uma
              oradora muito procurada. Caroline recebeu um doutorado honorário, bem como vários prêmios e elogios por seu trabalho como ativista da deficiência.
            </p>
          </>
        ),
      },
      image: {
        personImportName: CarolineCasey,
        altText: 'Foto de Caroline sorrindo, ela veste uma blusa preta.',
      },
      buttons: [
        {
          label: 'Acompanhe a Caroline no LinkedIn',
          url: 'https://www.linkedin.com/in/caroline-casey-837ab822/',
        },
      ],
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Inspirações de pessoas com deficiência visual. ✨
        </Typography>
        <Typography variant="body2">Redes sociais de pessoas com deficiência visual pra você se inspirar todos os dias.</Typography>
      </Box>
      <ListOfPeople people={ListOfInspirationalPeople} />
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-com-deficiencia-visual/conquistas-incriveis" />
        </Stack>
      </Box>
    </Stack>
  );
};
