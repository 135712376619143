import React from 'react';

import { Typography, Button } from '@mui/material';

import { AppDialog } from '../../components';

export interface IActionsButtonProps {
  title: string;
  description: JSX.Element;
}

export const ModalTextSecondaryButton = ({ title, description }: IActionsButtonProps) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  function handleOnClick() {
    setIsModalOpen(true);
  }
  return (
    <>
      <Button onClick={handleOnClick}>{title}</Button>
      {isModalOpen && (
        <AppDialog
          open={isModalOpen}
          title={title}
          onClose={() => {
            setIsModalOpen(false);
          }}
        >
          <Typography variant="body1" component="p">
            {description}
          </Typography>
        </AppDialog>
      )}
    </>
  );
};
