import React from 'react';

import { Typography } from '@mui/material';

export const FormQuestionInvalidNotFound = () => {
  return (
    <div>
      <Typography variant="h5" component="h1">
        Formulário não encontrado para exibir em modo visualização.
      </Typography>
      <Typography variant="body1" component="p">
        O formulário com o link que você utilizou para acessar não foi encontrado.
      </Typography>
      <Typography variant="body1" component="p">
        Verifique se você salvou as alterações antes de abrir esse link de visualização.
      </Typography>
    </div>
  );
};
