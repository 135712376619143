import React from 'react';
import { Box, Stack, Typography, TextField, InputAdornment, IconButton } from '@mui/material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { Skeleton } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { EllaMessage, ErrorMessage } from '../../components';
import { AppUserContext } from '../../config/AppUserContext';
import { GlossaryData, useGetDataGlossaryTerms } from '../../hooks/queries/useGlossaryQuery';
import { TagsChips } from '../../components/TagsChips';

export const Glossary = () => {
  const profile = React.useContext(AppUserContext);

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary">
          Glossário
        </Typography>
      </Box>
      <Box sx={{ pl: 1 }}>
        <EllaMessage>
          <>
            <Typography variant="h6" color="primary">
              {profile?.short_name || profile?.first_name},
            </Typography>
            <Typography variant="body2">Algumas palavras podem ser difíceis de entender. Aprenda mais sobre elas no glossário que criamos pra você.</Typography>
          </>
        </EllaMessage>
      </Box>
      <Box sx={{ pl: 1 }}>
        <GlossaryTerms />
      </Box>
    </Stack>
  );
};

const GlossaryTerms = (): JSX.Element => {
  const [searchText, setSearchText] = React.useState('');
  const { data, isLoading, isError, error } = useGetDataGlossaryTerms(searchText);

  if (isError) {
    return <ErrorMessage error={error} />;
  }

  if (isLoading) {
    return <LoadingGlossary />;
  }

  if (!data) {
    return (
      <p>
        <Typography>Nenhum conteúdo por aqui ainda, volte mais tarde.</Typography>
      </p>
    );
  }

  const handleCleanSearch = () => {
    setSearchText('');
  };

  let startLetter = '';

  return (
    <>
      <p>
        <TextField
          variant="outlined"
          fullWidth
          label="Pesquise por uma palavra."
          placeholder="Ex: NPS"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          sx={{ mb: 2 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {searchText ? (
                  <IconButton aria-label="Limpar pesquisa" onClick={handleCleanSearch} onMouseDown={handleCleanSearch} edge="end">
                    <CloseIcon />
                  </IconButton>
                ) : (
                  <></>
                )}
              </InputAdornment>
            ),
          }}
        />
        {data.map((term) => {
          const termFirstLetter = term.title[0].toUpperCase();
          if (startLetter !== termFirstLetter) {
            startLetter = termFirstLetter;
            return (
              <>
                <Typography variant="h6" color="primary" style={{ margin: '10px' }}>
                  {startLetter}
                </Typography>
                <GlossaryTermAccordion key={term.id} term={term} />
              </>
            );
          }

          return <GlossaryTermAccordion key={term.id} term={term} />;
        })}
      </p>
    </>
  );
};

const GlossaryTermAccordion = ({ term }: { term: GlossaryData }): JSX.Element => {
  const [textExpanded, setTextExpanded] = React.useState(false);
  return (
    <Accordion key={term.id} onChange={(_event: object, expanded: boolean) => setTextExpanded(expanded)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <div style={{ flexFlow: 'column' }}>
          <Typography>{term.title}</Typography>
          <Typography variant="caption" style={{ visibility: textExpanded ? 'hidden' : undefined }}>
            {term.description.substring(0, 40)}...
          </Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails style={{ marginTop: '-35px' }}>
        <Typography>{term.description}</Typography>
        {term.source_url && (
          <Typography variant="body2" component="p">
            <br />
            Mais detalhes no link:
            <br />
            <a href={term.source_url} target="_blank" rel="noopener noreferrer">
              {term.source_url}
            </a>
          </Typography>
        )}
        <p>
          <TagsChips tags={term.tags} />
        </p>
      </AccordionDetails>
    </Accordion>
  );
};

const LoadingGlossary = (): JSX.Element => {
  return (
    <p>
      {Array(7)
        .fill('')
        .map((_item, index) => (
          <Accordion key={index}>
            <Skeleton height={68} variant="rectangular" />
          </Accordion>
        ))}
    </p>
  );
};
