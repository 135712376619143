import { useMutation, useQueryClient } from 'react-query';

import { QueryKeys } from '../../config/QueryKeys';
import { confirmReadStep } from '../../services/api/confirm-read';

export const useConfirmReadStep = () => {
  const queryClient = useQueryClient();
  return useMutation(confirmReadStep, {
    onMutate: () => {
      queryClient.cancelQueries(QueryKeys.getJourney);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.getJourney);
    },
  });
};
