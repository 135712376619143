import React from 'react';
import { Alert, Box, Button, Typography } from '@mui/material';

import undrawMailsent from '../../assets/undrawMail_sent.svg';

interface Props {
  handler: () => void;
  email: string;
}

export const Confirmation = ({ handler, email }: Props) => {
  return (
    <Box>
      <Box sx={{ textAlign: 'center' }}>
        <img style={{ maxHeight: '7em', margin: '3em 0' }} src={undrawMailsent} alt="mail-sent" />
        <Typography variant="h6">Enviamos o link de acesso pro seu e-mail!</Typography>
        <Alert severity="success" sx={{ m: 3 }}>
          <strong>Vai no e-mail que você informou {email} e clique no link pra acessar o Portal do Colaborador.</strong>
        </Alert>
      </Box>
      <Box sx={{ mt: 6, px: 4 }}>
        <Button fullWidth variant="outlined" onClick={handler}>
          Não recebi o e-mail
        </Button>
      </Box>
    </Box>
  );
};
