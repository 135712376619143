import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

export const PwPDCapacitism = () => {
  const PwPDTextItems = [
    {
      title: 'O que é capacitismo?',
      description:
        'Segundo Ivan Baron, teórico da área, capacitismo é toda discriminação, violência ou atitude contra a pessoa com deficiência e se expressa desde formas mais sutis até as mais gritantes." Também acontece quando imaginamos , de maneira errada, que uma pessoa com deficiência é limitada, e a partir daí, adotamos um comportamento de superioridade em relação a ela.',
    },
    {
      title: 'Expressões capacitistas',
      description: (
        <>
          <ul>
            <li>
              <strong>“PNE (Portador de Necessidade Especial)”</strong>
            </li>
            A deficiência não é uma necessidade especial e sim uma condição. Não é um objeto ou roupa em que pode escolher a hora que irá portar. O termo correto é Pessoa com
            Deficiência (PcD).
            <li>
              <strong>“Dar uma de João sem braço”</strong>
            </li>
            Fortalece a ideia de que pessoas que não possuem algum membro são incapazes. Substitua por “a pessoa é preguiçosa” ou “fugiu da responsabilidade”.
            <li>
              <strong>“Dar uma mancada”</strong>
            </li>
            Muitas pessoas mancam quando estão caminhando, pois têm alguma deficiência motora ou neurológica. Substitua por “faltar com o compromisso”.
            <li>
              <strong>“Nossa, nem parece que você é PcD!”</strong>
            </li>
            Parte da ideia de que ter uma deficiência é algo ruim, ou que deve ser ocultado/escondido.
            <li>
              <strong>“Seu problema tem cura?”</strong>
            </li>
            Uma deficiência física não é um problema e nem uma doença, é uma característica que compõe uma pessoa.
            <li>
              <strong>“Mas você consegue fazer as coisas?”</strong>
            </li>
            Pessoas com deficiência são tão capazes quanto qualquer pessoa. Adaptações são diferentes de incapacidade.
          </ul>
        </>
      ),
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          O que é capacitismo e como evitar. 🤝
        </Typography>
        <Typography variant="body2">O time e a liderança na criação de uma rotina com respeito.</Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={PwPDTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-com-deficiencia-fisica/contratacao-acessivel" />
        </Stack>
      </Box>
    </Stack>
  );
};
