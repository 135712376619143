import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import RedeDeApoio from './images/illustrations/rede-de-apoio.svg';

export const BlackPeopleSupportNetwork = () => {
  const blackPeopleTextItems = [
    {
      title: 'Seja um apoio para pessoas negras.',
      description:
        'Se mostre disponível para ajudar e compartilhar conhecimento. Acompanhe a evolução das pessoas negras na empresa e descubra como você pode ser útil para ajudar no trabalho e na carreira. Essas são formas de apoio que podem fazer a diferença.',
    },
    {
      title: 'Ofereça mentoria para pessoas negras que estão começando.',
      description:
        'Assim, pessoas negras podem se sentir mais apoiadas desde o início. Contando com o apoio de quem é mais experiente para conversar sobre carreira, inseguranças e afins. É importante ter mentores preparados.',
    },
    {
      title: 'Mentoria para líderes negros e negras.',
      description:
        'Crie um programa específico para posições de alta liderança. Dessa forma, esses profissionais vão se sentir menos sozinhos, já que na maioria das vezes são os únicos a ocupar posições de liderança.',
    },
    {
      title: 'Conte com programas que já existem.',
      description: (
        <>
          <p>
            Profissionais negros e negras oferecem programas de mentoria gratuita pra quem está começando, migrando ou em desenvolvimento de carreira. Selecionamos alguns pra você:
          </p>
          <ul>
            <li>
              <a href="https://carreirapreta.com.br/">Carreira preta</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/indique-uma-preta">Indique uma preta</a>
            </li>
            <li>
              <a href="https://www.ibobr.com.br/quem-somos/">Instituto Black Office</a>
            </li>
          </ul>
        </>
      ),
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Criando uma rede de apoio para pessoas negras. 🤝
        </Typography>
        <Typography variant="body2">A importância dessa rede para pessoas negras crescerem.</Typography>
      </Box>
      <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <img
          src={RedeDeApoio}
          aria-hidden="true"
          alt="Ilustração de uma mulher negra olhando para um cartaz. As mãos dela estão com a palma sobre o cartaz."
          style={{ maxWidth: '100%', width: '430px', height: '300px' }}
        />
      </Stack>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={blackPeopleTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-negras/maes" />
        </Stack>
      </Box>
    </Stack>
  );
};
