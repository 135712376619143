import React from 'react';
import { Box, Typography, Button, MobileStepper, Paper, LinearProgress } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { SequenceLoadError } from './SequenceError';
import { JourneyStepsSequenceContentType, SequenceBlock } from '../../hooks/queries/useSequenceGetQuery';

import CardMedia from '@mui/material/CardMedia';
import ReactPlayer from 'react-player/lazy';
import { LoadingButton } from '@mui/lab';
import { PeopleJourneyStepsStatus } from '../../hooks/queries/useJourneyStepsQuery';

export const SequenceLearn = ({
  sequenceLearnBlocks,
  currentStep,
  journeyStepStatus,
  handleUpdateLastStep,
  loadingNextStep,
  handleOnComplete,
  loadingOnComplete,
}: {
  sequenceLearnBlocks: SequenceBlock[];
  currentStep: number;
  journeyStepStatus?: PeopleJourneyStepsStatus;
  handleUpdateLastStep?: (lastIndex: number) => Promise<boolean>;
  loadingNextStep?: boolean;
  handleOnComplete?: () => Promise<boolean>;
  loadingOnComplete?: boolean;
}) => {
  const [sequenceCompleted, setCompletedSequence] = React.useState(journeyStepStatus && journeyStepStatus === PeopleJourneyStepsStatus.Confirmed ? true : false);
  const [activeStep, setActiveStep] = React.useState(currentStep || 0);

  if (!sequenceLearnBlocks) {
    console.error('Error loading learn blocks. Empty sequence blocks.');
    return <SequenceLoadError error="Conteúdo vazio." />;
  }

  const handleFinish = async () => {
    if (handleOnComplete) {
      const updateStatus = await handleOnComplete();
      if (!updateStatus) {
        alert('Ocorreu um erro ao completar a trilha. Pode ser a internet, tente novamente.');
        return;
      }
    }
    setCompletedSequence(true);
  };

  const handleNext = async () => {
    if (handleUpdateLastStep) {
      await handleUpdateLastStep(activeStep + 1);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompletedSequence(false);
  };

  const maxSteps = sequenceLearnBlocks.length;
  const currentBlock = sequenceLearnBlocks[activeStep];

  return (
    <>
      <Box sx={{ width: '90%', margin: '20px auto', flexGrow: 1, fontFamily: (theme) => theme.typography.fontFamily }}>
        <LinearProgressWithLabel value={activeStep} sequenceLength={maxSteps} sequenceCompleted={sequenceCompleted} />
        <Paper
          square
          elevation={0}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            pt: 1,
          }}
        >
          <SequenceHeader currentBlock={currentBlock} sequenceCompleted={sequenceCompleted} />
        </Paper>
        {sequenceCompleted ? (
          <Box sx={{ m: '20px', textAlign: 'center' }}>
            <Typography variant={'subtitle1'}>
              Pronto! ✅ Você concluiu a trilha e está tudo certo!
              <br />
              Acesse o <a href="/dashboard">portal de colaboradores</a> e acompanhe sua jornada.
            </Typography>
          </Box>
        ) : (
          <SequenceContent currentBlock={currentBlock} />
        )}
        <MobileStepper
          variant="text"
          steps={maxSteps}
          position="bottom"
          activeStep={activeStep}
          sx={{ backgroundColor: '#E8F1F8', py: 2 }}
          nextButton={
            <SequenceNextButton
              activeStep={activeStep}
              maxSteps={maxSteps}
              sequenceCompleted={sequenceCompleted}
              loadingOnComplete={loadingOnComplete}
              loadingNextStep={loadingNextStep}
              handleNext={handleNext}
              handleFinish={handleFinish}
            />
          }
          backButton={<SequenceBackButton activeStep={activeStep} sequenceCompleted={sequenceCompleted} handleBack={handleBack} handleReset={handleReset} />}
        />
      </Box>
    </>
  );
};

const SequenceBackButton = ({
  activeStep,
  sequenceCompleted,
  handleBack,
  handleReset,
}: {
  activeStep: number;
  sequenceCompleted: boolean;
  handleBack: () => void;
  handleReset: () => void;
}) => {
  if (sequenceCompleted) {
    return (
      <Button size="medium" color="primary" onClick={handleReset}>
        Voltar do início
      </Button>
    );
  }
  return (
    <Button size="medium" color="primary" onClick={handleBack} disabled={activeStep === 0}>
      <KeyboardArrowLeft /> Voltar
    </Button>
  );
};

const SequenceNextButton = ({
  activeStep,
  maxSteps,
  sequenceCompleted,
  loadingOnComplete,
  loadingNextStep,
  handleNext,
  handleFinish,
}: {
  activeStep: number;
  maxSteps: number;
  sequenceCompleted: boolean;
  loadingOnComplete?: boolean;
  loadingNextStep?: boolean;
  handleNext: () => void;
  handleFinish: () => void;
}) => {
  if (activeStep === maxSteps - 1) {
    return (
      <LoadingButton loading={loadingOnComplete} variant="contained" color="primary" size="large" onClick={handleFinish} disabled={sequenceCompleted}>
        Concluir
      </LoadingButton>
    );
  }

  if (activeStep === 0) {
    return (
      <LoadingButton loading={loadingNextStep} variant="contained" color="primary" size="large" onClick={handleNext}>
        Iniciar
      </LoadingButton>
    );
  }

  return (
    <LoadingButton loading={loadingNextStep} variant="contained" size="large" onClick={handleNext}>
      Próximo <KeyboardArrowRight />
    </LoadingButton>
  );
};

const SequenceHeader = ({ currentBlock, sequenceCompleted }: { currentBlock: SequenceBlock; sequenceCompleted: boolean }) => {
  return (
    <Paper square elevation={0} sx={{ width: '100%', textAlign: 'center' }}>
      <Typography variant={'h6'}>{sequenceCompleted ? 'Trilha concluída! 🎉' : currentBlock.title}</Typography>
    </Paper>
  );
};

const SequenceContent = ({ currentBlock }: { currentBlock: SequenceBlock }) => {
  return (
    <Box>
      <Typography variant={'body1'} sx={{ maxWidth: '100%', margin: '10px', whiteSpace: 'pre-line' }}>
        {currentBlock.text}
      </Typography>
      {currentBlock.content_type !== JourneyStepsSequenceContentType.Text && (
        <DynamicContentView url={currentBlock.url || ''} contentType={currentBlock.content_type} imageAltText={currentBlock.image_alt_text || ''} />
      )}
    </Box>
  );
};

const DynamicContentView = ({ url, contentType, imageAltText }: { url: string; contentType: JourneyStepsSequenceContentType; imageAltText?: string }) => {
  function normalizeUrl(url: string): string {
    if (!url) {
      return '';
    }

    if (url.indexOf('http') < 0) {
      return 'https://' + url;
    }

    return url;
  }

  switch (contentType) {
    case JourneyStepsSequenceContentType.Image:
      return (
        <>
          <Box
            component="img"
            sx={{
              height: 400,
              display: 'block',
              maxWidth: 600,
              overflow: 'hidden',
              width: '100%',
              margin: '0 auto',
            }}
            src={url}
            alt={imageAltText || ''}
          />
        </>
      );

    case JourneyStepsSequenceContentType.Video:
      return <SequenceVideoContent videoUrl={normalizeUrl(url)} />;

    case JourneyStepsSequenceContentType.Url:
      return (
        <>
          <Box sx={{ textAlign: 'center' }}>
            <Button variant="contained" size="medium" target="_blank" href={normalizeUrl(url)}>
              Link para o conteúdo
            </Button>
          </Box>
        </>
      );

    case JourneyStepsSequenceContentType.PdfFile:
      return (
        <>
          <Box sx={{ margin: '10px' }}>
            <object data={normalizeUrl(url)} type="application/pdf" width="100%" height="600">
              <Button variant="contained" size="medium" target="_blank" href={normalizeUrl(url)}>
                Abrir o arquivo PDF.
              </Button>
            </object>
          </Box>
        </>
      );
  }

  return <></>;
};

const SequenceVideoContent = ({ videoUrl }: { videoUrl: string }) => {
  const [canPlayVideo, setCanPlayVideo] = React.useState(ReactPlayer.canPlay(videoUrl));

  if (!canPlayVideo) {
    <SequenceVideoContentButton videoUrl={videoUrl} />;
  }

  function handleVideoOnError(error: Error) {
    console.log('Invalid video URL:' + videoUrl, error);
    setCanPlayVideo(false);
  }
  return (
    <CardMedia src="iframe">
      <ReactPlayer controls url={videoUrl} onError={handleVideoOnError} fallback={<SequenceVideoContentButton videoUrl={videoUrl} />} width="100%" light={true} />
    </CardMedia>
  );
};

const SequenceVideoContentButton = ({ videoUrl }: { videoUrl: string }) => {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <Button variant="contained" size="medium" target="_blank" href={videoUrl}>
        Clique aqui para acessar o vídeo
      </Button>
    </Box>
  );
};

function LinearProgressWithLabel({ value, sequenceLength, sequenceCompleted }: { value: number; sequenceLength: number; sequenceCompleted: boolean }) {
  const progressRate = sequenceCompleted ? 100 : (value / sequenceLength) * 100;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
      <Box sx={{ width: '100%' }}>
        <LinearProgress variant="determinate" value={progressRate} color="success" style={{ height: '10px', borderRadius: '10px' }} />
      </Box>
    </Box>
  );
}
