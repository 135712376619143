import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { List, ListItemButton, ListItemText } from '@mui/material';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { useNavigate } from 'react-router-dom';

export const DiversityPeopleWithTDAH = () => {
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h6" color="primary" component="h2">
          Pessoas com TDAH
        </Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1, mt: 2, textAlign: 'justify' }}>
        <Typography variant="body1" component="p">
          Neurodiversidade é o entendimento de que todos nós somos plurais e que temos formas diferentes de <strong>aprender e socializar</strong>. Dentro dessa diversidade, está o
          transtorno de déficit de atenção e hiperatividade (TDAH). Pessoas com TDAH não precisam ser curadas, mas sim compreendidas em suas diferenças que fazem parte da
          diversidade humana.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          A socióloga Judy Singer, mulher neurodiversa, criou o termo “neurodiversidade” e junto ao jornalista Harvey Blume popularizou o conceito. O símbolo da neurodiversidade
          foi criado e divulgado pela comunidade de pessoas autistas e, hoje, representa toda a comunidade neurodivergente. O símbolo é o infinito e um espectro de cores, o que
          representa exatamente a infinidade e pluralidade das pessoas no espectro.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          O TDAH atinge <strong>cerca de 2 milhões</strong> de pessoas no Brasil. Para aquelas pessoas que são diagnosticadas na fase adulta, as situações vivenciadas na infância e
          adolescência começam a fazer sentido, mas novos obstáculos surgem, principalmente dentro do contexto profissional.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          Não é possível realizar auto diagnóstico de TDAH. A definição se uma pessoa tem ou não tem TDAH é feita por profissionais capacitados da área da saúde (usualmente
          psiquiatras e profissionais da psicologia). Esses profissionais consideram várias coisas no processo de diagnóstico e algo que é muito importante é o fato de que as
          características de TDAH aparecem, sempre, durante a infância.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          Por ser uma condição de neurodesenvolvimento, o TDAH sempre começa na infância, se uma pessoa se percebe desatenta e hiperativa, por exemplo, na vida adulta, e nunca teve
          essas características durante a infância, essas características estão sendo causadas por outros motivos que não o TDAH.
        </Typography>
        <Typography variant="h6" component="h3" sx={{ pt: 2, pb: 1 }}>
          Diferenças entre pessoas com TDAH
        </Typography>
        <Typography variant="body1" component="p">
          Por causa do nome dado Transtorno de déficit de atenção e hiperatividade (TDAH), algumas pessoas pensam que quem tem TDAH convive apenas com questões relacionadas ao
          &apos;déficit de atenção&apos; e à &apos;hiperatividade&apos;, mas não é assim que funciona.{' '}
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          Pessoas com TDAH podem ter hiperatividade e questões com concentração, mas também podem ter outras características como:
          <ul>
            <li>Impulsividade;</li>
            <li>Hiperfoco;</li>
            <li>Uma forma diferente de lidar com a rotina;</li>
            <li>Entre outras.</li>
          </ul>
        </Typography>
        <Typography variant="body1" component="p">
          Algumas podem ter essas características de forma mais intensa que outras. Quando falamos de <strong>déficit de atenção</strong>, não quer dizer que a pessoa com TDAH não
          tem atenção, mas sim que ela direciona a atenção de formas diferentes.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          Em alguns momentos a pessoa com TDAH poderá se engajar em atividades do trabalho de forma focada e produtiva, sendo necessário até mesmo criar marcadores pra pausas,
          alimentação e descanso.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          No nosso manual, você vai aprender a receber, trabalhar e liderar pessoas com TDAH no dia a dia. Tudo isso através de um conteúdo simples e didático. Também criamos
          tópicos que falam diretamente com quem tem TDAH com o objetivo de iniciar uma reflexão sobre possíveis formas de lidar com algumas situações no trabalho.
        </Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1, mt: 2 }}>
        <Typography variant="h6" component="h3">
          Vamos conversar sobre
        </Typography>
        <PersonWithTDAHTopics />
      </Box>
    </Stack>
  );
};

export const TopiceMenuListItem = ({ title, text, handerClick }: { title: string; text: string; handerClick: () => void }) => {
  return (
    <ListItemButton disableGutters={true} onClick={handerClick} sx={{ p: 1 }}>
      <ListItemText primary={title} secondary={text} />
      <ArrowForwardIosIcon sx={{ fontSize: 15 }} />
    </ListItemButton>
  );
};

const PersonWithTDAHTopics = () => {
  const navigate = useNavigate();
  const handlerRoutePage = (path: string) => navigate(path);

  return (
    <>
      <List component="nav">
        <TopiceMenuListItem
          title="Aprenda a ser acessível antes e depois do sim."
          text="Entrevistas e onboarding para trabalho remoto ou presencial."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-tdah/onboarding-acessivel');
          }}
        />
        <TopiceMenuListItem
          title="Ambiente de trabalho mais saudável pra pessoas com TDAH."
          text="Boas práticas para a rotina de trabalho."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-tdah/ambiente-de-trabalho');
          }}
        />
        <TopiceMenuListItem
          title="Aprendendo a ter mais autoconfiança"
          text="Como as pessoas autistas podem ser mais confiantes."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-tdah/autoconfianca');
          }}
        />
        <TopiceMenuListItem
          title="Uma conversa com quem lidera pessoas com TDAH."
          text="Como criar oportunidades reais e justas."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-tdah/para-lideres');
          }}
        />
        <TopiceMenuListItem
          title="Inspirações de pessoas com TDAH."
          text="Redes sociais de pessoas com TDAH pra você se inspirar todos os dias."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-tdah/inspiracoes');
          }}
        />
        <TopiceMenuListItem
          title="Datas importantes."
          text="Datas que valorizam e marcam a história de pessoas com TDAH."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-tdah/datas-importantes');
          }}
        />
        <TopiceMenuListItem
          title="Conteúdos que a Inboarding indica"
          text="Pra você ir além desse manual."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-com-tdah/conteudos');
          }}
        />
      </List>
    </>
  );
};
