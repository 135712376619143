import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import Maes from './images/illustrations/maes.svg';

export const BlackPeopleMothers = () => {
  const blackPeopleTextItems = [
    {
      title: '  Busque grupos de mães pretas nas redes sociais.',
      description:
        'A maternidade é uma etapa de grande importância na vida de um povo. Para mulheres negras, ser mãe também é um processo de muitos desafios. O que pode ajudar são as redes de apoio que podem ser de vários formatos, inclusive virtual. Busque construir uma rede apoio com outras mães negras entrando em grupos nas redes sociais.',
    },
    {
      title: 'Aprenda a respeitar seus limites.',
      description:
        'Quando nos tornamos mães, trabalhar de forma exaustiva se torna um hábito. Temos a ideia de que precisamos provar a todo custo que somos boas, não nos permitimos falhar, mas é importante entender o quanto isso é autodestrutivo. Se não fazemos além do que esperam de nós sentimos culpa, se não damos atenção aos filhos, sentimos culpa, se não cuidamos bem da casa, da família, de nós mesmas , também nos culpamos, e este ciclo acaba não tendo fim. Mas existem outros caminhos e opções com a possibilidade de retornos mais positivos. Ter limites é um ato de amor, não se submeta a tudo, respeite seu processo e se acolha.',
    },
    {
      title: ' Saiba dizer não.',
      description:
        'A zona de conforto de uma mulher preta mãe é o excesso de tarefas. Você já pensou na quantidade de "sim" que você tem dado ao trabalho, enquanto diz "não" a você mesma e às pessoas que você ama? É esperado que nós mulheres pretas sempre estejamos disponíveis para atender todo mundo, mas assim nunca teremos tempo para nós. Diga não quando você tiver vontade de dizer, você também pode se fortalecer em grupos de mães.',
    },
    {
      title: 'Onde você se vê representada no seu ambiente de trabalho?',
      description:
        'Construa uma rede de mulheres negras que fazem parte do seu ambiente profissional e observe o tanto que isso reflete na sua satisfação de estar nesse espaço. Trabalhar num ambiente em que outras mulheres negras compartilham de vivências parecidas com a sua te ajuda a se sentir mais segura.',
    },
    {
      title: 'Cuide de você.',
      description:
        'Se amar é um gesto de amor aos filhos e também à família. Frustrar quem amamos é necessário quando precisamos que o outro entenda que você, mulher negra e mãe precisa de um momento de autocuidado. É difícil admitir que somos tão importantes quanto os nossos filhos. Mas, respeitar seu tempo, seus gostos e sua individualidade não é descaso e nem motivo para se culpar. Combinado?',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Bem-estar e crescimento para mães negras. 🍼
        </Typography>
        <Typography variant="body2">Uma conversa sobre autoestima, segurança e carreira.</Typography>
      </Box>
      <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <img
          src={Maes}
          aria-hidden="true"
          alt="Ilustração de uma mulher negra mãe segurando seu filho entre os braços dela."
          style={{ maxWidth: '100%', width: '430px', height: '300px' }}
        />
      </Stack>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={blackPeopleTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-negras/lideranca" />
        </Stack>
      </Box>
    </Stack>
  );
};
