import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { ListOfContents } from '../ListOfContents';

import iCom from './images/recommendations/iCom.jpg';
import Mentorama from './images/recommendations/mentorama.jpg';
import Uber from './images/recommendations/uber.jpg';
import MulheresSurdasEmAcao from './images/recommendations/MulheresSurdasEmAcao.jpg';
import LIBRAS from './images/recommendations/LIBRAS.jpg';
import IdentidadesSurdas from './images/recommendations/IdentidadesSurdas.jpg';
import AsImagens from './images/recommendations/AsImagens.jpg';

export const DeafPeopleContents = () => {
  const listOfContents = [
    {
      contentType: 'video',
      title: 'Somos diferentes de você?',
      channel: 'Isflocos',
      videoUrl: 'https://www.youtube.com/embed/efudeZSsMs8',
    },
    {
      contentType: 'video',
      title: 'Aparelho auditivo | Mãe de surdo | Regina Casé e Benedita Zerbini | Surdos que Ouvem',
      channel: 'Surdos que Ouvem',
      videoUrl: 'https://www.youtube.com/embed/8nJsf-CptBU',
    },
    {
      contentType: 'instagram',
      title:
        'Surda de nascença, @beatriz.lonskis é designer e atua na área de experiência do usuário (UX), focada em acessibilidade digital. Trabalha no jornal Valor Econômico como UX Designer.',
      embedUrl: 'https://www.instagram.com/p/Buwd5ufAfZ-/?utm_source=ig_web_copy_link',
    },
    {
      contentType: 'video',
      title: 'Não faça isso com os surdos',
      channel: 'Léo Viturinno',
      videoUrl: 'https://www.youtube.com/embed/3GAS66KMVOM',
    },
    {
      contentType: 'video',
      title: 'PORQUE EU NÃO PAREÇO SER SURDA? #LIBRAS | PAULA MARIA MARKEWICZ | SURDA',
      channel: 'Profª Paula | Libras Natural',
      videoUrl: 'https://www.youtube.com/embed/5Sstz-YNb2k',
    },
    {
      contentType: 'article',
      title: 'Aprenda com a identidade surda que as pessoas são diferentes!',
      description: 'Site: iCom',
      imageImportName: iCom,
      altText: 'Imagem do artigo sobre identidade surda no site iCom.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://www.icom-libras.com.br/2022/03/04/aprenda-com-a-identidade-surda-que-as-pessoas-sao-diferentes/',
    },
    {
      contentType: 'video',
      title: 'O mundo surdo (surdo oralizado)',
      channel: 'Alex Alvez',
      videoUrl: 'https://www.youtube.com/embed/qvQ2r_P-vW0',
    },
    {
      contentType: 'video',
      title: 'Você sabe o que é Surdez?',
      channel: 'Nerdologia',
      videoUrl: 'https://www.youtube.com/embed/bubbJSVJFRs',
    },
    {
      contentType: 'video',
      title: 'Pessoas surdas e os desafios para a inclusão.',
      channel: 'TV BrasilGov',
      videoUrl: 'https://www.youtube.com/embed/M4w1e297pWU',
    },
    {
      contentType: 'article',
      title: 'Mulheres surdas incríveis contam suas histórias sobre amor, autoconhecimento, autoestima, viagens, empreendedorismo, entre vários assuntos.',
      description: 'Canal do Youtube: Mulheres Surdas em Ação',
      imageImportName: MulheresSurdasEmAcao,
      altText: 'Imagem do vídeo com título: "Mulheres Surdas em Ação"',
      buttonLabel: 'Assistir vídeo no Youtube',
      buttonUrl: 'https://www.youtube.com/watch?v=_wXXza_oH3o',
    },
    {
      contentType: 'video',
      title: 'Precisamos do dia dos Surdos?',
      channel: 'Isflocos',
      videoUrl: 'https://www.youtube.com/embed/ZUJRoE8KhHg',
    },
    {
      contentType: 'video',
      title: 'Escola bilíngue ou inclusiva para os surdos?',
      channel: 'Isflocos',
      videoUrl: 'https://www.youtube.com/embed/qZMu6RG-EDM',
    },
    {
      contentType: 'article',
      title: 'Design inclusivo: uma metodologia para impulsionar inovação',
      description: 'Site: Mentorama',
      imageImportName: Mentorama,
      altText: 'Imagem do artigo Design inclusivo no site Mentorama.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://arizonaatwork.comhttps://mentorama.com.br/blog/design-inclusivo-uma-metodologia-para-impulsionar-inovacao/',
    },
    {
      contentType: 'article',
      title: 'Como Erica Riello utiliza sua carreira em tech a favor da inclusão',
      description: 'Site: Uber',
      imageImportName: Uber,
      altText: 'Imagem do artigo sobre Erica e sua carreira no site Uber.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://www.uber.com/pt-BR/blog/como-erica-riello-utiliza-sua-carreira-em-tech-a-favor-da-inclusao/?utm_source=',
    },
    {
      contentType: 'book',
      title: 'LIBRAS? Que língua é essa? : crenças e preconceitos em torno da língua de sinais e da realidade surda',
      imageImportName: LIBRAS,
      altText: 'Capa do livro, que consiste em um fundo branco e no centro uma mão aberta e colorida de várias cores.',
    },
    {
      contentType: 'book',
      title: 'Identidades surdas, capítulo do Livro "A surdez – um olhar sobre as diferenças"',
      imageImportName: IdentidadesSurdas,
      altText: 'Capa do livro com o título A Surdez em cima, e imagens de olhos em várias cores, com o subtítulo "Um olhar sobre as diferenças".',
    },
    {
      contentType: 'book',
      title: 'As imagens do outro sobre a cultura surda',
      imageImportName: AsImagens,
      altText: 'Capa do livro, que consiste em um fundo vermelho, um olho à direita e uma pessoa no reflexo.',
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          <strong>Aprenda mais com os conteúdos que a gente escolheu pra você. ✨</strong>
        </Typography>
        <Typography variant="body1" sx={{ pt: 2, pb: 3 }}>
          Antes de pesquisar no google mais informações sobre pessoas surdas, veja aqui nossa curadoria de conteúdos:
        </Typography>
      </Box>
      <ListOfContents contents={listOfContents} />
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
        </Stack>
      </Box>
    </Stack>
  );
};
