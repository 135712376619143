import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { ListOfContents } from '../ListOfContents';

import GuiaPratico from './images/contents/GuiaPratico.jpg';
import CapacitismoERacismo from './images/contents/CapacitismoERacismo.jpg';
import Atravessamentos from './images/contents/Atravessamentos.jpg';
import Nos from './images/contents/Nos.jpg';
import HistoriaSocial from './images/contents/HistoriaSocial.jpg';
import Inclusao from './images/contents/Inclusao.jpg';
import Adultos from './images/contents/Adultos.jpg';

export const AutisticPeopleContents = () => {
  const listOfContents = [
    {
      contentType: 'book',
      title: 'Guia Prático para Autistas Adultos: Como não surtar em situações do cotidiano',
      imageImportName: GuiaPratico,
      altText: 'Capa do livro na cor amarela, com o título simulando como se estivesse escrito em folhas de papel de caderno.',
    },
    {
      contentType: 'article',
      title: 'Capacitismo e racismo: ‘um menino preto e autista não oralizado’',
      description: 'Texto no site: Canal Autismo',
      imageImportName: CapacitismoERacismo,
      altText: 'Imagem da tela inicial do artigo no site Calnal Autismo.',
      buttonLabel: 'Ler matéria no site',
      buttonUrl: 'https://www.canalautismo.com.br/artigos/capacitismo-e-racismo-um-menino-preto-e-autista-nao-oralizado/',
    },
    {
      contentType: 'article',
      title: 'Atravessamentos de raça, gênero e classe no autismo',
      description: 'Texto no site: O Mundo Autista',
      imageImportName: Atravessamentos,
      altText: 'Imagem da tela inicial do artigo no site O Mundo Autista.',
      buttonLabel: 'Ler matéria no site',
      buttonUrl: 'https://omundoautista.uai.com.br/atravessamentos-de-raca-genero-e-classe-no-autismo/',
    },
    {
      contentType: 'article',
      title: 'NÓS, autistas: "Autismo no Brasil tem raça, gênero e classe"',
      description: 'Texto no site: Terra',
      imageImportName: Nos,
      altText: 'Imagem da tela inicial do artigo no site Terra.',
      buttonLabel: 'Ler materia no site',
      buttonUrl: 'https://www.terra.com.br/nos/videos/nos-autistas-autismo-no-brasil-tem-raca-genero-e-classe,38921240e9da2b31c159dadc75ce2291hgzy3wtf.html',
    },
    {
      contentType: 'video',
      title: 'Como você acolhe as diversas formas de existir? | Luciana Viegas | TEDxPelourinho',
      channel: 'Canal do Youtube: TEDx Talks',
      videoUrl: 'https://www.youtube.com/embed/5qvmFlMDUHQ',
    },
    {
      contentType: 'video',
      title: 'AUTISMO E RAÇA: A intersecção entre duas minorias sociais (com Luciana Viegas)',
      channel: 'Canal do Youtube: Ricardo Oliveira - Autismo Pensante',
      videoUrl: 'https://www.youtube.com/embed/SXCwXfVww2E',
    },
    {
      contentType: 'video',
      title: 'Será que pessoas autistas concordam em tudo?',
      channel: 'Canal do Youtube: Mescla',
      videoUrl: 'https://www.youtube.com/embed/Eue-xvsZkXg',
    },
    {
      contentType: 'video',
      title: 'A prevalência do autismo e o racismo estrutural',
      channel: 'Canal do Youtube: PROAES UFRRJ.',
      videoUrl: 'https://www.youtube.com/embed/5gFFjDscLsg',
    },
    {
      contentType: 'video',
      title: 'ciclo de Palestras - Autismo e Racismo',
      channel: 'Canal do Youtube: Projeto TEA-TDIC',
      videoUrl: 'https://www.youtube.com/embed/t6yKpE-RESU',
    },
    {
      contentType: 'article',
      title: 'História Social™ e Autismo: uma Revisão de Literatura',
      description: 'Autores: Fernanda Squassoni LAZZARINI e Nassim Chamel ELIAS',
      imageImportName: HistoriaSocial,
      altText: 'Imagem da tela inicial do artigo no site Scielo.',
      buttonLabel: 'Ler artigo no site',
      buttonUrl: 'https://doi.org/10.1590/1980-54702022v28e0017',
    },
    {
      contentType: 'article',
      title: 'A inclusão no mercado de trabalho de adultos com Transtorno do Espectro do Autismo: uma revisão bibliográfica',
      description: 'Autores: Mariana Valente Teixeira da Silva Talarico, Amanda Cristina dos Santos Pereira e Antonio Celso de Noronha Goyos.',
      imageImportName: Inclusao,
      altText: 'Imagem da tela inicial do artigo no site Revista Educação Especial.',
      buttonLabel: 'Ler artigo no site',
      buttonUrl: 'https://doi.org/10.5902/1984686X39795',
    },
    {
      contentType: 'article',
      title: 'AUTISMO EM ADULTOS: COMO LIDAR COM O DIAGNÓSTICO TARDIO',
      description: 'Texto no site: Drauzio Varella',
      imageImportName: Adultos,
      altText: 'Imagem da tela inicial do artigo no site Drauzio Varella.',
      buttonLabel: 'Ler pesquisa no site',
      buttonUrl: 'https://drauziovarella.uol.com.br/psiquiatria/autismo-em-adultos-como-lidar-com-o-diagnostico-tardio/',
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Conteúdos que a Inboarding indica. ✨
        </Typography>
        <Typography variant="body2">Pra você ir além desse manual.</Typography>
      </Box>
      <ListOfContents contents={listOfContents} />
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
        </Stack>
      </Box>
    </Stack>
  );
};
