import { useMutation, useQueryClient } from 'react-query';

import { QueryKeys } from '../../config/QueryKeys';
import { updateDataProfile } from '../../services/api';

export const useProfileUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation(updateDataProfile, {
    onMutate: () => {
      queryClient.cancelQueries(QueryKeys.getProfile);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.getProfile);
    },
  });
};
