import React from 'react';

import { Typography } from '@mui/material';

export const FormSurveyLoading = () => {
  return (
    <Typography variant="body1" component="p">
      Carregando dados da pesquisa...
    </Typography>
  );
};
