import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

export const DeafPeopleCapacitism = () => {
  const deafPeopleTextItems = [
    {
      title: 'O que é capacitismo?',
      description:
        'Segundo Ivan Baron, teórico da área, capacitismo é “toda discriminação, violência ou atitude contra a pessoa com deficiência e se expressa desde formas mais sutis até as mais gritantes." Também acontece quando imaginamos , de maneira errada, que uma pessoa com deficiência é limitada, e a partir daí, adotamos um comportamento de superioridade em relação a ela.',
    },
    {
      title: 'Pare de falar expressões capacitistas.',
      description: (
        <>
          <p>
            <strong>Surdo-mudo, mudo ou mudinho:</strong> as pessoas surdas podem emitir sons e aprender a língua oral. Então não use esses termos, ok? Eles são preconceituosos e
            capacitistas.
          </p>
          <p>
            <strong>Outras expressões:</strong>
          </p>
          <ul>
            <li>É tão bonita ou inteligente, nem parece surda.</li>
            <li>Você é o acompanhante, vou resolver com você!</li>
            <li>Acho lindo você (ouvinte) namorar esse surdo!</li>
            <li>A igreja pode curar sua surdez.</li>
            <li>Você tá surda!</li>
            <li>Ainda bem que você é surdo que não precisa ouvir.</li>
            <li>Por que não usa aparelho auditivo? </li>
            <li>Você é surda, mas fala?</li>
            <li>Por que você não fala?</li>
            <li>Nossa, tadinho! Ele é tão lindo, pena que é surdo.</li>
            <li>Ele fala? Então não é surdo.</li>
            <li>Pode falar? Tenho curiosidade em ouvir sua voz.</li>
            <li>Deve ser tão triste não conseguir ouvir, né?</li>
            <li>Pessoa surda dirige carro?</li>
            <li>Por que o surdo escreve errado?</li>
            <li>Os surdos podem ter filhos?</li>
          </ul>
        </>
      ),
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          O que é capacitismo e como evitar. 🤝
        </Typography>
        <Typography variant="body2">O time e a liderança na criação de uma rotina com respeito.</Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={deafPeopleTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-surdas/contratacao-acessivel" />
        </Stack>
      </Box>
    </Stack>
  );
};
