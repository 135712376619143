import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

export const PeopleWithTDAHLeaders = () => {
  const peopleWithTDAHTextItems = [
    {
      title: 'Cuidado com o microgerenciamento.',
      description:
        'Todas as pessoas e, também, pessoas com TDAH podem não se sentir bem com alguém vigiando elas durante todo o tempo. Converse com a pessoa para entender como a entrega das tarefas pode ser combinada. Ainda assim, é importante que você esteja próximo, acompanhe e esteja disponível para ajudar no que for necessário, inclusive lembrando dos objetivos coletivos.',
    },
    {
      title: 'Tenha organização com as tarefas.',
      description:
        'Pessoas com TDAH podem ter dificuldade com organização. Por isso, estabeleça prazos pra todas as tarefas. Não só isso, tenha clareza sobre o que precisa ser feito e se comunique de forma simples pra que essa pessoa entenda a participação dela na tarefa.',
    },
    {
      title: 'Dê autonomia para pessoas com TDAH.',
      description:
        'Principalmente para definir o tempo que cada tarefa vai precisar. Pessoas com TDAH podem ter variações sobre a forma de trabalho e produtividade. Isso só vai ser compreendido pela liderança se houver uma conversa sobre o assunto. Uma forma que muitas pessoas com TDAH gostam é de trabalhar por entrega.',
    },
    {
      title: 'Pergunte se precisam de apoio no dia a dia.',
      description:
        'Pessoas com TDAH podem ter dificuldade para colocar os planos em prática. Pergunte se a pessoa precisa de algum apoio para organizar a execução de alguma ideia. Às vezes uma reunião para organizar as ideias, para delimitar prioridades, já pode ser de grande ajuda para a pessoa com TDAH.',
    },
    {
      title: 'Conheça os pontos fortes dela.',
      description: (
        <>
          Todas as pessoas têm pontos fortes. Junto da pessoa, tente encontrar quais são os pontos fortes dela e como ela se sente realizando certas tarefas. Líder, você pode
          estimular a pessoa com TDAH pra que ela reconheça pontos fortes e usar isso pra alcançar maiores resultados no time.
          <a href="https://www.gallup.com/cliftonstrengths/pt/253652/como-funciona-o-cliftonstrengths.aspx">Uma metodologia que pode te ajudar é a GALLUP.</a>
        </>
      ),
    },
    {
      title: 'Criem objetivos de forma conjunta.',
      description:
        'Discuta suas expectativas em relação as entregas para que vocês possam criar metas realistas e possíveis de serem alcançadas. Além disso, tenha atenção  às expectativas da pessoa com TDAH em relação a carreira dela e desenvolvimento profissional. É importante também relembrar os objetivos e metas do time pra manter todo mundo engajado.',
    },
    {
      title: 'Pergunte se preferem trabalho remoto ou presencial.',
      description:
        'Algumas pessoas com TDAH preferem o trabalho remoto e outras preferem o trabalho presencial. Algumas dizem  que o trabalho remoto é muito bom porque nele elas conseguem organizar a rotina melhor, pois, muitos estímulos no ambiente de trabalho podem distrair, o que pode ser mais controlado em casa. Já outras pessoas podem preferir o trabalho presencial porque o ambiente do escritório pode ajudar a manter o foco. ',
    },
    {
      title: 'Ofereça abafador ou fone de ouvido que cancele ruídos.',
      description:
        'Pessoas com TDAH podem ter sensibilidade auditiva. Se esse for o caso, a empresa pode oferecer um abafador ou fone que cubra os ouvidos e educar o time para que todos entendam o motivo daquela pessoa precisar disso. Uma ação simples pode melhorar muito o bem-estar da pessoa no ambiente de trabalho. A sensibilidade sensorial, somada a um ambiente com muito barulho pode gerar uma sobrecarga sensorial que pode ser muito ruim para pessoas com TDAH.',
    },
    {
      title: 'Como lidar com o hiperfoco no trabalho.',
      description:
        'Acontece quando alguém tem tanto interesse em algo que aquele foco de interesse se torna o principal dela  por algum tempo. Caso você perceba que a pessoa está se dedicando demais a um projeto (não se lembra de fazer pausas para comer e descansar) converse com ela sobre estratégias que vocês podem criar para melhorar isso. Se o trabalho for presencial, você pode convidar a pessoa para tomar um café ou lembrar o horário de encerrar as atividades e ir pra casa.',
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Uma conversa com quem lidera pessoas com TDAH. 🤝
        </Typography>
        <Typography variant="body2">Como criar oportunidades reais e justas.</Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={peopleWithTDAHTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-com-tdah/inspiracoes" />
        </Stack>
      </Box>
    </Stack>
  );
};
