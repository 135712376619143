import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import Selfconfidence from './images/illustrations/selfconfidence.svg';

export const BlindPeopleSelfConfidence = () => {
  const blindPeopleTextItems = [
    {
      title: 'Você merece uma vaga que faça sentido pra sua carreira.',
      description:
        'É comum que algumas empresas façam ofertas de vagas que não têm relação com a especialidade da pessoa com deficiência visual, mas isso é capacitista e não é justo com você. Seus estudos e esforços são importantes e ter uma deficiência não te faz menor que outros colegas de trabalho.',
    },
    {
      title: 'Evite se comparar com outras pessoas com deficiência visual.',
      description:
        'A única coisa que você tem em comum com essas pessoas é a deficiência visual. Evite comparar suas habilidades com a de outras pessoas, cada um tem uma história e um processo diferente. Se você não se sente confortável fazendo certa tarefa ou se sente que não consegue fazê-la sem auxílio, tudo bem, isso não te faz menos capaz.',
    },
    {
      title: 'Valorize sua trajetória.',
      description:
        'Duvidar das suas capacidades, se colocar num lugar de alguém que não sabe, falar de si com desprezo, todas essas atitudes são injustas com você. Valorize quem você lutou pra ser, e isso começa da forma como você fala de si.',
    },
    {
      title: 'As pessoas precisam saber das suas habilidades.',
      description:
        'Pode parecer óbvio, mas as pessoas só saberão quem você é se você comunica isso, caso contrário suas horas de estudos e trabalho só vão ser percebidas por você. O LinkedIn é uma ótima forma de começar a compartilhar.',
    },
    {
      title: 'Comemore seus feedbacks.',
      description:
        'Receber feedback pelo trabalho não é algo fácil. Algumas pessoas com deficiência visual relatam que trabalham muito, mas não recebem feedbacks. Você merece ter reconhecimento pelo seu trabalho, comemore e valorize os feedbacks que você recebe.',
    },
    {
      title: 'Aproveite cada fase do seu crescimento.',
      description:
        'Existem muitas fases até chegar onde você quer. A que você está bem no comecinho e tudo é novidade, a que você já começa a ganhar a maturidade e precisa aperfeiçoar aquilo que aprendeu, e por aí em diante. A verdade é que cada fase tem seu lado bom e aproveitar cada uma delas é ótimo pra um crescimento saudável.',
    },
    {
      title: 'Converse com pessoas com deficiência visual que te inspiram.',
      description:
        'Existem pessoas com deficiência visual incríveis que já chegaram aonde você quer e estão abertas pra conversar e ajudar. Que tal aproveitar isso pra aprender com a trajetória delas e usar esses aprendizados na sua carreira? Aprender com a experiência de outras pessoas é importante.',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Aprendendo a ter mais autoconfiança. 💪
        </Typography>
        <Typography variant="body2">Para pessoas com deficiência visual que querem ter mais confiança no trabalho e na vida.</Typography>
      </Box>
      <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <img
          src={Selfconfidence}
          alt="Imagem ilustrativa de uma mulher em pé com círculos coloridos ao seu redor."
          style={{ maxWidth: '430px', width: '100%' }}
          aria-hidden="true"
        />
      </Stack>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={blindPeopleTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-com-deficiencia-visual/para-quem-lidera" />
        </Stack>
      </Box>
    </Stack>
  );
};
