import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

// import { LinkButton } from '../Deaf/DeafPeopleIncredibleAchievements';
import StevieWonder from './images/achievements/StevieWonder.jpg';
import DorinaDeGouveaNowill from './images/achievements/DorinaDeGouveaNowill.jpg';
import RayCharles from './images/achievements/RayCharles.jpg';
import MarlaLeeRunyan from './images/achievements/MarlaLeeRunyan.jpg';
import AntonioTenorio from './images/achievements/AntonioTenorio.jpg';
import { ListOfPeople } from '../ListOfPeople';

export const BlindPeopleIncredibleAchievements = () => {
  const ListOfPeopleAndAchievements = [
    {
      info: {
        name: 'Stevie Wonder',
        description: (
          <>
            <p>
              Stevie Wonder é um cantor, compositor e revolucionário de causas humanitárias e sociais estadunidense. Um dos maiores músicos da contemporaneidade, possui uma
              trajetória lendária, cuja música percorre épocas, com suas notas de piano belas e sua voz suave.
            </p>
            <p>
              Stevland Hardaway Morris é conhecido por seu funk pop retrô, que deu origem a inúmeras canções de sucesso e o levou ao topo das paradas musicais em todos os lugares.
              Stevie recebeu 25 prêmios Grammy e é aclamado pela crítica por produzir até 30 dos dez melhores hits dos EUA. Ele ainda é o único artista da Motown e o segundo músico
              afro-americano a ganhar um Oscar. Ele é considerado um dos 60 principais artistas da música a alcançar o sucesso mais crítico e comercial da história.
            </p>
          </>
        ),
      },
      image: {
        personImportName: StevieWonder,
        altText: 'Foto de Stevie Wonde usando óculos escuros e sorrindo. Ele está de tranças e com uma roupa preta.',
      },
      buttons: [],
    },
    {
      info: {
        name: 'Dorina de Gouvêa Nowill',
        description: (
          <>
            <p>
              Dorina nasceu em São Paulo, no dia 28 de maio de 1919 e acabou ficando cega aos 17 anos de idade, vítima de uma doença não diagnosticada. Ela foi a primeira aluna
              cega a frequentar um curso regular na Escola Normal Caetano de Campos, e conseguiu a integração de outra menina cega num curso regular da mesma escola. Educadora,
              filantropa e administradora brasileira.
            </p>
            <p>
              Dorina se formou na área da educação e trabalhou intensamente para a criação e implantação de instituições, leis e campanhas que tinham como propósito tornar a
              sociedade mais inclusiva para pessoas que possuem deficiência visual, seu trabalho foi diversas vezes reconhecido e premiado.
            </p>
          </>
        ),
      },
      image: {
        personImportName: DorinaDeGouveaNowill,
        altText: 'Foto de Dorina ao lado de um quadro. Ela está de óculos escuros e com uma roupa azul, verde e amarela.',
      },
      buttons: [],
    },
    {
      info: {
        name: 'Ray Charles',
        description: (
          <>
            <p>
              O grandioso cantor, músico, pianista e compositor Ray Charles é norte-americano, nasceu na Georgia, U.S.A. e é considerado um dos maiores músicos populares
              afro-americanos. Ele ficou completamente cego quando tinha apenas 7 anos de idade depois de lutar com glaucoma e a consequente diminuição da visão por dois anos.
            </p>
            <p>
              Ray se interessou pela música desde tenra idade e começou a aprender a tocar piano clássico. Ele é representante da essência da música negra e inventor do soul, um
              estilo musical construído a partir de elementos do blues e jazz.
            </p>
          </>
        ),
      },
      image: {
        personImportName: RayCharles,
        altText: 'Foto de Ray Charles se apresentando, cantando e tocando teclado. Ele está de óculos escuros, e uma roupa formal dourada e preta.',
      },
      buttons: [],
    },
    {
      info: {
        name: 'Marla Lee Runyan',
        description: (
          <>
            <p>
              Ela é atleta e mestra em Transtorno da Comunicação e Educação Especial. É a autora de um dos livros mais vendidos do mundo, &quot;Sem meta: minha vida como eu a
              vejo&quot;, em que ela contou sua história de vida.
            </p>
            <p>
              Marla ganhou inúmeros prêmios no atletismo, corridas de maratona e corrida em estrada. Marla também se destacou no mundo do atletismo, esta atleta americana é cega
              desde os 9 anos de idade. Ela foi três vezes campeã nacional nas Paraolimpíadas com cinco medalhas de ouro em seu nome e a primeira atleta cega nas Olimpíadas.
            </p>
          </>
        ),
      },
      image: {
        personImportName: MarlaLeeRunyan,
        altText: 'Foto de Marla em um palco se apresentando e segurando um microfone na mão esquerda. Ela veste uma roupa preta.',
      },
      buttons: [],
    },
    {
      info: {
        name: 'Antônio Tenório',
        description: (
          <>
            <p>
              Atleta e campeão paralímpico de Judô. Com 13 anos, Antônio, que pratica judô desde os 8, foi atingido no olho esquerdo por uma semente de mamona enquanto brincava com
              os amigos. O trauma deslocou a sua retina e o deixou cego. Seis anos mais tarde, o olho direito foi prejudicado por uma infecção, fazendo com que ele perdesse
              totalmente a visão.
            </p>
            <p>
              O atleta se adaptou à nova realidade e construiu sua identidade enquanto uma pessoa cega. Teve de se adaptar na modalidade paraolímpica de judô e foi se
              aperfeiçoando. Em 1996, nos jogos de Atlanta, garantiu a primeira medalha de ouro do Brasil fora do atletismo e da natação. Quando chegou em Pequim, em 2008, era o
              único judoca a conquistar quatro ouros consecutivos em Paraolimpíadas e o 6º maior vencedor brasileiro em Jogos Olímpicos. Aos 42 anos, subiu ao pódio da Rio 2016 com
              medalha de prata e segue firme fazendo o que ama.
            </p>
          </>
        ),
      },
      image: {
        personImportName: AntonioTenorio,
        altText: 'Foto de Antônio sorrindo. Ele está segurando a medalha de prata foi colocada no seu pescoço.',
      },
      buttons: [],
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Conquistas incríveis. 🏆
        </Typography>
        <Typography variant="body2">Uma lista de pessoas com deficiencia visual que fizeram história.</Typography>
      </Box>
      <ListOfPeople people={ListOfPeopleAndAchievements} />
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-com-deficiencia-visual/datas-importantes" />
        </Stack>
      </Box>
    </Stack>
  );
};
