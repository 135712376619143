import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';
import { ListOfPeople } from '../ListOfPeople';

import BrunoNunes from './images/inspirations/BrunoNunes.jpg';
import SandyaraPeres from './images/inspirations/SandyaraPeres.jpg';
import JanainaMoreira from './images/inspirations/JanainaMoreira.jpg';
import JanaineCavalcanti from './images/inspirations/JanaineCavalcanti.png';
import PedroAvelar from './images/inspirations/PedroAvelar.jpg';

export const PeopleWithTDAHInspirations = () => {
  const ListOfInspirationalPeople = [
    {
      info: {
        name: 'Pedro Avelar',
        description: (
          <>
            <p>Pedro é uma pessoa LGBTQIA+ e neurodiversa em movimento.</p>
            <p>Ele é formado em Negócios Internacionais , pós-graduado em Logística e em Direitos Humanos. Além das formações, ele estuda Diversidade e Inclusão há 5 anos.</p>
            <p>
              É apaixonado por pessoas, culturas e já viajou pelo mundo. Ao longo dos últimos anos, ele teve a oportunidade de morar em 4 países, conhecer outros 15 e no Brasil,
              como nômade digital, já morou e visitou 9 estados. Ele trabalha pra desenvolver pessoas e propor melhorias contínuas no ambiente de trabalho.{' '}
            </p>
          </>
        ),
      },
      image: {
        personImportName: PedroAvelar,
        altText: 'Foto de Pedro. Ele está sorrindo e usando uma camise branca com folhas estampadas.',
      },
      buttons: [
        {
          label: 'Acompanhe Pedro no LinkedIn',
          url: 'https://www.linkedin.com/in/avelarpeu/',
        },
      ],
    },
    {
      info: {
        name: 'Sandyara Peres',
        description: (
          <>
            <p>
              Uma mulher cis, cigana, pós-graduanda em Gestão de Negócios Digitais pela USP. Mestranda com ênfase em Interação Humano-Computador e Informática na Educação na
              UNICAMP e Coordenadora de Acessibilidade no PicPay. Questionadora das estruturas sociais e do mercado de trabalho, amante da cultura geek e entusiasta em ciências
              sociais.
            </p>
            <p>
              Leva a Transformação Digital através da paixão por Acessibilidade, Diversidade, Equidade e Inclusão. Constrói um ambiente cada vez melhor para todas as pessoas, tanto
              para pessoas colaboradoras quanto para as pessoas da comunidade onde estamos conectados.
            </p>
          </>
        ),
      },
      image: {
        personImportName: SandyaraPeres,
        altText: 'Foto de Sandyara, ela usa uma blusa verde.',
      },
      buttons: [
        {
          label: 'Acompanhe Sandyara no LinkedIn',
          url: 'https://www.linkedin.com/in/sandyaraperes/',
        },
      ],
    },
    {
      info: {
        name: 'Janaine Cavalcanti',
        description: (
          <>
            <p>
              Janaine é PhD em Biociências diagnosticada com TDAH na idade adulta, usa as suas redes sociais para compartilhar conhecimentos relacionados ao TDAH e a diversidade
              neuro cognitiva.
            </p>
            <p>
              No Youtube é possível acessar uma grande quantidade de conteúdos que ela produz para pessoas com TDAH. Os conteúdos variam entre dicas profissionais e até mesmo
              estratégias para o desenvolvimento de habilidade socioemocionais.
            </p>
            <p>
              Janaine é profissional de marketing digital, foi diagnosticada com o transtorno já na vida adulta e hoje compartilha informações sobre ele em seu perfil no Instagram,
              onde acumula cerca de 140 mil seguidores.
            </p>
          </>
        ),
      },
      image: {
        personImportName: JanaineCavalcanti,
        altText: 'Foto da Janaina.',
      },
      buttons: [
        {
          label: 'Siga a Janaine no Instagram',
          url: 'https://www.instagram.com/oh.tdah/',
        },
      ],
    },
    {
      info: {
        name: 'Bruno Nunes',
        description: (
          <>
            <p>
              Educador, Licenciado em Letras, pós-graduando em neuro e psicopedagogia. Foi diagnosticado com o Transtorno do Déficit de Atenção e Hiperatividade aos 21 anos.
              Acredita ter como missão de vida dividir com aqueles que querem uma vida extraordinária.
            </p>
            <p>
              Já treinou milhares de alunos nos últimos 10 anos. Seja em treinamentos, cursos ou em mentorias, o Bruno já ajudou pessoas a lerem de forma mais inteligente, usarem
              melhor a memória, organizarem o tempo, as tarefas e a vida de um modo geral.
            </p>
          </>
        ),
      },
      image: {
        personImportName: BrunoNunes,
        altText: 'Foto de Bruno. Ele usa uma camiseta preta e está sorrindo.',
      },
      buttons: [
        {
          label: 'Siga Bruno no Instagram',
          url: 'https://www.instagram.com/brunolimanunes/?igshid=YmMyMTA2M2Y%3D',
        },
      ],
    },
    {
      info: {
        name: 'Janaina Moreira',
        description: (
          <>
            <p>
              Apaixonada por Acessibilidade, Diversão e Inclusão. Especialista em projetar e desenvolver soluções para criar uma realidade mais inclusiva, diversa e acessível.
              Trabalha para encontrar áreas de melhoria para o negócio.
            </p>
            <p>
              Gerencia iniciativas estratégicas, operações de design, projetos de desenvolvimento digital e mais. Pra ela, acessibilidade, diversidade e inclusão não são apenas
              algo ético, mas também uma estratégia de negócios e diferencial competitivo.
            </p>
          </>
        ),
      },
      image: {
        personImportName: JanainaMoreira,
        altText: 'Foto de Janaina. Ela está usando uma blusa preta e colares.',
      },
      buttons: [
        {
          label: 'Acompanhe Janaína no LinkedIn',
          url: 'https://www.linkedin.com/in/janainalmoreira/',
        },
      ],
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Inspirações de pessoas com TDAH. ✨
        </Typography>
        <Typography variant="body2">Redes sociais de pessoas com TDAH pra você se inspirar todos os dias.</Typography>
      </Box>
      <ListOfPeople people={ListOfInspirationalPeople} />
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-com-tdah/datas-importantes" />
        </Stack>
      </Box>
    </Stack>
  );
};
