import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import MaternidadeSapatao from './images/inspirations/MaternidadeSapatao.jpg';
import YuriMarcal from './images/inspirations/YuriMarcal.jpg';
import FamiliaQuilombo from './images/inspirations/FamiliaQuilombo.jpg';
import GiovannaHeliodoro from './images/inspirations/GiovannaHeliodoro.jpg';
import RodrigoFranca from './images/inspirations/RodrigoFranca.jpg';
import FleuryJhonson from './images/inspirations/FleuryJhonson.jpg';
import FaydaBelo from './images/inspirations/FaydaBelo.jpg';
import DjamilaRibeiro from './images/inspirations/DjamilaRibeiro.jpg';

import { ListOfPeople } from '../ListOfPeople';

export const BlackPeopleInspirations = () => {
  const ListOfInspirationalPeople = [
    {
      info: {
        name: 'Maternidade Sapatão - Alinexú e Alessandra Ayabá',
        description: (
          <>
            <p>
              Compartilham, pelo Instagram, a vida de duas mães pretas, que vivem na periferia do Rio de Janeiro. Elas têm dois filhos, Jamal e Jawari. As duas trazem reflexões
              extremamente importantes sobre maternidade, comunidade, ancestralidade e família a partir de uma perspectiva preta.
            </p>
          </>
        ),
      },
      image: {
        personImportName: MaternidadeSapatao,
        altText: 'Foto de Alinexú e Alessandra Ayabá com cada uma segurando um dos dois filhos bebês delas. Elas estão sorrindo.',
      },
      buttons: [
        {
          label: 'Seguir no Instagram',
          url: 'https://www.instagram.com/maternidadesapatao/',
        },
      ],
    },
    {
      info: {
        name: 'Yuri Marçal - é influenciador digital e produtor de conteúdos',
        description: (
          <>
            <p>
              Em suas redes sociais ele contesta, através de um humor crítico, questões relacionadas ao racismo, intolerância religiosa e outras formas de preconceito e
              discriminação. Yuri é o primeiro humorista negro em 15 anos de Netflix a estrelar um especial de “Stand Up Comedy” dentro da plataforma.
            </p>
          </>
        ),
      },
      image: {
        personImportName: YuriMarcal,
        altText: 'Foto de Yuri Marçal sorrindo. Ele possui topete com dreads e veste uma blusa com capuz caído sobre os ombros.',
      },
      buttons: [
        {
          label: 'Seguir no Instagram',
          url: 'https://www.instagram.com/oyurimarcal/',
        },
      ],
    },
    {
      info: {
        name: 'Família Quilombo - Adriana Arcebispo e Josimar Silveira',
        description: (
          <>
            <p>
              Nesta página você vai encontrar os desafios, o cultivo e as colheitas fartas de alegria de uma família preta que vive no Brasil. O casal costuma compartilhar
              ensinamentos sobre a criação dos dois filhos, Akins e Dandara. Trazem uma perspectiva saudável de perceber a maternidade e a paternidade preta.
            </p>
          </>
        ),
      },
      image: {
        personImportName: FamiliaQuilombo,
        altText: 'Foto da Família Quilombo composta pela filha Dandara, pela mãe Adriana, pelo filho Akins e pelo pai Josimar. Eles estão abraçados e sorrindo. ',
      },
      buttons: [
        {
          label: 'Seguir no Instagram',
          url: 'https://www.instagram.com/familiaquilombo/',
        },
      ],
    },
    {
      info: {
        name: 'Giovanna Heliodoro - Historiadora, Comunicadora, Afrotransfeminista, Speaker do TEDx, Pesquisadora e colunista do BuzzFeed',
        description: (
          <>
            <p>
              Constrói pensamentos revolucionários a favor da igualdade de gênero, além de falar sobre assuntos atuais relacionados à política, empregabilidade e racismo, levando
              inúmeros debates em suas redes. A historiadora produz e apresenta o seu canal “Trans Preta”, no Youtube, onde compartilha as suas vivências.
            </p>
          </>
        ),
      },
      image: {
        personImportName: GiovannaHeliodoro,
        altText: 'Foto de Giovanna com cabelo solto crespo. Ela usa brincos grandes na cor verde e tem folhas ao redor dela.',
      },
      buttons: [
        {
          label: 'Seguir no Instagram',
          url: 'https://www.instagram.com/transpreta/',
        },
      ],
    },
    {
      info: {
        name: 'Rodrigo França',
        description: (
          <>
            <p>
              É um diretor de cinema e teatro, ator, dramaturgo, filósofo, professor, articulador cultural, produtor, escritor, artista plástico e empresário brasileiro. Em suas
              redes sociais ele compartilha conteúdos sobre ativismo social e políticos, além de lutas pelos direitos civis da população negra no Brasil.
            </p>
          </>
        ),
      },
      image: {
        personImportName: RodrigoFranca,
        altText: 'Foto de Rodrigo França com tranças enroladas em um coque no topo da cabeça. Ele tem barba e bigode. Está sorrindo.',
      },
      buttons: [
        {
          label: 'Seguir no Instagram',
          url: 'https://www.instagram.com/rodrigofranca/?hl=pt',
        },
      ],
    },
    {
      info: {
        name: 'Fleury Jhonson',
        description: (
          <>
            <p>
              Médico pela UFRJ, Fleury, faz a diferença na vida da população negra pela escolha de trabalhar no Sistema Único de Saúde. Hoje, ele se encontra também como Head
              Global de saúde do Nubank, cuidando e fazendo promoção de saúde para mais de 8 mil vidas. Especialista em Clínica médica pelo Hospital Pasteur e estrategista em
              desenvolvimento de linhas de cuidado para a Saúde da População Negra.
            </p>
            <p>Em suas redes sociais ele compartilha dicas e conhecimentos sobre a saúde da população negra.</p>
          </>
        ),
      },
      image: {
        personImportName: FleuryJhonson,
        altText: 'Foto de Fleury usando um jaleco e sorrindo. Ele usa tranças curtas e óculos',
      },
      buttons: [
        {
          label: 'Seguir no Linkedin',
          url: 'https://www.linkedin.com/in/fleury-johnson-md-64644ab0/?originalSubdomain=br',
        },
        {
          label: 'Seguir no Instagram',
          url: 'https://www.instagram.com/drfleuryjohnson/?hl=pt',
        },
      ],
    },
    {
      info: {
        name: 'Fayda Belo',
        description: (
          <>
            <p>
              Advogada criminalista, Fayda Belot é formada como advogada e pós-graduada em Direito Processual Penal, ela montou o próprio escritório de advocacia e trabalha na
              defesa de mulheres e crianças vítimas de violência doméstica, crimes ligados ao gênero e discriminação racial.
            </p>
            <p>Com muito carisma e simpatia, Fayda leva seus conhecimentos jurídicos às pessoas que não têm acesso pelas redes sociais.</p>
          </>
        ),
      },
      image: {
        personImportName: FaydaBelo,
        altText: 'Foto de Fayda Belo com cabelo curto solto e as costas da mão tocando o queixo. Ela usa óculos.',
      },
      buttons: [
        {
          label: 'Seguir no Instagram',
          url: 'https://www.instagram.com/faydabelo/',
        },
      ],
    },
    {
      info: {
        name: 'Djamila Ribeiro',
        description: (
          <>
            <p>
              Djamila Ribeiro é uma grande referência quando se fala em ativismo negro no Brasil. Djamila Taís Ribeiro dos Santos é Mestra em Filosofia Política pela Universidade
              Federal de São Paulo e autora de livros best-sellers como “Lugar de Fala”, “Quem tem medo do Feminismo Negro?” e “Pequeno Manual Antirracista”. É coordenadora do Selo
              Sueli Carneiro e da Coleção Feminismos Plurais que, em dois anos, publicou nove livros de oito autoras e autores negros. É colunista do jornal Folha de S. Paulo e
              considerada pela BBC uma das 100 mulheres mais influentes do mundo. Ganhou o prêmio Prince Claus Awards, concedido pelo governo holandês.
            </p>
          </>
        ),
      },
      image: {
        personImportName: DjamilaRibeiro,
        altText: 'Foto de Fayda Belo com cabelo curto solto e as costas da mão tocando o queixo. Ela usa óculos.',
      },
      buttons: [
        {
          label: 'Seguir no Instagram',
          url: 'https://www.instagram.com/djamilaribeiro1/?hl=pt-br',
        },
      ],
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Inspirações de pessoas negras. ✨
        </Typography>
        <Typography variant="body2">Pra você seguir e acompanhar nas redes sociais.</Typography>
      </Box>
      <ListOfPeople people={ListOfInspirationalPeople} />
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-negras/conquistas-incriveis" />
        </Stack>
      </Box>
    </Stack>
  );
};
