import { UserProfileData } from '../hooks/queries';
import { createTheme } from '@mui/material/styles';

export const createAppThemeFromUser = (userProfileData: UserProfileData | undefined | null) => {
  return createTheme({
    palette: {
      primary: {
        main: userProfileData?.company?.color_primary ? userProfileData.company.color_primary : '#F15A24',
      },
      secondary: {
        main: userProfileData?.company?.color_secondary ? userProfileData.company.color_secondary : '#4C2A82',
      },
    },
    typography: {
      fontFamily: '"PT Sans","Fira Sans","Droid Sans","Helvetica Neue",sans-serif',
      button: {
        textTransform: 'none',
      },
      allVariants: {
        fontFamily: '"PT Sans","Fira Sans","Droid Sans","Helvetica Neue",sans-serif',
        textTransform: 'none',
      },
    },
  });
};
