import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import remoteWork from './images/illustrations/remote-work.jpg';

export const DeafPeopleRemoteWork = () => {
  const deafTextItems = [
    {
      title: 'Transcreva mensagens enviadas em áudio.',
      description:
        'Sabe aquele áudio enviado no WhatsApp ou Slack? Se precisar compartilhar um, envie um texto junto dizendo tudo que está no áudio e não só um resumo. Assim você inclui pessoas surdas nas conversas e decisões.',
    },
    {
      title: 'Pergunte qual forma de comunicação ela prefere.',
      description: 'Não custa lembrar que pessoas surdas são diferentes. Algumas preferem um intérprete, outras não e por aí vai. Pergunte sempre!',
    },
    {
      title: 'Ligue a câmera nas reuniões.',
      description: 'Câmeras abertas ajudam pessoas surdas oralizadas a entenderem o que está sendo dito. Tente ligar a câmera sempre que falar.',
    },
    {
      title: 'Fale perto do microfone.',
      description: 'Quando estiver em reunião e a pessoa surda usar transcrição de fala, garanta que seu áudio está bom e claro pra que a legenda fique boa.',
    },
    {
      title: 'Atualize a pessoa em tempo real e não só no final da reunião.',
      description: 'Atualize a pessoa surda em tempo real através do chat, intérprete, transcrição do aplicativo de reuniões ou outra forma que vocês combinarem.',
    },
    {
      title: 'Pergunte se ela gosta de usar transcrição de fala nas reuniões.',
      description:
        'Se sim, ative a transcrição da reunião nos apps (meet, skype, zoom) junto com a pessoa surda. Assim, caso alguma palavra seja transcrita errada você pode dizer pra ela.',
    },
    {
      title: 'Evite e-mails complexos e longos.',
      description: 'Mesmo que a pessoa saiba português, essa ainda é sua segunda língua na maioria dos casos. Então, seja sempre simples na comunicação.',
    },
    {
      title: 'Não deixe ela sozinha no dia a dia.',
      description: 'Inclua ela nas conversas, nas decisões e pergunte sempre sua opinião mostrando que ela também é muito importante.',
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          <strong>Trabalho remoto com pessoas surdas. 💻</strong>
        </Typography>
        <Typography variant="body2">Boas práticas pra reuniões, e-mails, conversas e mais.</Typography>
      </Box>
      <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <img src={remoteWork} alt="Imagem ilustrativa de uma mulher fazendo uma apresentação." style={{ maxWidth: '430px', width: '100%' }} aria-hidden="true" />
      </Stack>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={deafTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-surdas/lider-pessoas-surdas" />
        </Stack>
      </Box>
    </Stack>
  );
};
