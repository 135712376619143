/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
import { useParams } from 'react-router-dom';

import type { SubmissionData, SubmissionDispatchers } from './quillFormTypes';

import { AsyncQuillForm } from './AsyncQuillForm';
import { sampleQuillFormBlocks } from './sampleQuillFormBlocks';
import { Typography } from '@mui/material';

export const FormQuestions = () => {
  const { id } = useParams();
  const formId = 1;

  if (id !== 'sample') {
    return <InvalidNotFoundForm />;
  }

  const formBlocks = sampleQuillFormBlocks;

  // const onFormSubmit = (data: SubmissionData, { completeForm, setIsSubmitting, goToBlock, setSubmissionErr }: SubmissionDispatchers) => {
  const onFormSubmit = (data: SubmissionData, { completeForm, setIsSubmitting }: SubmissionDispatchers) => {
    console.log('Submmit data = ', data);
    setTimeout(() => {
      setIsSubmitting(false);
      completeForm();
    }, 2000);
  };

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <AsyncQuillForm formId={formId} blocks={formBlocks} onSubmit={onFormSubmit} />
    </div>
  );
};

const InvalidNotFoundForm = () => {
  return (
    <>
      <Typography variant="h4" component="h1">
        Formulário não encontrado
      </Typography>
      <p>
        <Typography variant="body1">O formulário com o link que você utilizou para acessar não foi encontrado.</Typography>
      </p>
    </>
  );
};
