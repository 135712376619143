import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import ImportantDates from './images/illustrations/importantDates.svg';

export const BlindPeopleImportantDates = () => {
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Datas importantes. 🤝
        </Typography>
        <Typography variant="body2">Datas que valorizam e marcam a história de pessoas com deficiência visual.</Typography>
      </Box>
      <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <img src={ImportantDates} alt="Imagem ilustrativa de uma mulher adicionando pontos em um calendário." style={{ maxWidth: '430px', width: '100%' }} aria-hidden="true" />
      </Stack>
      <Stack sx={{ pl: 1, pb: 1, pt: 1, justifyContent: 'left', alignItems: 'left' }}>
        <Typography variant="body1" sx={{ pt: 1, pl: 1 }} paragraph={true}>
          <p>
            <strong>13 de Dezembro</strong>
          </p>
          <p>
            Dia nacional da pessoa cega é comemorado no Brasil em 13 de dezembro, desde 1961. Tem como objetivo conscientizar a sociedade para questões importantes como preconceito
            e discriminação, além de reduzir o desconhecimento sobre pessoas com deficiência visual.
          </p>
        </Typography>
        <Typography variant="body1" sx={{ pl: 1 }} paragraph={true}>
          <p>
            <strong>4 de Janeiro</strong>
          </p>
          <p>
            Dia Mundial do Braille é dedicado à reflexão sobre a importância de mecanismos que favoreçam o desenvolvimento das pessoas cegas ou com baixa visão. O sistema Braille
            de escrita e leitura foi criado há cerca de 200 anos na França. No Brasil, chegou por meio de José Álvares de Azevedo, que aprendeu a técnica ainda criança e se dedicou
            a disseminá-la, com apoio do Imperial Instituto de Meninos Cegos, hoje Instituto Benjamin Constant (IBC), no Rio de Janeiro-RJ.
          </p>
        </Typography>
        <Typography variant="body1" sx={{ pl: 1 }} paragraph={true}>
          <p>
            <strong>03 de Dezembro</strong>
          </p>
          <p>Dia Internacional da Pessoa com Deficiência.</p>
        </Typography>
        <Typography variant="body1" sx={{ pl: 1 }} paragraph={true}>
          <p>
            <strong>24 de Setembro</strong>
          </p>
          <p>Dia Mundial da Retina.</p>
        </Typography>
        <Typography variant="body1" sx={{ pl: 1 }} paragraph={true}>
          <p>
            <strong>21 de Setembro</strong>
          </p>
          <p>Dia Nacional de Luta da Pessoa com Deficiência.</p>
        </Typography>
        <Typography variant="body1" sx={{ pl: 1 }} paragraph={true}>
          <p>
            <strong>10 de Julho</strong>
          </p>
          <p>Dia da saúde Ocular.</p>
        </Typography>
        <Typography variant="body1" sx={{ pl: 1 }} paragraph={true}>
          <p>
            <strong>06 de Julho</strong>
          </p>
          <p>
            Lei Brasileira de Inclusão (LBI), também conhecida como Estatuto da Pessoa com Deficiência. É um conjunto de normas que asseguram e promovem os direitos e liberdades
            fundamentais por pessoas com deficiência, visando à sua inclusão social e a cidadania.
          </p>
        </Typography>
      </Stack>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-com-deficiencia-visual/indicacao" />
        </Stack>
      </Box>
    </Stack>
  );
};
