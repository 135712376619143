import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

export const AutisticPeopleLeaders = () => {
  const autisticPeopleTextItems = [
    {
      title: 'Cuidado com altas expectativas. ',
      description:
        'Nem todas as pessoas autistas têm altas habilidades, mas caso a pessoa do seu time tenha é importante ouvi-la. Evite falar que ela precisa produzir mais do que as outras pessoas, isso a pressiona mais. Pessoas com superdotação são humanas que podem ter facilidade em alguma área, isso não deve ser visto pela empresa como um diferencial competitivo, mas sim apenas mais uma característica daquela pessoa.',
    },
    {
      title: 'O trabalho remoto pode ser bom.',
      description:
        'Converse com a pessoa autista para saber se é importante que esta opção seja oferecida a ela. Caso ela ocupe uma posição que seja possível trabalhar remoto esta opção pode ser bom tanto para a colaboradora quanto para a empresa,  o que oferece mais qualidade de vida e saúde. Se não tiver como, faça as adaptações possíveis no presencial.',
    },
    {
      title: 'Converse sobre o prazo que pessoas autistas precisam.',
      description:
        'Em alguns momentos a pessoa autista pode precisar de um tempo de pausa, considere isso quando for calcular o tempo para a entrega das tarefas. Você pode conversar com ela pra entender quanto tempo seria e se é possível combinar um prazo.',
    },
    {
      title: 'Desenvolva pessoas autistas.',
      description:
        'Crie um programa de acompanhamento para a construção de um PDI - Plano de desenvolvimento Individual. Assim você vai além de vagas afirmativas, tornando a empresa realmente inclusiva, com pessoas diversas em diferentes cargos e níveis. Isso também é importante pra que a pessoa se sinta parte da empresa.',
    },
    {
      title: 'Mudar a rotina pode ser desafiador',
      description:
        'Então, tente informar sobre mudanças como horários, reuniões e rituais da empresa com antecedência. Ofereça previsibilidade, isso pode ajudar a pessoa autista a lidar melhor com as mudanças.',
    },
    {
      title: 'Evite reuniões longas',
      description:
        'Reuniões longas demais atrapalham a concentração e entendimento. Prefira sempre reuniões mais objetivas e focadas. Pra ajudar nisso, prepare uma pauta do que vai ser conversado e compartilhe isso com os participantes antes da reunião. Caso a reunião seja longa, ao final reforce o objetivo do encontro e o que a equipe precisa fazer.',
    },
    {
      title: 'Ofereça fone de ouvido com cancelamento de ruído ou abafador.',
      description:
        'Pessoas com autismo podem ter sensibilidade auditiva. Se esse for o caso, a empresa pode oferecer um abafador ou fone que cubra os ouvidos e educar o time para que todos entendam o motivo daquela pessoa precisar disso. Uma ação simples pode melhorar muito o bem-estar da pessoa no ambiente de trabalho. A sensibilidade sensorial, somada a um ambiente com muito barulho pode gerar uma sobrecarga sensorial que pode ser muito ruim para pessoas autistas.',
    },
    {
      title: 'Evite sites com muitas informações',
      description:
        'É ruim quando o site tem muitos anúncios, muitas cores ou informações, pois todos esses elementos geram uma carga sensorial que prejudicam a concentração de pessoas autistas.',
    },
    {
      title: 'Criar um espaço tranquilo',
      description:
        'A empresa pode criar um espaço tranquilo para momentos em que pessoas autistas e não autistas se sintam sobrecarregadas pelo estresse do dia a dia no trabalho.  Essa sala pode ser um espaço fechado, o mais silencioso possível, com poucos estímulos visuais.',
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Uma conversa com quem lidera pessoas autistas. 🤝
        </Typography>
        <Typography variant="body2">Como criar oportunidades reais e justas.</Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={autisticPeopleTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-autistas/inspiracoes" />
        </Stack>
      </Box>
    </Stack>
  );
};
