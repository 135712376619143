import React from 'react';

import { Button } from '@mui/material';

export interface ILinkButton {
  label: string;
  url: string;
}

export const LinkButton = ({ label, url }: ILinkButton) => {
  return (
    <Button sx={{ minWidth: '200px' }} href={url} variant="contained" size="large">
      {label}
    </Button>
  );
};
