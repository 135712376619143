import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import GabrielIsaac from './images/inspirations/GabrielIsaac.jpg';
import AlexAlves from './images/inspirations/AlexAlves.jpg';
import Benedita from './images/inspirations/Benedita.jpg';
import LeoCastilho from './images/inspirations/LeoCastilho.jpg';
import Paula from './images/inspirations/Paula.jpg';
import Visurdo from './images/inspirations/Visurdo.jpg';
import MarcosVinicius from './images/inspirations/MarcosVinicius.jpg';
import { ListOfPeople } from '../ListOfPeople';

export const DeafPeopleInspirations = () => {
  const ListOfInspirationalPeople = [
    {
      info: {
        name: 'Gabriel Isaac (Isflocos)',
        description:
          'Fala sobre o dia a dia da pessoa surda, dicas sobre o que fazer e o que não fazer com pessoas surdas, planejamento pessoal e mais. Os vídeos são em Libras e legendados em Português.',
      },
      image: {
        personImportName: GabrielIsaac,
        altText: 'Foto de Gabriel. Ele veste uma camiseta cinza e está com o cabelo pintado de azul.',
      },
      buttons: [
        {
          label: 'Se inscreva no canal',
          url: 'https://www.youtube.com/@isflocos',
        },
        {
          label: 'Siga o Gabriel',
          url: 'https://www.instagram.com/isflocos/',
        },
      ],
    },
    {
      info: {
        name: 'Visurdo (Andrei e Tainá Borges)',
        description: 'Divulgam a cultura surda, compartilham a importância da Libras e a capacidade que o surdo possui. Tudo isso de um jeito leve e descontraído.',
      },
      image: {
        personImportName: Visurdo,
        altText: 'Foto de Andrei e Tainá. Tainá está do lado esquerda, com o braço esquerdo apoiado em Andrei, e ele está com os braços cruzados. Os dois estão sorrindo.',
      },
      buttons: [
        {
          label: 'Se inscreva no canal',
          url: 'https://www.youtube.com/c/andreiborges11',
        },
        {
          label: 'Siga Visurdo',
          url: 'https://www.instagram.com/visurdo/',
        },
      ],
    },
    {
      info: {
        name: 'Leo Castilho',
        description: 'Cria conteúdo sobre Lifestyle, mundo LGBTQIA+, dia a dia, fashion e mais.',
      },
      image: {
        personImportName: LeoCastilho,
        altText: 'Foto de Leo sorrindo.',
      },
      buttons: [
        {
          label: 'Siga o Leo',
          url: 'https://www.instagram.com/leocastilho/',
        },
      ],
    },
    {
      info: {
        name: 'Benedita Casé Zerbini',
        description: 'Carioca, surda, diretora de audiovisual e mãe de um menino. Fez parte do projeto “Surdos que Ouvem”, contando a sua história e as dificuldades da surdez.',
      },
      image: {
        personImportName: Benedita,
        altText: 'Foto de Benedita sorrindo. Ela usa uma roupa preta com bolinhas.',
      },
      buttons: [
        {
          label: 'Siga a Benedita',
          url: 'https://www.instagram.com/beneditazerbini/',
        },
      ],
    },
    {
      info: {
        name: 'Alex Alves Jr',
        description:
          'Criador de conteúdo no LinkedIn, escritor e palestrante. Fala sobre acessibilidade, diversidade, inclusão, inteligência emocional e desenvolvimento de pessoas. Se autointitula como um “d-eficiente” auditivo. Fala sobre acessibilidade, diversidade, inclusão, inteligência emocional e desenvolvimento de pessoas. Escreveu o livro "Olhos que escutam" sobre a vida e seus desafios na ótica de quem não escuta.',
      },
      image: {
        personImportName: AlexAlves,
        altText: 'Foto de Alex sorrindo. Ele usa uma roupa marrom e está sentado, com o braço esquerdo cruzando sua perna.',
      },
      buttons: [
        {
          label: 'Siga o Alex',
          url: 'https://www.instagram.com/alexbilljr/',
        },
        {
          label: 'Acompanhe o Alex no Linkedin',
          url: 'https://www.linkedin.com/in/alexbilljr/',
        },
      ],
    },
    {
      info: {
        name: 'Paula Pfeifer',
        description:
          'É uma “surda que ouve” através de dois ouvidos biônicos, após uma vida de surdez progressiva e uso de aparelhos auditivos. Autora dos livros Crônicas da Surdez e Novas Crônicas da Surdez, TEDx Speaker, consultora e palestrante, também criou o projeto-marca-movimento “Surdos que ouvem”.',
      },
      image: {
        personImportName: Paula,
        altText: 'Foto de Paula olhando para cima e sorrindo. Ela usa uma roupa rosa.',
      },
      buttons: [
        {
          label: 'Siga a Paula no Instagram',
          url: 'https://www.instagram.com/cronicasdasurdez/',
        },
        {
          label: 'Acompanhe a Paula no Linkedin',
          url: 'https://www.linkedin.com/in/paula-pfeifer-75220731/',
        },
        {
          label: 'Se inscreva no canal',
          url: 'https://www.youtube.com/c/Cr%C3%B4nicasdaSurdezSurdosQueOuvem/featured',
        },
      ],
    },
    {
      info: {
        name: 'Marcos Vinícius',
        description:
          'É humorista, ator, e digital influencer. Produz vídeos de humor no Instagram sobre a vida dos surdos, compartilha um pouco do seu cotidiano, e debate temas importantes, como a luta contra o racismo.',
      },
      image: {
        personImportName: MarcosVinicius,
        altText: 'Foto de Marcos sorrindo. Ela usa uma roupa branca e ao fundo é possível ver algumas construções, um pouco borradas.',
      },
      buttons: [
        {
          label: 'Siga o Marcos no Instagram',
          url: 'https://instagram.com/vinnphoto?igshid=MDM4ZDc5MmU=',
        },
      ],
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          <strong>Inspirações de pessoas surdas. ✨</strong>
        </Typography>
        <Typography variant="body1" sx={{ pt: 2, pb: 3 }}>
          Redes sociais de pessoas surdas pra você se inspirar todos os dias.
        </Typography>
      </Box>
      <ListOfPeople people={ListOfInspirationalPeople} />
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-surdas/datas-importantes" />
        </Stack>
      </Box>
    </Stack>
  );
};
