import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const NavigationButtonBack = ({ label }: { label: string }) => {
  const navigate = useNavigate();
  return (
    <Button
      onClick={(e) => {
        e.preventDefault();
        navigate(-1);
      }}
      variant="outlined"
      size="small"
    >
      {label}
    </Button>
  );
};
