import { API } from './fetcher';

export const confirmReadStep = async (shortId: string) => {
  try {
    const payload = { data: { shortId } };
    const { data, status } = await API.put(`/journey/${shortId}/complete`, payload);
    return { data, status };
  } catch (error) {
    return { error };
  }
};
