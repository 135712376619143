import React from 'react';
import { Box, Typography } from '@mui/material';

export const FormCompleted = () => {
  return (
    <Box sx={{ m: '30px', textAlign: 'center', fontFamily: (theme) => theme.typography.fontFamily }}>
      <Typography variant={'h6'}>Tudo certo! ✅ Nós já recebemos a sua resposta.</Typography>
      <Typography variant={'subtitle1'}>
        Acesse o <a href="/dashboard">portal de colaboradores</a> e acompanhe sua jornada.
      </Typography>
    </Box>
  );
};
