import { useQuery } from 'react-query';

import { QueryKeys } from '../../config/QueryKeys';
import { getSurveyDataQuestions } from '../../services/api/survey-data';

export const useGetDataSurveyQuestions = (shortId: string) => {
  return useQuery(QueryKeys.getSurveyAnswers, () => getSurveyDataQuestions(shortId), {
    refetchOnWindowFocus: false,
    retry: false,
  });
};
