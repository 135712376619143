import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import AbdiasDoNascimento from './images/leadership/AbdiasDoNascimento.jpg';
import SilvioAlmeida from './images/leadership/SilvioAlmeida.jpg';
import MarcosAntonioCardoso from './images/leadership/MarcosAntonioCardoso.jpg';
import KatiuciaRibeiro from './images/leadership/KatiuciaRibeiro.jpg';
import LuanaTolentino from './images/leadership/LuanaTolentino.jpg';
import NinaSilva from './images/leadership/NinaSilva.jpg';

import { ListOfPeople } from '../ListOfPeople';

export const BlackPeopleLeadership = () => {
  const ListOfLeadingPeople = [
    {
      info: {
        name: 'Abdias do Nascimento',
        description: (
          <>
            <p>
              Primeiro senador negro do Brasil, responsável por instituir o dia Nacional da Consciência Negra, no dia 20 de Novembro, aniversário de morte de Zumbi dos Palmares.
              Fundou o Teatro Experimental do Negro (TEN), o Museu da Arte Negra (MAN), o Instituto de Pesquisas e Estudos Afro-Brasileiros (IPEAFRO), Idealizou o Memorial Zumbi e
              o Movimento Negro Unificado, foi indicado ao prêmnio Nobel da Paz de 2010.
            </p>
          </>
        ),
      },
      image: {
        personImportName: AbdiasDoNascimento,
        altText: 'Foto de Abdias do Nascimento falando ao microfone. Ele usa óculos e está vestido com terno e gravata.',
      },
      buttons: [],
    },
    {
      info: {
        name: 'Silvio Almeida',
        description: (
          <>
            <p>
              Um dos filósofos mais importantes da atualidade, é advogado, filósofo e professor universitário. Especialista em questões relacionadas à dinâmica racial no Brasil e
              autor do livro: Racismo Estrutural.
            </p>
          </>
        ),
      },
      image: {
        personImportName: SilvioAlmeida,
        altText:
          'Foto de Silvio Almeida sentado com as mão sobrepostas sobre a mesa. Ao fundo possui da foto possui uma estante com livros. Silvio usa óculos e veste uma camisa social de manga comprida na cor rosa claro. ',
      },
      buttons: [],
    },
    {
      info: {
        name: 'Marcos Antônio Cardoso',
        description: (
          <>
            <p>
              Militante do Movimento Negro, Mestre em História da África, Filósofo e especialista em história africana. Atua como Analista de políticas públicas no município de
              Belo Horizonte e tem histórica contribuição com a educação e os direitos de pessoas negras a cidade, a saúde e ao emprego Movimento Negro Unificado (MNU) e a
              Coordenação Nacional de Entidades Negras (Conen).
            </p>
          </>
        ),
      },
      image: {
        personImportName: MarcosAntonioCardoso,
        altText:
          'Foto de Marcos Antônio falando ao microfone. Ele usa óculos, o cabelo dele está com dreads, ele possui bigode e veste uma camisa social de manga comprida na cor branca com gravata cinza.',
      },
      buttons: [],
    },
    {
      info: {
        name: 'Katiucia Ribeiro',
        description: (
          <>
            <p>
              É uma filósofa brasileira especializada em filosofia africana. Coordenadora Geral do Laboratório Geru Maã de Africologia e Estudos Ameríndios UFRJ na área de
              Filosofia Africana e Indígena. Seu trabalho possui centralidade no Kemet (Antigo Egito), trazendo contribuições de grande magnitude para a história de pessoas negras
              nascidas no Brasil.
            </p>
          </>
        ),
      },
      image: {
        personImportName: KatiuciaRibeiro,
        altText:
          'Foto de Katiucia Ribeiro com black power usando um lenço com formas geométricas nas cores amarelo, roxo, rosa e preto amarrado no cabelo. Ela usa uma roupa com a mesma estampa do lenço e no fundo da foto há uma mandala.   ',
      },
      buttons: [],
    },
    {
      info: {
        name: 'Luana Tolentino',
        description: (
          <>
            <p>
              É belo-horizontina. Mestra em Educação, professora e escritora. Suas práticas pedagógicas e escritas caminham alicerçadas por métodos inclusivos, feministas e
              antirracistas, Luana é comprometida com a luta pelo direito e a igualdade de pessoas negras na sociedade brasileira.
            </p>
          </>
        ),
      },
      image: {
        personImportName: LuanaTolentino,
        altText: 'Foto de Luana Tolentino em um bibioteca. Ela segura um livro nas mão e está sorrindo.',
      },
      buttons: [],
    },
    {
      info: {
        name: 'NinaSilva',
        description: (
          <>
            <p>
              Foi eleita a mulher mais disruptiva e influente do mundo pelo Women in Tech Global Awards 2021, Nina é fundadora e diretora-executiva da fintech D’Black Bank, espaço
              que conecta consumidores a pessoas empreendedoras negras por meio de serviços financeiros digitais. A empreendedora também fundou o Movimento Black Money, para
              estimular o consumo afro centralizado.
            </p>
          </>
        ),
      },
      image: {
        personImportName: NinaSilva,
        altText: 'Foto da Nina Silva com black power. Ela está sorrindo e a cor de seu batom é vermelho.',
      },
      buttons: [],
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Lideranças negras que fazem a diferença. 🏆
        </Typography>
        <Typography variant="body2">Lideranças negras que fazem a diferença na nossa sociedade.</Typography>
      </Box>
      <ListOfPeople people={ListOfLeadingPeople} />
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-negras/inspiracoes" />
        </Stack>
      </Box>
    </Stack>
  );
};
