import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import ParaQuemLidera from './images/illustrations/para-quem-lidera.svg';

export const ForBlackPeopleLeaders = () => {
  const blackPeopleTextItems = [
    {
      title: 'Vagas afirmativas são importantes, mas não só elas.',
      description:
        'Além de abrir vagas afirmativas, é importante dar oportunidade pra que pessoas negras cresçam na empresa. Pra isso, a liderança pode criar um bom Plano de Desenvolvimento Individual (PDI) para pessoas negras. Afinal, o trabalho não acaba na contratação.',
    },
    {
      title: ' O time precisa de um programa de desenvolvimento.',
      description:
        'Esse programa pode ajudar a desenvolver pessoas negras através de cursos, bolsas de graduação e pós-graduação, palestras e encontros. Isso é importante pra que consigam cumprir com o Plano de Desenvolvimento Individual (PDI) tendo acesso aos recursos que precisam. Assim, elas podem entregar mais resultados se sentindo mais valorizadas.',
    },
    {
      title: 'Precisamos de mais liderança negras.',
      description:
        'Quantas pessoas negras ocupam cargos de liderança na sua empresa? Poucas ou nenhuma na maioria dos casos. Para mudar esses dados, além de criar um PDI, é importante incentivar e dar suporte para que possam desenvolver habilidades de liderança. Não só isso, mas para que tenham oportunidades reais de chegar lá.',
    },
    {
      title: 'Crie grupos de afinidade.',
      description:
        'São grupos com pessoas que têm interesses em comum que podem ser aproveitados para discussões e ações de crescimento na empresa. Os grupos ajudam pessoas negras a se sentirem mais engajadas, seguras e motivadas. Além disso, fazem com que elas se sintam parte da empresa, o que ajuda a crescer e enfrentar desafios em conjunto.',
    },
    {
      title: 'O racismo deve ser denunciado.',
      description:
        'Algumas vezes, pessoas negras são punidas por denunciarem racismo, o que faz com que elas tenham medo de denunciar. Pra ajudar a evitar isso, é importante criar formas de denúncia que protejam a pessoa que sofreu racismo. Você pode criar comitês ou ouvidorias pra que pessoas negras se sintam à vontade e sejam acolhidas. A luta contra o racismo precisa ser incluída no programa de diversidade.',
    },
    {
      title: 'Eduque o time desde a chegada na empresa.',
      description:
        'O time precisa ser educado desde a chegada na empresa, isso é importante pra reforçar o posicionamento da empresa com a diversidade e engajar as pessoas pra que todo mundo ajude a tornar a empresa melhor. Essa é uma oportunidade pra falar sobre como a empresa age em casos de racismo e o papel do time nesse sentido.',
    },
    {
      title: ' Dê oportunidade para mães negras.',
      description:
        'Mães são desvalorizadas no ambiente de trabalho porque muitas pessoas acreditam que elas não podem ser produtivas e competentes. Essa situação é ainda pior quando essas mães são negras, pois o machismo se soma ao racismo. Apoiar mães negras a se manterem empregadas e crescendo em suas profissões, sendo bem remuneradas é muito importante.',
    },
    {
      title: 'Inclua pessoas negras nas palestras da empresa.',
      description:
        'Convide pessoas negras para palestras e eventos pra falar de temas que vão além de racismo. Além disso, faça com que essas pessoas estejam em posições de destaque dentro do evento, como palestrantes, organizadores e produtores. Isso vai tornar o espaço mais diverso.',
    },
    {
      title: 'Valorize o trabalho de pessoas negras. ',
      description:
        'Muitas pessoas negras se sentem sozinhas na empresa, isso acontece por não se sentirem representadas e valorizadas. Então, é importante que você se mostre disponível para ouvir, incentivar e respeitar o trabalho delas. Preste atenção no que elas dizem e reconheça o trabalho que elas fazem.',
    },
    {
      title: 'Pessoas negras e brancas precisam de igualdade salarial.',
      description:
        'Quando comparado com pessoas brancas, pessoas negras recebem salários menores em cargos iguais. Na posição de liderança, você pode incentivar que a diretoria trate esse assunto como parte da cultura da empresa. Essa atenção precisa ser ainda mais forte em se tratando de mulheres negras.',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Uma conversa com quem lidera pessoas negras. 📣
        </Typography>
        <Typography variant="body2">O papel da liderança na criação de oportunidades.</Typography>
        <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
          <img
            src={ParaQuemLidera}
            aria-hidden="true"
            alt=" Ilustração de um homem negro sentado. Ele está segurando cartões de informações em suas mãos."
            style={{ maxWidth: '100%', width: '430px', height: '300px' }}
          />
        </Stack>
      </Box>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={blackPeopleTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-negras/que-estao-comecando" />
        </Stack>
      </Box>
    </Stack>
  );
};
