/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';

import { Typography } from '@mui/material';

import type { SubmissionData, SubmissionDispatchers } from './quillFormTypes';
import type { FormBlocks } from '@quillforms/types';

// Lazy load Charts to reduse bundle size
const QuillForms = React.lazy(() => import('./QuillForms'));

interface PortalFormProps {
  formId?: number;
  blocks: FormBlocks;
  onSubmit: (data: SubmissionData, dispatchers: SubmissionDispatchers) => void;
}

export const AsyncQuillForm = ({ formId, blocks, onSubmit }: PortalFormProps) => {
  return (
    <React.Suspense
      fallback={
        <Typography variant="body1" component="p">
          Abrindo formulário...
        </Typography>
      }
    >
      <QuillForms formId={formId} blocks={blocks} onSubmit={onSubmit} />
    </React.Suspense>
  );
};
