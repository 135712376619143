import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { setStorangeToken } from '../../utils';
import { SequenceInvalidNotFound } from './SequenceInvalidNotFound';
import { useGetSequenceData } from '../../hooks/queries/useSequenceGetQuery';
import { SequenceLoading } from './SequenceLoading';
import { SequenceLoadError } from './SequenceError';
import { SequenceLearn } from './SequenceLearn';
import { useSequenceUpdateComplete } from '../../hooks/queries/useSequenceUpdateComplete';
import { useSequenceUpdateLastIndex } from '../../hooks/queries/useSequenceUpdateLastIndex';

export const Sequence = () => {
  const { shortId } = useParams();
  if (!shortId) {
    return <SequenceInvalidNotFound />;
  }

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  if (token) {
    setStorangeToken(token);
  }

  const { mutateAsync: updateComplete, isLoading: isLoadingUpdateComplete } = useSequenceUpdateComplete();

  const { mutateAsync: updateLastIndex, isLoading: isLoadingNextStep } = useSequenceUpdateLastIndex();

  const { data, isLoading, isError, error } = useGetSequenceData(shortId);

  if (isLoading) {
    return <SequenceLoading />;
  }

  if (isError) {
    return <SequenceLoadError error={error} />;
  }

  if (!data?.sequence_blocks?.length) {
    return <SequenceInvalidNotFound />;
  }

  const sequenceLastIndex = data?.sequence_last_index ? parseInt(data.sequence_last_index) : 0;

  async function handleOnComplete(): Promise<boolean> {
    const { data, status } = await updateComplete(shortId || '');
    if (status === 200) {
      return true;
    } else {
      console.error('Error on complete sequence update. Status: ', status, ' data: ', data);
      return false;
    }
  }

  async function handleUpdateLastStep(lastIndex: number): Promise<boolean> {
    const { data, status } = await updateLastIndex({ shortId: shortId || '', stepIndex: lastIndex });
    if (status === 200) {
      return true;
    } else {
      console.error('Error on update last sequence index. Status: ', status, ' data: ', data);
      return false;
    }
  }

  return (
    <SequenceLearn
      sequenceLearnBlocks={data.sequence_blocks}
      currentStep={sequenceLastIndex}
      journeyStepStatus={data.sequence_status}
      handleUpdateLastStep={handleUpdateLastStep}
      handleOnComplete={handleOnComplete}
      loadingOnComplete={isLoadingUpdateComplete}
      loadingNextStep={isLoadingNextStep}
    />
  );
};
