import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

export const AutisticPeopleImportantDates = () => {
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Datas importantes. 🤝
        </Typography>
        <Typography variant="body2">Datas que valorizam e marcam a história de pessoas com autismo.</Typography>
      </Box>
      <Stack sx={{ pl: 1, pb: 1, pt: 1, justifyContent: 'left', alignItems: 'left' }}>
        <Typography variant="body1" sx={{ pt: 1, pl: 1 }} paragraph={true}>
          <p>
            <strong>02 de abril</strong>
          </p>
          <p>Dia Mundial de Conscientização Sobre o Autismo</p>
        </Typography>
        <Typography variant="body1" sx={{ pl: 1 }} paragraph={true}>
          <p>
            <strong>18 de junho</strong>
          </p>
          <p>É celebrado o Dia Mundial do Orgulho Autista</p>
        </Typography>
      </Stack>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-autistas/conteudos" />
        </Stack>
      </Box>
    </Stack>
  );
};
