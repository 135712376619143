import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Box, AppBar, Toolbar, IconButton } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import inboarding from '../../assets/inboarding.svg';

export const TopToolbar = ({ logoUrl, logoName, showBackButton }: { logoUrl?: string | null; logoName?: string | null; showBackButton?: boolean }) => {
  const navigate = useNavigate();

  const goBackOnePage = () => {
    navigate(-1);
  };

  if (logoName && logoName === 'Inboarding') {
    return (
      <>
        <AppBar elevation={0} sx={{ backgroundColor: '#E8F1F8', px: 0 }}>
          <Toolbar sx={{ px: 0 }}>
            <Container disableGutters maxWidth="md" sx={{ backgroundColor: '#E8F1F8', p: 2 }}>
              <Box sx={{ textAlign: 'center' }}>
                {showBackButton && (
                  <IconButton color="secondary" size="small" aria-label="Voltar" onClick={goBackOnePage} style={{ position: 'absolute', left: '20px', marginTop: '5px' }}>
                    <ArrowBackIosIcon fontSize="inherit" />
                  </IconButton>
                )}
                <Link to="/dashboard">
                  <img style={{ maxHeight: '35px' }} src={inboarding} alt={logoName} />
                </Link>
              </Box>
            </Container>
          </Toolbar>
        </AppBar>
        <Toolbar sx={{ marginBottom: '-2px', minHeight: '64px !important' }} />
      </>
    );
  }

  if (!logoUrl || !logoName) {
    return <></>;
  }

  return (
    <>
      <AppBar elevation={0} sx={{ backgroundColor: '#E8F1F8', px: 0 }}>
        <Toolbar sx={{ px: 0 }}>
          <Container disableGutters maxWidth="md" sx={{ backgroundColor: '#E8F1F8', p: 2 }}>
            <Box sx={{ textAlign: 'center' }}>
              {showBackButton && (
                <IconButton color="secondary" size="small" aria-label="Voltar" onClick={goBackOnePage} style={{ position: 'absolute', left: '20px', marginTop: '5px' }}>
                  <ArrowBackIosIcon fontSize="inherit" />
                </IconButton>
              )}
              <Link to="/dashboard">
                <img style={{ maxHeight: '35px' }} src={logoUrl} alt={logoName} />
              </Link>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      <Toolbar sx={{ marginBottom: '-2px', minHeight: '64px !important' }} />
    </>
  );
};
