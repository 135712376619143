import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';
import Leaders from './images/illustrations/leaders.svg';

export const DeafPeopleLeaders = () => {
  const deafTextItems = [
    {
      title: 'Inclua a pessoa surda em todas as decisões de acessibilidade.',
      description:
        'Sempre que for tomar uma decisão relacionada à inclusão e acessibilidade pra pessoa surda na empresa, chame a pessoa surda para que ela possa dar sua opinião. ',
    },
    {
      title: 'Confira se tem luz suficiente onde o intérprete de Libras está posicionado.',
      description:
        'Costumamos apagar as luzes para visualizar melhor os slides ou TV, mas se a fala estiver sendo interpretada para a Libras, é importante que tenha luz pra que a pessoa surda visualize quem está interpretando. ',
    },
    {
      title: 'Adapte as dinâmicas para um público com pessoas surdas',
      description: 'Quando for fazer dinâmicas, garanta que ela seja acessível. Evite atividades que dependem de música e olhos fechados pra acontecer.',
    },
    {
      title: 'Valorize as suas capacidades.',
      description: 'Pessoas surdas podem ser tão habilidosas e profissionais como qualquer outra pessoa. Então mostre confiança e dê a chance delas crescerem. ',
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          <strong>Uma conversa com quem lidera pessoas surdas. 🤝</strong>
        </Typography>
        <Typography variant="body2">O papel da liderança na criação de oportunidades</Typography>
      </Box>
      <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <img src={Leaders} alt="Imagem ilustrativa de duas pessoas analisando marcadores." style={{ maxWidth: '430px', width: '100%' }} aria-hidden="true" />
      </Stack>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={deafTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-surdas/autoconfianca-e-autopromocao-no-trabalho" />
        </Stack>
      </Box>
    </Stack>
  );
};
