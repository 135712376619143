import React from 'react';
import { Container, Box, Typography } from '@mui/material';

import inboarding from '../../assets/inboarding.svg';

export const FooterInboarding = ({ divider = true }: { divider?: boolean }) => {
  return (
    <Container disableGutters sx={{ p: 0, borderTop: divider ? '1px solid #dee2e6' : '', mt: 2 }}>
      <Box sx={{ textAlign: 'center' }}>
        <img style={{ maxHeight: '25px', marginTop: '5px' }} src={inboarding} alt="Inboarding" />
        <Typography variant="caption" sx={{ color: '#808B96' }} paragraph={true}>
          © Inboarding Tecnologia Ltda 2022 - All rights reserved
        </Typography>
      </Box>
    </Container>
  );
};
