import React, { useState, useEffect } from 'react';
import { Box, TextField, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import undrawMail from '../../assets/undrawOpen.svg';
import { validateEmail } from '../../utils';
import { usePostTokenByEmailMutation } from '../../hooks/queries';
import { SnackbarMessage } from '../../components/SnackbarMessage';

interface Props {
  handler: () => void;
  handlerSetEmail: (email: string) => void;
}

export const FormLogin = ({ handler, handlerSetEmail }: Props) => {
  const [isDisabledBtn, setIsDisabledBtn] = useState(true);
  const [valueInputEmail, setValueInputEmail] = useState('');
  const [mensageAlert, setMensageAlert] = useState('');
  const [loadinBtn, setLoadinBtn] = useState(false);

  const { mutateAsync: sendToken } = usePostTokenByEmailMutation();

  const requireToken = async (email: string) => {
    setLoadinBtn(true);
    const { data, status } = await sendToken(email);
    if (status !== 200) {
      console.log('Error on portal login. Status: ', status, ' Data: ', data);
      const alertResponse = `Não conseguimos enviar o acesso no email ${email}. Confira se o email informado é o seu email da empresa ou o mesmo que você utilizou no processo seletivo.`;
      setMensageAlert(alertResponse);
    }
    setLoadinBtn(false);
    return status && handler();
  };

  const handlerSetValueInputEmail = (email: string) => {
    setValueInputEmail(email);
    handlerSetEmail(email);
  };

  useEffect(() => {
    setIsDisabledBtn(validateEmail(valueInputEmail));
  }, [valueInputEmail]);

  return (
    <Box>
      {!!mensageAlert && <SnackbarMessage message={mensageAlert} severity="warning" onClose={() => setMensageAlert('')} />}
      <Box sx={{ textAlign: 'center' }}>
        <img style={{ maxHeight: '7em', margin: '3em 0' }} src={undrawMail} alt="mail-open" />
        <Typography variant="h6">Falta pouco pra você chegar no Portal do Colaborador!</Typography>
        <Typography variant="body1" sx={{ my: 3, color: '#808B96' }}>
          Pra começar, digite seu e-mail.
        </Typography>
      </Box>
      <Box sx={{ px: 3 }}>
        <TextField variant="outlined" fullWidth placeholder="Digite seu e-mail" onChange={(e) => handlerSetValueInputEmail(e.target.value)} />
      </Box>
      <Box sx={{ mt: 3, px: 4 }}>
        <LoadingButton fullWidth variant="contained" color="primary" onClick={() => requireToken(valueInputEmail)} disabled={!isDisabledBtn} loading={loadinBtn}>
          Acessar Portal
        </LoadingButton>
      </Box>
    </Box>
  );
};
