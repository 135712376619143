import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import { TextListAccordion } from '../TextListAccordion';

export const AutisticPeopleSelfConfidence = () => {
  const autisticPeopleTextItems = [
    {
      title: 'Seja você e converse com sua liderança.',
      description:
        'Se conheça e acolha suas características. Se conhecer é importante pra reconhecer suas habilidades e desafios. Saber conversar sobre esses desafios com sua liderança pode ser bom para o seu desenvolvimento na empresa. Assim a liderança pode encontrar formas de lidar com suas habilidades e desafios junto com você. Tá tudo bem ter sua forma única de lidar com a rotina.',
    },
    {
      title: 'Evite se comparar com outras pessoas.',
      description:
        'A única coisa que você tem em comum com outras pessoas autistas é o fato de vocês terem autismo. Evite comparar suas habilidades com a de outras pessoas, cada um tem uma história e um processo diferente. Se você não se sente confortável fazendo certa tarefa que outra pessoa faz com tranquilidade, isso não é um problema.',
    },
    {
      title: 'Aproveite cada fase do seu crescimento.',
      description:
        'Existem muitas fases até chegar onde você quer. A que você está bem no comecinho e tudo é novidade, a que você já começa a ganhar a maturidade e precisa aperfeiçoar aquilo que aprendeu, e por aí em diante. A verdade é que cada fase tem seu lado bom e aproveitar cada uma delas é ótimo pra um crescimento saudável.',
    },
    {
      title: 'Aprenda a se acolher',
      description:
        'Você não precisa ter vergonha de quem é. Sua forma de se comunicar, pensar e agir deve ser respeitada, inclusive por você. Se você tem crises e sente que precisa de apoio, converse com pessoas de forma aberta.',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Aprendendo a ter mais autoconfiança. 💪
        </Typography>
        <Typography variant="body2">Como as pessoas com autismo podem ser mais confiantes.</Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={autisticPeopleTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-autistas/para-lideres" />
        </Stack>
      </Box>
    </Stack>
  );
};
