import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import VirginiaLeoneBicudo from './images/achievements/VirginiaLeoneBicudo.jpg';
import GladysMaeWest from './images/achievements/GladysMaeWest.jpg';
import ValerieLThomas from './images/achievements/ValerieLThomas.jpg';
import GeraldALawson from './images/achievements/GeraldALawson.jpg';
import PercyLavonJulian from './images/achievements/PercyLavonJulian.jpg';
import { ListOfPeople } from '../ListOfPeople';

export const BlackPeopleIncredibleAchievements = () => {
  const ListOfPeopleAndAchievements = [
    {
      info: {
        name: 'Virgínia Leone Bicudo',
        description: (
          <>
            <p>
              Foi uma mulher preta, nascida no Brasil no ano de 1910. Visionária do estudo psicanalítico, foi a primeira pessoa preta e mulher a defender uma tese sobre questões
              raciais dentro do ambiente acadêmico no Brasil.
            </p>
            <p>
              Além disso, foi socióloga e pioneira na psicanálise no país, Virgínia Bicudo foi também a primeira pessoa não médica a ser reconhecida como psicanalista, algo que
              contribuiu veementemente para a institucionalização da psicanálise.
            </p>
          </>
        ),
      },
      image: {
        personImportName: VirginiaLeoneBicudo,
        altText: 'Foto de Virgínia em tons de cinza. Ela está com o cabelo preso e usa um colar de bolinhas brancas aparentando pérolas.',
      },
      buttons: [],
    },
    {
      info: {
        name: 'Gladys Mae West',
        description: (
          <>
            <p>
              É uma matemática preta, nascida nos Estados Unidos da América, que desenvolveu e criou o GPS. Foi a segunda mulher negra a trabalhar na base naval de Dahlgren, onde
              atuou por 42 anos com localização espacial de satélites. Gladys deixou um grande legado para a tecnologia!
            </p>
          </>
        ),
      },
      image: {
        personImportName: GladysMaeWest,
        altText:
          'Foto de Gladys Mae em tons de cinza sentada de frente a uma mesa com papéis espalhados e ao fundo há uma estante com pastas de arquivos. Ela tem cabelo curto, usa óculos e está sorrindo.',
      },
      buttons: [],
    },
    {
      info: {
        name: 'Valerie L. Thomas',
        description: (
          <>
            <p>
              É uma cientista e inventora nascida nos EUA. Ela inventou a tecnologia de filmes 3D, sendo a responsável por criar e patentear, em 1980, o transmissor de ilusão,
              dispositivo que simula a aparência tridimensional de um objeto. A invenção inovadora ainda é utilizada pela Nasa, agência espacial onde Valerie trabalhou entre 1964 e
              1995 como analista de dados e gerente de projetos.
            </p>
          </>
        ),
      },
      image: {
        personImportName: ValerieLThomas,
        altText: 'Foto de Valerie em tons de cinza com black power. Ela usa óculos, está sorrindo e no fundo da foto há um quadro.',
      },
      buttons: [],
    },
    {
      info: {
        name: 'Gerald A. Lawson',
        description: (
          <>
            <p>
              Foi um inventor e engenheiro da computação reconhecido pelo seu trabalho inovador dentro da tecnologia, Gerald desenvolveu e criou o Cartucho de videogame comercial
              na década de 1970, Jerry Lawson, como ficou conhecido, ajudou a desenvolver o primeiro console doméstico com cartuchos equipados com games e o conceito de pausar um
              jogo, algo que é utilizado até hoje nos produtos mais recentes e avançados.
            </p>
          </>
        ),
      },
      image: {
        personImportName: GeraldALawson,
        altText: 'Foto de Gerald  sorrindo sentado em frente a uma mesa com computador. Ele veste uma camisa social de manga até o cotovelo e uma gravata.',
      },
      buttons: [],
    },
    {
      info: {
        name: 'Percy Lavon Julian',
        description: (
          <>
            <p>
              Foi um pesquisador em química afro-americano que desenvolveu a importante síntese de medicamentos com base vegetal, uma invenção transformadora que contribuiu para o
              tratamento de doenças nervosas degenerativas. Percy é pioneiro na síntese química de medicamentos a partir de plantas, desenvolvendo remédios para doenças como
              câncer, anemia, asma e alergias.
            </p>
          </>
        ),
      },
      image: {
        personImportName: PercyLavonJulian,
        altText: 'Foto de Percy em tons de cinza. Ele usa óculos, um jaleco, uma gravata e está em um laboratório.',
      },
      buttons: [],
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Conquistas incríveis. 🏆
        </Typography>
        <Typography variant="body2">Uma lista de pessoas negras que fizeram história.</Typography>
      </Box>
      <ListOfPeople people={ListOfPeopleAndAchievements} />
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-negras/datas-importantes" />
        </Stack>
      </Box>
    </Stack>
  );
};
