import React from 'react';
import { Box, Typography, Stack, Button } from '@mui/material';
import { Edit } from '@mui/icons-material';
import Avatar from '@mui/material/Avatar';

import { UserProfileData } from '../../hooks/queries';

interface ICardProps {
  data: UserProfileData;
  openProfileEdit: () => void;
}

export const CardProfile = ({ data, openProfileEdit }: ICardProps) => {
  return (
    <Stack sx={{ alignItems: 'center' }}>
      <Avatar alt={data.first_name || ''} src={data.photo_url ?? ''} sx={{ width: 80, height: 80 }} color="primary" />
      <Box sx={{ border: 'none', pt: 5, pb: 2, mt: -5, borderRadius: 4, width: '96%', textAlign: 'center', backgroundColor: '#E8F1F8' }}>
        {!!data?.short_name && <Typography variant="h6"> {data?.short_name}</Typography>}
        <Typography variant="body1">
          {`${data?.first_name || ''} ${data?.last_name || ''}`}
          {!!data?.gender_pronouns && <Typography variant="caption"> ({data?.gender_pronouns})</Typography>}
        </Typography>
        <Typography variant="body2">{data?.job_title}</Typography>
        <Typography variant="subtitle2">{data?.department}</Typography>
        <Button onClick={openProfileEdit} size="small" aria-label="Editar perfil" startIcon={<Edit fontSize="small" />} color="inherit">
          Editar
        </Button>
      </Box>
    </Stack>
  );
};
