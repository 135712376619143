import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import BestPractices from './images/illustrations/deaf-best-practices.jpg';

export const DeafPeopleWorkEnvironment = () => {
  const deafTextItems = [
    {
      title: 'Procure saber como elas preferem conversar.',
      description: (
        <>
          <p>Algumas preferem leitura labial, pois são oralizadas. Outras preferem Libras ou legenda automática da fala. </p>{' '}
          <p>Pergunte antes mesmo da entrevista e compartilhe isso com o seu time. Ah, e não grite ou articule a sua boca com exagero.</p>
        </>
      ),
    },
    {
      title: 'Aprenda a dizer ‘oi’ e outros sinais básicos.',
      description: (
        <p>
          Você pode aprender a falar ‘oi’ e seu nome em Libras mesmo que seja o básico. Assim a pessoa surda que fala em Libras vai se sentir mais bem recebida.{' '}
          <a href="https://aprenda-libras.surdoparasurdo.com.br/" rel="noreferrer">
            Vem aprender em alguns minutos em um app gratuito.
          </a>
        </p>
      ),
    },
    {
      title: 'Chame as pessoas pelo nome.',
      description:
        'Ser surdo é uma característica da pessoa, e não o que a define. Pergunte qual é o seu sinal (seu ‘nome’ em Libras) e o use quando falar com ela. Já quando for falar sobre ela fale seu nome.',
    },
    {
      title: 'Inclua as pessoas surdas na conversa.',
      description: (
        <>
          <p>
            Diga em tempo real sobre o que vocês estão falando ou rindo, você pode usar Libras, texto ou fala pra oralizados. Dê a chance da pessoa participar das discussões e
            decisões.
          </p>{' '}
          <p>Uma dica é usar a tecnologia a favor de vocês. Se ela for oralizada, você pode falar normalmente, mas confira se ela consegue ver sua boca enquanto você fala.</p>
        </>
      ),
    },
    {
      title: 'Conte o que está fazendo e pra onde o time está indo.',
      description: 'Se saíram juntos, se vão cantar parabéns pra uma pessoa do time, ou fazer qualquer coisa em outro lugar, chame a pessoa surda pra fazer parte.',
    },
    {
      title: 'Use o termo "língua de sinais"',
      description:
        'Evite falar ‘linguagem de sinais’. A Libras tem uma estrutura gramatical própria reconhecida científica e legalmente (Lei 10.436/2002).  Assim como no Brasil falamos Português e na França falam Francês, o mesmo acontece com a Libras, ela é a Língua Brasileira de Sinais. Ah, e também existem diferenças regionais entre um estado e outro então podem existir vários sinais pra uma mesma coisa.',
    },
    {
      title: 'Evite tocar nas pessoas surdas com exagero.',
      description: 'Se você estiver longe, peça que a pessoa próxima chame a atenção da pessoa surda. Se você estiver perto, dê um toque suave pra chamar a atenção.',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          <strong>Ambiente de trabalho mais saudável para pessoas surdas. 👍</strong>
        </Typography>
        <Typography variant="body2">Um monte de boas práticas pro trabalho e pra vida.</Typography>
      </Box>
      <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <img
          src={BestPractices}
          alt="Imagem ilustrativa de um homem e uma mulher, o homem está segurando uma carinha sorridente."
          style={{ maxWidth: '430px', width: '100%' }}
          aria-hidden="true"
        />
      </Stack>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={deafTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-surdas/criar-conteudos-acessiveis" />
        </Stack>
      </Box>
    </Stack>
  );
};
