import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

export const DeafPeopleHowToReceive = () => {
  const deafPeopleTextItems = [
    {
      title: 'Eduque a equipe',
      description:
        'Isso é importante para que a pessoa surda seja bem recebida e comportamentos capacitistas sejam evitados. Muitas vezes as pessoas oferecem ajuda de forma invasiva ou diminuem profissionais por serem pessoas surdas. A equipe precisa saber qual é o papel dela pra agir com empatia e respeito.',
    },
    {
      title: 'Aprenda a dividir tarefas',
      description:
        'Isso é importante pra que a pessoa saiba qual é o papel dela na equipe. Você pode dividir essas tarefas antes ou no primeiro dia de trabalho. O importante aqui é que isso seja feito junto com a pessoa. Lembrando que pessoas surdas são profissionais com qualificação e merecem ter funções definidas como todo mundo.',
    },
    {
      title: 'Faça um onboarding simples e bem visual',
      description:
        'Evite treinamentos de horas, apresentações cheias de palavras formais e textos muito longos.  Opte por um onboarding bem visual e simples. Evite manuais com parágrafos grandes, termos e siglas que não acompanham tradução. Ah, e evite palavras estrangeiras, tá?',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Como receber uma pessoa surda no time. 👋
        </Typography>
        <Typography variant="body2">O que fazer antes de receber a pessoa na empresa (remoto e presencial)</Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={deafPeopleTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-surdas/o-que-fazer" />
        </Stack>
      </Box>
    </Stack>
  );
};
