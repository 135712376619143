import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';

import FerramentasIgualdade from './images/FerramentasIgualdade.png';
import AnotacoesTrabalhoGenero from './images/AnotacoesTrabalhoGenero.png';
import MachismoTecnologiaBarreiras from './images/MachismoTecnologiaBarreiras.png';
import MaeTrabalho from './images/MaeTrabalho.png';
import MulheresSurdasEmAcao from './images/MulheresSurdasEmAcao.jpg';
import ODS5 from './images/ODS5.png';
import OIT from './images/OIT.png';
import Panorama from './images/Panorama.png';
import RaioX from './images/RaioX.png';
import { ListOfContents } from '../ListOfContents';

export const WomenContents = () => {
  const listOfContents = [
    {
      contentType: 'video',
      title: 'A síndrome da impostora',
      channel: 'Rafa Brites',
      videoUrl: 'https://www.youtube.com/embed/OcclenRW8HA',
    },
    {
      contentType: 'video',
      title: 'Paula Paschoal | Paypal: Empoderamento feminino no mundo corporativo',
      channel: 'Ideia Sustentável',
      videoUrl: 'https://www.youtube.com/embed/Fl4w3VbA7eU?start=20',
    },
    {
      contentType: 'video',
      title: 'Dialogando apresenta: Tecnologia à disposição da Diversidade, por Lisiane Lemos',
      channel: 'Vivo',
      videoUrl: 'https://www.youtube.com/embed/SY4xNS40qRk',
    },
    {
      contentType: 'article',
      title: 'Mulheres surdas incríveis contam suas histórias sobre amor, autoconhecimento, autoestima, viagens, empreendedorismo, entre vários assuntos.',
      description: 'Canal do Youtube: Mulheres Surdas em Ação',
      imageImportName: MulheresSurdasEmAcao,
      altText: 'Imagem do vídeo com título: "Mulheres Surdas em Ação"',
      buttonLabel: 'Assistir vídeo no Youtube',
      buttonUrl: 'https://www.youtube.com/watch?v=_wXXza_oH3o',
    },
    {
      contentType: 'video',
      title: 'TEDx - Andrea Schwarz: Na cadeira de rodas descobri o meu propósito de vida',
      channel: 'TEDx Talks',
      videoUrl: 'https://www.youtube.com/embed/4GCnpn-nQaY?start=4',
    },
    {
      contentType: 'video',
      title: 'Como garantir igualdade às mulheres no mercado de trabalho?',
      channel: 'Tribunal Superior do Trabalho',
      videoUrl: 'https://www.youtube.com/embed/SNzjmZJYa2s?start=2',
    },
    {
      contentType: 'article',
      title: 'ODS 5: Igualdade de gênero.',
      description: 'Texto no site: ONU Mulheres.',
      imageImportName: ODS5,
      altText: 'Imagem do site da ONU Mulheres Brasil.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'http://www.onumulheres.org.br/planeta5050-2030/paridade/',
    },
    {
      contentType: 'article',
      title: 'Por que o machismo cria barreiras para as mulheres na tecnologia.',
      description: 'Texto no site: Programaria.',
      imageImportName: MachismoTecnologiaBarreiras,
      altText: 'Imagem do site Programaria.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://www.programaria.org/especiais/mulheres-tecnologia/',
    },
    {
      contentType: 'article',
      title: 'Ferramentas para um mundo mais igualitário.',
      description: 'Texto no site: Think Olga.',
      imageImportName: FerramentasIgualdade,
      altText: 'Imagem do site Think Olga.',
      buttonLabel: 'Ler texto no site',
      buttonUrl: 'https://thinkolga.com/ferramentas/',
    },
    {
      contentType: 'article',
      title: 'Ebook: O Raio X do Bem-estar no Trabalho das Mulheres Brasileiras.',
      description: 'Texto no ebook: Todas Group.',
      imageImportName: RaioX,
      altText: 'Imagem da capa do Ebook.',
      buttonLabel: 'Ler texto no ebook',
      buttonUrl: 'https://drive.google.com/file/d/1DvdETn5lpMnE2LzXx4YB506mGj4wTB6d/view?us%20p=sharing',
    },
    {
      contentType: 'article',
      title: 'TEDx - Dani Junco: Mãe, por que você está indo trabalhar?',
      description: 'Texto no site: B2Mamy.',
      imageImportName: MaeTrabalho,
      altText: 'Imagem do site B2Mamy.',
      buttonLabel: 'Ler texto no ebook',
      buttonUrl: 'https://www.b2mamyeplace.com.br/post/2017/12/27/m%C3%A3e-por-que-voc%C3%AA-est%C3%A1-indo-trabalhar',
    },
    {
      contentType: 'article',
      title: 'Anotações sobre trabalho e gênero.',
      description: 'Autora: Magda de Almeida Neves',
      imageImportName: AnotacoesTrabalhoGenero,
      altText: 'Imagem do site Scielo com o título do artigo: Anotações sobre trabalho e gênero.',
      buttonLabel: 'Ler artigo no site',
      buttonUrl: 'https://www.scielo.br/j/cp/a/5c6jtJQ7sLPTwQzHcBGc7pL/?lang=pt',
    },
    {
      contentType: 'article',
      title: 'Desigualdade de gênero no mercado de trabalho e as formas de enfrentamento alicerçadas na OIT.',
      description: 'Autoras: Helena Vicentini Julião, Aline Michelle Dib, Letícia Trevizolli de Oliveira.',
      imageImportName: OIT,
      altText: 'Imagem do site Brazilian Journals com o título do artigo: Desigualdade de gênero no mercado de trabalho e as formas de enfrentamento alicerçadas na OIT.',
      buttonLabel: 'Ler artigo no site',
      buttonUrl: 'https://ojs.brazilianjournals.com.br/ojs/index.php/BRJD/article/view/26114/20711',
    },
    {
      contentType: 'article',
      title: 'Panorama da participação feminina na educação superior, no mercado de trabalho e na sociedade.',
      description: 'Autoras: Suzane Carvalho da Vitória Barros, Luciana Mourão.',
      imageImportName: Panorama,
      altText: 'Imagem do site Scielo com o título do artigo: Panorama da participação feminina na educação superior, no mercado de trabalho e na sociedade.',
      buttonLabel: 'Ler artigo no site',
      buttonUrl: 'https://www.scielo.br/j/psoc/a/v6X4NdsLGPx7fmpJBCWxsdB/?lang=pt',
    },
  ];

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Conteúdos que a Inboarding indica. ✨
        </Typography>
        <Typography variant="body2">Pra você ir além desse manual.</Typography>
      </Box>
      <ListOfContents contents={listOfContents} />
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
        </Stack>
      </Box>
    </Stack>
  );
};
