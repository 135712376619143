import { UserProfileData } from '../../hooks/queries';
import { API } from './fetcher';

export const requestDataProfile = async () => {
  const { data } = await API.get('/profile');
  return { data };
};

const convertValuesToModel = (data: UserProfileData): UserProfileData => {
  const values = {
    gender_pronouns: data.gender_pronouns,
    short_name: data.short_name,
    instagram_handle: data.instagram_handle,
    linkedin_url: data.linkedin_url,
  } as UserProfileData;

  if (data.birth_date_celebrate) {
    values.birth_date_celebrate = data.birth_date_celebrate;
  }
  if (data.pwd_disability) {
    values.pwd_disability = data.pwd_disability;
  }
  if (data.inclusive_notes) {
    values.inclusive_notes = data.inclusive_notes;
  }
  if (data.custom_fields_values) {
    values.custom_fields_values = data.custom_fields_values;
  }
  return values;
};

export const updateDataProfile = async (profileData: UserProfileData) => {
  try {
    const payload = {
      data: convertValuesToModel(profileData),
    };
    const { data, status } = await API.put(`/profile/${profileData.id}`, payload);
    return { data, status };
  } catch (error) {
    return { error };
  }
};
