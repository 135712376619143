import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import AutoEstima from './images/illustrations/autoestima.svg';

export const BlackPeopleSelfEsteem = () => {
  const blackPeopleTextItems = [
    {
      title: 'Não somos descendentes de escravos.',
      description:
        'A escravidão durou 300 anos no Brasil, mas a história negra não começa nesse período. Na verdade, a escravidão interrompeu parte da história negra que começou desde o início da humanidade. Pessoas negras descendem do continente africano, lugar que deu origem à civilização e que tem uma história cheia de inovação, tecnologia e ciências. ',
    },
    {
      title: 'Saber da nossa história muda tudo.',
      description:
        'Aprenda sobre a contribuição intelectual de pessoas negras na sociedade. Pra isso, estude história e aprenda sobre o povo negro, afinal, pra entender bem quais caminhos seguir é importante conhecer de onde você veio. Isso é ‘Sankofa’, uma tecnologia ancestral africana que diz que escrever a própria história é libertador. A contribuição de pessoas negras pro mundo vai muito além da música, arte e esporte. Desde as pirâmides do Egito até a Dra. Jaqueline sequenciando o genoma do coronavírus no Brasil, nós temos deixado grandes contribuições pra humanidade.',
    },
    {
      title: 'Leia intelectuais negros e negras.',
      description: (
        <>
          <p>
            Para construir autoestima é importante ter referências negras que falem sobre questões que vão além do racismo. Pra te ajudar com isso, escolhemos 6 intelectuais negros
            e negras:
          </p>{' '}
          <ul>
            <li>Cheick Anta Diop: Polimata senegalês;</li>
            <li>Muniz Sodré: Professor na Escola de Comunicação da Universidade Federal do Rio de Janeiro;</li>
            <li>Carlos Moore: Pan-africanista e guardião da história preta;</li>
            <li>Lélia Gonzalez: Quilombola, professora e revolucionária preta;</li>
            <li>Beatriz Nascimento: Intelectual negra e quilombola;</li>
            <li>Paulina Chiziane: Escritora moçambicana.</li>
          </ul>
        </>
      ),
    },
    {
      title: 'Somos inteligentes e capazes. ',
      description:
        'Ouvir o tempo todo sobre como o racismo é cruel e como nossos povos foram prejudicados é algo que nos faz esquecer do quão inteligente e capazes nós somos. Nossa história é marcada por inovações criadas por pessoas negras e temos uma série de pessoas incríveis que você pode se inspirar hoje mesmo. Busque referências no mundo do trabalho pra aumentar sua confiança.',
    },
    {
      title: 'Aprenda sobre letramento racial.',
      description:
        'Letramento racial é um conjunto de práticas que ensina a desconstruir o modo de agir e pensar das pessoas. É um ponto de partida pra se tornar antirracista, pois ajuda na conscientização, respeito e igualdade. É importante pra que pessoas negras se sintam mais engajadas e pra que pessoas não negras sejam aliadas.',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Aprendendo a ter mais autoestima. 💪
        </Typography>
        <Typography variant="body2">Como as pessoas negras podem ser mais confiantes no trabalho e na vida.</Typography>
      </Box>
      <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <img
          src={AutoEstima}
          aria-hidden="true"
          alt=" Ilustração de um mulher negra sentada com a perna direita esticada e a perna esquerda encolhida com a mão esquerda trazendo-a para perto do corpo. Na mão direita da mulher há um coração azul e em seu colo também."
          style={{ maxWidth: '100%', width: '430px', height: '300px' }}
        />
      </Stack>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={blackPeopleTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-negras/para-quem-lidera" />
        </Stack>
      </Box>
    </Stack>
  );
};
