import { useQuery } from 'react-query';
import { QueryKeys } from '../../config/QueryKeys';

import { requestDataQuestions } from '../../services/api/questions';
import { normalizeRemoveAccents } from '../../utils/normalizeRemoveAccents';

export interface QuestionsData {
  id: string;
  tags: string;
  title: string;
  description: string;
  source_url: string;
}

export const useGetDataQuestions = (searchText: string) => {
  const searchTextParsed = normalizeRemoveAccents(searchText.trim().toLowerCase());
  return useQuery<QuestionsData[]>(QueryKeys.getQuestions, () => requestDataQuestions(), {
    refetchOnWindowFocus: false,
    select: (questions) => {
      if (!searchTextParsed) {
        return questions;
      }

      return questions.filter((question) => {
        const text = normalizeRemoveAccents(JSON.stringify(question).toLowerCase());
        return text.includes(searchTextParsed.toLowerCase());
      });
    },
  });
};
