import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import AmbienteDeTrabalho from './images/ambiente-de-trabalho.svg';

export const WomenWorkEnvironment = () => {
  const womenTextItems = [
    {
      title: 'Cuidado com interrupções. ',
      description:
        'Mesmo que você não faça de propósito, muitas vezes as falas das mulheres são interrompidas. É muito importante esperar a mulher finalizar a fala pra só então você falar, assim o ambiente se torna mais saudável.',
    },
    {
      title: 'Respeite suas opiniões. ',
      description:
        'É comum nós vermos as opiniões de mulheres serem excluídas ou não respeitadas, mas é importante que todas as pessoas tenham direito de falar suas opiniões. É assim que crescemos enquanto um grupo. ',
    },
    {
      title: 'Espere sua vez de falar.',
      description:
        'Quando você sentir vontade de responder algo por uma mulher, ou falar por ela, espere um pouco. Deixe ela se pronunciar e faça o seu comentário quando ela terminar a fala.',
    },
    {
      title: 'Você pode aprender com mulheres.',
      description:
        'Muitas vezes um homem explica coisas óbvias à mulher como se ela não fosse capaz de entender, isso se chama mansplaining. Algumas pessoas se ofendem em aprender com uma mulher no trabalho, mas é importante entender que mulheres e homens têm capacidades iguais.',
    },
    {
      title: 'Expressões machistas pra se atentar.',
      description: (
        <>
          <p>
            Falamos muitas expressões machistas sem perceber. É importante refletir sobre elas, afinal, falar essas expressões torna o ambiente incômodo pra mulheres. Evite falar
            isso:
          </p>{' '}
          <ul>
            <li>“É muito bonita pra ser inteligente”</li>
            <li>“Ela deve estar tendo um caso com o chefe…”</li>
            <li>“Só pode estar de TPM”</li>
            <li>“Coisa de mulher”</li>
            <li>“Tinha que ser mulher”</li>
            <li>“Lugar de mulher é…”</li>
            <li>“Olha aí quem chegou para enfeitar a reunião”</li>
          </ul>
        </>
      ),
    },
    {
      title: 'Cuidado com ofensas disfarçadas de piadas. ',
      description: (
        <>
          <p>Brincar com a capacidade de uma mulher não é piada, é ofensa. Mulheres se sentem desrespeitadas com comentários ou com “piadas” que dão a entender que mulheres:</p>{' '}
          <ul>
            <li>Não sabem dirigir;</li>
            <li>Não entendem de futebol/games/mercado financeiro;</li>
            <li>Gastam dinheiro com coisas fúteis;</li>
            <li>Loiras são burras;</li>
            <li>Deveriam se dedicar apenas ao trabalho doméstico;</li>
            <li>Não são inteligentes;</li>
            <li>São piores do que homens.</li>
          </ul>
        </>
      ),
    },
    {
      title: 'Inclua mulheres palestrantes nos eventos da empresa.',
      description:
        'Sempre que for trazer uma palestra ou fazer um evento sobre qualquer assunto, convide mulheres palestrantes. Isso vai proporcionar discussões mais diversas e mais engajamento também.',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Como criar um ambiente de trabalho mais saudável pra mulheres. 👋
        </Typography>
        <Typography variant="body2">Um monte de boas práticas pro trabalho e pra vida.</Typography>
      </Box>
      <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <img
          src={AmbienteDeTrabalho}
          aria-hidden="true"
          alt="Ilustração com três colegas de trabalho discutindo ideias"
          style={{ maxWidth: '100%', width: '430px', height: '300px' }}
        />
      </Stack>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={womenTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/mulheres/autoconfianca" />
        </Stack>
      </Box>
    </Stack>
  );
};
