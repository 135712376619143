import { useQuery } from 'react-query';

import { QueryKeys } from '../../config/QueryKeys';
import { getFormDataQuestions } from '../../services/api/form-data';

export const useGetDataFormQuestions = (shortId: string) => {
  return useQuery(QueryKeys.getFormsAnswers, () => getFormDataQuestions(shortId), {
    refetchOnWindowFocus: false,
    retry: false,
  });
};
