import React from 'react';

import { Typography } from '@mui/material';

export const SequenceInvalidNotFound = () => {
  return (
    <div>
      <Typography variant="h5" component="h1">
        Trilha não encontrada para exibir em modo visualização.
      </Typography>
      <Typography variant="body1" component="p">
        A trilha com o link que você utilizou para acessar não foi encontrada.
      </Typography>
    </div>
  );
};
