import React from 'react';

import { Stack, Box, Typography, Grid } from '@mui/material';
import { List, ListItemButton, ListItemText } from '@mui/material';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { ModalTextButton } from '../ModalTextButton';
import { useNavigate } from 'react-router-dom';

import deafPeopleGraphic from './images/deafPeopleGraphic.jpeg';

export const DiversityDeafPeople = () => {
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Pessoas Surdas 🦻
        </Typography>
      </Box>
      <Box sx={{ pl: 1 }}>
        <Typography variant="body1" component="p">
          Pessoas surdas são grande parte da população. No Brasil existem 10,7 milhões de pessoas com deficiência auditiva e 500 milhões no mundo, segundo a Organização Mundial da
          Saúde (OMS).
        </Typography>
        <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <img
            src={deafPeopleGraphic}
            alt="Imagem de um gráfico que mostra que a cada 100 surdos, 63 deles não tem oportunidade de trabalho."
            style={{ maxWidth: '430px', width: '100%', maxHeight: '450px' }}
          />
        </Stack>
        <Typography variant="body1" component="p">
          Então, é muito importante que empresas saibam receber e valorizar pessoas surdas. Seja no trabalho remoto ou presencial, existem boas práticas importantes para a
          liderança e o time incluir essas pessoas em ambientes de trabalho saudáveis.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          Pensando nisso, a equipe da Inboarding criou este manual do zero através de testes, pesquisas e vivências reais - contando com o apoio de um profissional surdo em todo
          processo.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          Esperamos te ajudar a tornar o ambiente de trabalho da sua empresa mais saudável e favorável ao crescimento de pessoas surdas. Boa leitura! 🙂
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          E essas pessoas não são iguais, por isso separamos <strong>6 grupos pra te ajudar a entender as principais diferenças.</strong>
        </Typography>
      </Box>
      <Box sx={{ pl: 1, mt: 2 }}>
        <DeafPersonGroups />
      </Box>
      <Box sx={{ pl: 1, pb: 1, mt: 2 }}>
        <Typography variant="body1" component="p" sx={{ mt: 1, pb: 3 }}>
          Pensando nisso, a equipe da Inboarding criou esse manual pra te ajudar a tornar o ambiente de trabalho da sua empresa mais saudável e favorável ao crescimento de pessoas
          surdas.{' '}
        </Typography>
        <Typography variant="h6" component="h3">
          Vamos conversar sobre
        </Typography>
        <DeafPersonTopics />
      </Box>
    </Stack>
  );
};

const DeafPersonGroups = () => {
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={6} md={4}>
        <ModalTextButton
          title="Se comunicam pela Língua Brasileira de Sinais"
          description={
            <>
              <p>Podem contar com um intérprete de Libras durante a comunicação com os ouvintes.</p>
              <p>Nesse caso, fale diretamente com a pessoa surda e não com o intérprete de Libras, pois esse profissional está ali apenas para intermediar a conversa.</p>
              <p>Ah, e não precisa falar muito alto e nem rápido demais.</p>
            </>
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <ModalTextButton
          title="Têm o português como segunda língua"
          description={
            <>
              <p>
                Ter o português como segunda língua pode fazer com que elas escrevam textos de um jeito que possa parecer errado, mas essa é a forma como um aprendiz de segunda
                língua escreve.
              </p>
              <p>Então, evite fazer piadas sobre isso.</p>
            </>
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <ModalTextButton
          title="Fluentes em português"
          description={
            <>
              <p>Algumas pessoas surdas aprendem a língua portuguesa antes de perder a audição, e essa é sua primeira língua.</p>
              <p>Outras pessoas surdas, mesmo que tenham nascido surdas, passam pelo processo de aprendizado da língua portuguesa e são fluentes nessa língua.</p>
            </>
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <ModalTextButton
          title="Fluentes em Libras e em Português"
          description={
            <>
              <p>Algumas pessoas surdas são fluentes em Libras e em português. E usam as duas línguas no seu dia a dia. </p>
            </>
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <ModalTextButton
          title="Fizeram implante coclear"
          description={
            <>
              <p>A cirurgia não faz com que a pessoa se torne uma ouvinte. Após a cirurgia, ela se torna uma pessoa surda com implante coclear.</p>
              <p>
                Nem todas as pessoas surdas querem ouvir, algumas pessoas surdas entendem a surdez como uma característica pessoal, e não tem vontade de fazer a cirurgia pra ouvir.
              </p>
            </>
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <ModalTextButton
          title="Fazem leitura labial"
          description={
            <>
              <p>Algumas pessoas surdas conseguem entender o que você fala pelo movimento dos seus lábios. </p>
              <p>Nesse caso, confira se sua boca está tampada, se for o caso chame outra pessoa para te ajudar na comunicação com essa pessoa surda. </p>
              <p>Às vezes só o movimento da boca basta, mas se falar não grite.</p>
            </>
          }
        />
      </Grid>
    </Grid>
  );
};

export const TopiceMenuListItem = ({ title, text, handerClick }: { title: string; text: string; handerClick: () => void }) => {
  return (
    <ListItemButton disableGutters={true} onClick={handerClick} sx={{ p: 1 }}>
      <ListItemText primary={title} secondary={text} />
      <ArrowForwardIosIcon sx={{ fontSize: 15 }} />
    </ListItemButton>
  );
};

const DeafPersonTopics = () => {
  const navigate = useNavigate();
  const handlerRoutePage = (path: string) => navigate(path);

  return (
    <>
      <List component="nav">
        <TopiceMenuListItem
          title="O que é capacitismo e como evitar"
          text="O time e a liderança na criação de uma rotina com respeito."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-surdas/capacitismo');
          }}
        />
        <TopiceMenuListItem
          title="Aprenda a ser acessível na contratação"
          text="Clínica do trabalho, vagas afirmativas e o que perguntar na entrevista."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-surdas/contratacao-acessivel');
          }}
        />
        <TopiceMenuListItem
          title="Como receber uma pessoa surda no time"
          text="O que fazer antes de receber a pessoa na empresa (remoto e presencial)."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-surdas/como-receber');
          }}
        />
        <TopiceMenuListItem
          title="Ambiente de trabalho mais saudável para pessoas surdas"
          text="Um monte de boas práticas pro trabalho e pra vida."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-surdas/o-que-fazer');
          }}
        />
        <TopiceMenuListItem
          title="Como criar conteúdos acessíveis pra apresentações e eventos."
          text="Dicas pra você criar apresentações acessíveis no trabalho."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-surdas/criar-conteudos-acessiveis');
          }}
        />
        <TopiceMenuListItem
          title="Trabalho remoto com pessoas surdas"
          text="Boas práticas pra reuniões, e-mails, conversas e mais."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-surdas/trabalho-remoto');
          }}
        />
        <TopiceMenuListItem
          title="Uma conversa com quem lidera pessoas surdas"
          text="O papel da liderança na criação de oportunidades."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-surdas/lider-pessoas-surdas');
          }}
        />
        <TopiceMenuListItem
          title="Aprendendo a ter mais autoconfiança"
          text="Como as pessoas surdas podem ser mais confiantes."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-surdas/autoconfianca-e-autopromocao-no-trabalho');
          }}
        />
        <TopiceMenuListItem
          title="Curiosidades sobre pessoas surdas"
          text="Lista de coisas que só pessoas surdas conseguem fazer."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-surdas/curiosidades');
          }}
        />
        <TopiceMenuListItem
          title="Conquistas incríveis"
          text="Uma lista de pessoas surdas que fizeram história"
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-surdas/conquistas-incriveis');
          }}
        />
        <TopiceMenuListItem
          title="Inspirações de pessoas surdas"
          text="Pra você seguir e acompanhar nas redes sociais."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-surdas/inspiracoes');
          }}
        />
        <TopiceMenuListItem
          title="Datas importantes"
          text="Datas que valorizam e marcam a história de pessoas surdas"
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-surdas/datas-importantes');
          }}
        />
        <TopiceMenuListItem
          title="Conteúdos que a Inboarding indica"
          text="Pra você ir além desse manual."
          handerClick={() => {
            return handlerRoutePage('/diversidade/pessoas-surdas/conteudos-indicados');
          }}
        />
      </List>
    </>
  );
};
