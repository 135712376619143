import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import Curiosities from './images/illustrations/curiosities.jpg';

export const DeafPeopleCuriosities = () => {
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          <strong>Curiosidades sobre pessoas surdas. 🔎</strong>
        </Typography>
        <Typography variant="body2">Lista de coisas que pessoas surdas podem fazer muito bem.</Typography>
      </Box>
      <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <img src={Curiosities} alt="Imagem ilustrativa de um homem e três balões de pensamento." style={{ maxWidth: '430px', width: '100%' }} aria-hidden="true" />
      </Stack>
      <Box sx={{ pl: 2, pb: 1, pt: 2 }}>
        <Typography variant="body1" component="p" sx={{ pt: 1 }}>
          <strong>1 - </strong>Podem fazer entregas com muita qualidade nos detalhes porque têm grande capacidade visual.
        </Typography>
        <Typography variant="body1" component="p" sx={{ pt: 1 }}>
          <strong>2 - </strong>A maioria tem mais expressão facial que o restante das pessoas
        </Typography>
        <Typography variant="body1" component="p" sx={{ pt: 1 }}>
          <strong>3 - </strong>Podem conversar em Libras com a boca cheia. Dá até pra aproveitar mais a hora do almoço e bater papo enquanto come.
        </Typography>
        <Typography variant="body1" component="p" sx={{ pt: 1 }}>
          <strong>4 - </strong>Podem conversar em Libras na sala ou departamento, pois não vão incomodar os outros já que a conversa pode não ter som.
        </Typography>
        <Typography variant="body1" component="p" sx={{ pt: 1 }}>
          <strong>5 - </strong>Podem conversar em Libras à distância (bem longe) sem precisar gritar. Dá até pra conversar com a colega que tá lá do outro lado da mesa sem
          atrapalhar ninguém.
        </Typography>
        <Typography variant="body1" component="p" sx={{ pt: 1 }}>
          <strong>6 - </strong>A maioria é muito observadora e detalhista. Podem conseguir entender o que está acontecendo e propor soluções com muita rapidez.
        </Typography>
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-surdas/conquistas-incriveis" />
        </Stack>
      </Box>
    </Stack>
  );
};
