import React from 'react';

import { Box, Skeleton } from '@mui/material';

export const SequenceLoading = () => {
  return (
    <Box sx={{ width: '90%', margin: '20px auto' }}>
      <Skeleton animation="wave" height={15} />
      <Skeleton variant="text" sx={{ fontSize: '1.25rem', my: 2 }} />
      <TextParagraphLoading />
      <TextParagraphLoading />
      <TextParagraphLoading />
      <Skeleton variant="rectangular" height="65px" style={{ backgroundColor: '#E8F1F8', width: '100%', position: 'fixed', bottom: 0, left: 0, right: 0 }} />
    </Box>
  );
};

const TextParagraphLoading = () => {
  return (
    <>
      <Skeleton variant="text" sx={{ fontSize: '1rem', mt: 1 }} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
      <Skeleton variant="text" sx={{ fontSize: '1rem' }} width="87%" />
    </>
  );
};
