import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import Leaders from './images/illustrations/leaders.svg';

export const ForBlindPeopleLeaders = () => {
  const blindPeopleTextItems = [
    {
      title: 'Crie vagas afirmativas para pessoas com deficiência visual.',
      description:
        'As pessoas com baixa visão ou cegueira estão incluídas na Lei de Cotas, que determina que empresas com equipes de 100 ou mais pessoas preencham uma parte das vagas com pessoas com deficiência. Para fazer isso você pode abrir vagas afirmativas, mas não só com objetivo de cumprir a lei, mas de tornar o ambiente inclusivo.',
    },
    {
      title: 'Eduque o time antes de receber a pessoa cega ou com baixa visão.',
      description:
        'É importante educar o time para evitar situações de capacitismo. A liderança precisa ajudar a criar um ambiente inclusivo de fato e isso não vai acontecer enquanto o time não souber como incluir a pessoa com deficiência visual no dia a dia. Você pode fazer isso com palestras, cartilhas e com o nosso manual sobre como incluir pessoas com deficiência visual no ambiente de trabalho, é claro.',
    },
    {
      title: 'Valorize pessoas com deficiência visual com feedbacks.',
      description:
        'Não adianta apenas contratar pessoas cegas ou com baixa visão, você precisa dar feedbacks pra que elas cresçam e se sintam valorizadas como qualquer profissional. Se está fazendo bem o trabalho ou se não está, elas precisam saber. É comum que pessoas com deficiência visual não recebam retorno da liderança e isso desmotiva o trabalho delas.',
    },
    {
      title: 'Crie um plano de carreira para pessoas cegas.',
      description:
        'Para se sentir parte da empresa, as pessoas cegas precisam ser incluídas a longo prazo. Isso pode ser feito através de um plano de carreira que deve ser criado de forma que incentive a especialização e dê oportunidades para que pessoas cegas cresçam.',
    },
    {
      title: 'Inclua pessoas cegas na rotina.',
      description: (
        <>
          <p>Festas da empresa, reuniões, comunicados, e por aí vai. Pessoas cegas precisam ser parte de tudo o que acontece e isso pode ser feito através de adaptações.</p>
          <p>
            <strong>Exemplos:</strong>
          </p>
          <ul>
            <li>Se o comunicado é de papel e está em um mural, avise a pessoa cega sobre ele;</li>
            <li>
              Se uma festa de comes e bebes vai acontecer, pergunte como ela prefere ter acessibilidade (placas em braile, avisos sonoros, audiodescrição feita por alguém do time,
              etc.).
            </li>
            <li>Quando fizer eventos, convide a pessoa cega para palestrar sobre a especialidade dela. Isso é importante para a diversidade.</li>
          </ul>
        </>
      ),
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Uma conversa com quem lidera pessoas com deficiência visual. 🤝
        </Typography>
        <Typography variant="body2">O papel da liderança para criar oportunidades.</Typography>
      </Box>
      <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
        <img src={Leaders} alt="Imagem ilustrativa de duas pessoas analisando marcadores." style={{ maxWidth: '430px', width: '100%' }} aria-hidden="true" />
      </Stack>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={blindPeopleTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/pessoas-com-deficiencia-visual/capacitismo" />
        </Stack>
      </Box>
    </Stack>
  );
};
