import { API } from './fetcher';

export const requestTokenByEmail = async (email: string, _API = API) => {
  const payload = {
    data: {
      email,
    },
  };
  try {
    const { data, status } = await _API.post('/login', payload);
    return { data, status };
  } catch (error) {
    return { error };
  }
};

export const validateToken = async (_API = API) => {
  try {
    const { data, status } = await _API.get('/login/token');
    return { data, status };
  } catch (error) {
    return { error };
  }
};
