import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Container, Box, Toolbar, BottomNavigation, BottomNavigationAction, useMediaQuery, Theme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import HomeIcon from '@mui/icons-material/Home';
import ExploreIcon from '@mui/icons-material/Explore';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

import { TopToolbar } from '../TopToolbar';
import { AppUserContext } from '../../config/AppUserContext';
import { useGetDataProfile, UserProfileData } from '../../hooks/queries';
import { Loading } from '../Loading';
import { setSessionUserData } from '../../utils/sessionUser';
import { createAppThemeFromUser } from '../../utils/createAppThemeFromUser';

export const NavigationToolbar = () => {
  const navigate = useNavigate();
  const [toolbarValue, setToolbarValue] = React.useState(0);
  const handlerRoutePage = (path: string) => navigate(path);

  return (
    <BottomNavigation sx={{ py: 0, backgroundColor: '#E8F1F8 ' }} showLabels value={toolbarValue} onChange={(event, newValue) => setToolbarValue(newValue)}>
      <BottomNavigationAction label="Início" icon={<HomeIcon />} onClick={() => handlerRoutePage('/dashboard')} />
      <BottomNavigationAction label="Jornada" icon={<ExploreIcon />} onClick={() => handlerRoutePage('/jornada')} />
      <BottomNavigationAction label="Meu perfil" icon={<AccountCircleIcon />} onClick={() => handlerRoutePage('/perfil')} />
    </BottomNavigation>
  );
};

export const MobileToolbar = () => {
  return (
    <Toolbar sx={{ p: 0, backgroundColor: '#E8F1F8', width: '100%', position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1 }}>
      <Container disableGutters maxWidth="md" sx={{ backgroundColor: '#E8F1F8', py: 1 }}>
        <NavigationToolbar />
      </Container>
    </Toolbar>
  );
};

export const DesktopToolbar = () => {
  return (
    <>
      <Toolbar sx={{ p: 0, backgroundColor: '#E8F1F8', width: '100%', position: 'fixed', left: 0, zIndex: 1 }}>
        <Container disableGutters maxWidth="md" sx={{ backgroundColor: '#E8F1F8', py: 1 }}>
          <NavigationToolbar />
        </Container>
      </Toolbar>
      <Toolbar />
    </>
  );
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return null;
};

export const LayoutComponent = () => {
  const { pathname } = useLocation();

  const mobileMedia = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { data: profile, isLoading } = useGetDataProfile();
  const userProfileData = profile?.data?.data as UserProfileData;

  if (isLoading) {
    return <Loading />;
  }

  setSessionUserData(userProfileData);

  const theme = createAppThemeFromUser(userProfileData);

  const showBackButton = pathname !== '/dashboard';

  return (
    <AppUserContext.Provider value={userProfileData}>
      <ThemeProvider theme={theme}>
        <Container
          disableGutters
          maxWidth="md"
          sx={{
            minHeight: '100vh',
          }}
        >
          <ScrollToTop />
          <TopToolbar showBackButton={showBackButton} logoUrl={userProfileData?.company?.logo_url} logoName={userProfileData?.company?.name} />
          {!mobileMedia && <DesktopToolbar />}
          <Container>
            <Box sx={{ pb: 9, pt: 2 }}>
              <Outlet />
            </Box>
          </Container>
          {mobileMedia && <MobileToolbar />}
        </Container>
      </ThemeProvider>
    </AppUserContext.Provider>
  );
};
