import React, { useState } from 'react';
import { Accordion, Box, Button, Icon, Skeleton, Stack, Tooltip, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { EllaMessage, ErrorMessage } from '../../components';
import { AppUserContext } from '../../config/AppUserContext';
import {
  useGetJourneySteps,
  PeopleJourneyStepsData,
  PeopleJourneyStepsStatus,
  PeopleJourneyStepsChannel,
  StepEmailMessage,
  StepSlackMessage,
  WhatsAppSendMessage,
  MessageBlocksTypes,
  StepMessageHtml,
} from '../../hooks/queries/useJourneyStepsQuery';
import { dateTimeFormat } from '../../components/DateFormatted/dateTimeFormat';
import { MessagePopup } from './MessagePopup';
import { useConfirmReadStep } from '../../hooks/queries/useConfirmReadStep';
import { SnackbarMessage } from '../../components/SnackbarMessage';

export const Journey = () => {
  const profile = React.useContext(AppUserContext);

  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary">
          Minha jornada
        </Typography>
      </Box>
      <Box sx={{ pl: 1 }}>
        <EllaMessage>
          <>
            <Typography variant="h6" color="primary">
              {profile?.short_name || profile?.first_name},
            </Typography>
            <Typography variant="body2">Você pode acompanhar sua jornada desde o primeiro dia de trabalho. Todos os seus aprendizados e conquistas ficam aqui.</Typography>
          </>
        </EllaMessage>
      </Box>
      <Box sx={{ pl: 1 }}>
        <JourneySteps />
      </Box>
    </Stack>
  );
};

const JourneySteps = (): JSX.Element => {
  const { data, isLoading, isError, error } = useGetJourneySteps();

  if (isError) {
    return <ErrorMessage error={error} />;
  }

  if (isLoading) {
    return <LoadingJourneySteps />;
  }

  if (!data || !data.length) {
    return (
      <p>
        <Typography variant="h6">Você não tem jornadas iniciadas.</Typography>
      </p>
    );
  }

  const stepsSortedByDate = sortByDate(data);

  return (
    <Stack>
      <ListJourneySteps data={stepsSortedByDate} />
    </Stack>
  );
};

const sortByDate = (steps: PeopleJourneyStepsData[]) => {
  steps.sort(function (a: PeopleJourneyStepsData, b: PeopleJourneyStepsData) {
    const aDate = a.sent_date || a.schedule_date || a.createdAt;
    const bDate = b.sent_date || b.schedule_date || b.createdAt;
    return bDate < aDate ? -1 : 1;
  });

  return steps;
};

const setMessageByType = (
  channel: PeopleJourneyStepsChannel,
  message: StepEmailMessage | StepSlackMessage | WhatsAppSendMessage,
  messageHtml: StepMessageHtml | undefined,
): string | undefined => {
  switch (channel) {
    case 'EMAIL': {
      const messageEmail = message as StepEmailMessage;
      if (messageEmail && messageEmail.html !== undefined) {
        return messageEmail.html;
      }
      return '';
    }

    case 'SLACK': {
      if (messageHtml && messageHtml.text !== undefined) {
        return messageHtml.text + (messageHtml.actions || '');
      } else {
        const messageSlack = message as StepSlackMessage;
        if (messageSlack && messageSlack.text !== undefined) {
          return messageSlack.text;
        }
      }
      return '';
    }

    case 'WHATSAPP':
      if (messageHtml && messageHtml.text !== undefined) {
        return messageHtml.text + (messageHtml.actions || '');
      }
      return '';

    default:
      return '';
  }
};

const ListJourneySteps = ({ data }: { data: PeopleJourneyStepsData[] }) => {
  return (
    <>
      {data.map((stepItem: PeopleJourneyStepsData) => (
        <JourneyStep
          key={stepItem.id}
          shortId={stepItem.id_short}
          title={stepItem.step_name}
          date={stepItem.sent_date || stepItem.schedule_date}
          status={stepItem.status}
          channel={stepItem.step_channel}
          message={stepItem.message_sent}
          messageHtml={stepItem.message_html}
          blockType={stepItem.message_block_type}
        />
      ))}
    </>
  );
};

const JourneyStep = ({
  shortId,
  title,
  date,
  status,
  channel,
  message,
  messageHtml,
  blockType,
}: {
  shortId: string;
  title: string;
  date: string;
  status: PeopleJourneyStepsStatus;
  channel: PeopleJourneyStepsChannel;
  message: StepEmailMessage | StepSlackMessage | WhatsAppSendMessage;
  messageHtml: StepMessageHtml | undefined;
  blockType: MessageBlocksTypes | undefined;
}) => {
  const [showValidatorPopup, setIsPopupShown] = useState(false);
  const [messageSuccess, setMessageSuccess] = React.useState('');
  const [messageAlert, setMessageAlert] = React.useState('');

  const { mutateAsync: confirmRead, isLoading: isLoadingConfirmRead } = useConfirmReadStep();

  async function handleConfirmRead(): Promise<boolean> {
    const { data, status } = await confirmRead(shortId || '');
    if (status === 200) {
      setMessageSuccess('Mensagem confirmada com sucesso!');
      setIsPopupShown(false);
      return true;
    } else {
      setMessageAlert('Ocorreu um probleminha ao confirmar a mensagem! Confira internet e tente novamente.');
      console.error('Error on confirming read on the step. Status: ', status, ' data: ', data);
      return false;
    }
  }

  const formattedChannel = channel ? channel.toLocaleLowerCase() : 'email';

  const correctStepMessage = setMessageByType(channel, message, messageHtml);

  return (
    <>
      {!!messageAlert && <SnackbarMessage message={messageAlert} severity="warning" onClose={() => setMessageAlert('')} />}
      {!!messageSuccess && <SnackbarMessage message={messageSuccess} severity="success" onClose={() => setMessageSuccess('')} />}
      <Stack>
        <Stack sx={{ pl: 1 }}>
          <Stack direction="row">
            <Box>
              <SelectIcon status={status} blockType={blockType} />
            </Box>
            <Box sx={{ mt: 1 }}>
              <Typography variant="subtitle1">{title}</Typography>
            </Box>
          </Stack>
          <Stack sx={{ borderLeft: '2px solid rgba(0,0,0,0.5)', ml: 2.15, pl: 2, pb: 3 }}>
            <Box>
              <Typography variant="subtitle1">
                Por {formattedChannel}, {dateTimeFormat(date)}
              </Typography>
              {correctStepMessage && (
                <Button onClick={() => setIsPopupShown(true)} aria-label={`Ler mensagem: ${title}`}>
                  Ler mensagem
                </Button>
              )}
            </Box>
            {showValidatorPopup && (
              <MessagePopup
                open={true}
                messageHtml={correctStepMessage}
                title={title}
                status={status}
                blockType={blockType}
                loadingConfirm={isLoadingConfirmRead}
                onClose={() => {
                  setIsPopupShown(false);
                }}
                confirmRead={handleConfirmRead}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

const SelectIcon = ({ status, blockType }: { status: PeopleJourneyStepsStatus; blockType: MessageBlocksTypes | undefined }): JSX.Element => {
  const isSent = [PeopleJourneyStepsStatus.Sent, PeopleJourneyStepsStatus.Delivered, PeopleJourneyStepsStatus.Sending].includes(status);
  const isOpened = [PeopleJourneyStepsStatus.Opened, PeopleJourneyStepsStatus.Clicked].includes(status);
  const isConfirmed = [PeopleJourneyStepsStatus.Confirmed, PeopleJourneyStepsStatus.ManualRead].includes(status);
  const isError = [PeopleJourneyStepsStatus.Error, PeopleJourneyStepsStatus.Rejected, PeopleJourneyStepsStatus.Bounced].includes(status);

  const hasMessageBlocks = !!blockType && [MessageBlocksTypes.Form, MessageBlocksTypes.Poll, MessageBlocksTypes.Sequence].includes(blockType);

  if (isConfirmed || (isOpened && !hasMessageBlocks)) {
    const statusDescription = 'Mensagem confirmada.';

    return (
      <Tooltip placement="top" arrow title={statusDescription}>
        <Icon fontSize="large" color="success" aria-label={statusDescription}>
          <CheckCircleIcon />
        </Icon>
      </Tooltip>
    );
  }

  if (isSent || isError || (isOpened && hasMessageBlocks)) {
    const statusDescription = 'Pendente para confirmação.';

    return (
      <Tooltip placement="top" arrow title={statusDescription}>
        <Icon fontSize="large" color="warning" aria-label={statusDescription}>
          <ErrorOutlineIcon />
        </Icon>
      </Tooltip>
    );
  }

  const scheduledStepDescription = 'Próxima mensagem agendada.';

  return (
    <Tooltip placement="top" arrow title={scheduledStepDescription}>
      <Icon fontSize="large" color="action" aria-label={scheduledStepDescription}>
        <ScheduleIcon />
      </Icon>
    </Tooltip>
  );
};

const LoadingJourneySteps = (): JSX.Element => {
  return (
    <p>
      {Array(7)
        .fill('')
        .map((_item, index) => (
          <Accordion key={index}>
            <Skeleton height={68} variant="rectangular" />
          </Accordion>
        ))}
    </p>
  );
};
