import { UserProfileData } from '../hooks/queries';

export const setStorangeAuthUser = (authUser: UserProfileData): void => {
  return authUser && localStorage.setItem('@inboarding_authu', JSON.stringify(authUser));
};

export const getStorangeAuthUser = (): UserProfileData | null => {
  const sessionAuthUser = localStorage.getItem('@inboarding_authu');
  if (!sessionAuthUser) {
    return null;
  }
  return JSON.parse(sessionAuthUser) as UserProfileData;
};

export const removeStorangeAuthUser = () => {
  return localStorage.removeItem('@inboarding_authu');
};
