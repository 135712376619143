import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { EllaMessage } from '../../components';
import { UserProfileData } from '../../hooks/queries/useProfileQuery';
import { Link } from 'react-router-dom';

export function LastJourneyMessage({ userProfileData }: { userProfileData: UserProfileData }): JSX.Element {
  if (!userProfileData?.last_journey_step_sent?.step_name) {
    return <></>;
  }

  return (
    <EllaMessage>
      <>
        <Typography variant="h6" color="primary">
          Olá, {userProfileData?.short_name || userProfileData?.first_name}! 👋
        </Typography>
        <Typography variant="body2">Sua jornada está em andamento, aqui você pode acompanhar mais detalhes.</Typography>
        <Stack>
          <Typography variant="body1" color="dimgray" sx={{ marginTop: '1rem' }}>
            <strong>Etapa: {userProfileData.last_journey_step_sent.step_name}</strong>
          </Typography>
          <Box>
            <Button variant="contained" component={Link} to={'/jornada'} sx={{ padding: '0.5rem 2rem', marginTop: '0.5rem', textAlign: 'center' }}>
              Acompanhar Jornada
            </Button>
          </Box>
        </Stack>
      </>
    </EllaMessage>
  );
}
