import React from 'react';

import { Typography } from '@mui/material';

export const FormSurveyInvalidNotFound = () => {
  return (
    <div>
      <Typography variant="h5" component="h1">
        Pesquisa não encontrada.
      </Typography>
      <Typography variant="body1" component="p">
        A pesquisa com o link que você utilizou para acessar não foi encontrado.
      </Typography>
      <Typography variant="body1" component="p">
        Acesse a <a href="/jornada">jornada no portal de colaboradores</a> para tentar novamente.
      </Typography>
    </div>
  );
};
