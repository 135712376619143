import React, { FC } from 'react';
import { createRoot } from 'react-dom/client';

import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import * as Sentry from '@sentry/react';
import { Integration } from '@sentry/types';
import { BrowserTracing } from '@sentry/tracing';
import { CaptureConsole } from '@sentry/integrations';

import './index.css';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, useLocation } from 'react-router-dom';
import { Environment } from './config/Environment';

LogRocket.init('mrlzk2/portal-inboarding');
setupLogRocketReact(LogRocket);

Sentry.init({
  dsn: 'https://5abe0578b96546b4b6350e0f3df67920@o1139190.ingest.sentry.io/6194556',
  integrations: [new BrowserTracing(), new CaptureConsole({ levels: ['error'] }) as Integration],
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
  beforeSend: function (event: Sentry.Event) {
    const logRocketSession = LogRocket.sessionURL;
    if (logRocketSession) {
      if (!event.extra) {
        event.extra = {};
      }
      event.extra['LogRocket'] = logRocketSession;
      return event;
    } else {
      return event;
    }
  },
});

LogRocket.getSessionURL((sessionURL: string) => {
  Sentry.configureScope((scope) => {
    scope.setExtra('sessionURL', sessionURL);
  });
});

interface WrappedCompProps {
  appUpdatePending: boolean;
  updateAction: () => void;
}

/* eslint-disable react/display-name */
const withSwRegistration = (WrappedComp: FC<WrappedCompProps>) => {
  return () => {
    // holds all the SW registration setup
    const [appUpdatePending, setAppUpdatePending] = React.useState(false);
    const location = useLocation();
    // updates the state when a new update is pending.
    const onSWUpdate = () => {
      setAppUpdatePending(!appUpdatePending);
    };
    // action for updating the service worker.
    const updateAction = () => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
          if (registration.waiting) {
            // send the skip message to kick off the service worker install.
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
            // add an listener to reload page when the new service worker is ready.
            registration.waiting.addEventListener('statechange', (event: Event) => {
              const { state = '' } = (event.target as unknown as { state: string }) || {};
              if (state === 'activated') {
                window.location.reload();
              }
            });
          }
        });
      }
    };
    // effect added from router location to check for a new service worker on every
    // page transition (change of route).
    React.useEffect(() => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
          registration.update();
        });
      }
    }, [location]);

    // registers the service worker based on config setting.
    // remove isStaging after test on staging
    if (Environment.isProduction() || Environment.isStaging()) {
      serviceWorkerRegistration.register({ onUpdate: onSWUpdate });
    } else {
      serviceWorkerRegistration.unregister();
    }
    return <WrappedComp updateAction={updateAction} appUpdatePending={appUpdatePending} />;
  };
};

const AppWithSwRegistration = withSwRegistration(App);

const container = document.getElementById('root');
const root = createRoot(container!); // eslint-disable-line @typescript-eslint/no-non-null-assertion
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppWithSwRegistration />
    </BrowserRouter>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
