/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import type { SubmissionData, SubmissionDispatchers } from './quillFormTypes';

import { AsyncQuillForm } from './AsyncQuillForm';
import { useGetDataFormPreview } from '../../hooks/queries/useFormPreviewQuery';
import { setStorangeToken } from '../../utils';

import { FormQuestionLoading } from './FormQuestionLoading';
import { FormQuestionLoadError } from './FormQuestionLoadError';
import { FormQuestionInvalidNotFound } from './FormQuestionInvalidNotFound';

export const FormQuestionsPreview = () => {
  const { journeyId, stepId } = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  if (token) {
    setStorangeToken(token);
  }

  if (!journeyId || !stepId) {
    return <FormQuestionInvalidNotFound />;
  }

  const { data, isLoading, isError, error } = useGetDataFormPreview(journeyId, stepId);

  if (isLoading) {
    return <FormQuestionLoading />;
  }

  if (isError) {
    return <FormQuestionLoadError error={error} />;
  }

  if (!data) {
    return <FormQuestionInvalidNotFound />;
  }

  const parsedBlocks = data.data.data;

  if (!parsedBlocks || !parsedBlocks.length) {
    return <FormQuestionInvalidNotFound />;
  }

  const previewBlocks = [
    {
      name: 'welcome-screen',
      id: 'preview-welcome-screen',
      attributes: {
        label: 'Você está abrindo o formulário em modo visualização e os dados não serão salvos.',
        description: 'O usuário ao acessar o link oficial não vai ver essa etapa.',
        buttonText: 'Visualizar o formulário!',
      },
    },
    ...parsedBlocks,
  ];
  const onFormSubmit = (data: SubmissionData, { completeForm, setIsSubmitting }: SubmissionDispatchers) => {
    setTimeout(() => {
      setIsSubmitting(false);
      completeForm();
    }, 3000);
  };

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      <AsyncQuillForm formId={1} blocks={previewBlocks} onSubmit={onFormSubmit} />
    </div>
  );
};
