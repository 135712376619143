import React from 'react';

import { Stack, Typography, Box } from '@mui/material';
import { InstagramEmbed } from 'react-social-media-embed';
import { LinkButton } from './LinkButton';

interface IVideoContent {
  contentType: string;
  title: string;
  channel: string;
  videoUrl: string;
}

const VideoContent = ({ index, content }: { index: number; content: IVideoContent }): JSX.Element => {
  return (
    <>
      <Stack sx={{ pl: 1 }}>
        <Typography variant="body1" sx={{ pt: 2 }} paragraph={true}>
          <strong>
            {index + 1} - {content.title}
          </strong>
        </Typography>
        <Typography variant="body1" sx={{ pl: 1 }} paragraph={true}>
          Canal do Youtube: {content.channel}
        </Typography>
      </Stack>
      <Stack sx={{ pl: 1, pb: 1, pt: 1, justifyContent: 'center', alignItems: 'center' }}>
        <iframe
          width="560"
          height="315"
          src={content.videoUrl}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </Stack>
    </>
  );
};

interface ITextContent {
  contentType: string;
  title: string;
  description: string;
  imageImportName: string;
  altText: string;
  buttonLabel: string;
  buttonUrl: string;
}

const TextContent = ({ index, content }: { index: number; content: ITextContent }): JSX.Element => {
  return (
    <>
      <Stack sx={{ pl: 1 }}>
        <Typography variant="body1" sx={{ pt: 2 }} paragraph={true}>
          <strong>
            {index + 1} - {content.title}
          </strong>
        </Typography>
        <Typography variant="body1" sx={{ pl: 1 }} paragraph={true}>
          {content.description}
        </Typography>
      </Stack>
      <Stack sx={{ pl: 1, pb: 1, pt: 1, justifyContent: 'center', alignItems: 'center' }}>
        <img
          src={content.imageImportName}
          alt={content.altText}
          style={{ maxWidth: '100%', width: '430px', maxHeight: '90%', border: 'solid', borderWidth: '3px', borderColor: 'rgba(242, 246, 255)' }}
        />
        <Box sx={{ pt: 2 }}>
          <LinkButton label={content.buttonLabel} url={content.buttonUrl} />
        </Box>
      </Stack>
    </>
  );
};

interface IInstagramContent {
  contentType: string;
  title: string;
  embedUrl: string;
}

const InstagramContent = ({ index, content }: { index: number; content: IInstagramContent }): JSX.Element => {
  return (
    <>
      <Stack sx={{ pl: 1 }}>
        <Typography variant="body1" sx={{ pt: 2 }} paragraph={true}>
          <strong>
            {index + 1} - {content.title}
          </strong>
        </Typography>
      </Stack>
      <Stack sx={{ pl: 1, pb: 1, pt: 1, justifyContent: 'center', alignItems: 'center' }}>
        <InstagramEmbed url={content.embedUrl} width={328} />
      </Stack>
    </>
  );
};

interface IBookContent {
  contentType: string;
  title: string;
  imageImportName: string;
  altText: string;
}

const BookContent = ({ index, content }: { index: number; content: IBookContent }): JSX.Element => {
  return (
    <>
      <Stack sx={{ pl: 1 }}>
        <Typography variant="body1" sx={{ pt: 2 }} paragraph={true}>
          <strong>
            {index + 1} - {content.title}
          </strong>
        </Typography>
      </Stack>
      <Stack sx={{ pl: 1, pb: 1, pt: 1, justifyContent: 'center', alignItems: 'center' }}>
        <img
          src={content.imageImportName}
          alt={content.altText}
          style={{ maxWidth: '35%', maxHeight: '100%', border: 'solid', borderWidth: '3px', borderColor: 'rgba(242, 246, 255)' }}
        />
      </Stack>
    </>
  );
};

interface IContents {
  contents: Array<IVideoContent | ITextContent | IInstagramContent | IBookContent>;
}

export const ListOfContents = ({ contents }: IContents): JSX.Element => {
  return (
    <>
      {contents.map((content: IVideoContent | ITextContent | IInstagramContent | IBookContent, index: number) => {
        switch (content.contentType) {
          case 'video':
            return <VideoContent key={content.title} index={index} content={content as IVideoContent} />;
          case 'article':
            return <TextContent key={content.title} index={index} content={content as ITextContent} />;
          case 'instagram':
            return <InstagramContent key={content.title} index={index} content={content as IInstagramContent} />;
          case 'book':
            return <BookContent key={content.title} index={index} content={content as IBookContent} />;
        }
      })}
    </>
  );
};
