import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import DOMPurify from 'dompurify';
import { PeopleJourneyStepsStatus } from '../../hooks/queries/useJourneyStepsQuery';
import CheckIcon from '@mui/icons-material/Check';
import { LoadingButton } from '@mui/lab';

function fixURLs(messageHtml: string) {
  // remove token from URL to avoid expired token error
  messageHtml = messageHtml.replace(/(?<=token=)([^&"]+)/g, '');

  // insert https:// on all URLs to ensure they work properly
  messageHtml = messageHtml.replace(/(?<=href=")([^"]+)/g, function (url) {
    if (!url.startsWith('https://')) {
      url = 'https://' + url;
    }
    return url;
  });

  return messageHtml;
}

export const MessagePopup = ({
  open,
  messageHtml,
  title,
  status,
  blockType,
  loadingConfirm,
  onClose,
  confirmRead,
}: {
  open: boolean;
  messageHtml: string | undefined;
  title: string;
  status: PeopleJourneyStepsStatus;
  blockType: string | undefined;
  loadingConfirm: boolean;
  onClose: () => void;
  confirmRead: () => Promise<boolean>;
}): JSX.Element => {
  if (!messageHtml) {
    return <></>;
  }

  const hasMessageBlocks = !!blockType;

  messageHtml = fixURLs(messageHtml);

  const toBeConfirmed = [
    PeopleJourneyStepsStatus.Sent,
    PeopleJourneyStepsStatus.Delivered,
    PeopleJourneyStepsStatus.Sending,
    PeopleJourneyStepsStatus.Error,
    PeopleJourneyStepsStatus.Rejected,
    PeopleJourneyStepsStatus.Bounced,
  ].includes(status);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ fontFamily: (theme) => theme.typography.fontFamily }} dividers>
        <div
          style={{ padding: '30px 0px', textAlign: 'justify' }}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(messageHtml, hasMessageBlocks ? {} : { ADD_ATTR: ['target'] }) }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fechar</Button>
        {toBeConfirmed && !hasMessageBlocks && (
          <LoadingButton variant="contained" startIcon={<CheckIcon />} loading={loadingConfirm} onClick={confirmRead}>
            Confirmar leitura
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
