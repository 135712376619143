import { useQuery } from 'react-query';

import { QueryKeys } from '../../config/QueryKeys';
import { getSequenceDataOfficial, getSequenceDataPreview } from '../../services/api/sequence';
import { PeopleJourneyStepsStatus } from './useJourneyStepsQuery';

export type SequenceData = {
  sequence_blocks: SequenceBlock[];
  sequence_status?: PeopleJourneyStepsStatus;
  sequence_last_index?: string;
};

export type SequenceBlock = {
  content_type: JourneyStepsSequenceContentType;
  title: string;
  text: string;
  url?: string;
  image_alt_text?: string;
};

export enum JourneyStepsSequenceContentType {
  PdfFile = 'PDF-FILE',
  Video = 'VIDEO',
  Url = 'URL',
  Image = 'IMAGE',
  Text = 'TEXT',
}

export const useGetSequenceData = (shortId: string) => {
  return useQuery(QueryKeys.getSequenceData, () => getSequenceDataOfficial(shortId), {
    refetchOnWindowFocus: false,
    retry: false,
  });
};

export const useGetSequencePreview = (journeyId: string, stepId: string) => {
  return useQuery(QueryKeys.getSequenceData, () => getSequenceDataPreview(journeyId, stepId), {
    refetchOnWindowFocus: false,
    retry: false,
  });
};
