import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { List, ListItemButton, ListItemText } from '@mui/material';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import womenGraphic from './images/womenGraphic.jpeg';

import { useNavigate } from 'react-router-dom';

export const DiversityWomen = () => {
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Mercado de trabalho pra mulheres. 👩‍💻
        </Typography>
      </Box>
      <Box sx={{ pl: 1 }}>
        <Typography variant="body1" component="p">
          As mulheres compõem grande parte da força de trabalho no mercado. Esse número tem melhorado nos últimos anos, mas muito além de incluir é preciso criar ambientes de
          trabalho saudáveis.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          Pesquisa da Associação Brasileira de Comunicação Empresarial (Aberje) identificou que:
        </Typography>
        <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <img
            src={womenGraphic}
            alt="Imagem de um gráfico que mostra que mais de 70% das mulheres já sofreram assédio no trabalho."
            style={{ maxWidth: '430px', width: '100%', maxHeight: '450px' }}
          />
        </Stack>
        <Typography variant="body1" component="p">
          A pesquisa também diz que as duas principais dificuldades pra que as mulheres cheguem ao topo da carreira são:
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          <ul>
            <li>Superiores subestimam as capacidades delas;</li>
            <li>São julgadas por expressarem suas opiniões.</li>
          </ul>
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          Além disso, em 2021, segundo o IBGE, a desigualdade salarial entre mulheres e homens que desempenham a mesma função cresceu para 22%.
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 1 }}>
          Pensando nisso, a equipe de mulheres da Inboarding criou esse manual pra te ajudar a tornar o ambiente de trabalho da sua empresa mais saudável, e favorável ao
          crescimento de mulheres.
        </Typography>
      </Box>
      <Box sx={{ pl: 1, pb: 1, mt: 2 }}>
        <Typography variant="h6" component="h3">
          Vamos conversar sobre
        </Typography>
        <WomenTopics />
      </Box>
    </Stack>
  );
};

export const TopiceMenuListItem = ({ title, text, handerClick }: { title: string; text: string; handerClick: () => void }) => {
  return (
    <ListItemButton disableGutters={true} onClick={handerClick} sx={{ p: 1 }}>
      <ListItemText primary={title} secondary={text} />
      <ArrowForwardIosIcon sx={{ fontSize: 15 }} />
    </ListItemButton>
  );
};

const WomenTopics = () => {
  const navigate = useNavigate();
  const handlerRoutePage = (path: string) => navigate(path);

  return (
    <>
      <List component="nav">
        <TopiceMenuListItem
          title="Como criar um ambiente de trabalho mais saudável pra mulheres. "
          text="Um monte de boas práticas pro trabalho e pra vida."
          handerClick={() => {
            return handlerRoutePage('/diversidade/mulheres/ambiente-de-trabalho');
          }}
        />
        <TopiceMenuListItem
          title="Autoconfiança no trabalho. "
          text="O primeiro passo pra se valorizar é confiar em você mesma."
          handerClick={() => {
            return handlerRoutePage('/diversidade/mulheres/autoconfianca');
          }}
        />
        <TopiceMenuListItem
          title="Autopromoção no trabalho."
          text="Saber que você é boa é tão importante quanto comunicar isso."
          handerClick={() => {
            return handlerRoutePage('/diversidade/mulheres/autopromocao');
          }}
        />
        <TopiceMenuListItem
          title="Mulheres não são rivais. "
          text="Como mulheres podem se unir no trabalho e na vida."
          handerClick={() => {
            return handlerRoutePage('/diversidade/mulheres/nao-sao-rivais');
          }}
        />
        <TopiceMenuListItem
          title="Para mulheres que estão começando."
          text="Estratégias pra você crescer de forma saudável."
          handerClick={() => {
            return handlerRoutePage('/diversidade/mulheres/que-estao-comecando');
          }}
        />
        <TopiceMenuListItem
          title="Para quem lidera mulheres."
          text="Comportamentos que podem incentivar suas lideradas. "
          handerClick={() => {
            return handlerRoutePage('/diversidade/mulheres/para-quem-lidera');
          }}
        />
        <TopiceMenuListItem
          title="Conquistas incríveis. "
          text="Uma lista de indicações de mulheres que fizeram história."
          handerClick={() => {
            return handlerRoutePage('/diversidade/mulheres/conquistas-incriveis');
          }}
        />
        <TopiceMenuListItem
          title="Inspirações de mulheres. "
          text="Redes sociais de mulheres incríveis pra você se inspirar."
          handerClick={() => {
            return handlerRoutePage('/diversidade/mulheres/inspiracoes');
          }}
        />
        <TopiceMenuListItem
          title="Conteúdos que a Inboarding indica. "
          text="Pra ir além desse manual. "
          handerClick={() => {
            return handlerRoutePage('/diversidade/mulheres/indicacao');
          }}
        />
      </List>
    </>
  );
};
