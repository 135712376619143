import React from 'react';

import { Stack, Typography } from '@mui/material';
import { ILinkButton, LinkButton } from './LinkButton';

interface IPersonImage {
  personImportName: string;
  altText: string;
}

interface IPersonInfo {
  name: string;
  description: string | JSX.Element;
}

interface IPersonProps {
  info: IPersonInfo;
  image?: IPersonImage | undefined;
  buttons: ILinkButton[];
}

interface IPeopleProps {
  people: IPersonProps[];
}

const PersonImage = ({ personImportName, altText }: IPersonImage): JSX.Element => {
  return <img src={personImportName} alt={altText} style={{ maxWidth: '430px', width: '100%', maxHeight: '450px' }} />;
};

const PersonImageAndButtons = ({ index, person, image, buttons }: { index: number; person: IPersonInfo; image?: IPersonImage; buttons: ILinkButton[] }): JSX.Element => {
  return (
    <Stack>
      <Stack sx={{ pl: 1 }}>
        <Typography variant="body1" sx={{ pt: 1 }} paragraph={true}>
          <strong>
            {index + 1} - {person.name}
          </strong>
        </Typography>
        <Typography variant="body1" paragraph={true}>
          {person.description}
        </Typography>
      </Stack>
      <Stack sx={{ pl: 1, pb: 1, pt: 1, justifyContent: 'center', alignItems: 'center' }}>
        {image && <PersonImage personImportName={image.personImportName} altText={image.altText} key={image.personImportName} />}
        <Stack sx={{ pt: 2 }} direction="row" spacing={2}>
          <>
            {buttons.map((button: ILinkButton) => (
              <LinkButton label={button.label} url={button.url} key={button.url} />
            ))}
          </>
        </Stack>
      </Stack>
    </Stack>
  );
};

export const ListOfPeople = ({ people }: IPeopleProps): JSX.Element => {
  return (
    <>
      {people.map((person: IPersonProps, index: number) => (
        <PersonImageAndButtons index={index} person={person.info} image={person.image} buttons={person.buttons} key={person.info.name} />
      ))}
    </>
  );
};
