import React from 'react';

import { Stack, Box, Typography } from '@mui/material';
import { TextListAccordion } from '../TextListAccordion';
import { NavigationButtonBack } from '../NavigationButtonBack';
import { NavigationButtonNext } from '../NavigationButtonNext';

import ParaQuemLidera from './images/para-quem-lidera.svg';

export const ForWomenLeaders = () => {
  const womenTextItems = [
    {
      title: 'Remunere homens e mulheres de forma igualitária. ',
      description:
        'Algumas vezes a desigualdade salarial é histórica na empresa. Mas, talvez, na sua liderança essa realidade pode ser alterada. Ajude a propagar a importância dessa igualdade. Isso ajuda a encontrar ótimos talentos também. ',
    },
    {
      title: ' Mulheres que se tornam mães continuam boas profissionais.',
      description:
        'É comum que essas mulheres sejam demitidas quando retornam da licença maternidade, infelizmente. Isso acontece, normalmente, porque a empresa acredita que elas não darão conta do trabalho, mas elas continuam ótimas profissionais, ajude a combater essa visão por parte da diretoria.',
    },
    {
      title: 'Ajude mulheres que estão começando.',
      description:
        'Pra ajudar é importante confiar, dar oportunidade de crescimento, reconhecer e validar as ideias dessas mulheres. Essa é uma forma de criar relações a longo prazo com seus talentos.',
    },
    {
      title: 'Converse com diferentes mulheres pra dar o suporte que elas precisam.',
      description:
        'Cada mulher é única e o suporte a elas vai se adaptar ao grupo minoritário que participam. Uma mulher negra enfrenta desafios diferentes de uma mulher LGBTQIA+, por exemplo. É importante que a empresa esteja preparada pra isso.',
    },
    {
      title: 'Para mulheres que lideram outras mulheres.',
      description:
        'Se você é uma mulher que lidera outras mulheres lembre-se que você e suas lideradas compartilham algumas experiências em comum por serem mulheres. Aproveite isso pra  mostrar empatia, ouvir as necessidades delas e ajudar com as dificuldades.',
    },
    {
      title: 'Promova encontros frequentes de formação sobre assédio no trabalho.',
      description:
        'Nem todos têm conhecimento sobre o que é assédio no trabalho e que  ele pode ser moral ou sexual. É importante que a empresa ofereça oportunidades de formação sobre o que é assédio, tanto pra que as pessoas sejam educadas quanto para que vítimas consigam identificar e denunciar.',
    },
  ];
  return (
    <Stack sx={{ pt: 2 }}>
      <Box sx={{ pl: 1, pb: 1 }}>
        <Typography variant="h5" color="primary" component="h2">
          Para líderes de mulheres. 🤝
        </Typography>
        <Typography variant="body2">Comportamentos que podem incentivar suas lideradas.</Typography>
        <Stack sx={{ my: '40px', justifyContent: 'center', alignItems: 'center' }}>
          <img
            src={ParaQuemLidera}
            aria-hidden="true"
            alt="Ilustração de uma pessoa mulher líder apresentando informações em um monitor."
            style={{ maxWidth: '100%', width: '430px', height: '300px' }}
          />
        </Stack>
      </Box>
      <Box sx={{ pl: 1, pb: 1 }}>
        <TextListAccordion items={womenTextItems} />
      </Box>
      <Box sx={{ pt: 2, my: '30px' }}>
        <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <NavigationButtonBack label="Voltar" />
          <NavigationButtonNext label="Próximo Tópico" route="/diversidade/mulheres/conquistas-incriveis" />
        </Stack>
      </Box>
    </Stack>
  );
};
