import React from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import { TopToolbar } from '../TopToolbar';
import { AppUserContext } from '../../config/AppUserContext';
import { useGetDataProfile, UserProfileData } from '../../hooks/queries';
import { Loading } from '../Loading';
import { setSessionUserData } from '../../utils/sessionUser';
import { createAppThemeFromUser } from '../../utils/createAppThemeFromUser';

export const LayoutBlocksHeaderComponent = () => {
  const { data: profile, isLoading } = useGetDataProfile();
  const userProfileData = profile?.data?.data as UserProfileData;

  if (isLoading) {
    return <Loading />;
  }

  setSessionUserData(userProfileData);

  const theme = createAppThemeFromUser(userProfileData);

  return (
    <AppUserContext.Provider value={userProfileData}>
      <ThemeProvider theme={theme}>
        <Container
          disableGutters
          sx={{
            minHeight: '90vh',
          }}
        >
          <TopToolbar showBackButton={false} logoUrl={userProfileData?.company?.logo_url} logoName={userProfileData?.company?.name} />
          <Container>
            <Outlet />
          </Container>
        </Container>
      </ThemeProvider>
    </AppUserContext.Provider>
  );
};
