import { useQuery } from 'react-query';

import { QueryKeys } from '../../config/QueryKeys';
import { getFormDataPreview } from '../../services/api/form-data';

export const useGetDataFormPreview = (journeyId: string, stepId: string) => {
  return useQuery(QueryKeys.getFormsPreview, () => getFormDataPreview(journeyId, stepId), {
    refetchOnWindowFocus: false,
    retry: false,
  });
};
